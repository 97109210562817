import React, { useState } from 'react'
import cx from 'classnames'
import isMobile from 'ismobilejs'

import styles from '../auth-page.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconForward } from '@consta/uikit/IconForward'
import { IconBackward } from '@consta/uikit/IconBackward'

import { SelectList } from 'src/components/'

import { SELECT_COMPONENT_CHILDREN_PROPS } from 'src/config'

import { ISelectListOption } from 'src/interfaces'

const SelectStep = (props: SELECT_COMPONENT_CHILDREN_PROPS) => {
  const {
    selected,
    setSelected,
    handleSubmit,
    options,
    back = false,
    backFunc = () => false,
  } = props

  const handleSelected = (value: ISelectListOption): void => {
    if (value === selected) {
      setSelected(null)
    } else {
      setSelected(value)
    }
  }

  return (
    <div
      className={styles.datebaseWrap}
      style={{
        height: isMobile().any
          ? `calc(${window.innerHeight}px - 190px)`
          : 'auto',
      }}
    >
      <div className={styles.datebaseSelectWrap}>
        <SelectList
          value={selected}
          options={options}
          onChange={(value) => handleSelected(value)}
          className={styles.selectList}
        />
      </div>
      <div className={cx(styles.btn, back ? styles.btnGroup : null)}>
        <div>
          {back ? (
            <Button
              label="Назад"
              size="l"
              view="secondary"
              iconLeft={IconBackward}
              onClick={() => backFunc()}
            />
          ) : null}
        </div>
        <Button
          className={styles.btnContinue}
          label="Продолжить"
          iconRight={IconForward}
          onClick={() => handleSubmit()}
          disabled={!selected}
          size="l"
        />
      </div>
    </div>
  )
}

export default SelectStep
