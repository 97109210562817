import { createContext, useContext, useEffect } from 'react'
import { useSound } from 'use-sound'

const SOUND_NAMES = [
  'PACKING_ITEM_ADDED_TO_PLACE',
  'PACKING_WRONG_ITEM',
  'PACKING_SURPLUS_ITEM',
  'PACKING_PLACE_POSTED',
  'PACKING_COMPLETED',
  'PACKING_KIT_ASSEMBLE',
  'PACKING_WEIGHT_ITEM',
  'RECEIVING_SURPLUS_ITEM',
  'RECEIVING_WEIGHT_ITEM',
  'PACKING_PLACE_WEIGHT',
  'OTHER_ERROR',
]

interface ISoundContext {
  play: (id: string) => void
}

export const SoundContext = createContext<ISoundContext | undefined>(undefined)

export const useSoundHook = () => {
  const context = useContext(SoundContext)
  return context
}

export const SoundProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  useEffect(() => {
    window.soundPlay = play //TODO better
  }, [])

  const soundObj: { [key: string]: { play: () => void } } = {}

  for (let i = 0; i < SOUND_NAMES.length; i++) {
    const name = SOUND_NAMES[i]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [play] = useSound(`/sounds/${name}.mp3`)
    soundObj[name] = { play }
  }

  const play = (name: string) => soundObj[name].play()

  return (
    <SoundContext.Provider value={{ play }}>{children}</SoundContext.Provider>
  )
}
