import { useQuery } from 'react-query'
import api from 'src/api'

import { provideMock, MOCK_SEARCH_ITEM } from 'src/mocks'

import { ApiReqResult, ISupplyOriginalItem } from 'src/interfaces' //TODO mb сделать универсальный интерфейс для Item?

interface UseSearchItemByBarcodeParams {
  merchantId: string
  itemId?: string
  barcode?: string
}

const getSearchedItemByBarcode = async (
  params: UseSearchItemByBarcodeParams,
) => {
  const url = `/items/${params.merchantId}/item/${
    typeof params.barcode !== 'undefined'
      ? 'barcode/' + params.barcode
      : params.itemId
  }`
  const result = await api.get(url)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface SearchItemByBarcodeResult extends ApiReqResult {
  item: ISupplyOriginalItem
}

const useSearchItemByBarcode = () => ({
  fetch: (params: UseSearchItemByBarcodeParams) =>
    provideMock<UseSearchItemByBarcodeParams, SearchItemByBarcodeResult>(
      getSearchedItemByBarcode,
      params,
      {
        key: 'barcode',
        value: 'mock3',
        result: MOCK_SEARCH_ITEM,
      },
    )(),
})

export default useSearchItemByBarcode
