import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import isMobile from 'ismobilejs'
import api from 'src/api'

import { appLocalStorage } from 'src/libs'

import styles from './auth-page.module.scss'

import { Steps } from '@consta/uikit/Steps'

import { SelectStep, EmployeeStep, WorkplaceStep } from './Steps'
import MobileSteps from './MobileSteps'

import { EnterHandler } from 'src/components'

import { useAppContext } from 'src/context'

import { useLogin } from 'src/hooks'

import { rusToLatin } from 'src/helpers'

import { AUTH_STEPS, STEP, STEP_TYPES_OBJECT, PATHS } from 'src/config'

import { ISelectListOption } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

const dateBaseOptions: ISelectListOption[] = [
  { label: 'Общая база', value: 'api-wms-prod.tempoline.ru' },
  { label: 'Тестовая среда', value: 'api-wms-test.tempoline.ru' },
  { label: 'Летик', value: 'api-wms-letique.tempoline.ru' },
  { label: 'Мерлион', value: 'api-wms-merlion.tempoline.ru' },
]

const packageOptions: ISelectListOption[] = [
  {
    label: 'Упаковка товара',
    value: 'packaging',
  },
  {
    label: 'Приемка товара',
    value: 'acceptance',
  },
  {
    label: 'Сверка реестра',
    value: 'RegistryReconciliation',
  },
]

type authComponentObject = {
  [key: string]: object
}

interface AuthStepProps {
  type: string
  props: any //TODO better
}

const StepTypes: STEP_TYPES_OBJECT = {
  databaseStep: SelectStep,
  packageStep: SelectStep,
  employeeStep: EmployeeStep,
  workplaceStep: WorkplaceStep,
}

const AuthStep = ({ type, props }: AuthStepProps) => {
  let Component: any = StepTypes[type]

  return (
    <EnterHandler onEnter={props?.handleSubmit ?? (() => false)}>
      <Component {...props} />
    </EnterHandler>
  )
}

const AuthPage = () => {
  const { setCurrentUser, setGlobalLoading } = useAppContext()

  const [steps, setSteps] = useState<STEP[]>(AUTH_STEPS)
  const [activeStep, setActiveStep] = useState<STEP>(AUTH_STEPS[0])
  const [selectedBase, setSelectedBase] = useState<ISelectListOption | null>(
    null,
  )
  const [selectedPackage, setSelectedPackage] =
    useState<ISelectListOption | null>(null)
  const [employeeBarcode, setEmployeeBarcode] = useState<TextFieldPropValue>('')
  const [workplaceBarcode, setWorkplaceBarcode] =
    useState<TextFieldPropValue>('')
  const [error, setError] = useState<{ [key: string]: string }>({
    workplaceError: '',
  })

  const history = useHistory()

  const loginMutation = useLogin()

  useEffect(() => {
    const newStep: STEP | undefined = steps.find((s) => !s.completed)
    setActiveStep(newStep || steps[0])
  }, [steps])

  const handleSubmitBase = (): boolean | void => {
    if (!selectedBase) return false

    appLocalStorage.currentBase = selectedBase?.value ?? ''
    api.defaults.baseURL = `https://${selectedBase.value}`

    const newSteps: STEP[] = steps.map((step) => {
      if (step.value === 'databaseStep') {
        return {
          ...step,
          completed: true,
        }
      } else {
        return step
      }
    })
    setSteps(newSteps)
  }

  const handleSubmitPackage = (): boolean | void => {
    if (!selectedPackage) return false

    appLocalStorage.currentOperation = selectedPackage?.value ?? ''
    const newSteps: STEP[] = steps.map((step) => {
      if (step.value === 'packageStep') {
        return {
          ...step,
          completed: true,
        }
      } else {
        return step
      }
    })
    setSteps(newSteps)
  }

  const handleBackPackage = (): void => {
    if (selectedPackage) {
      setSelectedPackage(null)
    }
    const newSteps: STEP[] = steps.map((step) => {
      if (step.value === 'databaseStep') {
        return {
          ...step,
          completed: false,
        }
      } else {
        return step
      }
    })
    setSteps(newSteps)
  }

  const handleChangeEmployee = (value: TextFieldPropValue): boolean | void => {
    if (value === '') return false
    let newVal = rusToLatin(value)

    setEmployeeBarcode(newVal)

    const newSteps: STEP[] = steps.map((step) => {
      if (step.value === 'employeeStep') {
        return {
          ...step,
          completed: true,
        }
      } else {
        return step
      }
    })
    setSteps(newSteps)
  }

  const handleChangeWorkplace = async (value: TextFieldPropValue) => {
    if (value === '') return false
    let newVal = rusToLatin(value)

    setWorkplaceBarcode(newVal)

    if (newVal && !/(.+)-(.+)/i.test(newVal)) {
      setError({
        ...error,
        workplaceError: 'некорректный штрихкод рабочего стола',
      })
      return false
    }

    setError({
      ...error,
      workplaceError: '',
    })

    const newSteps: STEP[] = steps.map((step) => {
      if (step.value === 'workplaceStep') {
        return {
          ...step,
          completed: true,
        }
      } else {
        return step
      }
    })
    setSteps(newSteps)

    const workTable = String(newVal)

    setGlobalLoading(true)
    await loginMutation
      .mutateAsync({
        auth_key: String(employeeBarcode),
        work_table: workTable,
      })
      .then(({ data }) => {
        setCurrentUser({ workTable, username: data.username })

        switch (selectedPackage?.value) {
          case 'packaging':
            history.push(PATHS.ORDER_SCAN)
            break

          case 'acceptance':
            history.push(PATHS.ACCEPTANCE_SCAN)
            break

          case 'RegistryReconciliation':
            history.push(PATHS.REGISTRY_SCAN)
            break

          default:
            break
        }
      })
      .catch(() => {
        appLocalStorage.clearData('currentBase')
        appLocalStorage.clearData('currentOperation')
        api.defaults.baseURL = ''
        setSelectedBase(null)
        setSelectedPackage(null)
        setEmployeeBarcode('')
        setWorkplaceBarcode('')

        const newSteps = steps.map((step) => {
          return {
            ...step,
            completed: false,
          }
        })
        setSteps(newSteps)
      })
      .finally(() => {
        setGlobalLoading(false)
      })
  }

  const onChangeStep = (props: { e: React.MouseEvent; value: STEP | null }) => {
    const { value } = props
    if (!value || !value.completed) return false
    const stepIndex = steps.findIndex((step) => step.value === value.value)
    const newSteps = steps.map((step, i) => {
      if (i >= stepIndex) {
        return {
          ...step,
          completed: false,
        }
      } else {
        return step
      }
    })

    steps.forEach((step, i) => {
      if (i >= stepIndex) {
        if (step.value === 'databaseStep') {
          appLocalStorage.clearData('currentBase')
          api.defaults.baseURL = ''
          setSelectedBase(null)
        }
        if (step.value === 'packageStep') {
          appLocalStorage.clearData('currentOperation')
          setSelectedPackage(null)
        }
        if (step.value === 'employeeStep') {
          setEmployeeBarcode('')
        }
        if (step.value === 'workplaceStep') {
          setWorkplaceBarcode('')
        }
      }
    })

    setSteps(newSteps)
  }

  const props: authComponentObject = {
    databaseStep: {
      selected: selectedBase,
      setSelected: setSelectedBase,
      handleSubmit: handleSubmitBase,
      options: dateBaseOptions,
    },
    packageStep: {
      selected: selectedPackage,
      setSelected: setSelectedPackage,
      handleSubmit: handleSubmitPackage,
      options: packageOptions,
      back: true,
      backFunc: handleBackPackage,
    },
    employeeStep: {
      employeeBarcode,
      handleChange: handleChangeEmployee,
    },
    workplaceStep: {
      workplaceBarcode,
      setWorkplaceBarcode,
      handleChange: handleChangeWorkplace,
      error: error.workplaceError,
    },
  }

  return (
    <>
      <div className={styles.wrap}>
        <div
          className={styles.main}
          style={{ height: isMobile().any ? window.innerHeight : 'auto' }}
        >
          <div className={styles.top}>
            <h3 className={styles.title}>Начало работы</h3>
            <h5 className={styles.subtitle}>Добро пожаловать!</h5>
          </div>

          {isMobile().any ? (
            <MobileSteps
              steps={steps}
              activeStep={activeStep}
              onChangeStep={onChangeStep}
            />
          ) : (
            <div className={styles.steps}>
              <Steps
                items={steps}
                size="l"
                getLabel={(item) => item.label}
                getCompleted={(item) => item.completed || false}
                value={activeStep}
                onChange={onChangeStep}
                className={styles.stepsWrap}
              />
            </div>
          )}
          <AuthStep type={activeStep.value} props={props[activeStep.value]} />
        </div>
      </div>
    </>
  )
}

export default AuthPage
