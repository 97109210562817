import React from 'react'
import cx from 'classnames'

import styles from './scan-wrapper.module.scss'

import ScanHeader from '../ScanHeader'

declare const ScanWrapperPropSize: readonly ['s', 'm', 'l']
declare type ScanWrapperPropSize = typeof ScanWrapperPropSize[number]

interface ScanWrapperProps {
  className?: string
  children?: React.ReactNode
  title?: string
  subtitle?: string
  size?: ScanWrapperPropSize
}

const ScanWrapper = (props: ScanWrapperProps) => {
  const { title, subtitle, size, className } = props

  return (
    <div className={styles.wrap}>
      <div
        className={cx(styles.main, className, size ? styles[size] : styles.m)}
      >
        <ScanHeader title={title} subtitle={subtitle} />
        {props.children}
      </div>
    </div>
  )
}

export default ScanWrapper
