import api from 'src/api'

import { provideMock, MOCK_GET_STICKER_BY_PALLET } from 'src/mocks'

import { StickerResult } from 'src/interfaces'

interface useGetWBBarcodeParams {
  orderId: string
}

const getWBBarcode = async (params: useGetWBBarcodeParams) => {
  const url = `/packing/order/${params.orderId}/fbo/sticker/shipment`
  const result = await api.get(url)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const useGetWBBarcode = () => ({
  fetch: (params: useGetWBBarcodeParams) =>
    provideMock<useGetWBBarcodeParams, StickerResult>(getWBBarcode, params, [
      {
        key: 'orderId',
        value: 'mock',
        result: MOCK_GET_STICKER_BY_PALLET,
      },
    ])(),
})

export default useGetWBBarcode
