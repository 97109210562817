import React from 'react'
import cx from 'classnames'

import styles from './pallets-packed-table.module.scss'

import IconPrinter from './assets/IconPrinter.svg'

import { IconAdd } from '@consta/uikit/IconAdd'
import { IconRemove } from '@consta/uikit/IconRemove'
import { Badge } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'

import PalletsPackedTableCollapse from './PalletsPackedTableCollapse'
import PlaceItem from './PalletsPackedTableItem'

import { useGetStickerByPlace, useGetStickerByPallet } from 'src/hooks'

import { usePackingOrderContext, usePackingPrintContext } from '../../context'
import { useAppContext } from 'src/context'

import { getPlacesString, getPackageTitle } from 'src/helpers'

import {
  IOrder,
  IOrderPackagingPlace,
  IOrderPallet,
  IOrderPackagingPlaceItem,
} from 'src/interfaces'

interface IPalletsPackedTable {
  order: IOrder | null
  pallets: IOrderPallet[]
  currentPallet: number | null
  currentPlace: number | null
  showPacked?: () => void
  withTitles?: boolean
  movePlace?: (placeId?: string, palletId?: string) => void
  handlePrintItemSticker?: (
    id: string,
    barcode: string,
    expires?: string,
    dateOfManufacture?: string,
    advanced?: boolean,
  ) => void
}

const PalletsPackedTable = ({
  order,
  pallets,
  showPacked,
  movePlace,
  handlePrintItemSticker,
  withTitles = true,
}: IPalletsPackedTable) => {
  const { advanceMode, performWithAdvance } = useAppContext()
  const { packages, setCurrentOperation } = usePackingOrderContext()
  const { printSticker } = usePackingPrintContext()

  const getPlaceSticker = useGetStickerByPlace()
  const getPalletSticker = useGetStickerByPallet()

  const printPlaceSticker = async (id: string) => {
    if (!order?.id) return

    await getPlaceSticker
      .fetch({ placeId: id, orderId: order.id })
      .then((result) => {
        if (result.success) {
          const { sticker } = result
          printSticker(sticker.printer, sticker.content, {
            copies: 1,
            needShowPrintStickerInfo: true,
            needFocusAfterPrint: true,
          })
        }
      })
  }

  const printPalletSticker = async (id: string) => {
    if (!order?.id) return

    await getPalletSticker.fetch({ pallet_id: id }).then((result) => {
      if (result.success) {
        const { sticker } = result

        printSticker(sticker.printer, sticker.content, {
          copies: 1,
          needShowPrintStickerInfo: true,
          needFocusAfterPrint: true,
        })
      }
    })
  }

  const handlePrintSticker = async (type: 'place' | 'pallet', id?: string) => {
    if (!advanceMode) {
      performWithAdvance(() => () => {
        if (id && order?.id) {
          if (type === 'place') {
            setCurrentOperation('placeClosing')
            printPlaceSticker(id)
            return
          }
          setCurrentOperation('palletClosing')
          printPalletSticker(id)
        }
      })
    } else {
      if (id && order?.id) {
        if (type === 'place') {
          setCurrentOperation('placeClosing')
          printPlaceSticker(id)
          return
        }
        setCurrentOperation('palletClosing')
        printPalletSticker(id)
      }
    }
  }

  const getPalletFormattedPlaces = (places: IOrderPackagingPlace[]) =>
    places
      .slice()
      .reverse()
      .map((place: IOrderPackagingPlace) => ({
        ...place,
        items: place.items.reduce(
          (
            a: IOrderPackagingPlaceItem[],
            c: IOrderPackagingPlaceItem,
            i: number,
            arr: IOrderPackagingPlaceItem[],
          ) => {
            const findCurrentPlaceItem = a.find(
              (ci: IOrderPackagingPlaceItem) =>
                ci.id === c.id &&
                ci.packagings?.[0]?.packaging_id ===
                  c.packagings?.[0]?.packaging_id &&
                ci.expirationDate === c.expirationDate,
            )
            if (findCurrentPlaceItem) {
              findCurrentPlaceItem.quantity =
                findCurrentPlaceItem.quantity + c.quantity
            } else {
              a.push({ ...c })
            }

            return a
          },
          [] as IOrderPackagingPlaceItem[],
        ),
      }))

  return (
    <div className={styles.acceptedTable}>
      {withTitles ? (
        <div className={styles.titles}>
          <h4>Упаковано</h4>
          {/*<Button*/}
          {/*  label="Посмотреть полностью"*/}
          {/*  view="secondary"*/}
          {/*  onClick={showPacked}*/}
          {/*/>*/}
        </div>
      ) : null}
      <div>
        <div className={styles.tableHead}>
          <div>Паллета</div>
          <div>
            Номенклатура <span>Штрихкод товара</span>
          </div>
          <div>Срок годности</div>
          <div>Кол-во</div>
          <div></div>
        </div>
        <div className={styles.tableBody}>
          {pallets?.length ? (
            <>
              {pallets
                .slice()
                .reverse()
                .map((pallet) => (
                  <>
                    {pallet.places?.length ? (
                      <div className={styles.pallet}>
                        <div className={styles.palletInfoWrap}>
                          <div className={styles.palletInfo}>
                            <h3 className={styles.title}>
                              Паллета {pallet.pallet_num}
                            </h3>
                            <div className={styles.info}>
                              {pallet.id && (
                                <span className={styles.barcode}>
                                  {pallet.id}
                                </span>
                              )}
                              {!pallet?.id ? (
                                <div
                                  className={cx(
                                    styles.badgePallet,
                                    styles.badgeBlue,
                                  )}
                                >
                                  Открыто
                                </div>
                              ) : (
                                <div
                                  className={cx(
                                    styles.badgePallet,
                                    styles.badgeGreen,
                                  )}
                                >
                                  Закрыто
                                </div>
                              )}
                              {pallet?.id && handlePrintSticker ? (
                                <img
                                  className={styles.palletPrint}
                                  src={IconPrinter}
                                  alt="IconPrinter"
                                  onClick={() =>
                                    handlePrintSticker('pallet', pallet.id)
                                  }
                                />
                              ) : null}
                            </div>
                            <div className={styles.length}>
                              {getPlacesString(pallet?.places?.length ?? 1)}
                            </div>
                          </div>
                        </div>
                        {pallet.places.length ? (
                          <div className={styles.collapseWrap}>
                            {getPalletFormattedPlaces(pallet.places).map(
                              (place, i) => (
                                <PalletsPackedTableCollapse
                                  index={i}
                                  label=""
                                  icon={IconAdd}
                                  closeIcon={IconRemove}
                                  className={cx(styles.collapse)}
                                  rightSide={
                                    <div className={styles.rightSide}>
                                      <div className={styles.status}>
                                        <div className={styles.placeWrap}>
                                          {place?.id ? (
                                            <img
                                              className={styles.print}
                                              src={IconPrinter}
                                              alt="IconPrinter"
                                              onClick={() =>
                                                handlePrintSticker(
                                                  'place',
                                                  place.id,
                                                )
                                              }
                                            />
                                          ) : null}

                                          {!place?.id ? (
                                            <Badge
                                              form="round"
                                              label="Открыто"
                                              className={styles.badge}
                                              size="m"
                                            />
                                          ) : (
                                            <Badge
                                              form="round"
                                              label="Закрыто"
                                              className={styles.badge}
                                              size="m"
                                              status="success"
                                            />
                                          )}
                                          <span className={styles.num}>
                                            Место {place.sequence_num}
                                          </span>
                                          <span className={styles.id}>
                                            {place?.id}
                                          </span>
                                          <span className={styles.light}>
                                            {getPackageTitle(place, packages)}
                                          </span>
                                        </div>
                                      </div>
                                      <div className={styles.move}>
                                        {movePlace && place?.id ? (
                                          <Button
                                            label="Переместить"
                                            view="secondary"
                                            size="s"
                                            onClick={() =>
                                              movePlace(place?.id, pallet?.id)
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div
                                        className={styles.quantity}
                                      >{`${place.items.reduce(
                                        (a, c) => a + c.quantity,
                                        0,
                                      )} шт.`}</div>
                                    </div>
                                  }
                                >
                                  <>
                                    {place.items.length ? (
                                      <>
                                        {place.items.map((item, i) => (
                                          <PlaceItem
                                            key={`${item.id}-${i}`}
                                            status={!!place?.id}
                                            item={item}
                                            index={place.items.length - i}
                                            originalItem={
                                              order?.items.find(
                                                (originalItem) =>
                                                  originalItem.id === item.id,
                                              ) ?? null
                                            }
                                            placeNum={+place.sequence_num}
                                            needSticker={
                                              order?.stickerTask ?? false
                                            }
                                            handlePrintItemSticker={
                                              handlePrintItemSticker
                                            }
                                          />
                                        ))}
                                      </>
                                    ) : null}
                                  </>
                                </PalletsPackedTableCollapse>
                              ),
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PalletsPackedTable
