import React, { useEffect, useState } from 'react'
import days from 'dayjs'
import cx from 'classnames'

import styles from './discrepancies-modal.module.scss'

import { Modal, Img, Table } from 'src/components'

import {
  ModalProps,
  ISupply,
  IDiscrepanciesColumn,
  IDiscrepanciesRowItem,
  ISupplyItem,
  ISupplyPartItem,
  ISupplyPart,
  ISupplyOriginalItem,
} from 'src/interfaces'
import { Switch } from '@consta/uikit/Switch'

interface IDiscrepanciesModal extends ModalProps {
  cacheItems?: ISupplyOriginalItem[]
  planItems: ISupplyItem[]
  tares: ISupplyPart[]
  supplyId?: string
}

const DiscrepanciesModal = (props: IDiscrepanciesModal) => {
  const { isOpen, onClose, planItems, cacheItems, tares, supplyId } = props

  const [onlyDiscrepancies, setOnlyDiscrepancies] = useState<boolean>(false)

  const getGoodOrDefectiveItems = (
    tares: ISupplyPart[],
    item: ISupplyItem,
    condition: 'good' | 'defective' = 'good',
  ) => {
    return tares.reduce((acc: number, tare: ISupplyPart) => {
      if (tare.condition === condition) {
        const findItemCount = tare?.items?.reduce(
          (acc: number, ti: ISupplyPartItem) => {
            return ti.id === item.id && ti.pack_id === item.pack_id
              ? acc + ti.quantity
              : acc
          },
          0,
        )
        return acc + findItemCount
      }
      return acc
    }, 0)
  }

  const discrepanciesData =
    planItems?.map((item: ISupplyItem) => {
      const originItem = cacheItems?.find((curItem) => curItem.id === item.id)

      if (!originItem)
        return {
          id: item.id,
          article: '',
          title: '',
          image: '',
          barcode: '',
          plan: 0,
          fact: 0,
          defect: 0,
          shortages: 0,
          surplus: 0,
        }

      const fact = getGoodOrDefectiveItems(tares, item)
      const defective = getGoodOrDefectiveItems(tares, item, 'defective')
      const planQuantity =
        planItems.find(
          (curItem) =>
            curItem.id === item.id && curItem.pack_id === item.pack_id,
        )?.quantity || 0

      return {
        id: item.id,
        article: originItem.article,
        title: originItem.title,
        image: originItem.image,
        barcode:
          originItem.barcodes.find(
            (barcode) => barcode.pack_id === item.pack_id,
          )?.barcode ?? '',
        plan: planQuantity,
        fact: fact,
        defect: defective,
        shortages:
          planQuantity - fact - defective > 0
            ? planQuantity - fact - defective
            : 0,
        surplus:
          fact + defective - planQuantity > 0
            ? fact + defective - planQuantity
            : 0,
      }
    }) || []

  const getOnlyDiscrepancies = (data: IDiscrepanciesRowItem[]) => {
    return data.filter(
      (item) => !(item.fact === item.plan && !item.defect && !item.shortages),
    )
  }

  const curTableData = !onlyDiscrepancies
    ? discrepanciesData
    : getOnlyDiscrepancies(discrepanciesData)

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.discrepancies}
      withClose
      onClose={onClose}
    >
      <div className={styles.top}>
        Отчет о приемке заказа {supplyId}
        <span className={styles.date}>
          от {days().format('DD.MM.YY HH:mm')}
        </span>
        <div className={styles.switch}>
          <Switch
            size="l"
            checked={onlyDiscrepancies}
            onChange={({ e, checked }) => setOnlyDiscrepancies(checked)}
            label="Только расхождения"
          />
        </div>
      </div>
      <div className={styles.tableWrap}>
        {curTableData.length ? (
          <Table theme="blue" stickyHeader>
            {curTableData.map((row, i) => (
              <Table.Row key={`${row.id}-${i}`}>
                <Table.Column title="Фото" className={styles.tdImg}>
                  <Img
                    src={row.image}
                    className={styles.image}
                    size={100}
                    yandex
                  />
                </Table.Column>
                <Table.Column
                  title={
                    <div className={styles.tableHead}>
                      Номенклатура
                      <span className={styles.art}>Артикул</span>
                      <span className={styles.barcode}> Штрихкод товара</span>
                    </div>
                  }
                  className={styles.column}
                >
                  {row.title}
                  <span className={styles.blue}>{row.article}</span>
                  <span className={styles.light}>{row.barcode}</span>
                </Table.Column>
                <Table.Column
                  title={
                    <div className={styles.tableHead}>
                      План <div className={styles.light}>шт.</div>
                    </div>
                  }
                  className={styles.bolder}
                >
                  {row.plan ? row.plan : null}
                </Table.Column>
                <Table.Column
                  title={
                    <div className={styles.tableHead}>
                      Факт <div className={styles.light}>шт.</div>
                    </div>
                  }
                  className={styles.bolder}
                >
                  {row.fact ? row.fact : null}
                </Table.Column>
                <Table.Column
                  title={
                    <div className={cx(styles.tableHead, styles.red)}>
                      Брак
                      <div className={styles.light}>шт.</div>
                    </div>
                  }
                  className={styles.red}
                >
                  {row.defect ? row.defect : null}
                </Table.Column>
                <Table.Column
                  title={
                    <div className={cx(styles.tableHead, styles.yellow)}>
                      Недостачи <div className={styles.light}>шт.</div>
                    </div>
                  }
                  className={styles.yellow}
                >
                  {row.shortages ? row.shortages : null}
                </Table.Column>
                <Table.Column
                  title={
                    <div className={cx(styles.tableHead, styles.blue)}>
                      Излишки <div className={styles.light}>шт.</div>
                    </div>
                  }
                  className={styles.blue}
                >
                  {row.surplus ? row.surplus : null}
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        ) : (
          <Table theme="blue" emptyRow="Расхождений нет">
            <Table.Row key={123456789}>
              <Table.Column
                title="Фото"
                className={styles.tdImg}
              ></Table.Column>
              <Table.Column
                title={
                  <div className={styles.tableHead}>
                    Номенклатура
                    <span className={styles.art}>Артикул</span>
                    <span className={styles.barcode}> Штрихкод товара</span>
                  </div>
                }
                className={styles.column}
              ></Table.Column>
              <Table.Column
                title={
                  <div className={styles.tableHead}>
                    План <div className={styles.light}>шт.</div>
                  </div>
                }
                className={styles.bolder}
              ></Table.Column>
              <Table.Column
                title={
                  <div className={styles.tableHead}>
                    Факт <div className={styles.light}>шт.</div>
                  </div>
                }
                className={styles.bolder}
              ></Table.Column>
              <Table.Column
                title={
                  <div className={cx(styles.tableHead, styles.red)}>
                    Брак
                    <div className={styles.light}>шт.</div>
                  </div>
                }
                className={styles.red}
              ></Table.Column>
              <Table.Column
                title={
                  <div className={cx(styles.tableHead, styles.yellow)}>
                    Недостачи <div className={styles.light}>шт.</div>
                  </div>
                }
                className={styles.yellow}
              ></Table.Column>
              <Table.Column
                title={
                  <div className={cx(styles.tableHead, styles.blue)}>
                    Излишки <div className={styles.light}>шт.</div>
                  </div>
                }
                className={styles.blue}
              ></Table.Column>
            </Table.Row>
          </Table>
        )}
      </div>
    </Modal>
  )
}

export default DiscrepanciesModal
