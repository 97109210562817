import React, { useState } from 'react'
import cx from 'classnames'

import styles from './choice-package-modal.module.scss'

import img from './assets/package.svg'

import { Button } from '@consta/uikit/Button'
import { IconList } from '@consta/uikit/IconList'

import { Modal, InputWrapper } from 'src/components'

import { useNotifications } from 'src/notification'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { ModalProps, IOrderPackagingOption, IDimensions } from 'src/interfaces'

import { rusToLatin } from 'src/helpers'

interface ChoicePackageModalProps extends ModalProps {
  packagingOptions: IOrderPackagingOption[]
  showManual: () => void
  showOversized: () => void
  onBack: () => void //TODO remove onBack
  onSubmit: (packageId: string, options: { dimensions?: IDimensions }) => void
}

const ChoicePackageModal = (props: ChoicePackageModalProps) => {
  const {
    isOpen,
    packagingOptions,
    showManual,
    showOversized,
    onSubmit,
    onClose,
  } = props

  const notification = useNotifications()

  const [barcodePackage, setBarcodePackage] = useState<TextFieldPropValue>('')

  const handleChange = (value: TextFieldPropValue) => {
    let newVal = rusToLatin(value)
    const currentOption = packagingOptions.find((o: IOrderPackagingOption) =>
      o.barcodes.includes(String(newVal)),
    )

    if (currentOption) {
      onSubmit(currentOption.id, {})
    } else {
      notification?.show('alert', `Упаковка cо штрихкодом ${newVal} не найдена`)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.choice}
      title="Выбор упаковки"
      subtitle="Отсканируйте штрихкод упаковки"
      img={img}
      imgClass={styles.img}
      size="s"
    >
      <InputWrapper
        withDelay
        id={'barcodePackage'}
        autoFocus
        className={styles.itemInput}
        placeholder={'Штрих-код упаковки'}
        handleChange={handleChange}
        size="l"
      />

      <div className={styles.btns}>
        <Button
          label="Нестандартная упаковка"
          view="secondary"
          onClick={showOversized}
        />
        <Button
          label="Выбрать из списка"
          view="secondary"
          iconLeft={IconList}
          onClick={showManual}
        />
      </div>
    </Modal>
  )
}

export default ChoicePackageModal
