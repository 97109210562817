import React, { useEffect, useState } from 'react'

import styles from './weigth-dimensions-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import { Modal, InputWrapper, EnterHandler } from 'src/components'

import { useNotifications } from 'src/notification'

import { usePackingOrderContext } from '../../context'

import { ModalProps, ShippingOptionsType } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface ICountPalletModalPalletModal extends ModalProps {
  hardClose?: boolean
  onSubmit: (palletCount: string) => void
  title?: string
  subtitle?: string
  img?: string
  withClose?: boolean
}

const CountPalletModal = (props: ICountPalletModalPalletModal) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    onMount,
    title,
    subtitle,
    img,
    withClose,
  } = props

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  const [palletCount, setPalletCount] = useState<string | null>(null)

  const { order, places } = usePackingOrderContext()

  const isMonoPallet =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PALLETS
  const maxPalletWeight = order?.delivery?.maximum_pallet_weight

  const notification = useNotifications()

  const itemsCount = order?.items?.length ?? 0

  const placesWeight = places.reduce((acc, p) => acc + +(p?.weight ?? 0), 0)

  const handleSubmit = () => {
    if (!palletCount) {
      return false
    }

    if (places.length < +palletCount) {
      notification?.show(
        'alert',
        `Кол-во паллет не должно быть больше кол-ва мест`,
      )
      return
    }

    if (isMonoPallet && itemsCount > +palletCount) {
      notification?.show(
        'alert',
        `Монопаллеты. Кол-во паллет не должно быть меньше кол-ва артикулов товаров`,
      )
      return
    }

    if (
      +palletCount &&
      maxPalletWeight &&
      +palletCount < Math.ceil(placesWeight / maxPalletWeight)
    ) {
      notification?.show(
        'alert',
        `С учетом максимального веса паллет (${maxPalletWeight} г.). Минимальное кол-во паллет: ${Math.ceil(
          placesWeight / maxPalletWeight,
        )}`,
      )
      return
    }

    onSubmit(palletCount)
  }

  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.itemCount}
        withClose={withClose}
        onClose={onClose}
        title={title}
        subtitle={subtitle}
        img={img}
        imgClass={styles.palletImg}
        size="s"
      >
        <InputWrapper
          label={'Введите количество паллет'}
          value={palletCount}
          id={'palletCount'}
          handleChange={(value: TextFieldPropValue) => setPalletCount(value)}
          className={styles.itemInput}
          size="l"
          isInteger
          autoFocus
        />

        <div className={styles.btns}>
          <Button
            label="Подтвердить"
            className={styles.btn}
            onClick={handleSubmit}
            disabled={!palletCount}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default CountPalletModal
