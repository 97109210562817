import { usePackingOrderContext } from '../../context'

import { useNotifications } from 'src/notification'

import {
  IOrderPackagingPlace,
  IOrderItem,
  IOrderPackagingPlaceItem,
} from 'src/interfaces'

interface checkIsConditionsMaxCountCompletedRes {
  checkIsConditionsMaxCountCompleted: () => boolean
}

const useCheckIsConditionsMaxCountCompleted = () => {
  const { order, places } = usePackingOrderContext()

  const notification = useNotifications()

  const checkIsConditionsMaxCountCompleted = () => {
    const maxCount = order?.delivery?.maximum_places_count

    if (maxCount && places.length >= maxCount) {
      const placesItemsCount = places.reduce(
        (acc: number, cur: IOrderPackagingPlace) =>
          acc +
          cur.items.reduce(
            (acc: number, cur: IOrderPackagingPlaceItem) => acc + cur.quantity,
            0,
          ),
        0,
      )
      const itemsCount = order?.items.reduce(
        (acc: number, cur: IOrderItem) => acc + cur.quantity,
        0,
      )

      if (itemsCount !== placesItemsCount) {
        notification?.show(
          'alert',
          `Для данной службы доставки - максимальное количество мест ${maxCount}. Упакуйте весь товар в текущее место`,
        )
        return false
      }
    }
    return true
  }

  return {
    checkIsConditionsMaxCountCompleted,
  } as checkIsConditionsMaxCountCompletedRes
}

export default useCheckIsConditionsMaxCountCompleted
