import React from 'react'
import cx from 'classnames'

import styles from './sidebar.module.scss'

import { useAppContext } from 'src/context'
import { Badge } from '@consta/uikit/Badge'

import { AdvanceModeLock, UserBlock, AppVersion } from 'src/components'

interface ISideBar {
  children: React.ReactNode
  bottomBtns?: React.ReactNode
  btnsClass?: string
  withAdvance?: boolean
  badge?: React.ReactNode
}

const SideBar = (props: ISideBar) => {
  const { children, bottomBtns, btnsClass, withAdvance = true, badge } = props

  const { advanceMode } = useAppContext()

  return (
    <div className={styles.leftPanel}>
      <div>
        <div className={styles.top}>
          <UserBlock view="ghost" size="l" />
          <div className={styles.topRight}>
            {withAdvance ? (
              <>
                {advanceMode ? (
                  <Badge
                    form="round"
                    status="normal"
                    label="Расширенный"
                    className={styles.badge}
                  />
                ) : null}

                <AdvanceModeLock className={styles.lock} />
              </>
            ) : null}
            {badge}
          </div>
        </div>

        {children}
      </div>

      <div className={cx(styles.leftPanelBtn, btnsClass)}>
        {bottomBtns}
        <AppVersion className={styles.appVersion} />
      </div>
    </div>
  )
}

export default SideBar
