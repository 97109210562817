import React from 'react'

import styles from './b2b-relabeling-order.module.scss'

import { BoxMarkedInPallets } from '../components'

import {
  IOrderPackagingPlace,
  IOrderPackedPallet,
  IOrderPackagingOption,
  RelabelingTypes,
  IOrderPalletMarked,
} from 'src/interfaces'

interface IMainBlockProps {
  type: RelabelingTypes
  markedPlaces: IOrderPackagingPlace[]
  markedPallets: IOrderPackedPallet[]
  handlePrint: (itemId: string) => void
  packagingOptions: IOrderPackagingOption[]
  isAllPalletsFilled: boolean
  pallets: IOrderPalletMarked[]
  currentPallet: number | null
  showPacked: boolean
  setShowPacked: () => void
  setPallets: (pallets: IOrderPalletMarked[]) => void
}

const MainBlock = (props: IMainBlockProps) => {
  const { handlePrint, pallets, currentPallet } = props

  return (
    <div className={styles.mainBlock}>
      <BoxMarkedInPallets
        handlePrint={handlePrint}
        pallets={pallets}
        currentPallet={currentPallet}
      />
    </div>
  )
}

export default MainBlock
