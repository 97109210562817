import React, { useState } from 'react'

import styles from './change-weight.module.scss'

import { IconEdit } from '@consta/uikit/IconEdit'

import { useNotifications } from 'src/notification'

import { ItemPreview, WeightDimensionsModal } from 'src/components'

import {
  IOrderPackagingPlaceItem,
  IOrderItem,
  IOrderPackagingPlace,
} from 'src/interfaces'
import { usePackingOrderContext } from '../../context/PackingOrderContext'

const ChangeWeight = ({
  id,
  addWeightToCache,
  updatePlacesWeight,
}: {
  id: string
  addWeightToCache: (itemId: string, weight: number) => void
  updatePlacesWeight: (place: IOrderPackagingPlace) => void
}) => {
  const [item, setItem] = useState<IOrderPackagingPlaceItem | null>(null)
  const [originalItem, setOriginalItem] = useState<IOrderItem | null>(null)

  const { places, setPlaces, order } = usePackingOrderContext()

  const notification = useNotifications()

  const handleChangeWeight = () => {
    const lastPlace = places.find((p) => !p.id)
    const placeItem = lastPlace?.items.find((item) => item.id === id)
    if (!placeItem) return
    setItem(placeItem)

    const origItem = order?.items.find((item) => item.id === id)
    if (!origItem) return
    setOriginalItem(origItem)
  }

  const handleEnterWeigthDimensions = (weight?: number) => {
    if (!weight) return

    const lastPlace = places.find((p) => !p.id)
    const newPlace = {
      ...lastPlace,
      items:
        lastPlace?.items.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              weight,
            }
          } else {
            return item
          }
        }) ?? [],
    }

    if (!newPlace) return

    const newPlaces = places.filter((p) => p.id)
    //@ts-ignore
    newPlaces.push(newPlace)
    //@ts-ignore
    setPlaces(newPlaces)
    //@ts-ignore
    updatePlacesWeight(newPlace)

    addWeightToCache(id, weight)

    setItem(null)
    setOriginalItem(null)

    notification?.show('success', `Вес успешно обновлен`)
  }

  return (
    <div>
      <IconEdit
        className={styles.edit}
        view="brand"
        onClick={handleChangeWeight}
      />
      {item && originalItem ? (
        <WeightDimensionsModal
          item={originalItem}
          isOpen={true}
          onClose={() => setItem(null)}
          onSubmit={handleEnterWeigthDimensions}
          withClose
          addWeightToCache={addWeightToCache}
          needUpdateWeightAndDimensions={false}
          top={
            <ItemPreview
              image={originalItem.image}
              title={originalItem.title}
              barcode={item.barcode_used ?? ''}
              weight={item.weight ?? ''}
            />
          }
        />
      ) : null}
    </div>
  )
}

export default ChangeWeight
