import { useQuery } from 'react-query'
import api from 'src/api'

import { provideMock, MOCK_SEARCH_ITEMS } from 'src/mocks'

import { ApiReqResult, ISupplyOriginalItem } from 'src/interfaces' //TODO mb сделать универсальный интерфейс для Item?

interface UseSearchItemParams {
  merchantId: string
  query?: string
}

const getSearchedItem = async (params: UseSearchItemParams) => {
  const url = `/items/${params.merchantId}/item/${params.query}`
  const result = await api.get(url)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface SearchItemResult extends ApiReqResult {
  items: ISupplyOriginalItem[]
}

const useSearchItemByBarcode = () => ({
  fetch: (params: UseSearchItemParams) =>
    provideMock<UseSearchItemParams, SearchItemResult>(
      getSearchedItem,
      params,
      {
        key: 'query',
        value: 'mock3',
        result: MOCK_SEARCH_ITEMS,
      },
    )(),
})

export default useSearchItemByBarcode
