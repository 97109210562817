import { useMutation } from 'react-query'

import api from 'src/api'

interface UseResetPackedOrderByIdParams {
  orderId: string
}

const useResetPackedOrderById = (params: UseResetPackedOrderByIdParams) => {
  return useMutation(async () => {
    try {
      const result = await api.post(
        `/packing/order/${params.orderId}/reset`,
        {},
      )
      if (!result?.data?.success) {
        throw result?.data
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useResetPackedOrderById
