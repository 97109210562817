import { useQuery } from 'react-query'
import api from 'src/api'

import { provideMock, MOCK_GET_SUPPLY_BY_ID } from 'src/mocks'

import {
  ApiReqResult,
  ISupply,
  ISupplyItem,
  ISupplyPart,
  ISupplyOriginalItem,
} from 'src/interfaces'

interface UseSupplyByIdParamsType {
  supplyId: string
}

const getSupplyById = async (params: UseSupplyByIdParamsType) => {
  const result = await api.get(`/receiving/supply/${params.supplyId}`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface GetSupplyByIdResult extends ApiReqResult {
  receipts: ISupply
  items: ISupplyOriginalItem[]
}

const useGetSupplyById = (
  params: UseSupplyByIdParamsType,
  callback?: (
    items: ISupplyItem[],
    parts: ISupplyPart[],
    originalItems: ISupplyOriginalItem[],
  ) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetSupplyByIdResult>(
    [`getSupplyById`, params],
    //() => getSupplyById(params),
    provideMock<UseSupplyByIdParamsType, GetSupplyByIdResult>(
      getSupplyById,
      params,
      {
        key: 'supplyId',
        value: 'mock',
        result: MOCK_GET_SUPPLY_BY_ID,
      },
    ),
    {
      enabled: !!params,
      keepPreviousData: true,
      onSuccess: ({
        success,
        receipts: { items, parts },
        items: originalItems,
      }) => {
        if (success) {
          if (callback) {
            callback(items, parts, originalItems)
          }
        } else {
          if (error) {
            error()
          }
        }
      },
      onError: () => {
        if (error) {
          error()
        }
      },
      onSettled: () => {
        if (settled) {
          settled()
        }
      },
    },
  ) as { data: GetSupplyByIdResult }
}

export default useGetSupplyById
