import React from 'react'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import {
  ComboboxItem,
  ISelectListOption,
  ISelectOption,
  TypesEnum,
} from 'src/interfaces'

export const API_BASE_URL = 'https://api-wms-test.tempoline.ru'
export const LOCAL_STORAGE_NAME = '____TEMPOLINE___WMS____'
export const QZ_HOST = 'localhost'

export const CLOSE_PACKAGING_BARCODE = '3PL_CLPL'
export const PRINT_ITEM_STICKER_BARCODE = 'PR_ST'
export const CLOSE_PALLET_BARCODE = '3PL_CLPLT'
export const COMPLETE_REGISTER = 'CMP_RGS'
export const PRINT_PALLET_LISTS = 'PR_PLT_LSTS'
export const POST_PALLETS_COUNT = 'PST_PLTCNT'
export const PACK_DOCS = '3PL_PCDC'

export type STEP = {
  label: string
  value: string
  completed?: boolean
}

export const AUTH_STEPS: STEP[] = [
  {
    label: 'База данных',
    value: 'databaseStep',
    completed: false,
  },
  {
    label: 'Выбор операции',
    value: 'packageStep',
    completed: false,
  },
  {
    label: 'Сотрудник',
    value: 'employeeStep',
    completed: false,
  },
  {
    label: 'Рабочее место',
    value: 'workplaceStep',
    completed: false,
  },
]

export interface SELECT_COMPONENT_CHILDREN_PROPS {
  selected: ISelectListOption | null
  setSelected: (value: ISelectListOption | null) => void
  handleSubmit: () => void
  options: ISelectListOption[]
  back?: boolean
  backFunc?: () => void
}

export interface EMPLOYEE_CHILDREN_PROPS {
  employeeBarcode: TextFieldPropValue
  setEmployeeBarcode: () => void
  handleChange: (value: TextFieldPropValue) => void
  error?: string
}

export interface WORKPLACE_CHILDREN_PROPS {
  workplaceBarcode: TextFieldPropValue
  setWorkplaceBarcode: () => void
  handleChange: (value: TextFieldPropValue) => void
  error?: string
}

export type STEP_TYPES_OBJECT = {
  [key: string]:
    | React.FC<SELECT_COMPONENT_CHILDREN_PROPS>
    | React.FC<EMPLOYEE_CHILDREN_PROPS>
    | React.FC<WORKPLACE_CHILDREN_PROPS>
}

export type TAB_ITEM = {
  value: string
  id: string
}

export const TABS_ITEMS: TAB_ITEM[] = [
  {
    value: 'Основная',
    id: 'main',
  },
  {
    value: 'Дополнительно',
    id: 'secondary',
  },
  {
    value: 'Настройки',
    id: 'settings',
  },
]

export type PACKED_ORDERS_OPTION = {
  id: string
  label: string
  rightSide: React.ReactNode
}

export type STATUSES_PACKED_ORDERS = {
  [key: string]: [string, BadgePropStatus]
}

export type ORDER_PLACE_ITEM = {
  id: string
  img: string
  title: string
  barcode: string
  count: string
}

export type ORDER_PLACE = {
  id: string
  count: string
  status: string
  barcode: string | null
  dimensions: string | null
  weigth: string | null
  items: ORDER_PLACE_ITEM[]
}

export type SETTINGS_TAB = {
  label: string
  value: string
}

export const SETTINGS_TABS: SETTINGS_TAB[] = [
  {
    value: 'printSettings',
    label: 'Настройка печати',
  },
]

export const MARGIN_TRANSLATE: { [key: string]: string } = {
  top: 'Сверху',
  right: 'Справа',
  bottom: 'Снизу',
  left: 'Слева',
}

export const SIZE_TRANSLATE: { [key: string]: string } = {
  width: 'Ширина',
  height: 'Высота',
}

export const ACCEPTANCE_STATUSES: { [key: string]: string } = {
  arrived: 'Поступила на склад',
  processing: 'В процессе приемки',
  entered: 'Новая',
  registration: 'Оформление документов',
  done: 'Завершена',
}

export const ACCEPTANCE_STATUSES_STYLES: { [key: string]: BadgePropStatus } = {
  entered: 'warning',
  arrived: 'success',
  processing: 'normal',
  registration: 'system',
  done: 'error',
}

export const NOTIFICATIONS_TIMEOUT = 3000

export const MONTHS = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
]

export const DEFAUTL_STATUSES: ComboboxItem[] = [
  {
    value: 'processing',
    label: 'В процессе приемки',
    id: 'processing',
  },
  {
    value: 'entered',
    label: 'Новая',
    id: 'entered',
  },
  {
    value: 'arrived',
    label: 'Поступила на склад',
    id: 'arrived',
  },
]

export const SCAN_OPTIONS: ISelectOption[] = [
  {
    label: 'Упаковка заказа',
    value: 'simple',
  },
  {
    label: 'Упаковка батча',
    value: 'batch',
  },
  {
    label: 'Перемаркировка',
    value: 'relabel',
  },
  {
    label: 'Печать документов',
    value: 'docs_print',
  },
]

export const dateTypes: { label: string; value: TypesEnum }[] = [
  {
    label: 'дата изготовления',
    value: TypesEnum.dateOfManufacture,
  },
  {
    label: 'дата - годен до',
    value: TypesEnum.expirationDate,
  },
]
