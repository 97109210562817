import React, { useEffect, useRef, useState } from 'react'

import styles from './scan-sticker-modal.module.scss'

import img from './assets/barcode_scan.svg'
import IconPrinter from '../assets/IconPrinter.svg'

import { Modal, InputWrapper } from 'src/components'

import { useNotifications } from 'src/notification'
import { useUpdateRelabelingSticker } from 'src/hooks'
import { useCheckNewStickerBarcode } from '../hooks'

import { useAppContext } from 'src/context'
import { usePackingOrderContext } from '../../context'

import { ModalProps, RelabelingTypes, ApiReqResult } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Checkbox } from '@consta/uikit/Checkbox'

interface ScanStickerModallProps extends ModalProps {
  handleRepeatPrintSticker: () => void
  loadingPrint: boolean
  curRelabelId: string
  onSuccessCallback: (id: string, new_barcode: string) => void
  setNewBarcodesArr: (val: string[]) => void
  newBarcodesArr: string[]
  disableUniquenessCheck: boolean
  setDisableUniquenessCheck: (val: boolean) => void
}

const ScanStickerModal = (props: ScanStickerModallProps) => {
  const {
    isOpen,
    onMount,
    handleRepeatPrintSticker,
    loadingPrint,
    onClose,
    onSuccessCallback,
    curRelabelId,
    setNewBarcodesArr,
    newBarcodesArr,
    disableUniquenessCheck,
    setDisableUniquenessCheck,
  } = props

  const { setGlobalLoading, advanceMode, performWithAdvance } = useAppContext()
  const { order } = usePackingOrderContext()

  const checkBarcode = useCheckNewStickerBarcode()

  const isBoxesRelabeling =
    order?.relabelingType === RelabelingTypes.BOXES ||
    order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS

  const notification = useNotifications()
  const updateSticker = useUpdateRelabelingSticker({
    type: isBoxesRelabeling ? 'place' : 'pallet',
    orderId: order?.id || '',
  })

  const input = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null)

  const focusBarcodeInput = () => {
    if (!input?.current) return
    input.current.focus()
  }

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [onMount])

  const updateStickerBarcode = async (
    id: string,
    new_barcode: string,
  ): Promise<void> => {
    setGlobalLoading(true)
    await updateSticker
      .mutateAsync({ id, new_barcode })
      .then((result: ApiReqResult) => {
        notification?.show('success', 'ШК стикера успешно обновлен')
        setNewBarcodesArr([...newBarcodesArr, new_barcode])

        if (onSuccessCallback) {
          onSuccessCallback(id, new_barcode)
        }

        onClose()
      })
      .finally(() => {
        setGlobalLoading(false)
      })
  }

  const handleChange = (val: TextFieldPropValue) => {
    if (!val) return

    if (newBarcodesArr.includes(val)) {
      notification?.show(
        'alert',
        `Ошибка сканирования - такой штрихкод уже был отсканирован`,
      )
      return
    }

    if (!disableUniquenessCheck && !checkBarcode(val.trim())) {
      notification?.show(
        'alert',
        `Ошибка сканирования - такой штрихкод есть в составе заказа`,
      )
      return
    }

    updateStickerBarcode(curRelabelId, val.trim())
  }

  const handleCheck = async (checked: boolean) => {
    if (!advanceMode) {
      performWithAdvance(() => () => {
        setDisableUniquenessCheck(checked)
        focusBarcodeInput()
      })
    } else {
      setDisableUniquenessCheck(checked)
      focusBarcodeInput()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.sticker}
      title="Отсканируйте новый стикер"
      subtitle={`Отсканируйте ШК на новом стикере ${
        order?.relabelingType === RelabelingTypes.PALLETS ? 'паллеты' : 'места'
      }`}
      size="m"
      img={img}
      imgClass={styles.img}
    >
      <div className={styles.wrap}>
        <div className={styles.input_wrap}>
          <InputWrapper
            id={'setSticker'}
            handleChange={handleChange}
            autoFocus
            className={styles.itemInput}
            placeholder={'ШК нового стикера'}
            size="l"
            inputRef={input}
            withDelay
          />

          <Checkbox
            label="Отключить проверку уникальности шк"
            checked={disableUniquenessCheck}
            onChange={(e) => handleCheck(e.checked)}
          />
        </div>

        <button
          className={styles.additionalDocsBtn}
          onClick={handleRepeatPrintSticker}
          disabled={loadingPrint}
        >
          <img src={IconPrinter} alt="" />
          <span>Печать последнего стикера</span>
        </button>
      </div>
    </Modal>
  )
}

export default ScanStickerModal
