import React, { useState } from 'react'
import dayjs from 'dayjs'

import styles from './batch-packed-table.module.scss'

import IconPrinter from './assets/IconPrinter.svg'

import { Table, Img } from 'src/components'

import { useAppContext } from 'src/context'
import { usePackingOrderContext, usePackingPrintContext } from '../../context'

import { useGetStickerByPlace } from 'src/hooks'

import { hideSymbols } from 'src/helpers'

import { IOrderPackagingPlace, IOrderBatchPackagingPlace } from 'src/interfaces'

interface BatchPackedTableProps {
  title?: string
  places: IOrderPackagingPlace[]
}

const BatchPackedTable = (props: BatchPackedTableProps) => {
  const { title, places } = props

  const { originalItems } = usePackingOrderContext()
  const { performWithAdvance, advanceMode } = useAppContext()
  const { printSticker } = usePackingPrintContext()

  const getSticker = useGetStickerByPlace()

  const printPlaceSticker = async (id: string, orderId: string) => {
    await getSticker.fetch({ orderId: orderId, placeId: id }).then((result) => {
      if (result.success) {
        const { sticker } = result

        printSticker(sticker.printer, sticker.content, {
          copies: 1,
          needShowPrintStickerInfo: true,
          needFocusAfterPrint: true,
        })
      }
    })
  }

  const handleGetSticker = async (
    id: string | undefined,
    orderId: string | undefined,
  ) => {
    if (!id || !orderId) return false

    if (advanceMode) {
      printPlaceSticker(id, orderId)
    } else {
      performWithAdvance(() => () => {
        printPlaceSticker(id, orderId)
      })
    }
  }

  const items = places
    .slice()
    .reverse()
    .map((place: IOrderBatchPackagingPlace) => {
      const item = place.items[0]

      const image =
        originalItems.find((originalItem) => originalItem.id === item.id)
          ?.image ?? ''

      return {
        image: image,
        title: item.title,
        barcode: item.barcode_used,
        orderId: place.batchOrderId,
        orderDate: place.batchOrderDate,
        serial_numbers: item.serial_numbers,
        placeId: place.id,
      }
    })

  if (!places) return null

  return (
    <div className={styles.batchPackedTable}>
      {title ? <h4>{title}</h4> : null}

      <div className={styles.tableWrap}>
        <Table stickyHeader theme="blue">
          {items.map((item, i) => (
            <Table.Row key={item.orderId}>
              <Table.Column title="" className={styles.tdImg}>
                <Img
                  className={styles.img}
                  src={item.image}
                  size={100}
                  yandex
                  modal
                />
              </Table.Column>
              <Table.Column
                title={
                  <div>
                    Номенклатура{' '}
                    <span className={styles.light}>Штрихкод товара</span>
                  </div>
                }
                className={styles.tdName}
              >
                <span className={styles.title}>{item.title}</span>
                <span className={styles.light}>
                  {' '}
                  {hideSymbols(6, item.barcode)}
                </span>
              </Table.Column>
              <Table.Column title="Заказ" className={styles.order}>
                {item.orderId}
                <span className={styles.light}>
                  {item.orderDate
                    ? dayjs(item.orderDate).format('DD.MM.YYYY')
                    : null}
                </span>
              </Table.Column>
              <Table.Column title="Серийный номер" className={styles.serial}>
                {item.serial_numbers.map((serial) => (
                  <span title={serial.value}>{serial.value}</span>
                ))}
              </Table.Column>
              <Table.Column title="ШК места" className={styles.placeId}>
                <span>{item.placeId}</span>
              </Table.Column>
              <Table.Column title="" className={styles.print}>
                {item.placeId && item.orderId ? (
                  <img
                    src={IconPrinter}
                    onClick={() => handleGetSticker(item.placeId, item.orderId)}
                  />
                ) : null}
              </Table.Column>
            </Table.Row>
          ))}
        </Table>
      </div>
    </div>
  )
}

export default BatchPackedTable
