import React, { useEffect } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import styles from './b2b-relabeling-order.module.scss'

import IconPrinter from './assets/IconPrinter.svg'

import { Button } from '@consta/uikit/Button'
import { IconInfo } from '@consta/uikit/IconInfo'
import { Badge } from '@consta/uikit/Badge'

import { InputWrapper, SideBar, Img } from 'src/components'

import { useAppContext } from 'src/context'
import { usePackingOrderContext, usePackingPrintContext } from '../context'

import { ShippingOptionsType, RelabelingTypes } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface ISideBar {
  relabelIsDone: boolean
  originalCount: number
  markedCount: number
  orderStatus: string
  shippingFormViewed: boolean
  handleOpenAddFiles: () => void
  handleCompletePack: () => void
  handleScanBarcode: (val: TextFieldPropValue) => void
  handleGetWbBarcode: () => Promise<void>
  inputDisabled?: boolean
  isSuccessRelabel: boolean
  printWBPackingList: () => Promise<void>
}

const RelabelingSideBar = (props: ISideBar) => {
  const {
    relabelIsDone,
    originalCount,
    markedCount,
    orderStatus,
    shippingFormViewed,
    handleOpenAddFiles,
    handleScanBarcode,
    handleGetWbBarcode,
    printWBPackingList,
    inputDisabled = false,
    isSuccessRelabel,
  } = props

  const history = useHistory()

  const { advanceMode } = useAppContext()
  const { items, order, setShowShippingForm } = usePackingOrderContext()

  const { barcodeInput } = usePackingPrintContext()

  if (!order) return null

  const isMonoPack =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PACKINGS
  const isMonoPallet =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PALLETS
  const isWB = order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS
  const noPallets = !order?.pallets?.length

  return (
    <SideBar
      bottomBtns={
        <div className={styles.fboBtns}>
          <div className={styles.fboBtnsTop}>
            <Button
              className={cx(
                styles.iconBtn,
                order?.shipping_form?.length && !shippingFormViewed
                  ? styles.iconBtnCircle
                  : null,
              )}
              view="secondary"
              iconLeft={IconInfo}
              label="Анкета отгрузки"
              onClick={() => setShowShippingForm(true)}
              disabled={!order?.shipping_form?.length}
            />
            {advanceMode ? (
              <button
                className={styles.additionalDocsBtn}
                onClick={handleOpenAddFiles}
              >
                <img src={IconPrinter} alt="" />
                <span>Печать доп. документов</span>
              </button>
            ) : null}
            {isWB ? (
              <Button
                label="Печать шк поставки"
                view="secondary"
                onClick={handleGetWbBarcode}
              />
            ) : null}
            {isWB && noPallets ? (
              <Button
                style={{ marginTop: 8 }}
                label="Печать упаковочных листов"
                view="secondary"
                onClick={printWBPackingList}
              />
            ) : null}
          </div>
          <div className={styles.fboBtnsBottom}>
            <Button
              label="Прекратить упаковку"
              view="ghost"
              onClick={() => history.push('/')}
            />
            <Button
              label="Завершить упаковку"
              disabled={!relabelIsDone}
              onClick={() => history.push('/')}
            />
          </div>
        </div>
      }
    >
      <div className={styles.fboTop}>
        <Img className={styles.img} src={order?.delivery?.logo} yandex />
        <div className={styles.topBadge}>{orderStatus}</div>
      </div>

      <div
        className={cx(
          styles.packCount,
          isMonoPack || isMonoPallet ? styles.littleMargin : null,
        )}
      >
        Перемаркировано
        <h2>
          <span>{items ? <>{markedCount ?? 0}</> : null}</span> из{' '}
          {originalCount ?? 0}
        </h2>
      </div>

      <div className={styles.packName}>
        Заказ <h2>{order?.id}</h2>
        <span className={styles.merchant}>
          {order?.merchant?.title}{' '}
          {order?.merchant?.trade_title !== order?.merchant?.title ? (
            <>
              {!order?.merchant?.trade_title ||
                !order?.merchant?.title ||
                ' | '}{' '}
              {order?.merchant?.trade_title}
            </>
          ) : null}
        </span>
        <div className={styles.badgesBlock}>
          <Badge
            className={styles.badgeFbo}
            size="m"
            status="system"
            view="stroked"
            label={'FBO'}
          />
          {isMonoPack ? (
            <Badge
              className={styles.badge}
              size="m"
              status="error"
              label="МОНОкороб"
            />
          ) : null}
          {isMonoPallet ? (
            <Badge
              className={styles.badge}
              size="m"
              status="error"
              label="монопаллета"
            />
          ) : null}
          {isSuccessRelabel ? (
            <Badge
              className={styles.badge}
              size="m"
              status="success"
              label="успешно перемаркирован"
            />
          ) : null}
        </div>
      </div>

      <InputWrapper
        label="Отсканируйте штрихкод"
        className={styles.itemScanner}
        autoFocus
        withDelay
        size="l"
        handleChange={handleScanBarcode}
        inputRef={barcodeInput}
        disabled={inputDisabled}
      />
    </SideBar>
  )
}

export default RelabelingSideBar
