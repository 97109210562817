import React, { useState } from 'react'

import { useNotifications } from 'src/notification'
import { usePrinter } from 'src/printer'
import {
  useGetStickerByPlace,
  useGetStickerByPallet,
  useGetWBBarcode,
  useGetWBPalletList,
} from 'src/hooks'

import { usePackingOrderContext, usePackingPrintContext } from '../../context'

import { checkBase64MIMEType } from 'src/helpers'

import { StickerResult, useManagePrintStickerRes } from 'src/interfaces'

interface IUseManagePrintSticker {
  setCurOperation: (val: string | null) => void
  setCurRelabelId?: (val: string | null) => void
  setShowScanSticker?: (val: boolean) => void
}

const useManagePrintSticker = ({
  setCurOperation,
  setCurRelabelId,
  setShowScanSticker,
}: IUseManagePrintSticker) => {
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false)

  const { order, setPrintedPalletLists } = usePackingOrderContext()

  const {
    setSticker,
    setShowStickerError,
    setShowStickerPrintInfo,
    blurOrFocusBarcodeInput,
  } = usePackingPrintContext()

  const getPlaceSticker = useGetStickerByPlace()
  const getPalletSticker = useGetStickerByPallet()
  const getWBBarcode = useGetWBBarcode()
  const getWBPalletList = useGetWBPalletList()

  const notification = useNotifications()
  const printer = usePrinter()

  const printSticker = async (
    id: string,
    sticker: {
      content: string
      printer: string
    },
    options: {
      message: string
      isNeedScan?: boolean
      isNeedShowPrintInfo?: boolean
      isNeedFocus?: boolean
    },
  ): Promise<void> => {
    if (checkBase64MIMEType(sticker.content) !== 'application/pdf') {
      setShowStickerError(true)
      notification?.show(
        'alert',
        'Ошибка формирования стикера - данные получены не в PDF',
      )
      return
    }

    setSticker(sticker)

    printer?.setPrinter(sticker.printer)
    //printer?.setPrinter('Microsoft Print to PDF')

    await printer?.print(sticker.content, {
      onSuccess: () => {
        notification?.show('success', options.message)
        if (options?.isNeedShowPrintInfo ?? true) {
          setShowStickerPrintInfo(true)
        }
        if (options?.isNeedFocus) {
          blurOrFocusBarcodeInput('focus')
        }
        // вызываем модалку сканирования нового стикера, для обновления стикера на бэке
        if (options?.isNeedScan ?? true) {
          if (setCurRelabelId) {
            setCurRelabelId(id)
          }
          if (setShowScanSticker) {
            setShowScanSticker(true)
          }
        }
      },
      onError: (err) => {
        console.error('STICKER PRINT ERROR:')
        console.error(err)
        notification?.show('alert', `Ошибка печати - ${err}`)
        setShowStickerError(true)
      },
      onFinally: () => {
        setLoadingPrint(false)
      },
    })
  }

  const printPalletList = async (
    id: string,
    num: number,
    isFirst: boolean = false,
  ): Promise<void> => {
    try {
      const result = await getPalletSticker.fetch({
        pallet_id: id,
        operationType: order?.operationType,
      })
      if (result.success) {
        const { sticker } = result

        if (!checkSticker(sticker)) {
          return
        }

        await printSticker(id, sticker, {
          message: `Упаковочный лист ${num} успешно отправлен на печать`,
          isNeedScan: false,
          isNeedShowPrintInfo: isFirst,
        })
        //@ts-ignore
        setPrintedPalletLists((state) => [...state, id])
      }
    } catch (e) {
      return Promise.reject()
    }
  }

  const printPlaceSticker = async (
    id: string,
    isRepeat: boolean = false,
  ): Promise<void> => {
    if (!order) return

    setCurOperation('placeSticker')
    setLoadingPrint(true)

    await getPlaceSticker
      .fetch({
        placeId: id,
        orderId: order.id,
        operationType: order?.operationType,
      })
      .then((result) => {
        const { sticker } = result

        if (!checkSticker(sticker)) {
          return
        }

        printSticker(id, sticker, {
          message: 'Стикер успешно отправлен на печать',
          isNeedScan: !isRepeat,
        })
      })
  }

  const printWBPackingList = async () => {
    if (!order) return

    setCurOperation('WBPackingList')

    await getWBPalletList
      .fetch({
        orderId: order.id,
      })
      .then(async (result) => {
        const { sticker } = result

        if (!checkSticker(sticker)) {
          return
        }

        await printSticker('', sticker, {
          message: 'Стикер успешно отправлен на печать',
          isNeedScan: false,
          isNeedShowPrintInfo: true,
        })
      })
  }

  const printPalletSticker = async (
    id: string,
    isRepeat: boolean = false,
  ): Promise<void> => {
    setCurOperation('palletSticker')

    await getPalletSticker
      .fetch({
        pallet_id: id,
        operationType: order?.operationType,
      })
      .then((result: StickerResult) => {
        const { sticker } = result

        if (!checkSticker(sticker)) {
          return
        }

        printSticker(id, sticker, {
          message: 'Стикер успешно отправлен на печать',
          isNeedScan: !isRepeat,
        })
      })
  }

  const printWBBarcode = async () => {
    if (!order) return
    await getWBBarcode
      .fetch({
        orderId: order.id,
      })
      .then((result) => {
        const { sticker } = result

        if (!checkSticker(sticker)) {
          return
        }

        printSticker('', sticker, {
          message: 'Стикер успешно отправлен на печать',
          isNeedScan: false,
          isNeedShowPrintInfo: false,
        })
      })
  }

  const checkSticker = (sticker: {
    content: string
    printer: string
  }): boolean => {
    if (!sticker.printer) {
      notification?.show(
        'alert',
        `Ошибка получения стикера - не указан принтер для печати`,
      )
      return false
    }
    if (!sticker.content) {
      notification?.show(
        'alert',
        `Ошибка получения стикера - нет макета стикера`,
      )
      return false
    }
    return true
  }

  return {
    loadingPrint,
    printPalletSticker,
    printPlaceSticker,
    printSticker,
    printWBBarcode,
    printPalletList,
    printWBPackingList,
  } as useManagePrintStickerRes
}

export default useManagePrintSticker
