import { v4 as uuid } from 'uuid'

import { MOCK_GET_STICKER_BY_PLACE } from './'

export const MOCK_UPDATE_PACKING_PLACE = {
  data: {
    ...MOCK_GET_STICKER_BY_PLACE,
    place_id: 'b16019c0-a274',
  },
}
