import React, { useEffect, useState } from 'react'

import ChoicePackage from './ChoicePackage'
import WeightingPlace from './WeightingPlace'

import {
  ModalProps,
  IOrderPackagingOption,
  IOrderPackagingPlace,
  IDimensions,
} from 'src/interfaces'

interface ClosePlaceProps extends ModalProps {
  requireWeight: boolean
  maxWeight: number | null
  currentPlaceData: IOrderPackagingPlace
  packagingOptions: IOrderPackagingOption[]
  onSubmit: (newPlaceData: IOrderPackagingPlace) => void
  currentModal: string
  setCurrentModal: (value: string) => void
  errorWeight: string | null
  clearErrorWeight: () => void
}

const ClosePlace = (props: ClosePlaceProps) => {
  const {
    currentPlaceData,
    packagingOptions,
    onSubmit,
    onClose,
    requireWeight,
    maxWeight,
    currentModal,
    setCurrentModal,
    errorWeight,
    clearErrorWeight,
  } = props

  const [dataForSaving, setDataForSaving] = useState(currentPlaceData)

  const handleChoicePackage = (
    packageId: string,
    options: { dimensions?: IDimensions } = {},
  ) => {
    setDataForSaving({
      ...currentPlaceData,
      packaging_id: packageId,
      ...options,
    })

    if (!requireWeight) {
      const newData = {
        ...currentPlaceData,
        packaging_id: packageId,
        ...options,
      }
      delete newData.weight

      onSubmit({ ...newData })
      onClose()
      return false
    }

    setCurrentModal('weightingPlace')
  }

  const handleWeightPlace = (weight: number) => {
    onSubmit({ ...dataForSaving, weight })
    onClose()
    setCurrentModal('choicePackage')
  }

  useEffect(() => {
    return () => {
      setCurrentModal('choicePackage')
      clearErrorWeight()
    }
  }, [])

  return (
    <>
      {currentModal === 'choicePackage' ? (
        <ChoicePackage
          isOpen={true}
          packagingOptions={packagingOptions}
          onBack={onClose}
          onSubmit={handleChoicePackage}
          onClose={onClose}
        />
      ) : null}

      {currentModal === 'weightingPlace' ? (
        <WeightingPlace
          isOpen={true}
          onSubmit={handleWeightPlace}
          onClose={onClose}
          maxWeight={maxWeight}
          errorWeight={errorWeight}
          clearErrorWeight={clearErrorWeight}
        />
      ) : null}
    </>
  )
}

export default ClosePlace
