import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'

import { useNotifications } from 'src/notification'
import { usePrinter } from 'src/printer'

import { useAppContext } from 'src/context'
import { usePackingPrintContext } from './PackingPrintContext'

import {
  //saveBase64AsPdf,
  checkBase64MIMEType,
  getItemsQuantity,
  getFormattedOrderSimilarItems,
} from 'src/helpers'

import {
  IOrderItem,
  IOrderPackagingPlace,
  IOrderPackagingPlaceItem,
  IPlacePacked,
  getStickerResult,
  IOrder,
  IOrderPackagingOption,
  IOrderPallet,
  ICachedPackage,
  IOrderPackedPallet,
  IAddFile,
  IBatchOrder,
  IBatchOrderItem,
  IOrderPalletMarked,
  IPalletErrorData,
  packType,
  IPlaceErrorData,
} from 'src/interfaces'

export const PackingOrderContext = createContext({
  order: null as IOrder | null,
  items: null as IOrderItem[] | null,
  places: [] as IOrderPackagingPlace[],
  relabelingPlaces: [] as IOrderPackagingPlace[],
  packages: [] as IOrderPackagingOption[],
  showShippingForm: false,
  cachedItem: null as IOrderPackagingPlaceItem | null,
  addingItem: null as IOrderItem | null,
  currentPlace: null as number | null,
  shippingFormViewed: false,
  curItem: null as IOrderItem | null,
  showWeigthDimensions: false,
  kitModalData: null as {
    kit_id?: number | undefined
    kit_title?: string | undefined
  } | null,
  pallets: [] as IOrderPallet[],
  palletsForRelabeling: [] as IOrderPackedPallet[],
  addFiles: [] as IAddFile[],
  batch: [] as IBatchOrder[],
  originalItems: [] as IBatchOrderItem[],
  currentPallet: null as number | null,
  packedAttachments: [] as { id: string; quantity: number }[],
  currentOperation: 'placeClosing' as string,
  setPackedAttachments: (
    packedAttachments: { id: string; quantity: number }[],
  ) => void packedAttachments as void,
  cachedPackages: {} as ICachedPackage,
  cachedSerials: [] as string[],
  setCachedSerials: (serials: string[]) => void [] as void,
  originalItemsCount: 0,
  relabeledPallets: [] as IOrderPalletMarked[],
  setRelabeledPallets: (pallets: IOrderPalletMarked[]) => void pallets as void,
  showClosePallet: false as boolean,
  setShowClosePallet: (val: boolean) => void val as void,
  closingErrorData: null as IPlaceErrorData | IPalletErrorData | null,
  setClosingErrorData: (val: IPalletErrorData | null) => void val as void,
  setOrder: (order: IOrder | null) => void order as void,
  setShowShippingForm: (showShippingForm: boolean) =>
    void showShippingForm as void,
  setItems: (items: IOrderItem[] | null) => void items as void,
  setPlaces: (places: IOrderPackagingPlace[]) => void places as void,
  setRelabelingPlaces: (places: IOrderPackagingPlace[]) => void places as void,
  setPackagings: (packages: IOrderPackagingOption[]) => void packages as void,
  setCachedItem: (cachedItem: IOrderPackagingPlaceItem | null) =>
    void cachedItem as void,
  setAddingItem: (addingItem: IOrderItem | null) => void addingItem as void,
  setCurrentPlace: (currentPlace: number | null) => void currentPlace as void,
  setCurrentPallet: (currentPallet: number | null) =>
    void currentPallet as void,
  setShippingFormViewed: (shippingFormViewed: boolean) =>
    void shippingFormViewed as void,
  setCurItem: (curItem: IOrderItem | null) => void curItem as void,
  setShowWeigthDimensions: (showWeigthDimensions: boolean) =>
    void showWeigthDimensions as void,
  setKitModalData: (
    kitModalData: {
      kit_id?: number
      kit_title?: string
    } | null,
  ) => void kitModalData as void,
  setPallets: (pallets: IOrderPallet[]) => void pallets as void,
  setPalletsForRelabeling: (palletsForRelabeling: IOrderPackedPallet[]) =>
    void palletsForRelabeling as void,
  setAddFiles: (addFiles: IAddFile[]) => void addFiles as void,
  setBatch: (batch: IBatchOrder[]) => void batch as void,
  setCachedPackages: (cachedPackages: ICachedPackage) =>
    void cachedPackages as void,
  handleGetDataForOrder: () => void false as void,
  checkItemInPlaces: (item: IOrderItem, type: 'pallet' | 'place') =>
    false as boolean,
  isInValidItemQuantity: (
    newItemId: string,
    quantity: number,
    type?: 'places' | 'pallets',
  ) => false as boolean,
  handleRemoveItem: (removingItemId: string, count: number) =>
    void false as void,
  handleChangePlace: (newData: IOrderPackagingPlace) => void false as void,
  handleSavePlace: (
    placeId: string,
    data: IOrderPackagingPlace,
    notificationMessage?: string,
  ) => void false as void,
  handleShowWeigthDimensions: (item: IOrderItem) => void false as void,
  handleCloseKitModal: () => void false as void,
  setType: (type: string | undefined) => void undefined as void,
  resetOrder: (func: any) => void false as void,
  setOriginalItemsCount: (count: number) => void count as void,
  setOriginalItems: (items: IBatchOrderItem[]) => void items as void,
  setCurrentOperation: (op: string) => void op as void,
  printedPalletLists: [] as string[],
  setPrintedPalletLists: (val: string[]) => void val as void,
  currentClosePlaceModal: '' as string,
  setCurrentClosePlaceModal: (val: string) => void val as void,
  curPackingType: packType.packInPlaces as packType,
  setCurPackingType: (val: packType) => void val as void,
  showClosePlace: false as boolean,
  setShowClosePlace: (val: boolean) => void val as void,
  errorWeight: null as string | null,
  setErrorWeight: (val: string | null) => void val as void,
  curPackageId: null as string | null,
  setCurPackageId: (val: string | null) => void val as void,
  palletCount: 0 as number | null,
  setPalletCount: (val: number | null) => void val as void,
  indicateItemCount: false as boolean,
  setIndicateItemCount: (val: boolean) => void val as void,
  alert: '' as string,
  setAlert: (val: string) => void val as void,
})

export const PackingOrderProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const history = useHistory()
  const notification = useNotifications()
  const printer = usePrinter()

  const { setGlobalLoading } = useAppContext()

  const [order, setOrder] = useState<IOrder | null>(null)
  const [type, setType] = useState<string | undefined>(undefined)
  const [showShippingForm, setShowShippingForm] = useState<boolean>(false)
  const [items, setItems] = useState<IOrderItem[] | null>(null)
  const [places, setPlaces] = useState<IOrderPackagingPlace[]>([])
  const [relabelingPlaces, setRelabelingPlaces] = useState<
    IOrderPackagingPlace[]
  >([])
  const [packages, setPackagings] = useState<IOrderPackagingOption[]>([])

  const [cachedItem, setCachedItem] = useState<IOrderPackagingPlaceItem | null>(
    null,
  )
  const [addingItem, setAddingItem] = useState<IOrderItem | null>(null)
  const [currentPlace, setCurrentPlace] = useState<number | null>(null)
  const [currentPallet, setCurrentPallet] = useState<number | null>(null)
  const [curItem, setCurItem] = useState<IOrderItem | null>(null)
  const [showWeigthDimensions, setShowWeigthDimensions] =
    useState<boolean>(false)
  const [kitModalData, setKitModalData] = useState<{
    kit_id?: number
    kit_title?: string
  } | null>(null)
  const [packedAttachments, setPackedAttachments] = useState<
    { id: string; quantity: number }[]
  >([])
  const [pallets, setPallets] = useState<IOrderPallet[]>([])
  const [palletsForRelabeling, setPalletsForRelabeling] = useState<
    IOrderPackedPallet[]
  >([])
  const [cachedPackages, setCachedPackages] = useState<ICachedPackage>({})
  const [addFiles, setAddFiles] = useState<IAddFile[]>([])
  const [shippingFormViewed, setShippingFormViewed] = useState<boolean>(false)
  const [batch, setBatch] = useState<IBatchOrder[]>([])
  const [originalItemsCount, setOriginalItemsCount] = useState<number>(0)
  const [originalItems, setOriginalItems] = useState<IBatchOrderItem[]>([])
  const [currentOperation, setCurrentOperation] =
    useState<string>('placeClosing')
  const [relabeledPallets, setRelabeledPallets] = useState<
    IOrderPalletMarked[]
  >([])
  const [showClosePallet, setShowClosePallet] = useState<boolean>(false)
  const [closingErrorData, setClosingErrorData] = useState<
    IPlaceErrorData | IPalletErrorData | null
  >(null)
  const [printedPalletLists, setPrintedPalletLists] = useState<string[]>([])
  const [cachedSerials, setCachedSerials] = useState<string[]>([])
  const [currentClosePlaceModal, setCurrentClosePlaceModal] =
    useState('choicePackage')
  const [curPackingType, setCurPackingType] = useState<packType>(
    packType.packInPlaces,
  )
  const [errorWeight, setErrorWeight] = useState<string | null>(null) //TODO better
  const [showClosePlace, setShowClosePlace] = useState<boolean>(false)
  const [curPackageId, setCurPackageId] = useState<string | null>(null)
  const [palletCount, setPalletCount] = useState<null | number>(0)
  const [indicateItemCount, setIndicateItemCount] = useState<boolean>(false)
  const [alert, setAlert] = useState<string>('')

  const { blurOrFocusBarcodeInput } = usePackingPrintContext()

  useEffect(() => {
    if (order) {
      handleGetDataForOrder()
    }
  }, [order])

  useEffect(() => {
    if (batch?.length) {
      handleSetItemsAndPlacesForBatch(batch)
    }
  }, [batch])

  const getItemsWithotPlaceItems = (
    items: IOrderItem[],
    places: IOrderPackagingPlace[],
  ) => {
    const formattedItems = getFormattedOrderSimilarItems(items)

    return formattedItems
      .map((i) => {
        const actualItemsCount = places.reduce(
          (acc: number, p: IPlacePacked) => {
            const places = p.items.filter((pItem) => i.id === pItem.id)
            const itemsInPlacesQuantity = places.reduce(
              (iacc: number, i: { quantity: number }) => iacc + i.quantity,
              0,
            )

            return itemsInPlacesQuantity ? acc + itemsInPlacesQuantity : acc
          },
          0,
        )
        if (actualItemsCount) {
          return {
            ...i,
            quantity: i.quantity - actualItemsCount,
          }
        } else {
          return {
            ...i,
          }
        }
      })
      .filter((i) => {
        return i.quantity
      })
  }

  const getFormattedPlaces = (
    items: IOrderItem[],
    places: IOrderPackagingPlace[],
  ) => {
    return places.map((p) => {
      const newItems = p.items.slice().map((i) => {
        return {
          ...i,
          title: items.find((item) => item.id === i.id)?.title || '',
        }
      })
      return {
        ...p,
        packaging_id: p.packaging_id,
        is_final: false,
        items: newItems,
      }
    })
  }

  const handleSetItemsAndPlacesForBatch = (batch: IBatchOrder[]) => {
    const newItems = batch.reduce(
      (acc: IBatchOrderItem[], curOrder) => [
        ...acc,
        ...curOrder.items.map((item) => ({
          ...item,
          batchOrderId: curOrder.id,
          batchOrderDate: curOrder.date,
        })),
      ],
      [],
    )

    const batchPlaces = batch.reduce(
      (acc: IOrderPackagingPlace[], curOrder) => [
        ...acc,
        ...curOrder.places.map((p) => ({
          ...p,
          batchOrderId: curOrder.id,
          batchOrderDate: curOrder.date,
        })),
      ],
      [],
    )

    const formattedPlaces = getFormattedPlaces(newItems, batchPlaces)

    setPlaces(formattedPlaces)

    if (!batchPlaces?.length) {
      setOriginalItemsCount(getItemsQuantity(newItems) ?? 0)
      setOriginalItems(newItems)
      setItems(newItems)
      return
    }

    const formattedItems = getItemsWithotPlaceItems(newItems, batchPlaces)
    setItems(formattedItems)
    setOriginalItemsCount(getItemsQuantity(newItems) ?? 0)
    setOriginalItems(newItems)
  }

  const handleSetItemsAndPlacesForOrder = (
    items: IOrderItem[],
    places: IOrderPackagingPlace[],
    pallets?: IOrderPackedPallet[],
  ) => {
    if (!places?.length) {
      const newItems = items?.length ? items : null
      setItems(newItems)
      return
    }

    const newItems = getItemsWithotPlaceItems(items, places)
    setItems(newItems)

    const formattedPlaces = getFormattedPlaces(items, places)
    setPlaces(formattedPlaces)
    setRelabelingPlaces(formattedPlaces)

    if (pallets) {
      const newPallets = pallets.map((pallet) => {
        return {
          ...pallet,
          id: pallet.pallet_id,
          places: pallet.places
            .map((place) => {
              return formattedPlaces.find((p) => p.id === place)
            })
            .filter((place) => place?.id),
        }
      })

      const newPalletsForRelabeling = pallets.map((pallet) => {
        return {
          ...pallet,
          id: pallet.pallet_id,
        }
      })

      // @ts-ignore
      // TODO - сделать без @ts-ignore
      setPallets(newPallets)

      setPalletsForRelabeling(newPalletsForRelabeling)
    }

    const addPacks = places.reduce((acc, p) => {
      p.items.forEach((item) => {
        if (item.packagings?.[0]?.packaging_id && !acc[item.id] && item.id) {
          acc[item.id] = item.packagings?.[0]?.packaging_id
        }
        if (!item.packagings?.[0]?.packaging_id && !acc[item.id] && item.id) {
          acc[item.id] = 'noAddPackage'
        }
      })
      return acc
    }, {} as { [key: string]: string })

    setCachedPackages(addPacks)
  }

  const handleGetDataForOrder = () => {
    if (order?.shipping_form?.length && !type) {
      setShowShippingForm(true)
    }

    handleSetItemsAndPlacesForOrder(
      order?.items ?? [],
      order?.places ?? [],
      order?.pallets ?? [],
    )
  }

  const checkItemInPlaces = (
    item: IOrderItem,
    type: 'pallet' | 'place' = 'place',
  ) => {
    if (type === 'place' && !places.length) {
      return false
    }
    if (type === 'pallet' && !places.length && !pallets.length) {
      return false
    }

    let isExist = false
    if (type === 'place') {
      places.forEach((p) => {
        p.items.forEach((i) => {
          if (i.id === item.id) {
            isExist = true
          }
        })
      })
    }
    if (type === 'pallet') {
      pallets.forEach((pallet) => {
        pallet.places.forEach((p) => {
          p.items.forEach((i) => {
            if (i.id === item.id) {
              isExist = true
            }
          })
        })
      })
    }
    return isExist
  }

  const handleRemoveItem = (removingItemId: string, count: number = 1) => {
    if (!items) return

    const itemForDeletingIndex = items.findIndex(
      (item: IOrderItem) => item.id === removingItemId,
    )

    if (itemForDeletingIndex > -1) {
      const newItems = [...items.map((item) => ({ ...item }))]
      if (items[itemForDeletingIndex].quantity === count) {
        newItems.splice(itemForDeletingIndex, 1)
      } else {
        newItems[itemForDeletingIndex].quantity =
          newItems[itemForDeletingIndex].quantity - count
      }
      setItems(newItems)
    }

    setAddingItem(null)
  }

  const isInValidItemQuantity = (
    newItemId: string,
    quantity: number,
    type: 'places' | 'pallets' = 'places',
  ) => {
    const getItemsCountInPlaces = () =>
      places.reduce((acc: number, p: IOrderPackagingPlace) => {
        const count = p.items.reduce((a: number, item) => {
          return item.id === newItemId ? a + Number(item.quantity) : a
        }, 0)
        return acc + count
      }, 0)

    const getItemsCountInPallets = () =>
      pallets.reduce((accP: number, pallet) => {
        const countP = pallet.places.reduce(
          (acc: number, p: IOrderPackagingPlace) => {
            const count = p.items.reduce((a: number, item) => {
              return item.id === newItemId ? a + Number(item.quantity) : a
            }, 0)
            return acc + count
          },
          0,
        )
        return accP + countP
      }, 0)

    const allItemsQuantity =
      type === 'places' ? getItemsCountInPlaces() : getItemsCountInPallets()

    const originalItemQuantity = order?.items.find(
      (item: IOrderItem) => item.id === newItemId,
    )?.quantity

    const valid = !!(
      originalItemQuantity && originalItemQuantity < allItemsQuantity + quantity
    )

    if (valid) {
      notification?.show('alert', 'Недопустимое кол-во товара', {
        soundName: 'PACKING_SURPLUS_ITEM',
      })
    }

    return valid
  }

  const handleChangePlace = (newData: IOrderPackagingPlace) => {
    if (currentPlace === null) return false

    const newPlaces = places.slice()
    newPlaces[currentPlace] = newData
    setPlaces(newPlaces)
  }

  const handleSavePlace = (
    placeId: string,
    data: IOrderPackagingPlace,
    notificationMessage?: string,
  ) => {
    if (currentPlace === null) return false

    handleChangePlace({
      ...data,
      id: placeId,
    })
    setCurrentPlace(null)

    if (notificationMessage) {
      notification?.show('success', notificationMessage, {
        soundName: 'PACKING_PLACE_POSTED',
      })
    } else {
      notification?.show('success', `Место ${places.length} успешно создано`, {
        soundName: 'PACKING_PLACE_POSTED',
      })
    }
  }

  const handleShowWeigthDimensions = (item: IOrderItem) => {
    setCurItem(item)
    setShowWeigthDimensions(true)
  }

  const handleCloseKitModal = () => {
    setKitModalData(null)
    blurOrFocusBarcodeInput('focus')
  }

  const resetOrder = (resetOrderFunc: any) => {
    setGlobalLoading(true)
    resetOrderFunc
      .mutateAsync()
      .then(async (result: { success: boolean }) => {
        notification?.show('success', 'Упаковка успешно сброшена')
        setTimeout(() => {
          history.push('/order/scan')
        }, 1500)
      })
      .finally(() => setGlobalLoading(false))
  }

  return (
    <PackingOrderContext.Provider
      value={{
        order,
        items,
        places,
        packages,
        showShippingForm,
        cachedItem,
        addingItem,
        currentPlace,
        curItem,
        showWeigthDimensions,
        kitModalData,
        setShowShippingForm,
        setOrder,
        setItems,
        setPlaces,
        setCachedItem,
        setAddingItem,
        setCurrentPlace,
        setCurItem,
        setShowWeigthDimensions,
        setKitModalData,
        handleGetDataForOrder,
        checkItemInPlaces,
        isInValidItemQuantity,
        handleRemoveItem,
        handleChangePlace,
        handleSavePlace,
        handleShowWeigthDimensions,
        handleCloseKitModal,
        setType,
        resetOrder,
        pallets,
        setPallets,
        currentPallet,
        setCurrentPallet,
        setPackagings,
        setCachedPackages,
        cachedPackages,
        palletsForRelabeling,
        setPalletsForRelabeling,
        addFiles,
        setAddFiles,
        shippingFormViewed,
        setShippingFormViewed,
        batch,
        setBatch,
        originalItemsCount,
        setOriginalItemsCount,
        originalItems,
        setOriginalItems,
        packedAttachments,
        setPackedAttachments,
        currentOperation,
        setCurrentOperation,
        showClosePallet,
        setShowClosePallet,
        relabeledPallets,
        setRelabeledPallets,
        closingErrorData,
        setClosingErrorData,
        relabelingPlaces,
        setRelabelingPlaces,
        printedPalletLists,
        setPrintedPalletLists,
        cachedSerials,
        setCachedSerials,
        currentClosePlaceModal,
        setCurrentClosePlaceModal,
        curPackingType,
        setCurPackingType,
        errorWeight,
        setErrorWeight,
        showClosePlace,
        setShowClosePlace,
        curPackageId,
        setCurPackageId,
        palletCount,
        setPalletCount,
        indicateItemCount,
        setIndicateItemCount,
        alert,
        setAlert,
      }}
    >
      {children}
    </PackingOrderContext.Provider>
  )
}

export const usePackingOrderContext = () => {
  const context = useContext(PackingOrderContext)
  return context
}
