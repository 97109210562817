import React, { useState } from 'react'

import styles from './additional-packaging-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconCheck } from '@consta/uikit/IconCheck'
import { IconCancel } from '@consta/uikit/IconCancel'

import { Modal, ItemPreview } from 'src/components'

import ChoiceManualPackageModal from '../ChoiceManualPackageModal'

import { usePackingOrderContext } from '../../context'

import {
  ModalProps,
  IOrderItem,
  IOrderPackagingOption,
  IDimensions,
} from 'src/interfaces'

interface AdditionalPackagingModalProps extends ModalProps {
  item: IOrderItem | null
  onAdditionalPackagingAdding: (packageId: string | null) => void
}

const AdditionalPackagingModal = (props: AdditionalPackagingModalProps) => {
  const { isOpen, item, onClose, onAdditionalPackagingAdding } = props

  const { packages, setCachedPackages, cachedPackages } =
    usePackingOrderContext()

  const [selected, setSelected] = useState<IOrderPackagingOption | null>(null)
  const [showChoiceManualPackage, setShowChoiceManualPackage] =
    useState<boolean>(false)

  const handleChoicePackage = () => {
    // если у текущего товара уже была выбрана доп. упаковка, выбор упаковки пропускается
    if (item?.id && cachedPackages[item.id]) {
      const cachedPackage = cachedPackages[item.id]
      onAdditionalPackagingAdding(cachedPackage)
      return
    }

    setShowChoiceManualPackage(true)
  }

  const handleAdditionalPackagingAdding = (packageId: string) => {
    if (item?.id) {
      setCachedPackages({
        ...cachedPackages,
        [item.id]: packageId,
      })
    }

    onAdditionalPackagingAdding(packageId)
  }

  const handleCloseAddPackage = () => {
    if (item?.id) {
      setCachedPackages({
        ...cachedPackages,
        [item.id]: 'noAddPackage',
      })
    }
    onAdditionalPackagingAdding(null)
  }

  return (
    <>
      {showChoiceManualPackage ? (
        <ChoiceManualPackageModal
          isOpen={isOpen}
          packagingOptions={packages}
          selected={selected}
          onSelect={setSelected}
          onBack={onClose}
          onClose={onClose}
          onSubmit={handleAdditionalPackagingAdding}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onOverlayClick={(): boolean => false}
          className={styles.additionalPack}
          size="s"
          hasOverlay={false}
        >
          {item ? (
            <ItemPreview
              image={item.image}
              title={item.title}
              barcode={item.barcodes[0].barcode}
            />
          ) : null}

          <h3 className={styles.redText}>
            Требуется ли дополнительная упаковка?
          </h3>

          <div className={styles.btns}>
            <Button
              label="Нет"
              view="ghost"
              className={styles.btn}
              onClick={handleCloseAddPackage}
              iconLeft={IconCancel}
              size="l"
            />
            <Button
              label="Да"
              className={styles.btn}
              iconLeft={IconCheck}
              onClick={handleChoicePackage}
              size="l"
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default AdditionalPackagingModal
