import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './batch-packing-order.module.scss'

import { Badge } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'

import MainBlock from './MainBlock'
import ClosePlace from './ClosePlace'

import {
  StickerPrintModal,
  ItemAdding,
  ShippingFormModal,
  WaitingClosingModal,
  PackedModal,
} from '../components'

import {
  ItemScanner,
  SideBar,
  Img,
  StickerErrorModal,
  ItemStickerPrintModal,
} from 'src/components'

import { useNotifications } from 'src/notification'
import { useSound } from 'src/sound'
import { useUpdateBatchPackingPlace, useResetBatchOrder } from 'src/hooks'

import { useAppContext } from 'src/context'
import { usePackingOrderContext } from '../context'

import { CLOSE_PACKAGING_BARCODE } from 'src/config'

import {
  //saveBase64AsPdf,
  getItemsQuantity,
  parseError,
  rusToLatin,
  getOrderItem,
} from 'src/helpers'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { PackingOrderProps } from '../interfaces'
import {
  IOrderItem,
  IOrderPackagingPlace,
  IError,
  IUpdatePackingPlaceResult,
  ResError,
  IPlaceErrorData,
  IOrderBatchPackagingPlace,
  IOrderPackagingOption,
} from 'src/interfaces'
import { usePackingPrintContext } from '../context/PackingPrintContext'

interface B2CPackingOrderProps extends PackingOrderProps {}

const BatchPackingOrder = (props: B2CPackingOrderProps) => {
  const { orderId } = props

  // context

  const { setGlobalLoading } = useAppContext()
  const {
    items,
    places,
    showShippingForm,
    addingItem,
    currentPlace,
    setShowShippingForm,
    setPlaces,
    setCachedItem,
    setAddingItem,
    setCurrentPlace,
    setCurItem,
    handleRemoveItem,
    handleSavePlace,
    batch,
    originalItemsCount,
    originalItems,
    order,
    packages: packagingOptions,
  } = usePackingOrderContext()

  const {
    printSticker,
    showStickerError,
    sticker,
    setShowStickerPrintInfo,
    showStickerPrintInfo,
    handleCloseStickerError,
    barcodeInput,
    blurOrFocusBarcodeInput,
    handleCloseAndFocusBarcode,
  } = usePackingPrintContext()

  //TODO move all useState to useReducer
  // state

  const [errorWeight, setErrorWeight] = useState<string | null>(null) //TODO better
  const [showClosePlace, setShowClosePlace] = useState<boolean>(false)
  const [placeErrorData, setPlaceErrorData] = useState<IPlaceErrorData | null>(
    null,
  )
  const [showStickerPrint, setShowStickerPrint] = useState<boolean>(false)
  const [showPacked, setShowPacked] = useState<boolean>(false)
  const [currentClosePlaceModal, setCurrentClosePlaceModal] =
    useState('choicePackage')
  const [curOrder, setCurOrder] = useState<string | null>(null)
  const [packagings, setPackagings] = useState<IOrderPackagingOption[]>([])
  const [currentOperation, setCurrentOperation] = useState<string>('itemAdding')

  // request hooks

  const packingPlace = useUpdateBatchPackingPlace()
  const resetBatchOrder = useResetBatchOrder()

  // other hooks

  const notification = useNotifications()
  const history = useHistory()
  const sound = useSound()

  // hooks

  useEffect(() => {
    if (isAllPacked) {
      notification?.show('success', `Батч успешно укомплектован`, {
        soundName: 'PACKING_COMPLETED',
      })
    }
  }, [places])

  // other functions

  const getFormattedItemForAdding = (
    barcode: string,
    isItemId: boolean = false,
    item: IOrderItem,
  ) => {
    const formattedBarcode = !isItemId
      ? barcode
      : item.barcodes?.find((b) => b.barcode)?.barcode ?? ''
    return { ...item, barcode_used: formattedBarcode }
  }

  const setCurrentOrderPackagings = (orderId: string) => {
    const curOrderPackages =
      batch?.find((batchItem) => batchItem?.id === orderId)?.packagings ?? []
    setPackagings(curOrderPackages)
  }

  const getReqDataForUpdateBatchPlace = ({
    batchOrderDate,
    batchOrderId,
    ...data
  }: IOrderBatchPackagingPlace): IOrderBatchPackagingPlace => {
    return {
      ...data,
      items: data.items.map(({ article, ...rest }) => ({ ...rest })),
    }
  }

  const getPlaceData = (
    data: IOrderBatchPackagingPlace,
  ): IOrderBatchPackagingPlace => {
    const batchOrderDate = originalItems.find(
      (item) => item.batchOrderId === curOrder,
    )?.batchOrderDate
    return {
      ...data,
      is_final: true,
      batchOrderId: curOrder ?? '',
      batchOrderDate,
    }
  }

  const getDeliveryLogo = (): string => {
    if (!batch?.length) return ''

    const firstDelivery = batch[0]?.delivery
    const isOnlyOneDelivery =
      batch.filter(
        (order) =>
          order?.delivery?.contract_title === firstDelivery?.contract_title,
      ).length === batch.length

    if (isOnlyOneDelivery && firstDelivery?.logo) {
      return firstDelivery.logo
    }

    return ''
  }

  const shouldAddItem = (value: string, isItemId: boolean): boolean => {
    const item = getOrderItem(value, isItemId, originalItems)
    if (!item) {
      notification?.show(
        'alert',
        `Товар со штрихкодом ${value} не найден в задании на упаковку`,
        { soundName: 'PACKING_WRONG_ITEM' },
      )
      return false
    }

    const currentActualItem = !!items?.find((i: IOrderItem) => i.id === item.id)
    if (!currentActualItem) {
      notification?.show('alert', 'Лишний товар', {
        soundName: 'PACKING_SURPLUS_ITEM',
      })
      return false
    }

    return true
  }

  const prepareItemForAdding = (barcode: string, isItemId: boolean = false) => {
    const currentItem = getOrderItem(barcode, isItemId, items ?? [])
    if (!currentItem || !currentItem.batchOrderId) return

    const formattedItem = getFormattedItemForAdding(
      barcode,
      isItemId,
      currentItem,
    )
    setCurItem(formattedItem)
    setCurOrder(currentItem.batchOrderId)
    setCurrentOrderPackagings(currentItem.batchOrderId)

    handleSetAddingItem(formattedItem)
  }

  const updateBatchPlace = async (data: IOrderBatchPackagingPlace) => {
    if (!curOrder) return

    const reqPlaceData = getReqDataForUpdateBatchPlace(data)

    console.log('reqPlaceData')
    console.log(reqPlaceData)
    setGlobalLoading(true)

    await packingPlace
      .fetch({
        orderId: curOrder,
        place: reqPlaceData,
      })
      .then(async (result: IUpdatePackingPlaceResult) => {
        const {
          place_id,
          sticker: { content, printer: printerName },
        } = result

        setPlaceErrorData(null)

        printSticker(printerName, content, { copies: 1 })

        handleSavePlace(
          place_id,
          data,
          `Заказ ${curOrder} успешно укомплектован`,
        )
      })
      .catch((err: any) => {
        processErrorsOfUpdatePlace(err, data)
      })
      .finally(() => setGlobalLoading(false))
  }

  const processErrorsOfUpdatePlace = (
    err: any,
    data: IOrderBatchPackagingPlace,
  ): void | never => {
    //если есть стикер ошибки, выйти из заказа
    if (err?.errors) {
      err.errors.forEach((e: ResError) => {
        if (e?.errorsticker?.content) {
          setTimeout(() => {
            history.push('/')
          }, 3000)
        }
      })
    }

    //если есть ошибка 'INCORRECT_WEIGHT', вывести заново модалку ввода веса
    if (err?.errors) {
      const weightErr = err.errors.find(
        (e: IError) => e.code === 'INCORRECT_WEIGHT',
      )
      if (weightErr) {
        setErrorWeight(weightErr.message)
        setCurrentClosePlaceModal('weightingPlace')
        setShowClosePlace(true)
        return
      }
    }

    //вывести модалку ожидания закрытия места
    setPlaceErrorData({
      show: true,
      data: data,
      errors: err.data ?? err,
    })
    parseError(err)
    throw err
  }

  // handlers

  const handleSetAddingItem = (currentItem: IOrderItem): void => {
    setAddingItem(currentItem)
  }

  const handleScanBarcode = (
    value: TextFieldPropValue,
    isItemId: boolean = false,
  ): void => {
    if (!value) return
    let newVal = rusToLatin(value)

    if (newVal === CLOSE_PACKAGING_BARCODE) {
      setShowClosePlace(true)
      return
    }

    if (shouldAddItem(value, isItemId)) {
      setCurrentOperation('itemAdding')
      setCurrentPlace(null)
      prepareItemForAdding(value, isItemId)
    }
  }

  const handlePlaceAdd = async (newPlace: IOrderPackagingPlace) => {
    setCurrentPlace(places.length)
    setPlaces([...places, newPlace])

    const removingItem = newPlace?.items?.[0]
    setCachedItem(removingItem)

    const removingItemId = removingItem?.id
    const removingItemQuantity = removingItem?.quantity
    handleRemoveItem(removingItemId, removingItemQuantity)
    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')

    await blurOrFocusBarcodeInput('blur')
    setShowClosePlace(true)
  }

  const handleClosePlace = async (newData: IOrderBatchPackagingPlace) => {
    if (!curOrder) return false

    setCurrentOperation('placeClosing')

    const newPlaceData = getPlaceData(newData)
    return await updateBatchPlace(newPlaceData)
  }

  // const handleResetOrder = (orderId: string) => {
  //   setGlobalLoading(true)
  //   resetBatchOrder
  //     .fetch({ orderId })
  //     .then(async (result: { success: boolean }) => {
  //       notification?.show('success', 'Упаковка успешно сброшена')
  //     })
  //     .finally(() => setGlobalLoading(false))
  // }

  const handleChooseItem = (item: IOrderItem) => {
    handleScanBarcode(item.id ?? '', true)
  }

  // other

  const isOrderRequirePlaceWeight = useMemo(
    () => !!batch.find((order) => order?.id === curOrder)?.require_place_weight,
    [batch, curOrder],
  )

  const isAllPacked =
    places.length && places.filter((p) => p.id).length === batch?.length

  const deliveryLogo = useMemo(() => getDeliveryLogo(), [batch])

  return (
    <>
      <div className={styles.main}>
        <SideBar
          bottomBtns={
            <>
              {/*<Button*/}
              {/*  className={cx(*/}
              {/*    styles.iconBtn,*/}
              {/*    order?.shipping_form?.length && !shippingFormViewed*/}
              {/*      ? styles.iconBtnCircle*/}
              {/*      : null,*/}
              {/*  )}*/}
              {/*  view="secondary"*/}
              {/*  iconLeft={IconInfo}*/}
              {/*  label="Анкета отгрузки"*/}
              {/*  onClick={() => setShowShippingForm(true)}*/}
              {/*  disabled={!order?.shipping_form?.length}*/}
              {/*/>*/}
              {/*<div>*/}
              {/*  {batch.map((b) => (*/}
              {/*    <Button*/}
              {/*      label={b.id}*/}
              {/*      onClick={() => handleResetOrder(b.id ?? '')}*/}
              {/*    />*/}
              {/*  ))}*/}
              {/*</div>*/}
              <div className={styles.btns}>
                <Button
                  label="Прекратить упаковку"
                  onClick={() => history.push('/')}
                  view="ghost"
                />
                <Button
                  label="Завершить упаковку"
                  onClick={() => history.push('/')}
                  disabled={!isAllPacked}
                />
              </div>
            </>
          }
        >
          <div className={styles.fboTop}>
            {deliveryLogo && (
              <Img className={styles.img} src={deliveryLogo} yandex />
            )}
            <Badge
              className={styles.badge}
              size="m"
              view="stroked"
              label="Single Упаковка"
            />
          </div>

          <div className={styles.packCount}>
            Упаковано
            <h2>
              <span>
                {items ? (
                  <>
                    {(originalItemsCount || 0) - (getItemsQuantity(items) || 0)}
                  </>
                ) : null}
              </span>{' '}
              из {originalItemsCount || 0}
            </h2>
          </div>

          <div className={styles.packName}>
            Заказ <h2>{orderId}</h2>
            <div className={styles.badgesBlock}>
              <Badge
                className={styles.badgeFbo}
                size="m"
                status="system"
                view="stroked"
                label="BATCH"
              />
            </div>
          </div>

          <ItemScanner
            label="Отсканируйте штрихкод"
            advanceModeForCount
            showItemCountCheckbox={false}
            onChange={(value) => handleScanBarcode(value)}
            inputRef={barcodeInput}
          />
        </SideBar>
        <div className={styles.bodyWrap}>
          <div className={styles.body}>
            <MainBlock
              order={order}
              items={items}
              places={places}
              currentPlace={currentPlace}
              packagingOptions={packagingOptions || []}
              setShowPacked={setShowPacked}
              handleChooseItem={handleChooseItem}
            />
          </div>
        </div>
      </div>

      {addingItem ? (
        <ItemAdding
          addingItem={addingItem}
          packagingOptions={packagingOptions || []}
          currentPlace={currentPlace}
          places={places}
          onPlaceAdd={handlePlaceAdd}
          onClose={() => setAddingItem(null)}
          setAddingItem={(item) => setAddingItem(item)}
          blurOrFocusBarcodeInput={blurOrFocusBarcodeInput}
          sequence_num={1}
          type="places"
        />
      ) : null}

      {showClosePlace && currentPlace !== null ? (
        <ClosePlace
          isOpen={true}
          currentModal={currentClosePlaceModal}
          setCurrentModal={setCurrentClosePlaceModal}
          requireWeight={isOrderRequirePlaceWeight}
          currentPlaceData={places[currentPlace]}
          packagingOptions={packagings}
          onSubmit={handleClosePlace}
          onClose={() => setShowClosePlace(false)}
          errorWeight={errorWeight}
          maxWeight={
            order?.delivery?.maximum_place_weight
              ? order.delivery.maximum_place_weight
              : null
          }
          clearErrorWeight={() => setErrorWeight(null)}
          blurOrFocusBarcodeInput={blurOrFocusBarcodeInput}
        />
      ) : null}

      {showStickerPrint ? (
        <StickerPrintModal
          isOpen={true}
          onSuccess={handleSavePlace}
          onClose={() => history.push('/')}
        />
      ) : null}

      {/* TODO вынести модалки ошибок в один унифицированный компонент??? */}
      {showStickerError ? (
        <StickerErrorModal
          isOpen={true}
          sticker={sticker}
          onClose={handleCloseStickerError}
          isFinal={!items?.length}
          showStickerPrint={() => setShowStickerPrint(true)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {placeErrorData?.show ? (
        <WaitingClosingModal
          orderId={curOrder ?? ''}
          isOpen={true}
          onClose={() => {
            setPlaceErrorData(null)
          }}
          errorData={placeErrorData?.errors}
          onRetry={() => handleClosePlace(placeErrorData.data)}
        />
      ) : null}

      {showShippingForm ? (
        <ShippingFormModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowShippingForm)}
          shippingFormData={order?.shipping_form || []}
          blurRef={barcodeInput}
          order={order}
        />
      ) : null}

      <PackedModal
        places={places}
        order={order}
        isOpen={showPacked}
        onClose={() => setShowPacked(false)}
        packagingOptions={packagingOptions ?? []}
      />

      {showStickerPrintInfo ? (
        <ItemStickerPrintModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowStickerPrintInfo)}
          subtitle={
            currentOperation === 'placeClosing'
              ? 'Приклейте стикер на место'
              : 'Приклейте стикер на упаковку'
          }
        />
      ) : null}
    </>
  )
}

export default BatchPackingOrder
