import { useEffect, useMemo, useState } from 'react'
import { usePackingOrderContext } from '../../context'

import { checkAllPalletsFilled } from 'src/helpers'

import { RelabelingTypes } from 'src/interfaces'

const useSetOrderStatus = ({
  isSuccessRelabel,
}: {
  isSuccessRelabel: boolean
}) => {
  const [orderStatus, setOrderStatus] = useState<string>('Перемаркировка')

  const { order, pallets } = usePackingOrderContext()

  const isAllPalletsFilled = useMemo(
    () => checkAllPalletsFilled(pallets),
    [pallets],
  )

  useEffect(() => {
    if (isSuccessRelabel) {
      setOrderStatus('Успешно перемаркирован')
      return
    }

    if (
      (order?.relabelingType === RelabelingTypes.BOXES && isAllPalletsFilled) ||
      (order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS &&
        !pallets.length)
    ) {
      setOrderStatus('Перемаркировка Коробов')
      return
    }

    if (
      order?.relabelingType === RelabelingTypes.BOXES &&
      !isAllPalletsFilled
    ) {
      setOrderStatus('Перемаркировка Коробов с паллетирование')
      return
    }

    if (
      (order?.relabelingType === RelabelingTypes.PALLETS ||
        order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS) &&
      isAllPalletsFilled
    ) {
      setOrderStatus('Перемаркировка паллет')
      return
    }

    if (
      (order?.relabelingType === RelabelingTypes.PALLETS ||
        order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS) &&
      !isAllPalletsFilled
    ) {
      setOrderStatus('Перемаркировка паллет с паллетирование')
    }
  }, [order?.operationType, isAllPalletsFilled, isSuccessRelabel])

  return { orderStatus } as { orderStatus: string }
}
export default useSetOrderStatus
