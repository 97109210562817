import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import B2CPackingOrder from './B2CPackingOrder'
import B2BPackingOrder from './B2BPackingOrder'
import B2BRelabelingOrder from './B2BRelabelingOrder'
import ClosedOrder from './ClosedOrder'
import BatchPackingOrder from './BatchPackingOrder'
import B2BDocsOrder from './B2BDocsOrder'

import {
  PackingOrderProvider,
  PackingPrintProvider,
  usePackingOrderContext,
} from './context'
import { useAppContext } from 'src/context'

import { useGetPackingById, useGetBatch } from 'src/hooks'
import { useNotifications } from 'src/notification'
import {
  IBatchOrder,
  IOrder,
  IOrderPackagingOption,
  OperationTypes,
} from 'src/interfaces'

type orderType = 'closed' | 'batch' | 'order' | 'docs' | 'relabel'
type orderDataType = {
  order?: IOrder
  packagings?: IOrderPackagingOption[]
  orders?: IBatchOrder[]
  orderData?: null
}

const getArmComponent = (
  orderType: orderType,
  operationType: string | null,
) => {
  if (orderType === 'closed') {
    return ClosedOrder
  }

  if (orderType === 'batch') {
    return BatchPackingOrder
  }

  if (orderType === 'docs') {
    return B2BDocsOrder
  }

  if (orderType === 'relabel') {
    return B2BRelabelingOrder
  }

  switch (operationType) {
    case OperationTypes.PACKAGING_B2B:
      return B2BPackingOrder
    case OperationTypes.RELABELING:
      return B2BRelabelingOrder
    default:
      return B2CPackingOrder
  }
}

const reqObj = {
  closed: useGetPackingById,
  resume: useGetPackingById,
  relabel: useGetPackingById,
  order: useGetPackingById,
  docs: useGetPackingById,
  batch: useGetBatch,
}

const PackingOrderPage = () => {
  const params = useParams<{ orderId: string }>()
  const history = useHistory()
  const location = useLocation<{ type?: orderType }>()
  const notification = useNotifications()

  const type = location?.state?.type

  const { setGlobalLoading } = useAppContext()
  const { setOrder, setType, setPackagings, setBatch, batch, order } =
    usePackingOrderContext()

  const [operationType, setOperationType] = useState<string | null>(null)

  useEffect(() => {
    setGlobalLoading(true)
  }, [])

  const getRequestType = (type?: string) => {
    if (type === 'closed') return
    return type
  }

  const requestHook = reqObj[type ?? 'order']

  const {
    data: { success: dataLoaded, ...orderData } = {
      success: false,
      orderData: null,
    },
  } = requestHook(
    { orderId: params.orderId, type: getRequestType(type) },
    undefined,
    () => history.push('/'),
    () => setGlobalLoading(false),
  )

  const handleGetData = (data: orderDataType) => {
    if (type === 'batch' && data?.orders) {
      setBatch(data?.orders)
      return
    }
    if (data?.packagings && data?.order) {
      setOrder(data.order)
      setPackagings(data.packagings)
      if (data.order.operationType) {
        setOperationType(data.order.operationType)
      }
    }
  }

  useEffect(() => {
    if (dataLoaded) {
      handleGetData(orderData)
    }
  }, [orderData])

  useEffect(() => {
    setType(type)
  }, [type])

  const ArmComponent = getArmComponent(type ?? 'order', operationType)

  if (!dataLoaded || (type === 'batch' ? !batch : !order)) return null

  return <ArmComponent orderId={params.orderId} type={type} />
}

const PackingOrderPageWithContext = () => (
  <PackingPrintProvider>
    <PackingOrderProvider>
      <PackingOrderPage />
    </PackingOrderProvider>
  </PackingPrintProvider>
)

export default PackingOrderPageWithContext
