import React, { useEffect, useState } from 'react'

import styles from './weigth-dimensions-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import {
  Modal,
  InputWrapper,
  DimensionsInput,
  EnterHandler,
} from 'src/components'

import { useUpdateWeightAndDimensions } from 'src/hooks'

import {
  ModalProps,
  IOrderItem,
  IItemWeightAndDimensions,
  IDimensions,
  updateWeightAndDimensionsRes,
} from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface IWeightDimensionsModal extends ModalProps {
  item: IOrderItem | null
  hardClose?: boolean
  onSubmit: (weight?: number, manualRequireWeight?: boolean) => void
  top?: React.ReactNode
  title?: string
  subtitle?: string
  img?: string
  imgClass?: string
  withClose?: boolean
  checkPlaceWeight?: (weight: number) => boolean
  addWeightToCache?: (itemId: string, weight: number) => void
  needUpdateWeightAndDimensions?: boolean
}

const WeightDimensionsModal = (props: IWeightDimensionsModal) => {
  const {
    item,
    isOpen,
    hardClose,
    onClose,
    onSubmit,
    onMount,
    top,
    title,
    subtitle,
    img,
    imgClass,
    withClose,
    checkPlaceWeight,
    addWeightToCache,
    needUpdateWeightAndDimensions = true,
  } = props

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  const updateWeightAndDimensions = useUpdateWeightAndDimensions({
    itemId: item?.id || '',
  })

  const [weight, setWeight] = useState<string | null>(null)

  const [dimensions, setDimensions] = useState<IDimensions>({
    width: null,
    height: null,
    depth: null,
  })

  const handleSubmit = () => {
    if (!checkDisabled()) {
      return false
    }

    if (checkPlaceWeight && weight && !checkPlaceWeight(+weight)) {
      return false
    }

    const newItem: IItemWeightAndDimensions = {}
    if (weight) {
      newItem.weight = +weight
    }
    if (dimensions.width && dimensions.height && dimensions.depth) {
      newItem.dimensions = {
        width: +dimensions.width,
        height: +dimensions.height,
        depth: +dimensions.depth,
      }
    }
    if (needUpdateWeightAndDimensions) {
      updateWeightAndDimensions
        .mutateAsync({ item: newItem })
        .then(async (result: updateWeightAndDimensionsRes) => {
          const { success } = result
          if (success) {
            onSubmit(+(weight ?? 0))
            if (addWeightToCache && item && weight && +weight) {
              addWeightToCache(item.id, +weight)
            }
            if (hardClose) {
              onClose()
            }
          }
        })
    } else {
      onSubmit(+(weight ?? 0), true)
      if (addWeightToCache && item && weight && +weight) {
        addWeightToCache(item.id, +weight)
      }
    }
  }

  const checkDisabled = () => {
    if (item?.require_weight && item?.require_dimensions) {
      return (
        Number(weight) &&
        Number(dimensions.width) &&
        Number(dimensions.height) &&
        Number(dimensions.depth)
      )
    }
    if (item?.require_weight) {
      return Number(weight)
    }
    if (item?.require_dimensions) {
      return (
        Number(dimensions.width) &&
        Number(dimensions.height) &&
        Number(dimensions.depth)
      )
    }
    if (!item?.require_weight && !item?.weight) {
      return Number(weight)
    }
    return false
  }

  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.itemCount}
        onClose={onClose}
        title={title}
        subtitle={subtitle}
        img={img}
        imgClass={imgClass}
        size="s"
      >
        {top}

        {item?.require_weight || !item?.weight ? (
          <InputWrapper
            label={'Введите вес товара'}
            value={weight}
            id={'weight'}
            handleChange={(value: TextFieldPropValue) => setWeight(value)}
            className={styles.itemInput}
            placeholder={'Вес товара'}
            size="l"
            rightSide="гр"
            isInteger
            autoFocus
          />
        ) : null}

        {item?.require_dimensions ? (
          <DimensionsInput
            value={dimensions}
            onChange={setDimensions}
            integerNumbersInputs
            autoFocus={!item?.require_weight}
          />
        ) : null}

        <div className={styles.btns}>
          <Button
            label="Подтвердить"
            className={styles.btn}
            onClick={handleSubmit}
            disabled={!checkDisabled()}
          />
          {withClose ? (
            <Button
              label="Закрыть"
              view="ghost"
              className={styles.btn}
              onClick={onClose}
            />
          ) : null}
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default WeightDimensionsModal
