import React from 'react'
import cx from 'classnames'

import styles from './select.module.scss'

import { Select } from '@consta/uikit/Select'

import { useAppContext } from 'src/context'

import { ISelectOption } from 'src/interfaces'

interface SelectProps {
  label?: string
  options: ISelectOption[]
  value?: ISelectOption | null
  className?: any
  onChange: (props: {
    value: ISelectOption | null
    e: React.SyntheticEvent<Element, Event>
  }) => void
  size?: 'xs' | 's' | 'm' | 'l'
  form?:
    | 'default'
    | 'round'
    | 'brick'
    | 'brickDefault'
    | 'defaultBrick'
    | 'clearRound'
    | 'roundClear'
    | 'clearDefault'
    | 'defaultClear'
    | 'brickClear'
    | 'clearBrick'
    | 'clearClear'
}

const SelectWrapper = (props: SelectProps) => {
  const { label, options, value, onChange, className, form, size } = props

  const { resetAfkInterval } = useAppContext()

  const handleChange = (data: {
    value: ISelectOption | null
    e: React.SyntheticEvent<Element, Event>
  }) => {
    resetAfkInterval()

    onChange(data)
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      {label ? <label>{label}</label> : null}
      <Select
        items={options}
        value={value}
        getItemKey={(o: ISelectOption) => o.value}
        onChange={onChange}
        form={form}
        size={size}
      />
    </div>
  )
}

export default SelectWrapper
