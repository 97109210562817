import { ISupply } from 'src/interfaces'

export enum AcceptanceContextActionTypes {
  setSavedChz = 'setSavedChz',
  addSavedWeightAndDimensions = 'addSavedWeightAndDimensions',
  setSupply = 'setSupply',
}

export interface AcceptanceContextState {
  savedChz: string | null
  savedWeightAndDimensions: string[]
  supply: ISupply | null
}

export type setSavedChzAction = {
  type: AcceptanceContextActionTypes.setSavedChz
  savedChz: string | null
}

export type addSavedWeightAndDimensions = {
  type: AcceptanceContextActionTypes.addSavedWeightAndDimensions
  itemId: string
}

export type setSupplyAction = {
  type: AcceptanceContextActionTypes.setSupply
  supply: ISupply | null
}

export type AcceptanceContextActions =
  | setSavedChzAction
  | addSavedWeightAndDimensions
  | setSupplyAction
