import React from 'react'

import styles from './pallets-packed-table-item.module.scss'
import IconPrinter from './assets/IconPrinter.svg'

import ChangeAddPack from '../../ChangeAddPack'
import { Img } from 'src/components'

import { hideSymbols } from 'src/helpers'

import { IOrderPackagingPlaceItem, IOrderItem } from 'src/interfaces'
import dayjs from 'dayjs'

interface IPlaceItemProps {
  item: IOrderPackagingPlaceItem
  index: number
  originalItem: IOrderItem | null
  status: boolean
  placeNum: number
  needSticker: boolean
  handlePrintItemSticker?: (
    id: string,
    barcode: string,
    expires?: string,
    dateOfManufacture?: string,
    advanced?: boolean,
  ) => void
}

const PlaceItem = ({
  item,
  index,
  originalItem,
  status,
  needSticker,
  placeNum,
  handlePrintItemSticker,
}: IPlaceItemProps) => {
  if (!originalItem) return null

  return (
    <>
      {item ? (
        <div className={styles.tableTr}>
          <div>
            <Img src={originalItem.image} size={100} yandex modal />
          </div>
          <div className={styles.name}>
            {originalItem?.title}{' '}
            <span>{hideSymbols(6, item.barcode_used)}</span>
          </div>
          <div className={styles.addPack}>
            {item.packagings ? (
              <ChangeAddPack
                placeNum={placeNum}
                index={index}
                item={item}
                status={status}
              />
            ) : null}
          </div>
          <div className={styles.batches}>
            {item.expirationDate
              ? dayjs(item.expirationDate).format('DD.MM.YYYY')
              : null}
          </div>
          <div>{item.quantity}</div>
          <div className={styles.print}>
            {needSticker && handlePrintItemSticker ? (
              <img
                src={IconPrinter}
                onClick={() =>
                  handlePrintItemSticker(
                    item.id,
                    item.barcode_used,
                    item.expirationDate,
                    item.dateOfManufacture,
                    false,
                  )
                }
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default PlaceItem
