import React from 'react'
import isMobile from 'ismobilejs'

import styles from '../auth-page.module.scss'

import { InputWrapper } from 'src/components'

import { EMPLOYEE_CHILDREN_PROPS } from 'src/config'

const EmployeeStep = (props: EMPLOYEE_CHILDREN_PROPS) => {
  const { handleChange, error = '' } = props

  return (
    <div className={styles.employeeMain}>
      <InputWrapper
        className={styles.input}
        withDelay
        label={
          isMobile().any
            ? 'Отсканируйте шк сотрудника'
            : 'Отсканируйте штрихкод сотрудника'
        }
        id={'employeeBarcode'}
        handleChange={handleChange}
        autoFocus
        error={error}
        size="l"
      />
    </div>
  )
}

export default EmployeeStep
