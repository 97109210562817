import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import cx from 'classnames'

import styles from './b2c-packing-order.module.scss'

import { Badge } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'
import { IconWarning } from '@consta/uikit/IconWarning'
import { Informer } from '@consta/uikit/Informer'
import { IconInfo } from '@consta/uikit/IconInfo'
import { IconSearch } from '@consta/uikit/IconSearch'
import { IconStorage } from '@consta/uikit/IconStorage'

import MainBlock from './MainBlock'
import ClosePlace from './ClosePlace'

import {
  StickerPrintModal,
  ItemAdding,
  ShippingFormModal,
  ItemScanSerial,
  KitModal,
  WaitingClosingModal,
  PackedModal,
  AddAttachmentsModal,
} from '../components'

import {
  WeightDimensionsModal,
  ItemScanner,
  SideBar,
  Img,
  StickerErrorModal,
  SearchItemsModal,
  ItemPreview,
  ItemStickerPrintModal,
} from 'src/components'

import { useNotifications } from 'src/notification'
import { useSound } from 'src/sound'
import {
  useUpdatePackingPlace,
  useResetPackedOrderById,
  useGetSticker,
} from 'src/hooks'

import { useAppContext } from 'src/context'
import { usePackingOrderContext } from '../context'

import {
  //saveBase64AsPdf,
  getItemsQuantity,
  parseError,
  rusToLatin,
} from 'src/helpers'

import {
  CLOSE_PACKAGING_BARCODE,
  PRINT_ITEM_STICKER_BARCODE,
  TAB_ITEM,
  TABS_ITEMS,
  MONTHS,
  PACK_DOCS,
} from 'src/config'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { PackingOrderProps } from '../interfaces'
import {
  IOrderItem,
  IOrderPackagingPlace,
  IOrderPackagingPlaceItem,
  ISerialNumber,
  IError,
  IUpdatePackingPlaceResult,
  ResError,
  IPlaceErrorData,
  IOrderPallet,
} from 'src/interfaces'
import { usePackingPrintContext } from '../context/PackingPrintContext'

interface B2CPackingOrderProps extends PackingOrderProps {}

const B2CPackingOrder = (props: B2CPackingOrderProps) => {
  const { orderId } = props

  const history = useHistory()
  const sound = useSound()

  const { setGlobalLoading, advanceMode } = useAppContext()

  const {
    items,
    places,
    showShippingForm,
    cachedItem,
    addingItem,
    currentPlace,
    kitModalData,
    setShowShippingForm,
    setPlaces,
    setCachedItem,
    setAddingItem,
    setCurrentPlace,
    setCurItem,
    setKitModalData,
    checkItemInPlaces,
    isInValidItemQuantity,
    handleRemoveItem,
    handleChangePlace,
    handleSavePlace,
    handleCloseKitModal,
    resetOrder,
    shippingFormViewed,
    order,
    packages: packagingOptions,
  } = usePackingOrderContext()

  const {
    applyPrintItemSticker,
    setShowStickerPrintInfo,
    showStickerPrintInfo,
    printSticker,
    showStickerError,
    sticker,
    handleCloseStickerError,
    barcodeInput,
    blurOrFocusBarcodeInput,
    handleCloseAndFocusBarcode,
  } = usePackingPrintContext()

  const packingPlaceMutation = useUpdatePackingPlace({
    orderId,
  })
  const resetOrderMutation = useResetPackedOrderById({
    orderId,
  })
  const getItemSticker = useGetSticker({ id: orderId, type: 'order' })

  const notification = useNotifications()

  //TODO move all useState to useReducer

  const [errorWeight, setErrorWeight] = useState<string | null>(null) //TODO better
  const [showClosePlace, setShowClosePlace] = useState<boolean>(false)
  const [placeErrorData, setPlaceErrorData] = useState<IPlaceErrorData | null>(
    null,
  )
  const [showStickerPrint, setShowStickerPrint] = useState<boolean>(false)
  const [showPacked, setShowPacked] = useState<boolean>(false)
  const [currentClosePlaceModal, setCurrentClosePlaceModal] =
    useState('choicePackage')
  const [showSearchItemsModal, setShowSearchItemsModal] =
    useState<boolean>(false)
  const [indicateItemCount, setIndicateItemCount] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [activeTab, setActive] = useState<TAB_ITEM | null>(TABS_ITEMS[0])
  const [currentOperation, setCurrentOperation] = useState<string>('itemAdding')
  const [showAddAttachments, setShowAddAttachments] = useState<boolean>(false)
  const [addAttachmentsIsPacked, setAddAttachmentsIsPacked] =
    useState<boolean>(false)

  const originalItemsCount = getItemsQuantity(order?.items)
  const remainingItems = useMemo(
    () => Number(getItemsQuantity(items ?? [])),
    [items],
  )

  const handleSetAddingItem = (currentItem: IOrderItem) => {
    setAddingItem(currentItem)
  }

  useEffect(() => {
    const itemsQuantity = items ? getItemsQuantity(items) : null

    if (
      itemsQuantity !== null &&
      originalItemsCount &&
      originalItemsCount === originalItemsCount - itemsQuantity
    ) {
      blurOrFocusBarcodeInput('blur')
      setShowClosePlace(true)
    }
  }, [originalItemsCount, items])

  const printLastItemSticker = () => {
    if (cachedItem?.id) {
      setCurrentOperation('itemAdding')
      printItemSticker(cachedItem.id, 1, cachedItem?.dateOfManufacture)
    }
  }

  const handleStartItem = (
    value: TextFieldPropValue,
    isItemId: boolean = false,
  ) => {
    let newVal = rusToLatin(value)

    if (newVal === PACK_DOCS) {
      const currentItem = items?.find((item) => item.id === 'SDoc')
      if (!currentItem) {
        notification?.show('alert', 'Сопроводительные документы не найдены')
        return
      }

      if (order?.ExtraSeat && currentPlace !== null) {
        notification?.show(
          'alert',
          'Сопроводительные документы должны быть упакованы в отдельное место',
        )
        return
      }
      handleSetAddingItem({
        ...currentItem,
        barcode_used: 'SDoc',
      })
      return
    }

    if (newVal === CLOSE_PACKAGING_BARCODE) {
      const maxCount = order?.delivery?.maximum_places_count

      if (maxCount && places.length >= maxCount) {
        const placesItemsCount = places.reduce(
          (acc: number, cur: IOrderPackagingPlace) =>
            acc +
            cur.items.reduce(
              (acc: number, cur: IOrderPackagingPlaceItem) =>
                acc + cur.quantity,
              0,
            ),
          0,
        )
        const itemsCount = order?.items.reduce(
          (acc: number, cur: IOrderItem) => acc + cur.quantity,
          0,
        )

        if (itemsCount !== placesItemsCount) {
          notification?.show(
            'alert',
            `Для данной службы доставки - максимальное количество мест ${maxCount}. Упакуйте весь товар в текущее место`,
          )
          return false
        }
      }

      setShowClosePlace(true)
      return false
    }

    if (newVal === PRINT_ITEM_STICKER_BARCODE) {
      printLastItemSticker()
      return
    }

    setCurrentOperation('itemAdding')

    const getItemByBarcode = () =>
      order?.items.find((item: IOrderItem) => {
        const findedBarcode = item.barcodes.find((b) => b.barcode === newVal)
        if (findedBarcode) {
          return item
        }
      })
    const getItemById = () =>
      order?.items.find((item: IOrderItem) => item.id === newVal)

    const currentItem = !isItemId ? getItemByBarcode() : getItemById()

    if (
      order?.ExtraSeat &&
      currentPlace !== null &&
      places[currentPlace].items[0].id === 'SDoc'
    ) {
      notification?.show(
        'alert',
        'Сопроводительные документы должны быть упакованы в отдельное место',
      )
      return false
    }

    if (currentItem) {
      const currentActualItem = Boolean(
        items?.find((i: IOrderItem) => i.id === currentItem.id),
      )
      if (currentActualItem) {
        const barcode = !isItemId
          ? newVal
          : currentItem.barcodes?.find((b) => b.barcode)?.barcode ?? ''

        if (order?.ExtraSeat && currentPlace !== null && barcode === 'SDoc') {
          notification?.show(
            'alert',
            'Сопроводительные документы должны быть упакованы в отдельное место',
          )
          return
        }

        setCurItem({ ...currentItem, barcode_used: barcode })
        handleSetAddingItem({ ...currentItem, barcode_used: barcode })
      } else {
        notification?.show('alert', 'Лишний товар', {
          soundName: 'PACKING_SURPLUS_ITEM',
        })
      }
    } else {
      notification?.show(
        'alert',
        `Товар со штрихкодом ${newVal} не найден в задании на упаковку`,
        { soundName: 'PACKING_WRONG_ITEM' },
      )
    }
  }

  const printItemSticker = applyPrintItemSticker(getItemSticker)

  const handlePlaceAdd = (newPlace: IOrderPackagingPlace) => {
    const allQuantity = newPlace?.items?.reduce(
      (acc, item) => acc + item.quantity,
      0,
    )

    if (order?.stickerTask) {
      printItemSticker(
        newPlace?.items?.[0].id,
        allQuantity,
        newPlace?.items?.[0].expirationDate,
      )
    }
    if (isInValidItemQuantity(newPlace?.items?.[0].id, allQuantity, 'places')) {
      return false
    }

    setCurrentPlace(places.length)
    setPlaces([...places, newPlace])

    if (typeof newPlace?.items?.[0]?.kit_id !== 'undefined') {
      setKitModalData({
        kit_id: newPlace?.items?.[0]?.kit_id,
        kit_title: newPlace?.items?.[0]?.kit_title,
      })
    }

    setCachedItem(newPlace?.items?.[0])

    handleRemoveItem(newPlace?.items?.[0]?.id, allQuantity)
    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')
  }

  const handleAddItemToPlace = (newItems: IOrderPackagingPlaceItem[]) => {
    if (currentPlace === null) return false

    const newItem = newItems[0]

    if (typeof newItem?.kit_id !== 'undefined' && !kitModalData) {
      //TODO make better
      setKitModalData({
        kit_id: newItem?.kit_id,
        kit_title: newItem?.kit_title,
      })
    }

    if (kitModalData && kitModalData?.kit_id !== newItem?.kit_id) {
      notification?.show('alert', 'Сканируемый товар не входит в данный набор')
      return false
    }

    const allQuantity = newItems.reduce((acc, item) => acc + item.quantity, 0)

    if (order?.stickerTask) {
      printItemSticker(newItem.id, allQuantity, newItem?.expirationDate)
    }

    setCachedItem(newItem)

    const newPlaces = places.slice()
    newItems.forEach((newItem) => {
      newPlaces[currentPlace].items.unshift(newItem)
    })

    setPlaces(newPlaces)

    handleRemoveItem(newItem.id, allQuantity)
    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')
  }

  const handleClosePlace = async (
    newData: IOrderPackagingPlace,
    reqCount: number = 0,
  ) => {
    if (currentPlace === null) return false

    setCurrentOperation('placeClosing')

    const isFinal = !items?.length
    const newPlaceData = { ...newData, is_final: isFinal }

    handleChangePlace(newPlaceData)

    const reqPlaceData = {
      ...newPlaceData,
      items: newPlaceData.items.map(
        ({ article, weight, packagings, title, ...rest }) => ({ ...rest }),
      ),
    }

    return await packingPlaceMutation
      .mutateAsync({ place: reqPlaceData })
      .then(async (result: IUpdatePackingPlaceResult) => {
        const {
          place_id,
          sticker: { content, printer: printerName },
        } = result

        setPlaceErrorData(null)

        printSticker(printerName, content, {
          copies: 1,
          needShowPrintStickerInfo: isFinal ? false : true,
          needFocusAfterPrint: isFinal ? false : true,
        }).then((res) => {
          if (isFinal) {
            // если есть доп вложения и они еще не упакованы
            if (!addAttachmentsIsPacked && order?.ad_attachments?.length) {
              setShowAddAttachments(true)
              return
            }
            setShowStickerPrint(true)
            notification?.show('success', `Заказ успешно укомплектован`, {
              soundName: 'PACKING_COMPLETED',
            })
          }
        })

        handleSavePlace(place_id, newPlaceData)
      })
      .catch((err: any) => {
        if (err?.data?.errors) {
          err.data.errors.forEach((e: ResError) => {
            if (e?.errorsticker?.content) {
              setTimeout(() => {
                history.push('/')
              }, 3000)
            }
          })
        }

        if (err?.data?.errors) {
          const weightErr = err.data.errors.find(
            (e: IError) => e.code === 'INCORRECT_WEIGHT',
          )
          if (weightErr) {
            notification?.show('alert', weightErr.message)
            setErrorWeight(weightErr.message)
            setCurrentClosePlaceModal('weightingPlace')
            setShowClosePlace(true)
            return false
          }
        }

        setPlaceErrorData({
          show: true,
          data: newData,
          errors: err.data ?? err,
        })
        parseError(err)
        throw err
      })
      .finally(() => setGlobalLoading(false))
  }

  const handleResetOrder = () => {
    resetOrder(resetOrderMutation)
  }

  useEffect(() => {
    console.log(places)
  }, [places])

  const handleChooseItem = (item: IOrderItem) => {
    handleStartItem(item.id ?? '', true)
  }

  const handlePrintItemSticker = (id: string, expires?: string) => {
    setCurrentOperation('itemAdding')
    printItemSticker(id, 1, expires)
  }

  const handleСompleteAddAttachment = () => {
    if (getItemsQuantity(items ?? [])) {
      handleCloseAndFocusBarcode(setShowAddAttachments)
    } else {
      setShowAddAttachments(false)
    }
    setAddAttachmentsIsPacked(true)

    const lastPlace = places[places.length - 1]
    if (!items?.length && lastPlace?.is_final) {
      setShowStickerPrint(true)
      notification?.show('success', `Заказ успешно укомплектован`, {
        soundName: 'PACKING_COMPLETED',
      })
    }
  }

  return (
    <>
      <div className={cx(styles.main, !advanceMode ? styles.mainMini : null)}>
        <SideBar
          bottomBtns={
            <>
              <Button
                className={cx(
                  styles.iconBtn,
                  order?.shipping_form?.length && !shippingFormViewed
                    ? styles.iconBtnCircle
                    : null,
                )}
                view="secondary"
                iconLeft={IconInfo}
                label="Анкета отгрузки"
                onClick={() => setShowShippingForm(true)}
                disabled={!order?.shipping_form?.length}
              />
              <Button
                className={styles.iconBtn}
                iconLeft={IconStorage}
                label="Дополнительные вложения"
                disabled={!order?.ad_attachments?.length}
                view="secondary"
                onClick={() => setShowAddAttachments(true)}
              />
              {advanceMode ? (
                <Button
                  className={styles.btnClear}
                  label="Сбросить упаковку"
                  disabled={!advanceMode}
                  onClick={handleResetOrder}
                />
              ) : null}
              <Button
                className={styles.packCancel}
                view="ghost"
                label="Прекратить упаковку"
                onClick={() => history.push('/')}
              />
            </>
          }
        >
          <div className={styles.delivery}>
            <div className={styles.icon}>
              <Img
                className={styles.deliveryImg}
                src={order?.delivery?.logo}
                yandex
              />
              <div className={styles.iconTitles}>
                <h6>{order?.delivery?.title}</h6>
                {order?.delivery?.contract_title}
              </div>
            </div>
            {order?.delivery?.sorting_center_acceptance_date ? (
              <Badge
                className={styles.badge}
                size="l"
                status="system"
                view="stroked"
                label={`${dayjs(
                  order?.delivery?.sorting_center_acceptance_date,
                ).date()} ${
                  MONTHS[
                    dayjs(
                      order?.delivery?.sorting_center_acceptance_date,
                    ).month()
                  ]
                }`}
              />
            ) : null}
          </div>

          <div className={styles.packCount}>
            Упаковано
            <h2>
              <span>
                {items ? (
                  <>
                    {(originalItemsCount || 0) - (getItemsQuantity(items) || 0)}
                  </>
                ) : null}
              </span>{' '}
              из {originalItemsCount || 0}
            </h2>
          </div>

          <div className={styles.packName}>
            Заказ <h2>{orderId}</h2>
            <span className={styles.merchant}>
              {order?.merchant?.title}{' '}
              {order?.merchant?.trade_title !== order?.merchant?.title ? (
                <>
                  {!order?.merchant?.trade_title ||
                    !order?.merchant?.title ||
                    ' | '}{' '}
                  {order?.merchant?.trade_title}
                </>
              ) : null}
            </span>
            <div className={styles.badgesBlock}>
              {order?.ExtraSeat ? (
                <Badge
                  className={styles.badge}
                  size="m"
                  status="warning"
                  label="документы в доп место"
                />
              ) : null}
            </div>
          </div>

          <ItemScanner
            className={styles.itemScanner}
            label="Отсканируйте штрихкод товара"
            advanceModeForCount
            itemCountCheckboxValue={indicateItemCount}
            onItemCountCheckboxChange={setIndicateItemCount}
            onChange={(value) => handleStartItem(value)}
            inputRef={barcodeInput}
            additional={
              advanceMode ? (
                <Button
                  iconLeft={IconSearch}
                  view="secondary"
                  size="l"
                  className={styles.searchBtn}
                  onClick={() => setShowSearchItemsModal(true)}
                />
              ) : null
            }
          />

          <div className={styles.comment}>
            <h5>Комментарий к отгрузке</h5>
            <div>{order?.order_shipping_comments}</div>
          </div>
        </SideBar>
        <div className={styles.bodyWrap}>
          {showAlert ? (
            <Informer
              status="alert"
              view="filled"
              label="Время рабочего стола (21.07.2021 14:32:11) не совпадает с серверным временем (21.07.2021 14:32:10)"
              icon={IconWarning}
              className={styles.alert}
            />
          ) : null}
          <div className={styles.body}>
            {activeTab && activeTab.id === 'main' && (
              <MainBlock
                order={order}
                items={items}
                places={places}
                currentPlace={currentPlace}
                packagingOptions={packagingOptions || []}
                setShowPacked={setShowPacked}
                handlePrintItemSticker={handlePrintItemSticker}
                handleChooseItem={handleChooseItem}
              />
            )}
          </div>
        </div>
      </div>

      {addingItem ? (
        <ItemAdding
          indicateItemCount={indicateItemCount}
          addingItem={addingItem}
          packagingOptions={packagingOptions || []}
          currentPlace={currentPlace}
          places={places}
          onPlaceAdd={handlePlaceAdd}
          onAddItemToPlace={handleAddItemToPlace}
          onClose={() => setAddingItem(null)}
          setAddingItem={(item) => setAddingItem(item)}
          blurOrFocusBarcodeInput={blurOrFocusBarcodeInput}
          sequence_num={places.length + 1}
          remainingItems={remainingItems}
          isNeedEnterWeigthDimensions={
            (addingItem.require_weight || addingItem.require_dimensions) &&
            !checkItemInPlaces(addingItem, 'place')
          }
          type="places"
        />
      ) : null}

      {showClosePlace && currentPlace !== null ? (
        <ClosePlace
          isOpen={true}
          currentModal={currentClosePlaceModal}
          setCurrentModal={setCurrentClosePlaceModal}
          requireWeight={!!order?.require_place_weight}
          currentPlaceData={places[currentPlace]}
          packagingOptions={packagingOptions || []}
          onSubmit={handleClosePlace}
          onClose={() => setShowClosePlace(false)}
          errorWeight={errorWeight}
          maxWeight={
            order?.delivery?.maximum_place_weight
              ? order.delivery.maximum_place_weight
              : null
          }
          clearErrorWeight={() => setErrorWeight(null)}
        />
      ) : null}

      {showStickerPrint ? (
        <StickerPrintModal
          isOpen={true}
          onSuccess={handleSavePlace}
          onClose={() => history.push('/')}
          btnTitle={'Вернуться на главную'}
        />
      ) : null}

      {/* TODO вынести модалки ошибок в один унифицированный компонент??? */}
      {showStickerError ? (
        <StickerErrorModal
          isOpen={true}
          sticker={sticker}
          onClose={handleCloseStickerError}
          isFinal={!items?.length}
          showStickerPrint={() => setShowStickerPrint(true)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {placeErrorData?.show ? (
        <WaitingClosingModal
          orderId={order?.id ?? ''}
          isOpen={true}
          onClose={() => {
            setPlaceErrorData(null)
          }}
          errorData={placeErrorData?.errors}
          onRetry={() => handleClosePlace(placeErrorData.data)}
        />
      ) : null}

      {showShippingForm ? (
        <ShippingFormModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowShippingForm)}
          shippingFormData={order?.shipping_form || []}
          blurRef={barcodeInput}
          order={order}
        />
      ) : null}

      {kitModalData ? (
        <KitModal
          isOpen={true}
          kitData={kitModalData}
          items={items}
          onStartItem={handleStartItem}
          itemCountCheckboxValue={indicateItemCount}
          onItemCountCheckboxChange={setIndicateItemCount}
          onClose={() => handleCloseKitModal()}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {showSearchItemsModal ? (
        <SearchItemsModal
          isOpen={true}
          merchantId={order?.merchant?.id ?? ''}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          onClose={() => handleCloseAndFocusBarcode(setShowSearchItemsModal)}
        />
      ) : null}

      <PackedModal
        places={places}
        order={order}
        packagingOptions={packagingOptions ?? []}
        isOpen={showPacked}
        onClose={() => handleCloseAndFocusBarcode(setShowPacked)}
      />

      {showStickerPrintInfo ? (
        <ItemStickerPrintModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowStickerPrintInfo)}
          subtitle={
            currentOperation === 'placeClosing'
              ? 'Приклейте стикер на место'
              : 'Приклейте стикер на упаковку'
          }
        />
      ) : null}

      {showAddAttachments &&
      order?.ad_attachments?.length &&
      !showStickerError ? (
        <AddAttachmentsModal
          addAttachments={order.ad_attachments}
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowAddAttachments)}
          handleСompleteAddAttachment={handleСompleteAddAttachment}
        />
      ) : null}
    </>
  )
}

export default B2CPackingOrder
