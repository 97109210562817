import React from 'react'

import { DefaultItem } from '@consta/uikit/Combobox'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { ApiReqResult, ISelectOption, ISerialNumber } from './index'

interface IObjKeysString {
  [key: string]: any
}

export interface IContragent extends ISelectOption {
  subtitle: string
  id: number
  disabled?: boolean
}

export interface ICombobox extends ISelectOption {
  id: number | string
  disabled?: boolean
}

type Item = {
  [key: string]: any
}

export interface IAcceptanceItem extends Item {
  id: string
  date: string
  counteragent: {
    id: string
    title: string
  }
  supplier: {
    id: string
    title: string
  }
  warehouse: {
    id: string
    title: string
  }
  status: string
}

export interface IAcceptanceRowItem extends Item {
  id: string
  date: string
  time: string
  counteragent: string
  tradeName: string
  supplier: string
  warehouse: string
  status: string
}

export interface IDiscrepanciesRowItem extends Item {
  id: string
  image: string
  barcode: string
  article: string
  plan: number
  fact: number
  defect: number
  surplus: number
  shortages: number
}

export interface IAcceptanceColumn {
  title: string | React.ReactElement
  accessor: any
  sortable?: boolean
  width?: number
  renderCell?: (row: IAcceptanceRowItem) => React.ReactNode
  sortByField?: keyof IAcceptanceItem
}

export interface IDiscrepanciesColumn {
  title: string | React.ReactElement
  accessor: any
  sortable?: boolean
  width?: number
  renderCell?: (row: IDiscrepanciesRowItem) => React.ReactNode
}

export interface IAcceptanceFilterDate {
  from: Date | null
  to: Date | null
}

export interface IPackType {
  id: string
  title: string
  quantity: number
}

export interface IBatch {
  id?: string
  exp_date?: string
  num?: string
}

export interface IMerchant {
  id: string
  title: string
  trade_title: string
}

export interface ISupplier {
  id: string
  title: string
}

export interface IWarehouse {
  id: string
  title: string
}

export interface ISupplyItemBarcode extends IObjKeysString {
  type: string
  barcode: string
  pack_id: string
}

export interface ISerialNumberPart extends IObjKeysString {
  code: string
  value: string
}

export interface ISupplyPartItem {
  id: string
  pack_id: string
  quantity: number
  batch_id?: string
  serial_numbers?: ISerialNumberPart[]
  allItemQuantityPrevTars?: number
  batchesQuantity?: { [key: string]: number }
}

export interface ISupplyPart {
  id?: string
  tare_id: string
  condition: string
  created_at?: string
  status?: string
  items: ISupplyPartItem[]
}

export interface ISupplyPartFinal {
  tare_id: string
  condition: string
  items: ISupplyPartItem[]
}

export interface ISupplyOriginalItem {
  id: string
  article: string
  title: string
  quantity: number
  pack_id?: string
  batch?: IBatch
  image: string
  image_preview: string
  require_weight: boolean
  require_dimensions: boolean
  batch_accounting: boolean
  batch_expdate: boolean
  packs: IPackType[]
  barcodes: ISupplyItemBarcode[]
  batches?: IBatch[]
  scan_serial_numbers: ISerialNumber[]
  scanned_serial_numbers?: ISerialNumberPart[]
}

export interface IformattedItem {
  id: string
  article: string
  title: string
  pack_id: string
  quantity: number
  image_preview: string
  packs: IPackType[]
  barcodes: ISupplyItemBarcode[]
  batches: IBatch[]
}

export interface ISupplyItem {
  id: string
  quantity: number
  pack_id: string
  batches?: IBatch[]
}

export interface ISupply {
  id: string
  date: string
  status: string
  scan_serial_numbers: boolean
  stickerTask?: boolean
  merchant?: IMerchant
  supplier?: ISupplier
  warehouse?: IWarehouse
  items: ISupplyItem[]
  parts: ISupplyPart[]
}

export interface IAcceptanceFormState {
  items: ISupplyItem[]
  cacheItems: ISupplyOriginalItem[]
  indicateItemCount: boolean
  startFormDate: string
  addingItem: ISupplyOriginalItem | null
  curTareId: TextFieldPropValue
  tares: ISupplyPart[]
  supplyIsStart: boolean

  showSelectBatch: boolean
  showDiscrepancies: boolean
  showOpenTareError: boolean
  showSearchItemsModal: boolean
  showPrintError: boolean
  sticker: {
    content: string
    printer: string
  } | null
}

export enum AcceptanceFormActionTypes {
  setItems = `setItems`,
  setCacheItems = `setCacheItems`,
  setIndicateItemCount = 'setIndicateItemCount',
  setStartFormDate = 'setStartFormDate',
  setAddingItem = 'setAddingItem',
  setCurItem = 'setCurItem',
  setCurTareId = 'setCurTareId',
  setTares = 'setTares',
  setShowSelectBatch = 'setShowSelectBatch',
  setShowDiscrepancies = 'setShowDiscrepancies',
  setStartSupply = 'setStartSupply',
  setShowScanSerial = 'setShowScanSerial',
  setSavedChz = 'setSavedChz',
  setShowOpenTareError = 'setShowOpenTareError',
  setShowSearchItemsModal = 'setShowSearchItemsModal',
  setShowPrintError = 'setShowPrintError',
  setSticker = 'setSticker',
}

type setItemsAction = {
  type: AcceptanceFormActionTypes.setItems
  items: ISupplyItem[]
}

type setCacheItemsAction = {
  type: AcceptanceFormActionTypes.setCacheItems
  cacheItems: ISupplyOriginalItem[]
}

type setIndicateItemCountAction = {
  type: AcceptanceFormActionTypes.setIndicateItemCount
  indicateItemCount: boolean
}

type setStartFormDateAction = {
  type: AcceptanceFormActionTypes.setStartFormDate
  startFormDate: string
}

type setAddingItemAction = {
  type: AcceptanceFormActionTypes.setAddingItem
  addingItem: ISupplyOriginalItem | null
}
type setCurItemAction = {
  type: AcceptanceFormActionTypes.setCurItem
  curItem: ISupplyPartItem | null
}

type setCurTareIdAction = {
  type: AcceptanceFormActionTypes.setCurTareId
  curTareId: TextFieldPropValue
}

type setTaresAction = {
  type: AcceptanceFormActionTypes.setTares
  tares: ISupplyPart[]
}

type setShowSelectBatchAction = {
  type: AcceptanceFormActionTypes.setShowSelectBatch
  showSelectBatch: boolean
}

type setShowDiscrepanciesAction = {
  type: AcceptanceFormActionTypes.setShowDiscrepancies
  showDiscrepancies: boolean
}

type setStartSupplyAction = {
  type: AcceptanceFormActionTypes.setStartSupply
  supplyIsStart: boolean
}

type setShowScanSerialAction = {
  type: AcceptanceFormActionTypes.setShowScanSerial
  showScanSerial: boolean
}

type setShowOpenTareError = {
  type: AcceptanceFormActionTypes.setShowOpenTareError
  showOpenTareError: boolean
}

type setShowSearchItemsModalAction = {
  type: AcceptanceFormActionTypes.setShowSearchItemsModal
  showSearchItemsModal: boolean
}

type setShowPrintErrorAction = {
  type: AcceptanceFormActionTypes.setShowPrintError
  showPrintError: boolean
}

type setStickerAction = {
  type: AcceptanceFormActionTypes.setSticker
  sticker: {
    content: string
    printer: string
  } | null
}

export type AcceptanceFormActions =
  | setItemsAction
  | setCacheItemsAction
  | setIndicateItemCountAction
  | setStartFormDateAction
  | setAddingItemAction
  | setCurTareIdAction
  | setTaresAction
  | setShowSelectBatchAction
  | setShowDiscrepanciesAction
  | setStartSupplyAction
  | setShowScanSerialAction
  | setCurItemAction
  | setShowOpenTareError
  | setShowSearchItemsModalAction
  | setShowPrintErrorAction
  | setStickerAction

export interface useUpdateSupplyPartResult extends ApiReqResult {
  part_id: string
  tare_id: string
}

export interface useDeleteSupplyPartResult extends ApiReqResult {}

export interface getSuppliesResult extends ApiReqResult {
  receipts: IAcceptanceItem[] | []
}

export interface getAcceptanceOptionsResult extends ApiReqResult {
  type: string
  options: ISelectOption[] | []
}

export interface ComboboxItem extends DefaultItem {
  subtitle?: string
  value?: string
}

export interface IAcceptanceFilterOptions {
  suppliers: ICombobox[] | []
  contragents: IContragent[] | []
  warehouses: ICombobox[] | []
}

export interface IGetSupplyReqObj {
  multi?: string
  dateFrom?: string
  dateTo?: string
  counteragent?: string | null
  supplier?: string
  warehouse?: string
  status?: string
}

export interface IOptionsLoading {
  suppliers: boolean
  contragents: boolean
  warehouses: boolean
}

export interface getStickerResult extends ApiReqResult {
  stickerExists: boolean
  sticker?: {
    content: string
    printer: string
  }
}
