import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import styles from './exp-date-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconCalendar } from '@consta/uikit/IconCalendar'
import { RadioGroup } from '@consta/uikit/RadioGroup'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'

import {
  Modal,
  ItemPreview,
  InputWithDateMask,
  EnterHandler,
} from 'src/components'

import { useNotifications } from 'src/notification'

import { dateTypes } from 'src/config'

import { ModalProps, IOrderItem, TypesEnum } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface ItemCountProps extends ModalProps {
  item: IOrderItem | null
  onDateExpAdding: (newItems: IOrderItem[]) => void
  newItems: IOrderItem[]
}

const ExpDateModal = (props: ItemCountProps) => {
  const { item, onDateExpAdding, newItems } = props

  const [date, setDate] = useState<TextFieldPropValue>('')
  const [type, setType] = useState<TypesEnum>(TypesEnum.dateOfManufacture)
  const [error, setError] = useState<string>('')
  const [curItems, setCurItems] = useState<IOrderItem[]>([])
  const [curItemIndex, setCurItemIndex] = useState<number>(0)

  const notification = useNotifications()

  useEffect(() => {
    console.log(type)
  }, [type])

  const checkDate = (date: string): boolean => {
    if (!dayjs(date, 'DD.MM.YYYY').isValid()) {
      setError('Некорректная дата')
      return false
    }

    const now = dayjs(dayjs(), 'DD.MM.YYYY')

    if (
      type === TypesEnum.expirationDate &&
      (dayjs(date, 'DD.MM.YYYY').isBefore(now) ||
        dayjs(date, 'DD.MM.YYYY').isSame(now, 'day'))
    ) {
      setError('Дата срока годности должна быть позже текущей даты')

      notification?.show(
        'alert',
        'дата - годен до должна быть позже текущей даты',
      )

      return false
    }

    if (
      type === TypesEnum.expirationDate &&
      (dayjs(date, 'DD.MM.YYYY')
        .subtract(item?.expiryMonths || 999, 'month')
        .isAfter(now) ||
        dayjs(date, 'DD.MM.YYYY').isSame(now, 'day'))
    ) {
      setError('Некорректная дата')

      notification?.show('alert', 'Некорректная дата')

      return false
    }

    if (
      type === TypesEnum.dateOfManufacture &&
      dayjs(date, 'DD.MM.YYYY').isAfter(now)
    ) {
      setError('Дата изготовления должна быть раньше текущей даты')

      notification?.show(
        'alert',
        'Дата изготовления должна быть раньше текущей даты',
      )

      return false
    }

    const expirationDate =
      type === TypesEnum.expirationDate
        ? dayjs(date, 'DD.MM.YYYY')
        : dayjs(date, 'DD.MM.YYYY')
            .add(item?.expiryMonths || 999, 'month')
            .format('DD.MM.YYYY')
    const nowWithLimitDays = now.add(item?.expiryDaysLimitShipments ?? 0, 'day')
    const isAfterLimits = dayjs(nowWithLimitDays, 'DD.MM.YYYY').isAfter(
      dayjs(expirationDate, 'DD.MM.YYYY'),
      'day',
    )

    if (isAfterLimits) {
      setError('Превышены лимиты макс. допустимых сроков годности')

      notification?.show(
        'alert',
        'Превышены лимиты макс. допустимых сроков годности',
      )

      return false
    }

    return true
  }

  const handleAddExpDate = () => {
    if (!item || !date) return
    if (!checkDate(date)) return

    const expirationDate =
      type === TypesEnum.expirationDate
        ? dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : dayjs(date, 'DD.MM.YYYY')
            .add(item.expiryMonths || 999, 'month')
            .format('YYYY-MM-DD')
    const dateOfManufacture =
      type === TypesEnum.dateOfManufacture
        ? dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : undefined

    const newCurItems = [
      ...curItems,
      { ...item, expirationDate, dateOfManufacture },
    ]

    if (curItemIndex === newItems.length - 1) {
      onDateExpAdding(newCurItems)
    }

    setCurItems(newCurItems)
    setCurItemIndex((prev) => prev + 1)

    setError('')
    setDate('')
  }

  const handleChange = (value: TextFieldPropValue) => {
    setError('')
    setDate(value)
  }

  return (
    <>
      {newItems.map((newItem, i) => (
        <>
          {curItemIndex === i ? (
            <EnterHandler onEnter={handleAddExpDate}>
              <Modal
                isOpen={true}
                hasOverlay
                onOverlayClick={(): boolean => false}
                className={styles.expDate}
                size="s"
              >
                {item ? (
                  <ItemPreview
                    image={item.image}
                    title={item.title}
                    barcode={item.barcodes[0].barcode}
                  />
                ) : null}

                <ChoiceGroup
                  className={styles.choiceGroup}
                  value={dateTypes.find((t) => t.value === type) ?? null}
                  items={dateTypes}
                  getLabel={(item) => item.label}
                  onChange={({ value }) => setType(value.value)}
                  name={'dateTypes'}
                  multiple={false}
                  width={'full'}
                />

                {/*<RadioGroup*/}
                {/*  className={styles.radioGroup}*/}
                {/*  value={dateTypes.find((t) => t.value === type) ?? null}*/}
                {/*  items={dateTypes}*/}
                {/*  getLabel={(item) => item.label}*/}
                {/*  onChange={({ value }) => setType(value.value)}*/}
                {/*  direction="row"*/}
                {/*  view="primary"*/}
                {/*/>*/}

                {newItems.length > 1 ? (
                  <div className={styles.itemsCount}>
                    {i + 1} из {newItems.length}
                  </div>
                ) : null}

                <div className={styles.input} key={`newItem-${i}`}>
                  <InputWithDateMask
                    label={
                      type === TypesEnum.expirationDate
                        ? 'Годен до'
                        : 'Введите дату изготовления'
                    }
                    value={date}
                    id={'date'}
                    handleChange={(value: TextFieldPropValue) =>
                      handleChange(value)
                    }
                    className={styles.itemInput}
                    size="l"
                    placeholder="DD.MM.20YY"
                    rightSide={IconCalendar}
                    autofocus
                    form="defaultClear"
                    error={error}
                  />
                  <Button
                    form="brickDefault"
                    size="l"
                    label="Подтвердить"
                    className={styles.btn}
                    disabled={!date || !!error}
                    onClick={handleAddExpDate}
                  />
                </div>
              </Modal>
            </EnterHandler>
          ) : null}
        </>
      ))}
    </>
  )
}

export default ExpDateModal
