import api from 'src/api'

import { provideMock } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface useResetBatchOrderParamsType {
  orderId: string
}

const resetBatchOrder = async (params: useResetBatchOrderParamsType) => {
  const result = await api.post(`/packing/order/${params.orderId}/reset`, {})

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useResetBatchOrder = () => ({
  fetch: (params: useResetBatchOrderParamsType) =>
    provideMock<useResetBatchOrderParamsType, ApiReqResult>(
      resetBatchOrder,
      params,
      {
        key: 'orderId',
        value: 'mock',
        result: { success: true },
      },
    )(),
})

export default useResetBatchOrder
