import React, { useEffect, useMemo, useState } from 'react'

import { usePackingOrderContext, usePackingPrintContext } from '../../context'

import { useSound } from 'src/sound'

import { checkAllPalletsFilled } from 'src/helpers'

import {
  IOrderPackagingPlace,
  IOrderPackedPallet,
  RelabelingTypes,
  useMoveRelabelingItemRes,
  IOrderPallet,
} from 'src/interfaces'

interface IUseMoveRelabelingItem {
  originalCount: number
  startClosePallet: () => void
  handleOpenAddFiles: () => void
  printPalletsLists: () => void
}

const useMoveRelabelingItem = ({
  originalCount,
  startClosePallet,
  handleOpenAddFiles,
  printPalletsLists,
}: IUseMoveRelabelingItem) => {
  const [markedPlaces, setMarkedPlaces] = useState<IOrderPackagingPlace[]>([])
  const [markedPallets, setMarkedPallets] = useState<IOrderPackedPallet[]>([])

  const sound = useSound()

  const {
    order,
    relabelingPlaces,
    places,
    setRelabelingPlaces,
    setPalletsForRelabeling,
    palletsForRelabeling,
    pallets,
    setRelabeledPallets,
  } = usePackingOrderContext()

  const { blurOrFocusBarcodeInput } = usePackingPrintContext()

  const isAllPalletsFilled = useMemo(
    () => checkAllPalletsFilled(pallets),
    [pallets],
  )

  const movePalletToMarked = (id: string, newBarcode: string): void => {
    const pallet = palletsForRelabeling.find((p) => {
      return p.id === id
    })
    if (!pallet) return

    const newPallets = palletsForRelabeling.filter((p) => p.id !== id)
    setPalletsForRelabeling(newPallets)

    const newMarkedPallets = [...markedPallets, { ...pallet, newBarcode }]
    setMarkedPallets(newMarkedPallets)

    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')

    if (isAllPalletsFilled) {
      showAddDocs(newMarkedPallets.length)
    }

    if (!isAllPalletsFilled) {
      showAddDocs(newMarkedPallets.length)
    }
  }

  const movePlaceToMarked = (id: string, newBarcode: string): void => {
    const place = relabelingPlaces.find((p) => p.id === id)
    if (!place) return

    const newPlaces = relabelingPlaces.filter((p) => p.id !== id)
    setRelabelingPlaces(newPlaces)

    const newMarkedPlaces = [...markedPlaces, { ...place, newBarcode }]
    setMarkedPlaces(newMarkedPlaces)

    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')

    if (!newPlaces.length && !isAllPalletsFilled) {
      startClosePallet()
      return
    }

    const isNeedPrintPalletsLists =
      originalCount === newMarkedPlaces.length &&
      order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS
    if (isAllPalletsFilled && !isNeedPrintPalletsLists) {
      showAddDocs(newMarkedPlaces.length)
    }
    if (isAllPalletsFilled && isNeedPrintPalletsLists) {
      printPalletsLists()
      return
    }
  }

  const showAddDocs = (markedCount: number): void => {
    const isAllRelabel = originalCount === markedCount

    // если всё перемаркировано и еще не все доп документы напечатаны, выводим модалку печати документов
    if (isAllRelabel) {
      handleOpenAddFiles()
    }
  }

  useEffect(() => {
    if (isBoxesRelabeling) {
      const newPlaces = places.filter((p) => !p.temp_id)
      setRelabelingPlaces(newPlaces)

      const newMarkedPlaces = places.filter((p) => p.temp_id)
      setMarkedPlaces(newMarkedPlaces)

      if (!isAllPalletsFilled) {
        const newPallets = pallets.filter((p) => p.places.length)
        setRelabeledPallets(newPallets)
      }
      if (isAllPalletsFilled) {
        setRelabeledPallets(pallets)
      }
      return
    }

    const placesInpallets = pallets.reduce(
      (acc: IOrderPackagingPlace[], pallet: IOrderPallet) => {
        return [...acc, ...pallet.places]
      },
      [],
    )
    const newPlaces = places.filter(
      (p) => !placesInpallets.find((place) => place.id === p.id),
    )
    setRelabelingPlaces(newPlaces)
    const newPallets = pallets.filter((p) => !p.temp_id)
    //@ts-ignore
    setPalletsForRelabeling(newPallets)
    const newMarkedPallets = pallets.filter((p) => p.temp_id)
    //@ts-ignore
    setMarkedPallets(newMarkedPallets)

    if (!isAllPalletsFilled) {
      const newPallets = pallets.filter((p) => p.temp_id)
      setRelabeledPallets(newPallets)
    }
  }, [pallets, places])

  const isBoxesRelabeling =
    order?.relabelingType === RelabelingTypes.BOXES ||
    order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS

  const markedCount = isBoxesRelabeling
    ? markedPlaces.length
    : markedPallets.length

  return {
    markedPallets,
    markedPlaces,
    movePalletToMarked,
    movePlaceToMarked,
    markedCount,
  } as useMoveRelabelingItemRes
}

export default useMoveRelabelingItem
