import { ComponentClass, FunctionComponent } from 'react'

import {
  AuthPage,
  PackingOrderPage,
  OrderScanFormPage,
  SettingsPage,
  AcceptanceScanPage,
  AcceptanceFormPage,
  RegistriesScanPage,
  RegisterFormPage,
} from 'src/pages'

import { PRIVATE_PATHS, PUBLIC_PATHS } from 'src/config'

export type Routes = {
  path?: string
  component: FunctionComponent | ComponentClass
  default?: boolean
  template?: string
}[]

export const privateRoutes: Routes = [
  {
    path: PRIVATE_PATHS.ORDER_SCAN,
    component: OrderScanFormPage,
    default: true,
    template: 'scan',
  },
  {
    path: PRIVATE_PATHS.ORDER_FORM,
    component: PackingOrderPage,
    template: 'single',
  },
  {
    path: PRIVATE_PATHS.RESUME_ORDER_FORM,
    //component: OrderFormPage,
    component: PackingOrderPage,
    template: 'single',
  },
  {
    path: PRIVATE_PATHS.ORDER_CLOSED,
    component: PackingOrderPage,
    template: 'single',
  },
  {
    path: PRIVATE_PATHS.SETTINGS,
    component: SettingsPage,
  },
  {
    path: PRIVATE_PATHS.ACCEPTANCE_SCAN,
    component: AcceptanceScanPage,
  },
  {
    path: PRIVATE_PATHS.ACCEPTANCE_FORM,
    component: AcceptanceFormPage,
    template: 'single',
  },
  {
    path: PRIVATE_PATHS.REGISTRY_SCAN,
    component: RegistriesScanPage,
  },
  {
    path: PRIVATE_PATHS.REGISTRY_FORM,
    component: RegisterFormPage,
  },
]

export const publicRoutes: Routes = [
  { path: PUBLIC_PATHS.AUTH, component: AuthPage, default: true },
]
