import React from 'react'
import cx from 'classnames'

import styles from './item-scanner.module.scss'

import { Checkbox } from '@consta/uikit/Checkbox'

import { InputWrapper } from 'src/components'

import { useAppContext } from 'src/context'

interface ItemScannerProps {
  onChange: (value: string | null) => void
  itemCountCheckboxValue?: boolean
  showItemCountCheckbox?: boolean
  onItemCountCheckboxChange?: (value: boolean) => void
  className?: string
  label?: string
  advanceModeForCount?: boolean
  inputRef?: React.MutableRefObject<
    HTMLTextAreaElement | HTMLInputElement | null
  >
  additional?: React.ReactNode
  disabled?: boolean
}

const ItemScanner = (props: ItemScannerProps) => {
  const {
    onChange,
    itemCountCheckboxValue = false,
    onItemCountCheckboxChange = () => false,
    className,
    label,
    advanceModeForCount = false,
    inputRef,
    additional,
    showItemCountCheckbox = true,
    disabled = false,
  } = props

  const { advanceMode } = useAppContext()

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.group}>
        <InputWrapper
          label={label}
          className={styles.itemInput}
          autoFocus
          withDelay
          size="l"
          handleChange={onChange}
          inputRef={inputRef}
          disabled={disabled}
        />
        {additional ? <>{additional}</> : null}
      </div>
      {showItemCountCheckbox ? (
        <Checkbox
          label="Указывать количество товара"
          checked={itemCountCheckboxValue}
          disabled={advanceModeForCount ? !advanceMode : false}
          onChange={(e) => onItemCountCheckboxChange(e.checked)}
        />
      ) : null}
    </div>
  )
}

export default ItemScanner
