import React, { useEffect } from 'react'

import { useAppContext } from 'src/context'

interface EnterHandlerProps extends React.PropsWithChildren<{}> {
  onEnter: () => void
}

const EnterHandler = (props: EnterHandlerProps) => {
  const { children, onEnter } = props

  const { resetAfkInterval } = useAppContext()

  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        resetAfkInterval()

        onEnter()
      }
    }
    window.removeEventListener('keydown', keyDownEventHandler, false)
    window.addEventListener('keydown', keyDownEventHandler, false)

    return () => {
      window.removeEventListener('keydown', keyDownEventHandler, false)
    }
  }, [onEnter])

  return <>{children}</>
}

export default EnterHandler
