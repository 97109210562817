import api from 'src/api'

import { provideMock, MOCK_COMPLETE_REGISTER } from 'src/mocks'

import { IPlaceItem, ApiReqResult } from 'src/interfaces'

interface useCompleteRegisterCheckParamsType {
  number: string
  items: IPlaceItem[]
}

const completeRegisterCheck = async (
  params: useCompleteRegisterCheckParamsType,
) => {
  const result = await api.post(
    `/getregistry/registry/${params.number}/theend`,
    {
      number: params.number,
      items: params.items,
    },
  )

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useCompleteRegisterCheck = () => ({
  fetch: (params: useCompleteRegisterCheckParamsType) =>
    provideMock<useCompleteRegisterCheckParamsType, ApiReqResult>(
      completeRegisterCheck,
      params,
      {
        key: 'number',
        value: 'mock',
        result: MOCK_COMPLETE_REGISTER,
      },
    )(),
})

export default useCompleteRegisterCheck
