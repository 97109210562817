import React from 'react'
import days from 'dayjs'

import styles from './error-place-modal.module.scss'

import img from './assets/error_icon.svg'

import { Button } from '@consta/uikit/Button'

import { Modal } from 'src/components'

import { ModalProps, IOrderErrorData } from 'src/interfaces'

interface IErrorPlaceModal extends ModalProps {
  errorData: IOrderErrorData
}

const ErrorPlaceModal = (props: IErrorPlaceModal) => {
  const { isOpen, onClose, errorData } = props

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.error}
      title={errorData.title}
      subtitle={errorData.subtitle}
      img={img}
      imgClass={styles.img}
      size="s"
    >
      {errorData.order_id || errorData.ship_date || errorData.courier_id ? (
        <div className={styles.body}>
          {errorData.order_id ? (
            <div className={styles.block}>
              <span>Заказ:</span>{' '}
              <span className={styles.bolder}>{errorData.order_id}</span>
            </div>
          ) : null}
          {errorData.ship_date ? (
            <div className={styles.block}>
              <span>Дата отгрузки:</span>{' '}
              <span className={styles.bolder}>
                {days(errorData.ship_date).format('DD.MM.YYYY')}
              </span>
            </div>
          ) : null}
          {errorData.courier_id ? (
            <div className={styles.block}>
              <span>Служба доставки:</span>{' '}
              <span className={styles.bolder}>{errorData.courier_id}</span>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={styles.btns}>
        <Button
          label="Ок"
          view="primary"
          className={styles.primary}
          onClick={onClose}
          size="m"
        />
      </div>
    </Modal>
  )
}

export default ErrorPlaceModal
