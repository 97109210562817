import { IOrderItem } from 'src/interfaces'

interface useCheckMatchWithItemBarcodesProps {
  items: IOrderItem[]
}

const useCheckMatchWithItemBarcodes = ({
  items,
}: useCheckMatchWithItemBarcodesProps) => {
  const checkBarcode = (barcode: string) => {
    return !items.find((item) =>
      item.barcodes.find((b) => b.barcode === barcode.trim()),
    )
  }

  return checkBarcode
}

export default useCheckMatchWithItemBarcodes
