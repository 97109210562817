import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_DELETE_SUPPLY_PART } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface useDeleteSupplyPartParamsType {
  supplyId: string
}

const useDeleteSupplyPart = (params: useDeleteSupplyPartParamsType) => {
  return useMutation(async (data: { partId: string }) => {
    console.log(`/receiving/supply/${params.supplyId}/part/${data.partId}`)
    try {
      const result = await provideMock<
        useDeleteSupplyPartParamsType,
        { data: ApiReqResult }
      >(
        async () =>
          await api.delete(
            `/receiving/supply/${params.supplyId}/part/${data.partId}`,
          ),
        params,
        {
          key: 'supplyId',
          value: 'mock',
          result: MOCK_DELETE_SUPPLY_PART,
        },
      )()

      if (!result?.data.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useDeleteSupplyPart
