interface IMock<P, R> {
  key: keyof P
  value: any
  result: R
}

export const provideMock = <P, R>(
  hook: (props: P) => Promise<R>,
  params: P,
  mock?: IMock<P, R> | IMock<P, R>[],
) => {
  if (process.env.NODE_ENV !== 'production' && mock) {
    if (!Array.isArray(mock)) {
      if (params[mock.key] === mock.value) {
        return async () => await Promise.resolve(mock.result)
      }
    } else {
      const findMock = mock.find((m: IMock<P, R>) => params[m.key] === m.value)
      if (findMock) {
        return async () => await Promise.resolve(findMock.result)
      }
    }
  }

  return async () => await hook(params)
}

export * from './printer'
export * from './packing'
export * from './receiving'
export * from './items'
export * from './registries'
