import React from 'react'

import styles from './item-preview.module.scss'

import { Img } from 'src/components'

import { getBatchStr, hideSymbols } from 'src/helpers'

import { IBatch } from 'src/interfaces'

interface ItemPreviewProps {
  image: string
  title: string
  barcode: string
  batch?: IBatch
  weight?: string | number
}

const ItemPreview = (props: ItemPreviewProps) => {
  const { image, title, barcode, batch, weight } = props

  return (
    <div className={styles.top}>
      <Img className={styles.img} src={image} yandex size={100} />
      <div className={styles.nameBlock}>
        {batch?.num || batch?.exp_date ? (
          <div>
            <h5>Партия</h5>
            <h2>{getBatchStr(batch)}</h2>
          </div>
        ) : null}

        <div>
          <h5>Наименование</h5>
          <h2>{title}</h2>
        </div>

        <div>
          <h5>Штрихкод</h5>
          <h3>{hideSymbols(6, barcode)}</h3>
        </div>

        {weight ? (
          <div>
            <h5>Текущий вес</h5>
            <h2>{weight} гр.</h2>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ItemPreview
