import React from 'react'
import cx from 'classnames'

import styles from './modal.module.scss'

import { Modal as ConstaModal } from '@consta/uikit/Modal'
import { IconClose } from '@consta/uikit/IconClose'

declare const modalPropWidth: readonly ['auto']
declare type ModalPropWidth = typeof modalPropWidth[number]

declare const ModalPropSize: readonly ['s', 'm', 'm-plus', 'l', 'min']
declare type ModalPropSize = typeof ModalPropSize[number]

declare const modalPropPosition: readonly ['center', 'top']
declare type ModalPropPosition = typeof modalPropPosition[number]

interface ConstaModalProps {
  isOpen?: boolean
  onClose?: () => void
  onOpen?: () => void
  hasOverlay?: boolean
  onOverlayClick?: (event: MouseEvent) => void
  onClickOutside?: (event: MouseEvent) => void
  className?: string
  rootClassName?: string
  width?: ModalPropWidth
  position?: ModalPropPosition
  children?: React.ReactNode
  container?: HTMLDivElement | undefined
  refsForExcludeClickOutside?: React.RefObject<HTMLElement>[]
  title?: string
  subtitle?: string
  alertSubtitle?: boolean
  titleAlign?: 'center' | 'left'
  img?: string
  imgClass?: string
  size?: ModalPropSize
  withClose?: boolean
  closeClass?: string
  headerTitle?: string
}

const Modal = (props: ConstaModalProps) => {
  const {
    img,
    imgClass,
    title,
    titleAlign = 'center',
    subtitle,
    withClose = false,
    onClose = () => false,
    size,
    headerTitle,
    closeClass,
    alertSubtitle = false,
  } = props

  return (
    <div className={styles.wrap}>
      <>
        <ConstaModal
          {...props}
          className={cx(
            styles.modal,
            props.className,
            size ? styles[size] : styles.m,
          )}
        >
          {withClose ? (
            <IconClose
              size="m"
              view="brand"
              className={cx(styles.close, closeClass)}
              onClick={() => onClose()}
            />
          ) : null}
          {headerTitle ? (
            <h3 className={styles.headerTitle}>{headerTitle}</h3>
          ) : null}
          {img ? <img className={imgClass} src={img} alt="" /> : null}
          {title ? (
            <h3 className={cx(styles.title, styles[titleAlign])}>{title}</h3>
          ) : null}
          {subtitle ? (
            <h5
              className={cx(
                styles.subtitle,
                styles[titleAlign],
                alertSubtitle ? styles.alert : null,
              )}
            >
              {subtitle}
            </h5>
          ) : null}
          {props.children}
        </ConstaModal>
      </>
    </div>
  )
}

export default Modal
