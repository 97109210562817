import React, { useMemo } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import styles from './b2b-packing-order.module.scss'

import { IconInfo } from '@consta/uikit/IconInfo'
import { IconStorage } from '@consta/uikit/IconStorage'
import { Badge } from '@consta/uikit/Badge'
import { IconSearch } from '@consta/uikit/IconSearch'
import { Button } from '@consta/uikit/Button'

import { ItemScanner, SideBar, Img } from 'src/components'

import { usePackingOrderContext, usePackingPrintContext } from '../context'
import { useAppContext } from 'src/context'

import { getItemsQuantity } from 'src/helpers'

import { ShippingOptionsType } from 'src/interfaces'

interface IB2bSideBar {
  orderStatus: string
  isPackEnd: boolean
  handleScanBarcode: (value: string | null) => void
  showAddAttachements: () => void
  showSeacrhItem: () => void
}

const B2bSideBar = ({
  orderStatus,
  isPackEnd,
  handleScanBarcode,
  showAddAttachements,
  showSeacrhItem,
}: IB2bSideBar) => {
  const { advanceMode } = useAppContext()
  const {
    order,
    setShowShippingForm,
    items,
    indicateItemCount,
    setIndicateItemCount,
    shippingFormViewed,
  } = usePackingOrderContext()

  const { loadingSticker, barcodeInput } = usePackingPrintContext()

  const history = useHistory()

  const isMonoPack =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PACKINGS
  const isMonoPallet =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PALLETS

  const originalItemsCount = useMemo(
    () => getItemsQuantity(order?.items),
    [order?.items],
  )

  return (
    <SideBar
      bottomBtns={
        <div className={styles.fboBtns}>
          <div className={styles.fboBtnsTop}>
            <Button
              className={cx(
                styles.iconBtn,
                order?.shipping_form?.length && !shippingFormViewed
                  ? styles.iconBtnCircle
                  : null,
              )}
              view="secondary"
              iconLeft={IconInfo}
              label="Анкета отгрузки"
              onClick={() => setShowShippingForm(true)}
              disabled={!order?.shipping_form?.length}
            />
            <Button
              className={styles.iconBtn}
              iconLeft={IconStorage}
              label="Дополнительные вложения"
              disabled={!order?.ad_attachments?.length}
              view="secondary"
              onClick={showAddAttachements}
            />
          </div>
          <div className={styles.fboBtnsBottom}>
            <Button
              label="Прекратить упаковку"
              view="ghost"
              onClick={() => history.push('/')}
            />
            <Button
              label="Завершить упаковку"
              disabled={!isPackEnd}
              onClick={() => history.push('/')}
            />
          </div>
        </div>
      }
    >
      <div className={styles.fboTop}>
        <Img className={styles.img} src={order?.delivery?.logo} yandex />
        <Badge
          className={styles.badge}
          size="m"
          view="stroked"
          label={orderStatus}
        />
      </div>

      <div
        className={cx(
          styles.packCount,
          isMonoPack || isMonoPallet ? styles.littleMargin : null,
        )}
      >
        Упаковано
        <h2>
          <span>
            {items ? (
              <>{(originalItemsCount || 0) - (getItemsQuantity(items) || 0)}</>
            ) : null}
          </span>{' '}
          из {originalItemsCount || 0}
        </h2>
      </div>

      <div className={styles.packName}>
        Заказ <h2>{order?.id}</h2>
        <span className={styles.merchant}>
          {order?.merchant?.title}{' '}
          {order?.merchant?.trade_title !== order?.merchant?.title ? (
            <>
              {!order?.merchant?.trade_title ||
                !order?.merchant?.title ||
                ' | '}{' '}
              {order?.merchant?.trade_title}
            </>
          ) : null}
        </span>
        <div className={styles.badgesBlock}>
          <Badge
            className={styles.badgeFbo}
            size="m"
            status="system"
            view="stroked"
            label={'FBO'}
          />
          {isMonoPack ? (
            <Badge
              className={styles.badge}
              size="m"
              status="error"
              label="МОНОкороб"
            />
          ) : null}
          {isMonoPallet ? (
            <Badge
              className={styles.badge}
              size="m"
              status="error"
              label="монопаллета"
            />
          ) : null}
        </div>
      </div>

      <ItemScanner
        label="Отсканируйте штрихкод товара"
        advanceModeForCount
        itemCountCheckboxValue={indicateItemCount}
        onItemCountCheckboxChange={setIndicateItemCount}
        onChange={(value) => handleScanBarcode(value)}
        inputRef={barcodeInput}
        className={styles.itemScanner}
        disabled={loadingSticker}
        additional={
          advanceMode ? (
            <Button
              iconLeft={IconSearch}
              view="secondary"
              size="l"
              className={styles.searchBtn}
              onClick={showSeacrhItem}
            />
          ) : null
        }
      />
    </SideBar>
  )
}

export default B2bSideBar
