export const PUBLIC_PATHS = {
  AUTH: '/auth',
}

export const PRIVATE_PATHS = {
  ORDER_SCAN: '/order/scan',
  ORDER_FORM: '/order/:orderId',
  ORDER_CLOSED: '/order/closed/:orderId',
  SETTINGS: '/order-settings',
  ACCEPTANCE_SCAN: '/acceptance/scan',
  ACCEPTANCE_FORM: '/acceptance/:supplyId',
  RESUME_ORDER_FORM: '/order/resume/:orderId',
  REGISTRY_SCAN: '/register/scan',
  REGISTRY_FORM: '/register/:registerId',
}

export const PATHS = {
  ...PUBLIC_PATHS,
  ...PRIVATE_PATHS,
}
