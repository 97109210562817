import React, { useEffect, useState } from 'react'

import styles from './packed-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconSearch } from '@consta/uikit/IconSearch'
import { IconAllDone } from '@consta/uikit/IconAllDone'

import ChoicePalletModal from './ChoicePalletModal'
import { BoxMarkedInPallets } from '../../components'
import { Modal, InputWrapper } from 'src/components'

import { usePackingOrderContext } from '../../context'

import { useNotifications } from 'src/notification'

import {
  ShippingOptionsType,
  ModalProps,
  IOrderPallet,
  IOrderPalletMarked,
  IOrderReqPallet,
} from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface PackedModalProps extends ModalProps {
  handlePrint: (itemId: string) => void
  pallets: IOrderPalletMarked[]
  setPallets: (pallets: IOrderPalletMarked[]) => void
  fetchClosePackedPallet: (
    palletData: IOrderReqPallet,
    options?: {
      processErr?: boolean
      isNeedPrint?: boolean
      isNeedSave?: boolean
    },
  ) => void
}

const PalletPackedModal = (props: PackedModalProps) => {
  const {
    isOpen,
    onClose,
    handlePrint,
    pallets,
    setPallets,
    fetchClosePackedPallet,
  } = props

  // state

  const [search, setSearch] = useState<TextFieldPropValue>('')
  const [editable, setEditable] = useState<boolean>(false)
  const [showChoicePallet, setShowChoicePallet] = useState<boolean>(false)
  const [curPallets, setCurPallets] = useState<IOrderPalletMarked[]>([])
  const [movedPlace, setMovedPlace] = useState<{
    placeId: string
    palletId: string
  } | null>(null)
  const [modifiedPallets, setModifiedPallets] = useState<string[]>([])

  // context

  const { currentPallet, order } = usePackingOrderContext()

  //other
  const isMonoPallet =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PALLETS

  // hooks

  const notification = useNotifications()

  // useEffect hooks

  useEffect(() => {
    setCurPallets(pallets)
  }, [pallets])

  // other functions

  const checkPlacesForMonoPallet = () => {
    let status = true
    const wrongPallets = new Set<string>()
    for (const value of modifiedPallets) {
      const isOpenedPallet = value === '0'

      const pallet = !isOpenedPallet
        ? curPallets.find((p) => p.id === value)
        : curPallets.find((p) => !p.id)
      if (!pallet)
        return { status, wrongPallets } as {
          status: boolean
          wrongPallets: Set<string>
        }

      const firstId = pallet.places[0].items[0].id

      pallet.places.forEach((place) => {
        console.log(place.items[0].id !== firstId)
        if (place.items[0].id !== firstId) {
          status = false
          wrongPallets.add(pallet.id ?? 'текущая паллета')
        }
      })
    }
    return { status, wrongPallets } as {
      status: boolean
      wrongPallets: Set<string>
    }
  }

  const getPalletReqData = (
    palletId: string,
    pallet: IOrderPallet,
  ): IOrderReqPallet => {
    const formattedPlaces = pallet.places.map((place) => ({
      //@ts-ignore
      placeId: place.newBarcode ?? place.id,
      orderId: order?.id ?? '',
    }))

    return {
      ...pallet,
      pallet_id: palletId,
      places: formattedPlaces,
    }
  }

  const getRefactorPallets = (newPalletId: string) => {
    if (!movedPlace) return pallets

    const curNewPallets = curPallets.slice()

    const lastPallet = curNewPallets.find(
      (pallet) => (pallet?.id ?? '0') === movedPlace.palletId,
    )
    const newPallet = curNewPallets.find(
      (pallet) => (pallet?.id ?? '0') === newPalletId,
    )
    const movedPlaceObj = lastPallet?.places.find(
      (place) => place.id === movedPlace.placeId,
    )

    if (!lastPallet || !newPallet || !movedPlaceObj) return pallets

    const newPalletWithNewPlaces = {
      ...newPallet,
      places: [movedPlaceObj, ...newPallet.places],
    }
    const lastPalletWithNewPlaces = {
      ...lastPallet,
      places: lastPallet.places.filter(
        (place) => place.id !== movedPlace.placeId,
      ),
    }

    const newPallets = curNewPallets.map((pallet) => {
      if (pallet.id === newPalletWithNewPlaces.id) {
        return newPalletWithNewPlaces
      }
      if (pallet.id === lastPalletWithNewPlaces.id) {
        return lastPalletWithNewPlaces
      }
      return pallet
    })

    return newPallets
  }

  // handlers

  const handleEditPallets = () => {
    setEditable(true)
  }

  const handleСancel = () => {
    setCurPallets(pallets)
    setEditable(false)
  }

  const handleMovePlace = (placeId?: string, palletId?: string) => {
    setShowChoicePallet(true)
    setMovedPlace({ placeId: placeId ?? '', palletId: palletId ?? '0' })
  }

  const handleMovePlaceInNewPallet = (newPalletId: string) => {
    if (!movedPlace) return
    setShowChoicePallet(false)

    const newPallets = getRefactorPallets(newPalletId)

    setCurPallets(newPallets)
    setModifiedPallets([
      ...modifiedPallets.filter(
        (palletId) =>
          palletId !== newPalletId && palletId !== movedPlace.palletId,
      ),
      newPalletId,
      movedPlace.palletId,
    ])
  }

  const handleClose = () => {
    setCurPallets(pallets)
    setModifiedPallets([])
    onClose()
  }

  const checkPalletsWithoutPlaces = () => {
    let status = false
    curPallets.forEach((p) => {
      if (!p.places.length && p.id) {
        status = true
      }
    })
    return status
  }

  const handleClearPallets = async (modifiedClosePallets: string[]) => {
    for (const value of modifiedClosePallets) {
      const curPallet = pallets.find((p) => p.id === value)
      if (!curPallet?.id) {
        notification?.show('alert', 'Ошибка редактирования паллет')
        return
      }
      const emptyPallet = {
        ...curPallet,
        pallet_id: curPallet.id,
        places: [{ orderId: order?.id ?? '' }],
      }
      await fetchClosePackedPallet(emptyPallet, {
        processErr: true,
      })
    }
  }

  const handleCloseNewPallets = async (modifiedClosePallets: string[]) => {
    for (const value of modifiedClosePallets) {
      const curPallet = curPallets.find((p) => p.id === value)
      if (!curPallet?.id) {
        notification?.show('alert', 'Ошибка редактирования паллет')
        return
      }
      const pallet = getPalletReqData(value, curPallet)
      await fetchClosePackedPallet(pallet, {
        processErr: true,
      })
    }
  }

  const handleSaveNewPallets = async () => {
    if (isMonoPallet) {
      const res: { status: boolean; wrongPallets: Set<string> } =
        checkPlacesForMonoPallet()
      if (!res.status) {
        const strArr = [...res.wrongPallets].reduce(
          (acc, item) => (acc ? acc + ', ' + item : acc + item),
          '',
        )
        notification?.show(
          'alert',
          `Ошибка редактирования паллет - тип отгрузки: монопаллеты, в следующих паллетах есть короба с неодинаковыми артикулами: ${strArr}`,
        )
        return
      }
    }
    if (checkPalletsWithoutPlaces()) {
      notification?.show(
        'alert',
        'Ошибка редактирования паллет - есть паллета без мест',
      )
      return
    }
    const modifiedClosePallets = modifiedPallets.filter((p) => p !== '0')
    await handleClearPallets(modifiedClosePallets)
    await handleCloseNewPallets(modifiedClosePallets)

    notification?.show('success', 'Паллеты успешно отредактированы')
    setEditable(false)
    setPallets(curPallets)
    setModifiedPallets([])
  }

  if (!order) return null

  return (
    <>
      {!showChoicePallet ? (
        <Modal
          isOpen={isOpen}
          hasOverlay
          onOverlayClick={onClose}
          className={styles.packed}
          rootClassName={styles.wrap}
          onClose={handleClose}
          withClose
          headerTitle="Упакованный товар"
          size="l"
          closeClass={styles.close}
        >
          <div className={styles.fboTop}>
            <InputWrapper
              placeholder={'Отсканируйте штрихкод товара для поиска'}
              value={search}
              id={'search'}
              handleChange={setSearch}
              className={styles.itemInput}
              size="l"
              leftSide={IconSearch}
            />
            {!editable ? (
              <Button
                label="Редактировать состав паллет"
                size="l"
                className={styles.btn}
                onClick={handleEditPallets}
              />
            ) : (
              <div className={styles.btns}>
                <Button
                  label="Отмена"
                  view="ghost"
                  size="l"
                  className={styles.btn}
                  onClick={handleСancel}
                />
                <Button
                  iconLeft={IconAllDone}
                  label="Сохранить"
                  size="l"
                  className={styles.btn}
                  onClick={handleSaveNewPallets}
                />
              </div>
            )}
          </div>

          <div className={styles.table}>
            {curPallets.length ? (
              <BoxMarkedInPallets
                handlePrint={handlePrint}
                pallets={curPallets}
                currentPallet={currentPallet}
                movePlace={editable ? handleMovePlace : undefined}
                withTitle={false}
                needShowPrintStickerInfo={false}
                needFocusAfterPrint={false}
                advance={true}
              />
            ) : null}
          </div>
        </Modal>
      ) : null}
      {showChoicePallet ? (
        <ChoicePalletModal
          isOpen={true}
          onClose={() => setShowChoicePallet(false)}
          pallets={pallets}
          sourcePallet={movedPlace?.palletId ?? '0'}
          handleSubmit={handleMovePlaceInNewPallet}
        />
      ) : null}
    </>
  )
}

export default PalletPackedModal
