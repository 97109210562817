import React, { createContext, useContext, useReducer, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qz from 'qz-tray'
import qzSign from 'src/printer/sign'

import { useNotifications } from 'src/notification'

import { IconWarning } from '@consta/uikit/IconWarning'

import { saveBase64AsPdf, getPathWithoutLastPart } from 'src/helpers'

import { QZ_HOST, PATHS } from 'src/config'

import { IQzOptions } from 'src/interfaces'

const ROUTES_WITHOUT_QZ = [
  PATHS.AUTH,
  PATHS.REGISTRY_SCAN,
  PATHS.REGISTRY_FORM,
].map((path: string) => getPathWithoutLastPart(path))

interface IPrintOptions {
  onSuccess?: () => void
  onError?: (err: any) => void
  onFinally?: () => void
  qzOptions?: IQzOptions
  copies?: number
}

interface IPrinterContext {
  state: IPrinterState
  connect: () => void
  print: (base64Data: string, options?: IPrintOptions) => Promise<undefined>
  setPrinter: (printerName: string) => void
}

export const PrinterContext = createContext<IPrinterContext | undefined>(
  undefined,
)

export const usePrinter = () => {
  const context = useContext(PrinterContext)

  return context
}

interface IPrinterState {
  connected: boolean
}

const reducer = (state: IPrinterState, action: any) => {
  switch (action.type) {
    case 'connected':
      return { ...state, connected: true }
    case 'disconnected':
      return { ...state, connected: false }
    default:
      return state
  }
}

const qzConfig = qz.configs.create(null)
qzConfig.reconfigure({ copies: 1 })

//Mb move from context to simple hook

export const PrinterProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const { pathname } = useLocation()
  const notification = useNotifications()

  const [state, dispatch] = useReducer(reducer, {
    connected: false,
  })

  const displayError = (err: any) => {
    console.error(err)

    notification?.show('alert', err?.message ?? err)
  }

  const findPrinters = () =>
    qz.printers
      .find()
      .then((data: string[]) => {
        console.log('Available printers:')

        for (var i = 0; i < data.length; i++) {
          console.log(data[i])
        }
      })
      .catch(displayError)

  const findVersion = () => qz.api.getVersion().catch(displayError)

  const connect = async () => {
    const config = { host: QZ_HOST /*usingSecure: true*/ }
    if (!qz.websocket.isActive()) {
      return await qz.websocket
        .connect(config)
        .then(async () => {
          await findPrinters()
          await findVersion()
          dispatch({ type: 'connected' })
        })
        .catch(() => {
          notification?.show(
            'alert',
            'Ошибка доступа к сервису печати QZ Tray. Обратитесь к администратору.',
            {
              id: 'qzStartError',
              persistent: true,
              allWidth: true,
              icon: IconWarning,
            },
          )
        })
    } else {
      return Promise.resolve()
    }
  }

  useEffect(() => {
    ;(async () => {
      const routeNeedQzTray = !ROUTES_WITHOUT_QZ.includes(
        getPathWithoutLastPart(pathname),
      )
      if (routeNeedQzTray && !state.connected) {
        await qzSign()
        await connect()

        window.printSticker = print //TODO better
      }
    })()
  }, [pathname])

  const print = async (base64Data: string, options?: IPrintOptions) => {
    if (!qz.websocket.isActive()) {
      await connect()
    }

    const printData = [
      {
        type: 'pixel',
        format: 'pdf',
        flavor: 'base64',
        data: base64Data,
      },
    ]

    await findPrinters()

    const currentConfig = options?.qzOptions
      ? qz.configs.create(options?.qzOptions?.printer, {
          ...options.qzOptions,
          copies: options.qzOptions?.copies ?? 1,
        })
      : qzConfig

    // saveBase64AsPdf({
    //   content: base64Data,
    //   fileName: 'sticker.pdf',
    // })

    return await qz
      .print(currentConfig, printData)
      .then((result: any) => {
        if (options?.onSuccess) {
          options.onSuccess()
        } else {
          notification?.show('success', 'Стикер успешно отправлен на печать')
        }
      })
      .catch((err: any) => {
        console.log('printer error')
        console.dir(err)
        if (options?.onError) {
          options.onError(err)
        } else {
          displayError(err)
        }
      })
      .finally(() => {
        if (options?.onFinally) {
          options.onFinally()
        }
      })
  }

  const setPrinter = (printerName: string) => {
    qzConfig.setPrinter(printerName)
  }

  return (
    <PrinterContext.Provider
      value={{
        state,
        connect,
        print,
        setPrinter,
      }}
    >
      {children}
    </PrinterContext.Provider>
  )
}

// function endConnection() {
//     if (qz.websocket.isActive()) {
//         qz.websocket.disconnect().then(function() {
//             updateState('Inactive', 'default');
//         }).catch(handleConnectionError);
//     } else {
//         displayMessage('No active connection with QZ exists.', 'alert-warning');
//     }
// }
