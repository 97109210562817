import React, { useEffect, useMemo } from 'react'
import cx from 'classnames'
import { useNotifications } from '../'

import styles from './notifications.module.scss'

import { Informer } from '@consta/uikit/Informer'

import { NOTIFICATIONS_TIMEOUT } from 'src/config'

import { INotification } from '../Notifications'

type NotificationProps = INotification & {
  id: string
}

const Notification = (props: NotificationProps) => {
  const { id, type, content, options } = props

  const context = useNotifications()

  useEffect(() => {
    context?.playSound(type || '', options)
    if (!options?.persistent) {
      setTimeout(() => {
        context?.clear(id)
      }, options?.timeout ?? NOTIFICATIONS_TIMEOUT)
    }
  }, [])

  return (
    <Informer
      className={cx(styles.wrap, options?.allWidth && styles.allWidth)}
      title={undefined}
      status={type}
      label={content}
      icon={options?.icon}
    />
  )
}

export default Notification
