import React, { useState, useEffect } from 'react'

import styles from './weighting-place.module.scss'

import img from './assets/weightImg.svg'

import { Button } from '@consta/uikit/Button'
import { IconClose } from '@consta/uikit/IconClose'

import { Modal, InputWrapper, EnterHandler } from 'src/components'

import { useAppContext } from 'src/context'
import { useSound } from 'src/sound'

import { checkWeight } from 'src/helpers'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { ModalProps, IOrderPackagingPlace } from 'src/interfaces'

interface WeighingFormProps extends ModalProps {
  onSubmit: (weight: number) => void
  maxWeight: number | null
  errorWeight: string | null
  clearErrorWeight: () => void
}

const WeightingPlace = (props: WeighingFormProps) => {
  const {
    isOpen,
    onSubmit,
    onClose,
    maxWeight,
    errorWeight,
    clearErrorWeight,
  } = props

  const { setGlobalLoading } = useAppContext()

  const sound = useSound()

  useEffect(() => {
    sound?.play('PACKING_PLACE_WEIGHT')
  }, [])

  const [weight, setWeight] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleSubmit = () => {
    if (weight) {
      if (!checkWeight(weight)) {
        setError('Число должно быть целым, неотрицательным, больше 0')
        return
      }
      if (maxWeight && Number(weight) > maxWeight) {
        setError(`Превышен максимальный вес места ${maxWeight / 1000} кг`)
        return
      }
      setGlobalLoading(true)
      onSubmit(Number(weight))
    }
  }

  const handleChange = (value: TextFieldPropValue) => {
    setError('')
    if (errorWeight) {
      clearErrorWeight()
    }

    setWeight(String(value))

    if (value) {
      if (!checkWeight(value)) {
        setError('Число должно быть целым, неотрицательным, больше 0')
        return
      }
      if (maxWeight && Number(value) > maxWeight) {
        setError(`Превышен максимальный вес места ${maxWeight / 1000} кг`)
      }
    }
  }
  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.weight}
        title="Взвешивание посылки"
        subtitle="Положите упаковку на весы"
        size="s"
        img={img}
        imgClass={styles.img}
      >
        <InputWrapper
          value={weight}
          id={'weight'}
          handleChange={handleChange}
          autoFocus
          className={styles.itemInput}
          rightSide="гр"
          placeholder={'Введите вес'}
          error={error || (errorWeight ?? '')}
          size="l"
          isInteger
        />
        <div className={styles.btns}>
          <Button label="Отменить" view="ghost" onClick={() => onClose()} />
          <Button
            label="Подтвердить"
            disabled={!weight || !!error}
            onClick={handleSubmit}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default WeightingPlace
