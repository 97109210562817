import React from 'react'

import styles from './dimensions-input.module.scss'

import { InputWrapper } from 'src/components'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IDimensions } from 'src/interfaces'

interface DimensionsInputProps {
  value: IDimensions
  onChange: (d: IDimensions, field: string) => void
  errors?: { [key: string]: string }
  integerNumbersInputs?: boolean
  autoFocus?: boolean
}

const DimensionsInput = (props: DimensionsInputProps) => {
  const {
    value,
    onChange,
    errors,
    integerNumbersInputs = false,
    autoFocus = false,
  } = props

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.colLabel}>Введите габариты, см</h4>
      <div className={styles.col3}>
        <InputWrapper
          value={value.width}
          id={'dimensions.width'}
          handleChange={(width: TextFieldPropValue) =>
            onChange(
              {
                ...value,
                width,
              },
              'width',
            )
          }
          className={styles.itemInput}
          size="l"
          rightSide="ширина"
          error={errors?.width ?? ''}
          isInteger={!!integerNumbersInputs}
          autoFocus={autoFocus}
        />
        <InputWrapper
          value={value.height}
          id={'dimensions.height'}
          handleChange={(height: TextFieldPropValue) =>
            onChange(
              {
                ...value,
                height,
              },
              'height',
            )
          }
          className={styles.itemInput}
          size="l"
          rightSide="высота"
          error={errors?.height ?? ''}
          isInteger={!!integerNumbersInputs}
        />
        <InputWrapper
          value={value.depth}
          id={'dimensions.depth'}
          handleChange={(depth: TextFieldPropValue) =>
            onChange(
              {
                ...value,
                depth,
              },
              'depth',
            )
          }
          className={styles.itemInput}
          size="l"
          rightSide="глубина"
          error={errors?.depth ?? ''}
          isInteger={!!integerNumbersInputs}
        />
      </div>
    </div>
  )
}

export default DimensionsInput
