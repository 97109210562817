import api from 'src/api'

import { provideMock, MOCK_GET_ORDER_BY_BARCODE } from 'src/mocks'

import { GetOrderByBarcodeResult } from 'src/interfaces'

interface UseOrderByBarcodeParamsType {
  placeBarcode: string
}

const getOrderByBarcode = async (params: UseOrderByBarcodeParamsType) => {
  const path = `/getorder/barcode/${params.placeBarcode}`

  const result = await api.get(path)

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useGetOrderByBarcode = () => ({
  fetch: (params: UseOrderByBarcodeParamsType) =>
    provideMock<UseOrderByBarcodeParamsType, GetOrderByBarcodeResult>(
      getOrderByBarcode,
      params,
      {
        key: 'placeBarcode',
        value: 'mock',
        result: MOCK_GET_ORDER_BY_BARCODE,
      },
    )(),
})

export default useGetOrderByBarcode
