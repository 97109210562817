import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import days from 'dayjs'
import ruLocale from 'date-fns/locale/ru'

import styles from './calendar-input.module.scss'

import { TextField } from '@consta/uikit/TextField'
import { Calendar } from '@consta/uikit/Calendar'

import { useAppContext } from 'src/context'

import { IconProps } from '@consta/uikit/__internal__/src/icons/Icon/Icon'
import { TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'

interface CalendarInputProps {
  id: string
  label?: string
  placeholder?: string
  size?: TextFieldPropSize
  value: Date | null
  handleChange?: (value: CalendarPropValue) => void
  className?: any
  rightSide?: string | React.FC<IconProps>
}

type CalendarPropValue = Date

const CalendarInput = ({
  className,
  id,
  label,
  value,
  handleChange = () => false,
  placeholder,
  rightSide,
  size,
}: CalendarInputProps) => {
  const { resetAfkInterval } = useAppContext()

  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const handleChangeCalendar = (value: CalendarPropValue) => {
    resetAfkInterval()

    setShowCalendar(false)
    handleChange(value)
  }

  return (
    <div className={cx(styles.inputCalendar, className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <div onClick={() => setShowCalendar(!showCalendar)}>
        <TextField
          readOnly
          type="text"
          id={id}
          value={value ? days(value).format('DD.MM.YYYY') : null}
          placeholder={placeholder}
          rightSide={rightSide}
          size={size}
          className={styles.input}
        />
      </div>
      {showCalendar ? (
        <Calendar
          view="oneMonth"
          type="date"
          locale={ruLocale}
          className={styles.calendar}
          maxDate={new Date()}
          value={value ? value : undefined}
          onChange={({ value }) => handleChangeCalendar(value)}
        />
      ) : null}
    </div>
  )
}

export default CalendarInput
