import React from 'react'
import cx from 'classnames'

import styles from './select-list.module.scss'

import { ISelectListOption } from 'src/interfaces'

interface SelectListProps {
  options: ISelectListOption[]
  value?: ISelectListOption | null
  onChange: (o: ISelectListOption) => void
  className?: string
  onLabelClick?: boolean
}

const SelectList = ({
  options,
  value,
  onChange,
  className,
  onLabelClick = false,
}: SelectListProps) => {
  return (
    <div className={cx(styles.wrap, className)}>
      <ul className={styles.main}>
        {options.length ? (
          <>
            {options.map((o, i) => (
              <li
                className={cx(
                  value &&
                    (value.value === o.value
                      ? styles.active
                      : styles.notActive),
                  styles.li,
                )}
                key={`${o.value}-${i}`}
                onClick={!onLabelClick ? () => onChange(o) : undefined}
              >
                <span onClick={onLabelClick ? () => onChange(o) : undefined}>
                  {o.label}
                </span>
                {o.rightSide && <>{o.rightSide}</>}
              </li>
            ))}
          </>
        ) : null}
      </ul>
    </div>
  )
}

export default SelectList
