import React, { useEffect, useState } from 'react'

import styles from './waiting-close-place-modal.module.scss'

import img from './assets/ConnectionError.svg'

import { Button } from '@consta/uikit/Button'
import { IconRestart } from '@consta/uikit/IconRestart'
import { Timer } from '@consta/uikit/Timer'

import { Modal } from 'src/components'

import { useNotifications } from 'src/notification'

import {
  ModalProps,
  IError,
  IOrderPackagingPlace,
  IPlaceErrorData,
  IPalletErrorData,
  IOrderPallet,
} from 'src/interfaces'

interface IWaitingClosePlaceModal extends ModalProps {
  orderId: string
  currentOperation?: string
  errorData: any
  onRetry: () => Promise<boolean | string | void>
}

const WaitingClosingModal = (props: IWaitingClosePlaceModal) => {
  const { isOpen, onClose, onRetry, currentOperation, errorData, orderId } =
    props

  const notification = useNotifications()

  const [loading, setLoading] = useState<boolean>(false)
  const [timeCount, setTimeCount] = useState<number>(30)
  const [timer, setTimer] = useState<any>(null)
  const [tryingCount, setTryingCount] = useState<number>(15)

  const countDown = () => {
    setTimeCount((state) => state - 1)
  }

  useEffect(() => {
    setTimer(setInterval(countDown, 1000))
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (timeCount === 0) {
      handleRetry()
    }
  }, [timeCount])

  const handleRetry = () => {
    notification?.show('warning', `Повтор попытки создания ${title}`)
    setLoading(true)
    clearInterval(timer)
    setTimeCount(30)
    if (tryingCount) {
      setTryingCount((state) => state - 1)
    }

    console.log(onRetry)
    onRetry()
      .catch((e) => {
        if (tryingCount - 1) {
          setTimer(setInterval(countDown, 1000))
        }
      })
      .finally(() => setLoading(false))
  }

  const title = currentOperation === 'palletClosing' ? 'паллеты' : 'места'

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.error}
      title={`Ожидание закрытия ${title}`}
      size="s"
      img={img}
      imgClass={styles.img}
      withClose={true}
      onClose={onClose}
    >
      {errorData?.errors?.length ? (
        errorData.errors.map((e: IError, i: number) => (
          <div key={`error-${i}`} className={styles.errorMes}>
            <p className={styles.orderId}>Заказ: {orderId}</p>
            <p>
              Код ошибки: {e.code}. {e.message}
            </p>
          </div>
        ))
      ) : (
        <div className={styles.errorMes}>
          <p className={styles.orderId}>Заказ: {orderId}</p>
          <p>
            {errorData?.message
              ? errorData.message
              : typeof errorData === 'string'
              ? errorData
              : 'Неизвестная ошибка'}
          </p>
        </div>
      )}
      <div className={styles.timer}>
        {tryingCount ? (
          <>
            <Timer
              className={styles.timer}
              size="m"
              seconds={timeCount}
              progress={timeCount * 3.3}
            />
            <span> Попытка повторного закрытия {title}</span>
          </>
        ) : null}
      </div>

      <div className={styles.btns}>
        <Button
          label="Повторить"
          view="primary"
          loading={loading}
          className={styles.repeat}
          onClick={handleRetry}
          size="l"
          iconLeft={IconRestart}
        />
      </div>
    </Modal>
  )
}

export default WaitingClosingModal
