import React, { useEffect, useState } from 'react'

import PalletImg from 'src/assets/images/pallet_avatar.svg'

import WeightDimensionsPalletModal from './WeightDimensionsPalletModal'

import { ModalProps, IOrderPallet } from 'src/interfaces'

interface ClosePalletProps extends ModalProps {
  currentPalletData: IOrderPallet
  onSubmit: (newPlaceData: IOrderPallet) => void
}

const ClosePallet = (props: ClosePalletProps) => {
  const { currentPalletData, onSubmit, onClose, onMount } = props

  useEffect(() => {
    if (onMount) {
      console.log('onMount')
      onMount()
    }
  }, [])

  const handleSaveWeightDimensions = (pallet: IOrderPallet) => {
    onSubmit(pallet)
    onClose()
    return false
  }
  return (
    <>
      <WeightDimensionsPalletModal
        pallet={currentPalletData}
        isOpen={true}
        onClose={() => false}
        onSubmit={(pallet) => handleSaveWeightDimensions(pallet)}
        img={PalletImg}
        title="Габариты и вес паллеты"
        subtitle="Укажите габариты и вес паллеты"
        withClose={false}
      />
    </>
  )
}

export default ClosePallet
