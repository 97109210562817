import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_UPDATE_PALLET_COUNT } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface useUpdatePalletCountParamsType {
  orderId: string
}

const useUpdatePalletCount = (params: useUpdatePalletCountParamsType) => {
  return useMutation(async (data: { palletCount: string }) => {
    console.log(data.palletCount)
    try {
      const result = await provideMock<
        useUpdatePalletCountParamsType,
        { data: ApiReqResult }
      >(
        async () =>
          await api.post(`/packing/pallet/generalCount`, [
            {
              palletCount: data.palletCount,
              orderId: params.orderId,
            },
          ]),
        params,
        [
          {
            key: 'orderId',
            value: 'mockb2b',
            result: MOCK_UPDATE_PALLET_COUNT,
          },
        ],
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useUpdatePalletCount
