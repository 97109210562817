import React, { useReducer, useContext, createContext } from 'react'

import { ISupply } from 'src/interfaces'

import {
  AcceptanceContextState,
  AcceptanceContextActions,
  AcceptanceContextActionTypes,
} from './interfaces'

const reducer = (
  state: AcceptanceContextState,
  action: AcceptanceContextActions,
) => {
  switch (action.type) {
    case AcceptanceContextActionTypes.setSavedChz:
      return { ...state, savedChz: action.savedChz }
    case AcceptanceContextActionTypes.setSupply:
      return { ...state, supply: action.supply }
    case AcceptanceContextActionTypes.addSavedWeightAndDimensions:
      return {
        ...state,
        savedWeightAndDimensions: [
          ...state.savedWeightAndDimensions,
          action.itemId,
        ],
      }
    default:
      return state
  }
}

const initialState = {
  savedChz: null,
  savedWeightAndDimensions: [],
  supply: null,
} as AcceptanceContextState

export const AcceptanceContext = createContext({
  ...initialState,
  setSavedChz: (value: string | null) => void value as void,
  addSavedWeightAndDimensions: (value: string) => void value as void,
  setSupply: (value: ISupply) => void value as void,
})

export const AcceptanceContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<AcceptanceContextState, AcceptanceContextActions>
  >(reducer, initialState)

  const setSavedChz = (value: string | null) => {
    dispatch({
      type: AcceptanceContextActionTypes.setSavedChz,
      savedChz: value,
    })
  }

  const setSupply = (value: ISupply) => {
    dispatch({
      type: AcceptanceContextActionTypes.setSupply,
      supply: value,
    })
  }

  const addSavedWeightAndDimensions = (itemId: string) => {
    dispatch({
      type: AcceptanceContextActionTypes.addSavedWeightAndDimensions,
      itemId,
    })
  }

  return (
    <AcceptanceContext.Provider
      value={{
        ...state,
        setSavedChz,
        addSavedWeightAndDimensions,
        setSupply,
      }}
    >
      {children}
    </AcceptanceContext.Provider>
  )
}

export const useAcceptanceContext = () => {
  const context = useContext(AcceptanceContext)
  return context
}
