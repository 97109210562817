import api from 'src/api'

import { provideMock, MOCK_GET_ADD_FILES } from 'src/mocks'

import { addFilesResult } from 'src/interfaces'

interface useGetAddFilesParams {
  orderId: string
}

const getAddFiles = async (params: useGetAddFilesParams) => {
  const url = `/packing/order/${params.orderId}/docs_for_print`
  const result = await api.get(url)

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useGetAddFiles = () => ({
  fetch: (params: useGetAddFilesParams) =>
    provideMock<useGetAddFilesParams, addFilesResult>(getAddFiles, params, {
      key: 'orderId',
      value: 'mock',
      result: MOCK_GET_ADD_FILES,
    })(),
})

export default useGetAddFiles
