import React, { useState, useEffect } from 'react'

import ItemCountModal from './ItemCountModal'
import SelectBatchModal from './SelectBatchModal'
import ItemScanSerialModal from './ItemScanSerialModal'

import { WeightDimensionsModal } from 'src/components'

import { useAcceptanceContext } from 'src/context'

import { useNotifications } from 'src/notification'
import { useSound } from 'src/sound'

import {
  ISupplyItem,
  ISupplyPart,
  ISupplyPartItem,
  IBatch,
  ISupplyOriginalItem,
} from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface ItemAddingProps {
  indicateItemCount: boolean
  addingItem: ISupplyOriginalItem | null
  setAddingItem: (item: ISupplyOriginalItem) => void
  items: ISupplyItem[]
  cacheItems: ISupplyOriginalItem[] | null
  updateCache: (newItems: ISupplyOriginalItem[]) => void
  tares: ISupplyPart[]
  currentTare: TextFieldPropValue
  onClose: (isFinal?: boolean) => void
  onAddItemToTare: (newItem: ISupplyPartItem[]) => void
  scanSerial?: boolean
  blurOrFocusBarcodeInput: (type: 'focus' | 'blur') => void
}

const getModalsArr = (
  addingItem: ISupplyOriginalItem | null,
  indicateItemCount?: boolean,
  scanSerial?: boolean,
  savedWeightAndDimensions: string[] = [],
) => {
  const modalsArr = []
  if (addingItem?.require_weight || addingItem?.require_dimensions) {
    const isSavedWeightAndDimensions = savedWeightAndDimensions.find(
      (s: string) => addingItem.id === s,
    )
    if (!isSavedWeightAndDimensions) {
      modalsArr.push('weightAndDimensions')
    }
  }

  if (addingItem?.batch_accounting) {
    modalsArr.push('selectBatch')
  }

  if (indicateItemCount) {
    modalsArr.push('itemCount')
  }

  if (scanSerial && addingItem?.scan_serial_numbers?.length) {
    modalsArr.push('scanSerial')
  }

  return modalsArr
}

const ItemAdding = (props: ItemAddingProps) => {
  const {
    indicateItemCount,
    addingItem,
    setAddingItem,
    cacheItems,
    updateCache,
    currentTare,
    tares,
    onClose,
    onAddItemToTare,
    scanSerial,
    blurOrFocusBarcodeInput,
  } = props

  const { savedWeightAndDimensions, addSavedWeightAndDimensions } =
    useAcceptanceContext()

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [count, setCount] = useState<string>('')

  const notification = useNotifications()
  const sound = useSound()

  useEffect(() => {
    blurOrFocusBarcodeInput('blur')

    if (currentTare === null) {
      notification?.show('alert', 'Нет открытой тары')
      onClose()
    }
  }, [])

  useEffect(() => {
    return () => blurOrFocusBarcodeInput('focus')
  }, [])

  const handleAdd = (newItems: ISupplyOriginalItem[]) => {
    onAddItemToTare(
      newItems.map((item: ISupplyOriginalItem) => ({
        id: item.id,
        pack_id: item.pack_id ?? '',
        batch_id: item?.batch?.id ?? '',
        quantity: item.quantity,
        serial_numbers: [...(item?.scanned_serial_numbers ?? [])],
      })),
    )
    onClose(true)
  }

  const gotoNextStep = ({ result }: { result: ISupplyOriginalItem[] }) => {
    const modalsArr = getModalsArr(
      addingItem,
      indicateItemCount,
      scanSerial,
      savedWeightAndDimensions,
    )

    const currentIndex = modalsArr.findIndex((m: string) => m === currentModal)
    if (currentIndex < modalsArr.length - 1) {
      setCurrentModal(modalsArr[currentIndex + 1])
    } else {
      console.log('result')
      console.log(result)
      handleAdd([...result])
    }
  }

  useEffect(() => {
    if (currentTare === null) {
      notification?.show('alert', 'Нет открытой тары')
      onClose()
    } else {
      if (addingItem) {
        gotoNextStep({ result: [{ ...addingItem, quantity: 1 }] })
      }
    }
  }, [])

  const handleSetWeightAndDimensions = () => {
    if (addingItem) {
      const isSavedWeightAndDimensions = savedWeightAndDimensions.find(
        (s: string) => addingItem.id === s,
      )
      if (!isSavedWeightAndDimensions) {
        addSavedWeightAndDimensions(addingItem.id)
      }
      gotoNextStep({ result: [{ ...addingItem, quantity: 1 }] })
    }
  }

  const handleSelectBatch = (batch: IBatch, isNewBatch?: boolean) => {
    if (addingItem) {
      const actualItem = {
        ...addingItem,
        batch,
        batches: isNewBatch
          ? [...(addingItem.batches || []), batch]
          : addingItem.batches,
      }
      setAddingItem(actualItem)

      if (isNewBatch) {
        updateCache(
          cacheItems?.map((item: ISupplyOriginalItem) => ({
            ...item,
            batches:
              item.id === addingItem.id
                ? [...(item.batches || []), batch]
                : item.batches,
          })) ?? [],
        )
      }

      gotoNextStep({ result: [{ ...actualItem, quantity: 1 }] })
    }
  }

  const handleChangeCount = (directCount?: number) => {
    if (addingItem) {
      const actualItem = {
        ...addingItem,
        quantity: directCount ? Number(directCount) : Number(count),
      }

      setAddingItem(actualItem)

      gotoNextStep({ result: [actualItem] })
    }
  }

  const handleScanSerial = (items: ISupplyOriginalItem[]) => {
    gotoNextStep({ result: items })
  }

  if (!addingItem) return null

  if (currentModal === 'weightAndDimensions') {
    return (
      <WeightDimensionsModal
        item={addingItem}
        isOpen={true}
        onClose={onClose}
        onSubmit={handleSetWeightAndDimensions}
        onMount={() => sound?.play('RECEIVING_WEIGHT_ITEM')}
      />
    )
  }

  if (currentModal === 'selectBatch') {
    return (
      <SelectBatchModal
        isOpen={true}
        expdate={addingItem?.batch_expdate}
        item={addingItem}
        onSubmit={handleSelectBatch}
        onClose={onClose}
      />
    )
  }

  if (currentModal === 'itemCount') {
    return (
      <ItemCountModal
        count={count}
        item={addingItem}
        isOpen={true}
        setCount={setCount}
        onItemAdding={handleChangeCount}
        onClose={onClose}
      />
    )
  }

  if (currentModal === 'scanSerial') {
    return (
      <ItemScanSerialModal
        multiple={indicateItemCount}
        isOpen={true}
        item={addingItem}
        cacheItems={cacheItems}
        onSubmit={handleScanSerial}
        onClose={onClose}
      />
    )
  }

  return null
}

export default ItemAdding
