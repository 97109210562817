import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import styles from './b2b-packing-order.module.scss'

import PalletImg from 'src/assets/images/pallet_avatar.svg'

import { IconWarning } from '@consta/uikit/IconWarning'
import { Informer } from '@consta/uikit/Informer'

import MainBlock from './MainBlock'
import ClosePlace from './ClosePlace'
import ClosePallet from './ClosePallet'
import CountPalletModal from './CountPalletModal'
import B2bSideBar from './B2bSideBar'

import {
  StickerPrintModal,
  ItemAdding,
  ShippingFormModal,
  KitModal,
  WaitingClosingModal,
  AddAttachmentsModal,
} from '../components'

import {
  StickerErrorModal,
  SearchItemsModal,
  ItemStickerPrintModal,
} from 'src/components'

import { useNotifications } from 'src/notification'
import { useSound } from 'src/sound'
import { useGetSticker } from 'src/hooks'
import {
  useManagePlacesAndPallets,
  useCheckIsConditionsMaxCountCompleted,
  useCheckShouldAddItem,
  useManageMaxWeight,
} from './hooks'

import { usePackingOrderContext, usePackingPrintContext } from '../context'

import {
  //saveBase64AsPdf,
  getItemsQuantity,
  getNewPallet,
  rusToLatin,
  getOrderItem,
  checkIsPreferredBarcodeType,
  getB2bSubtitleByOperation,
  getAddPackByItem,
} from 'src/helpers'

import {
  CLOSE_PACKAGING_BARCODE,
  PRINT_ITEM_STICKER_BARCODE,
  CLOSE_PALLET_BARCODE,
  POST_PALLETS_COUNT,
  PACK_DOCS,
} from 'src/config'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { PackingOrderProps } from '../interfaces'
import {
  IOrderItem,
  IOrderPackagingPlace,
  IOrderPackagingPlaceItem,
  IOrderPallet,
  IPlaceErrorData,
  IPalletErrorData,
  packType,
  useManagePlacesAndPalletsRes,
} from 'src/interfaces'

interface B2BPackingOrderProps extends PackingOrderProps {}

const B2BPackingOrder = (props: B2BPackingOrderProps) => {
  const { orderId } = props

  // context

  const {
    items,
    places,
    showShippingForm,
    cachedItem,
    addingItem,
    currentPlace,
    kitModalData,
    setShowShippingForm,
    setCachedItem,
    setAddingItem,
    setCurItem,
    setKitModalData,
    checkItemInPlaces,
    isInValidItemQuantity,
    handleRemoveItem,
    handleSavePlace,
    handleCloseKitModal,
    pallets,
    currentPallet,
    order,
    packages: packagingOptions,
    currentOperation,
    setCurrentOperation,
    cachedPackages,
    currentClosePlaceModal,
    setCurrentClosePlaceModal,
    curPackingType,
    closingErrorData,
    setClosingErrorData,
    showClosePallet,
    errorWeight,
    setErrorWeight,
    showClosePlace,
    setShowClosePlace,
    curPackageId,
    setShowClosePallet,
    indicateItemCount,
    setIndicateItemCount,
    alert,
  } = usePackingOrderContext()

  const {
    lastStickerWithoutError,
    showStickerPrintInfo,
    setShowStickerPrintInfo,
    applyPrintItemSticker,
    showStickerError,
    sticker,
    handleCloseStickerError,
    barcodeInput,
    blurOrFocusBarcodeInput,
    handleCloseAndFocusBarcode,
  } = usePackingPrintContext()

  // state

  const [showStickerPrint, setShowStickerPrint] = useState<boolean>(false)
  const [showPacked, setShowPacked] = useState<boolean>(false)

  const [showSearchItemsModal, setShowSearchItemsModal] =
    useState<boolean>(false)

  const [showAddAttachments, setShowAddAttachments] = useState<boolean>(false)
  const [addAttachmentsIsPacked, setAddAttachmentsIsPacked] =
    useState<boolean>(false)

  // other

  const originalItemsCount = useMemo(
    () => getItemsQuantity(order?.items),
    [order?.items],
  )
  const placesCount =
    curPackingType === packType.packInPallets
      ? pallets.reduce((acc, pallet) => pallet.places.length + acc, 0)
      : places.length
  const isPackingInPallets = curPackingType === packType.packInPallets
  const isPackingInPlaces = curPackingType === packType.packInPlaces
  const remainingItems = useMemo(
    () => Number(getItemsQuantity(items ?? [])),
    [items],
  )
  const allowPackInPallets = false
  const isPackEnd =
    originalItemsCount &&
    originalItemsCount ===
      originalItemsCount - (getItemsQuantity(items ?? []) ?? 0) &&
    currentPlace === null

  // request hooks

  const getItemSticker = useGetSticker({ id: orderId, type: 'order' })

  // other hooks

  const notification = useNotifications()
  const history = useHistory()
  const sound = useSound()

  // other fuctions

  const printItemSticker = applyPrintItemSticker(getItemSticker)

  const printLastItemSticker = (): void => {
    if (cachedItem?.id) {
      setCurrentOperation('itemAdding')
      printItemSticker(cachedItem.id, 1, cachedItem?.dateOfManufacture)
    }
  }

  const getPlaces = (): IOrderPackagingPlace[] => {
    if (curPackingType === packType.packInPlaces) return places
    return currentPallet !== null ? pallets[currentPallet].places : []
  }

  const onSuccessLastPallet = () => {
    if (!addAttachmentsIsPacked && order?.ad_attachments?.length) {
      setShowAddAttachments(true)
      return
    }
    notification?.show(
      'success',
      `Предварительная упаковка успешно завершена`,
      {
        soundName: 'PACKING_COMPLETED',
      },
    )
    setShowStickerPrint(true)
  }

  const updateKitModal = (item: IOrderPackagingPlaceItem) => {
    if (typeof item?.kit_id !== 'undefined') {
      setKitModalData({
        kit_id: item?.kit_id,
        kit_title: item?.kit_title,
      })
    }
  }

  // hooks

  const { checkIsConditionsMaxCountCompleted } =
    useCheckIsConditionsMaxCountCompleted()

  const {
    changeWeight,
    clearWeight,
    checkWeight,
    cachedWeights,
    addWeightToCache,
    updatePlacesWeight,
  } = useManageMaxWeight()

  const { shouldAddItem } = useCheckShouldAddItem({
    checkWeight,
    cachedWeights,
  })

  const {
    orderStatus,
    startClosePallet,
    handleFetchClosePallet,
    updatePlaceInPallet,
    updatePlaceInPlaces,
    updatePalletPlaceItems,
    updatePlaces,
    handleClosePlace,
    handleClosePallet,
    handleUpdatePalletCount,
    showPalletCount,
    setShowPalletCount,
  }: useManagePlacesAndPalletsRes = useManagePlacesAndPallets({
    orderId,
    onSuccessLastPallet,
    clearWeight,
  })

  // handlers

  const handleScanBarcode = (
    value: TextFieldPropValue,
    isItemId: boolean = false,
  ): void => {
    let newVal = rusToLatin(value)

    if (newVal === CLOSE_PALLET_BARCODE && allowPackInPallets) {
      startClosePallet()
      return
    }

    if (newVal === POST_PALLETS_COUNT) {
      setShowPalletCount(true)
      return
    }

    if (newVal === PACK_DOCS) {
      const currentItem = items?.find((item) => item.id === 'SDoc')
      if (!currentItem) {
        notification?.show('alert', 'Сопроводительные документы не найдены')
        return
      }
      if (order?.ExtraSeat && currentPlace !== null) {
        notification?.show(
          'alert',
          'Сопроводительные документы должны быть упакованы в отдельное место',
        )
        return
      }
      setAddingItem({
        ...currentItem,
        barcode_used: '',
      })
      return
    }

    if (newVal === CLOSE_PACKAGING_BARCODE) {
      if (currentPlace === null) {
        notification?.show('alert', 'Нет открытого места')
        return
      }

      const isConditionsMaxCountCompleted = checkIsConditionsMaxCountCompleted()
      if (!isConditionsMaxCountCompleted) {
        return
      }
      setShowClosePlace(true)
      return
    }

    if (newVal === PRINT_ITEM_STICKER_BARCODE) {
      printLastItemSticker()
      return
    }

    const currentItem = getOrderItem(newVal, isItemId, items ?? [])

    if (shouldAddItem(newVal, isItemId) && currentItem) {
      setCurrentOperation('itemAdding')
      const barcode = !isItemId
        ? newVal
        : currentItem.barcodes?.find((b) => b.barcode)?.barcode ?? ''

      const [needAddPack, packagings] = getAddPackByItem(
        currentItem.id,
        cachedPackages,
      )

      const addingItemData = {
        ...currentItem,
        barcode_used: barcode,
        packagings,
        needAddPack,
        weight: currentItem.weight ?? cachedWeights[currentItem.id] ?? null,
        manualRequireWeight: !!cachedWeights[currentItem.id],
      }

      setCurItem(addingItemData)
      setAddingItem(addingItemData)
    }
  }

  const handleAddPlaceInPallet = (newPlace: IOrderPackagingPlace): void => {
    if (currentPallet === null) {
      const newPallet = getNewPallet([], pallets.length + 1)
      const newPallets = [...pallets, newPallet]

      handleAddPlace('inPallets', newPlace, {
        currentPallet: newPallets.length - 1,
        pallets: newPallets,
      })
      return
    }
    handleAddPlace('inPallets', newPlace, {
      currentPallet: currentPallet,
      pallets: pallets,
    })
  }

  const handleAddPlace = (
    type: 'inPlaces' | 'inPallets',
    newPlace: IOrderPackagingPlace,
    palletInfo?: {
      currentPallet: number
      pallets: IOrderPallet[]
    },
  ): void => {
    if (type === 'inPallets' && !palletInfo) return

    const item = newPlace?.items?.[0]

    const allQuantity = newPlace?.items.reduce(
      (acc, item) => acc + item.quantity,
      0,
    )

    if (
      isInValidItemQuantity(
        item?.id,
        allQuantity,
        isPackingInPallets ? 'pallets' : 'places',
      )
    ) {
      return
    }

    checkForItemPrint(item, allQuantity)
    updateKitModal(item)

    setCachedItem(item)
    changeWeight((item.weight || 1000) * allQuantity, 'place')

    if (type === 'inPallets' && palletInfo) {
      updatePlaceInPallet(
        newPlace,
        palletInfo.currentPallet,
        palletInfo.pallets,
      )
    }

    if (type === 'inPlaces') {
      updatePlaceInPlaces(newPlace)
    }

    handleRemoveItem(item?.id, allQuantity)
    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')
  }

  const checkForItemPrint = async (
    item: IOrderPackagingPlaceItem,
    quantity: number,
  ) => {
    const isNotPreferredBarcodeType = order?.preferred_barcode_type
      ? !checkIsPreferredBarcodeType(
          item,
          order.preferred_barcode_type,
          order?.items ?? [],
        )
      : false
    //если есть задание на этикетирование или отсканирован непредпочтительный штрихкод, то напечатать стикер товара
    if (
      order?.stickerTask ||
      (isNotPreferredBarcodeType && quantity) ||
      item.packagings?.length
    ) {
      const expiryMonths = order?.items.find(
        (orderItem) => item.id === orderItem.id,
      )?.expiryMonths
      const dateOfManufacture = item?.dateOfManufacture
        ? dayjs(item.dateOfManufacture, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : item?.expirationDate
        ? dayjs(item.expirationDate, 'YYYY-MM-DD')
            .subtract(expiryMonths || 999, 'month')
            .format('YYYY-MM-DD')
        : undefined

      const getQuantity = () => {
        if (isNotPreferredBarcodeType && item.packagings?.length) {
          return 2 * quantity
        }
        return quantity
      }

      const isLastItems =
        items?.length === 1 &&
        items.find((i) => i.id === item.id)?.quantity === quantity

      // стикеры на доп упаковку (расширенные)
      await printItemSticker({
        itemId: item?.id,
        barcode: item?.barcode_used,
        quantity: getQuantity(),
        dateOfManufacture: item?.dateOfManufacture ?? dateOfManufacture,
        expiryMonths:
          item?.dateOfManufacture ?? dateOfManufacture
            ? expiryMonths || 999
            : undefined,
        advanced: true,
        needFocusAfterPrint: !isLastItems,
      })
    }
  }

  const handleAddItemToPlace = (newItems: IOrderPackagingPlaceItem[]): void => {
    const newItem = newItems?.[0]
    const allQuantity = newItems.reduce((acc, item) => acc + item.quantity, 0)

    if (
      isInValidItemQuantity(
        newItem?.id,
        allQuantity,
        isPackingInPallets ? 'pallets' : 'places',
      )
    ) {
      return
    }

    if (
      currentPlace === null ||
      (isPackingInPallets ? currentPallet === null : false)
    )
      return

    updateKitModal(newItem)
    if (kitModalData && kitModalData?.kit_id !== newItem?.kit_id) {
      notification?.show('alert', 'Сканируемый товар не входит в данный набор')
      return
    }

    checkForItemPrint(newItem, allQuantity)

    setCachedItem(newItem)
    changeWeight((newItem.weight || 1000) * allQuantity, 'place')

    if (isPackingInPallets) {
      updatePalletPlaceItems(newItems)
    }
    if (isPackingInPlaces) {
      updatePlaces(newItems)
    }

    handleRemoveItem(newItem.id, allQuantity)
    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')
  }

  const handleChooseItem = (item: IOrderItem): void => {
    handleScanBarcode(item.id ?? '', true)
  }

  useEffect(() => {
    console.log(places)
  }, [places])

  const handlePrintItemSticker = (
    id: string,
    barcode?: string,
    expires?: string,
    dateOfManufacture?: string,
    advanced?: boolean,
  ): void => {
    setCurrentOperation('itemAdding')

    const expiryMonths = order?.items.find(
      (orderItem) => id === orderItem.id,
    )?.expiryMonths
    const curDateOfManufacture = dateOfManufacture
      ? dayjs(dateOfManufacture, 'YYYY-MM-DD').format('YYYY-MM-DD')
      : expires
      ? dayjs(expires, 'YYYY-MM-DD')
          .subtract(expiryMonths || 999, 'month')
          .format('YYYY-MM-DD')
      : undefined

    printItemSticker({
      itemId: id,
      barcode,
      quantity: 1,
      dateOfManufacture: curDateOfManufacture,
      expiryMonths: curDateOfManufacture ? expiryMonths || 999 : undefined,
      advanced,
    })
  }

  const getLastPlace = () => {
    const lastPallet = pallets[pallets.length - 1]
    const lastPlace = lastPallet?.places?.length ?? 0
    return lastPallet?.places[lastPlace - 1]
  }

  const handleСompleteAddAttachment = () => {
    if (getItemsQuantity(items ?? [])) {
      handleCloseAndFocusBarcode(setShowAddAttachments)
    } else {
      setShowAddAttachments(false)
    }
    setAddAttachmentsIsPacked(true)

    const lastPlaceIsFinal = isPackingInPallets
      ? getLastPlace()?.is_final
      : places[places.length - 1]?.is_final
    if (!items?.length && lastPlaceIsFinal && lastStickerWithoutError) {
      setShowStickerPrint(true)
      notification?.show(
        'success',
        `Предварительная упаковка успешно завершена`,
        {
          soundName: 'PACKING_COMPLETED',
        },
      )
    }
  }

  return (
    <>
      <div className={styles.main}>
        <B2bSideBar
          orderStatus={orderStatus}
          isPackEnd={!!isPackEnd}
          handleScanBarcode={handleScanBarcode}
          showAddAttachements={() => setShowAddAttachments(true)}
          showSeacrhItem={() => setShowSearchItemsModal(true)}
        />
        <div className={styles.bodyWrap}>
          {alert ? (
            <Informer
              status="success"
              view="filled"
              label={alert}
              icon={IconWarning}
              className={styles.alert}
            />
          ) : null}
          <div className={styles.body}>
            <MainBlock
              order={order}
              items={items}
              pallets={pallets}
              currentPlace={currentPlace}
              currentPallet={currentPallet}
              setShowPacked={setShowPacked}
              handlePrintItemSticker={handlePrintItemSticker}
              handleChooseItem={handleChooseItem}
              curPackingType={curPackingType}
              packagingOptions={packagingOptions || []}
              places={places}
              addWeightToCache={addWeightToCache}
              updatePlacesWeight={updatePlacesWeight}
            />
          </div>
        </div>
      </div>
      {addingItem ? (
        <ItemAdding
          indicateItemCount={indicateItemCount}
          addingItem={addingItem}
          packagingOptions={packagingOptions || []}
          currentPlace={currentPlace}
          places={getPlaces()}
          onPlaceAdd={
            isPackingInPallets
              ? handleAddPlaceInPallet
              : (place) => handleAddPlace('inPlaces', place)
          }
          onAddItemToPlace={handleAddItemToPlace}
          onClose={() => setAddingItem(null)}
          setAddingItem={(item) => setAddingItem(item)}
          blurOrFocusBarcodeInput={blurOrFocusBarcodeInput}
          sequence_num={placesCount + 1}
          remainingItems={remainingItems}
          isNeedEnterWeigthDimensions={
            (addingItem.require_weight ||
              addingItem.require_dimensions ||
              (!addingItem.weight && !cachedWeights[addingItem.id])) &&
            !checkItemInPlaces(
              addingItem,
              isPackingInPallets ? 'pallet' : 'place',
            )
          }
          type={isPackingInPallets ? 'pallets' : 'places'}
          checkPlaceWeight={(weight) => checkWeight(weight, 'place')}
          addWeightToCache={addWeightToCache}
        />
      ) : null}
      {showClosePlace &&
      currentPlace !== null &&
      (isPackingInPallets
        ? currentPallet !== null
        : true && !showStickerPrintInfo) ? (
        <ClosePlace
          isOpen={true}
          currentModal={currentClosePlaceModal}
          setCurrentModal={setCurrentClosePlaceModal}
          requireWeight={!!order?.require_place_weight}
          currentPlaceData={
            isPackingInPallets && currentPallet !== null
              ? pallets[currentPallet].places[currentPlace]
              : places[currentPlace]
          }
          packagingOptions={packagingOptions || []}
          onSubmit={handleClosePlace}
          onClose={() => setShowClosePlace(false)}
          errorWeight={errorWeight}
          maxWeight={order?.delivery?.maximum_place_weight ?? null}
          clearErrorWeight={() => setErrorWeight(null)}
          curPackageId={curPackageId}
        />
      ) : null}

      {showClosePallet &&
      currentPallet !== null &&
      currentPlace === null &&
      !showStickerError ? (
        <ClosePallet
          isOpen={true}
          currentPalletData={pallets[currentPallet]}
          onSubmit={handleClosePallet}
          onClose={() => setShowClosePallet(false)}
        />
      ) : null}

      {showStickerPrint && !showStickerError ? (
        <StickerPrintModal
          isOpen={true}
          title={'Предварительная упаковка завершена'}
          onSuccess={handleSavePlace}
          onClose={() => setShowStickerPrint(false)}
          btnTitle={'Вернуться к заказу'}
        />
      ) : null}

      {/* TODO вынести модалки ошибок в один унифицированный компонент??? */}
      {showStickerError ? (
        <StickerErrorModal
          isOpen={true}
          sticker={sticker}
          onClose={() => handleCloseStickerError(!!items?.length)}
          isFinal={!items?.length}
          showStickerPrint={() => setShowStickerPrint(true)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {closingErrorData?.show ? (
        <WaitingClosingModal
          orderId={order?.id ?? ''}
          currentOperation={currentOperation}
          isOpen={true}
          onClose={() => {
            setClosingErrorData(null)
          }}
          errorData={closingErrorData?.errors}
          onRetry={
            currentOperation === 'placeClosing'
              ? () =>
                  handleClosePlace(
                    closingErrorData?.data as IPlaceErrorData['data'],
                  )
              : () =>
                  handleFetchClosePallet(
                    closingErrorData?.data as IPalletErrorData['data'],
                  )
          }
        />
      ) : null}

      {showShippingForm ? (
        <ShippingFormModal
          isOpen={showShippingForm}
          onClose={() => handleCloseAndFocusBarcode(setShowShippingForm)}
          shippingFormData={order?.shipping_form || []}
          blurRef={barcodeInput}
          order={order}
        />
      ) : null}

      {kitModalData ? (
        <KitModal
          isOpen={true}
          kitData={kitModalData}
          items={items}
          onStartItem={handleScanBarcode}
          itemCountCheckboxValue={indicateItemCount}
          onItemCountCheckboxChange={setIndicateItemCount}
          onClose={() => handleCloseKitModal()}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {showSearchItemsModal ? (
        <SearchItemsModal
          isOpen={true}
          merchantId={order?.merchant?.id ?? ''}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          onClose={() => handleCloseAndFocusBarcode(setShowSearchItemsModal)}
        />
      ) : null}

      {/*<PackedModal*/}
      {/*  order={order}*/}
      {/*  isOpen={showPacked}*/}
      {/*  onClose={() => handleCloseAndFocusBarcode(setShowPacked)}*/}
      {/*/>*/}

      {showStickerPrintInfo ? (
        <ItemStickerPrintModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowStickerPrintInfo)}
          subtitle={getB2bSubtitleByOperation(currentOperation)}
        />
      ) : null}

      {showAddAttachments &&
      order?.ad_attachments?.length &&
      !showStickerError ? (
        <AddAttachmentsModal
          addAttachments={order.ad_attachments}
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowAddAttachments)}
          handleСompleteAddAttachment={handleСompleteAddAttachment}
        />
      ) : null}

      {showPalletCount && !showStickerError ? (
        <CountPalletModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowPalletCount)}
          onSubmit={handleUpdatePalletCount}
          img={PalletImg}
          title="Количество паллет"
          subtitle="Укажите количество паллет"
          withClose={false}
        />
      ) : null}
    </>
  )
}

export default B2BPackingOrder
