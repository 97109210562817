import api from 'src/api'

import { provideMock, MOCK_GET_ADD_FILE } from 'src/mocks'

import { addFileResult } from 'src/interfaces'

interface useGetAddFileParams {
  id: string
  orderId: string
}

const getAddFile = async (params: useGetAddFileParams) => {
  const url = `/packing/order/${params.orderId}/docs_for_print/${params.id}`
  const result = await api.get(url)

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useGetAddFile = () => ({
  fetch: (params: useGetAddFileParams) =>
    provideMock<useGetAddFileParams, addFileResult>(getAddFile, params, {
      key: 'id',
      value: 'mock',
      result: MOCK_GET_ADD_FILE,
    })(),
})

export default useGetAddFile
