import React, { useState } from 'react'
import cx from 'classnames'

import styles from './defective-switch-wrapper.module.scss'

import { Switch } from '@consta/uikit/Switch'

import { WarningModal } from 'src/components'

import { ISupplyPart } from 'src/interfaces'

interface IDefectiveSwitchWrapper {
  tare: ISupplyPart
  handleConfirmGoods: (type: string) => void
  className?: string
  blurOrFocus: (type: 'focus' | 'blur') => void
}

const DefectiveSwitchWrapper = (props: IDefectiveSwitchWrapper) => {
  const { tare, handleConfirmGoods, className, blurOrFocus } = props

  const [showOnlyGoodWarning, setShowOnlyGoodWarning] = useState<boolean>(false)
  const [showOnlyDefectiveWarning, setShowOnlyDefectiveWarning] =
    useState<boolean>(false)

  const handleChangeDefective = (checked: boolean) => {
    if (checked) {
      setShowOnlyDefectiveWarning(true)
    } else {
      setShowOnlyGoodWarning(true)
    }
  }

  const handleConfirm = (type: string) => {
    handleConfirmGoods(type)

    if (type === 'defective') {
      setShowOnlyDefectiveWarning(false)
    } else {
      setShowOnlyGoodWarning(false)
    }
    blurOrFocus('focus')
  }

  const handleCloseWithFocus = (act: React.Dispatch<boolean>) => {
    act(false)
    blurOrFocus('focus')
  }

  return (
    <>
      <div
        className={cx(styles.wrap, className)}
        onClick={() =>
          handleChangeDefective(tare.condition === 'defective' ? false : true)
        }
      >
        <span>Приемка брака</span>
        <Switch checked={tare.condition === 'defective'} />
      </div>
      {showOnlyGoodWarning ? (
        <WarningModal
          title="В текущей таре только годные товары?"
          subtitle="При подтверждении операции все товары в открытой таре будут отмечены как 'Годный товар'."
          isOpen={true}
          handleConfirm={() => handleConfirm('good')}
          onClose={() => handleCloseWithFocus(setShowOnlyGoodWarning)}
        />
      ) : null}

      {showOnlyDefectiveWarning ? (
        <WarningModal
          title="В текущей таре только бракованные товары?"
          subtitle="При подтверждении операции все товары в открытой таре будут отмечены как 'Брак'."
          isOpen={true}
          handleConfirm={() => handleConfirm('defective')}
          onClose={() => handleCloseWithFocus(setShowOnlyDefectiveWarning)}
        />
      ) : null}
    </>
  )
}

export default DefectiveSwitchWrapper
