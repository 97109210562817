import React, { useEffect, useState } from 'react'

import styles from './packed-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconSearch } from '@consta/uikit/IconSearch'

import { Modal, InputWrapper, PackedItemsTable } from 'src/components'

import {
  ModalProps,
  IOrderPackagingPlace,
  IPackedOrderPlace,
  IOrder,
  IOrderPackagingOption,
} from 'src/interfaces'

import { TextFieldPropValue } from '@consta/uikit/TextField'

interface PackedModalProps extends ModalProps {
  type?: 'ordinary' | 'fbo'
  order: IOrder | null
  places: IOrderPackagingPlace[]
  packagingOptions: IOrderPackagingOption[]
}

const PackedModal = (props: PackedModalProps) => {
  const {
    isOpen,
    onClose,
    type = 'ordinary',
    order,
    places,
    packagingOptions,
  } = props

  const [error, setError] = useState<string>('')
  const [itemBarcode, setItemBarcode] = useState<TextFieldPropValue>('')
  const [packedPlaces, setPackedPlaces] = useState<
    IOrderPackagingPlace[] | null
  >(null)
  const [filteredPackedPlaces, setFilteredPackedPlaces] = useState<
    IOrderPackagingPlace[] | null
  >(null)

  useEffect(() => {
    setPackedPlaces(places)
  }, [places])

  useEffect(() => {
    if (!itemBarcode) {
      setFilteredPackedPlaces(packedPlaces)
    }
  }, [packedPlaces])

  useEffect(() => {
    if (packedPlaces) {
      if (itemBarcode) {
        const newPlaces = packedPlaces.filter((p) =>
          p.items.find((i) => {
            return i.barcode_used.startsWith(itemBarcode)
          }),
        )
        const formattedNewPlaces = newPlaces.map((p) => {
          const newItems = p.items.filter((i) =>
            i.barcode_used.startsWith(itemBarcode),
          )
          return {
            ...p,
            items: newItems,
          }
        })
        setFilteredPackedPlaces(formattedNewPlaces)
      } else {
        setFilteredPackedPlaces(packedPlaces)
      }
    }
  }, [itemBarcode])

  const handleClose = () => {
    setFilteredPackedPlaces(packedPlaces)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.packed}
      rootClassName={styles.wrap}
      onClose={handleClose}
      withClose
      headerTitle="Упакованный товар"
      size="l"
      closeClass={styles.close}
    >
      <div className={styles.itemForm}>
        <InputWrapper
          value={itemBarcode}
          label={'Отсканируйте штрихкод товара для поиска'}
          id={'itemBarcode'}
          handleChange={(value) => setItemBarcode(value)}
          autoFocus
          error={error}
          className={styles.itemInput}
          leftSide={IconSearch}
          size="l"
          clearable
          onClear={() => setItemBarcode('')}
          withDelay
        />
      </div>

      <div className={styles.bodyWrap}>
        <div className={styles.body}>
          {filteredPackedPlaces?.length ? (
            <PackedItemsTable
              orderId={order?.id}
              items={order?.items}
              places={filteredPackedPlaces}
              className={styles.table}
              packagingOptions={packagingOptions}
              packed
            />
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default PackedModal
