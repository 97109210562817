import { useContext } from 'react'
import { useMutation } from 'react-query'
import { appLocalStorage } from 'src/libs'
import api from 'src/api'

import { AppContext } from 'src/context'

const useLogin = () => {
  const { setAuthorized } = useContext(AppContext)

  return useMutation(
    async (data: { auth_key: string; work_table: string; username?: string }) =>
      api.post('/auth/login', data),
    {
      onSuccess: (result) => {
        if (result && result.data) {
          appLocalStorage.token = result.data.access_token
          api.defaults.headers.Authorization = `Bearer ${result.data.access_token}`
          setAuthorized(true)
        }
      },
    },
  )
}

export default useLogin
