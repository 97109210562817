import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './b2b-relabeling-order.module.scss'

import { IconWarning } from '@consta/uikit/IconWarning'

import MainBlock from './MainBlock'
import ScanStickerModal from './ScanStickerModal'
import ClosePallet from '../B2BPackingOrder/ClosePallet'
import RelabelingSideBar from './RelabelingSideBar'

import {
  StickerPrintModal,
  ShippingFormModal,
  AdditionalDocsModal,
  PalletPackedModal,
  WaitingClosingModal,
} from '../components'

import { StickerErrorModal, ItemStickerPrintModal } from 'src/components'

import { useNotifications } from 'src/notification'
import { useCompletePack, useGetAddFiles } from 'src/hooks'
import {
  useMoveRelabelingItem,
  useManagePallets,
  useManagePrintSticker,
  useSetOrderStatus,
} from './hooks'
import useManageMaxWeight from '../B2BPackingOrder/hooks/useManageMaxWeight'

import { usePackingOrderContext, usePackingPrintContext } from '../context'

import {
  //saveBase64AsPdf,
  rusToLatin,
  getSubtitleByOperation,
  getTitleByOperation,
  checkAllPalletsFilled,
  checkMonoPallet,
} from 'src/helpers'

import { CLOSE_PALLET_BARCODE } from 'src/config'

import {
  IOrderPackagingPlace,
  ApiReqResult,
  RelabelingTypes,
  useManagePrintStickerRes,
  IOrderPallet,
  useManagePalletsRes,
  ShippingOptionsType,
  useMoveRelabelingItemRes,
  IOrderPackedPallet,
  IPalletErrorData,
  OperationTypes,
} from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

const B2BRelabelingOrder = () => {
  // context

  const {
    items,
    relabelingPlaces,
    showShippingForm,
    setShowShippingForm,
    order,
    handleSavePlace,
    palletsForRelabeling,
    addFiles,
    packages: packagingOptions,
    shippingFormViewed,
    currentPallet,
    setAddFiles,
    pallets,
    showClosePallet,
    setShowClosePallet,
    relabeledPallets,
    setRelabeledPallets,
    closingErrorData,
    setClosingErrorData,
    printedPalletLists,
    currentOperation,
    setCurrentOperation,
  } = usePackingOrderContext()

  const {
    showStickerError,
    sticker,
    showStickerPrintInfo,
    setShowStickerPrintInfo,
    handleCloseStickerError,
    barcodeInput,
    blurOrFocusBarcodeInput,
    handleCloseAndFocusBarcode,
  } = usePackingPrintContext()

  // state

  const [showScanSticker, setShowScanSticker] = useState<boolean>(false)
  const [showStickerPrint, setShowStickerPrint] = useState<boolean>(false)
  const [showAdditionalDocs, setShowAdditionalDocs] = useState<boolean>(false)
  const [newBarcodesArr, setNewBarcodesArr] = useState<string[]>([])

  const [curRelabelId, setCurRelabelId] = useState<string | null>(null)
  const [currentPlace, setCurrentPlace] = useState<IOrderPackagingPlace | null>(
    null,
  )
  const [isAllDocsPrinted, setIsAllDocsPrinted] = useState<boolean>(false)
  const [showPacked, setShowPacked] = useState<boolean>(false)
  const [currentPalletData, setCurrentPalletData] =
    useState<IOrderPallet | null>(null)
  const [isSuccessRelabel, setIsSuccessRelabel] = useState<boolean>(false)
  const [disableUniquenessCheck, setDisableUniquenessCheck] =
    useState<boolean>(false)

  // other

  const isBoxesRelabeling =
    order?.relabelingType === RelabelingTypes.BOXES ||
    order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS
  const isPallets = order?.relabelingType === RelabelingTypes.PALLETS
  const originalCount =
    (isBoxesRelabeling ? order?.places?.length : order?.pallets?.length) ?? 0
  const isMonoPallet =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PALLETS
  const isPalletListsPrinted =
    palletsForRelabeling.length === printedPalletLists.length &&
    palletsForRelabeling.every((p) => p.id && printedPalletLists.includes(p.id))

  const isAllPalletsFilled = useMemo(
    () => checkAllPalletsFilled(pallets),
    [pallets],
  )

  // request hooks

  const getAddFiles = useGetAddFiles()
  const completePack = useCompletePack()

  //  other hooks

  const notification = useNotifications()
  const history = useHistory()

  // other functions

  //обработка не найденого при сканировании шк
  const processBarcodeNotFound = (barcode: string): void => {
    const title =
      order?.relabelingType === RelabelingTypes.PALLETS ? 'паллета' : 'короб'

    const isMarkedItem =
      order?.relabelingType === RelabelingTypes.PALLETS
        ? !!markedPallets.find((p) => p.id === barcode)
        : !!markedPlaces.find((p) => p.id === barcode)
    if (isMarkedItem) {
      notification?.show(
        'alert',
        `${title} со штрихкодом ${barcode} уже перемаркирован`,
        { soundName: 'PACKING_WRONG_ITEM' },
      )
      return
    }

    notification?.show(
      'alert',
      `${title} со штрихкодом ${barcode} не найден в задании на перемаркировку`,
      { soundName: 'PACKING_WRONG_ITEM' },
    )
  }

  const checkItemForMonoPallet = (currentItem: IOrderPackagingPlace) => {
    if (!isAllPalletsFilled && currentPallet !== null) {
      const id = currentItem?.items[0]?.id ?? ''
      if (!checkMonoPallet(id, currentPallet, relabeledPallets)) {
        notification?.show(
          'alert',
          <div className={styles.informer}>
            <h3>Ошибка сканирования</h3>
            <p>
              Упаковка монопаллетами. Нельзя добавить товар с другим артикулом к
              текущему месту и паллете
            </p>
          </div>,
          {
            icon: IconWarning,
          },
        )
        return false
      }
    }
    return true
  }

  const checkItemForPallet = () => {
    if (!relabeledPallets.length) return true

    const restPallets =
      pallets.length -
      (relabeledPallets[relabeledPallets.length - 1]?.id
        ? relabeledPallets.length + 1
        : relabeledPallets.length)
    if (restPallets > relabelingPlaces.length - 1) {
      notification?.show(
        'alert',
        <div className={styles.informer}>
          <h3>Ошибка сканирования</h3>
          <p>
            Должно быть сформировано {pallets.length} паллеты.
            Неперемаркированных мест меньше чем пустых паллет.
          </p>
        </div>,
        {
          icon: IconWarning,
        },
      )
      return false
    }

    return true
  }

  const startPlaceAdding = (
    currentItem: IOrderPackagingPlace,
    newVal: string,
  ) => {
    // проверяем вес паллеты с новым местом
    // if (
    //   currentItem.weight &&
    //   !checkWeight(currentItem.weight * 1000, 'pallet')
    // ) {
    //   return
    // }

    // если монопаллеты, проверяем чтобы в текущей паллеты были короба с одним артикулом
    if (isMonoPallet && !checkItemForMonoPallet(currentItem)) {
      return
    }

    // проверяем, чтобы неперемаркированных мест больше чем пустых паллет
    if (!isAllPalletsFilled && !checkItemForPallet()) {
      return
    }

    // если перемаркировываем паллеты, и паллеты не заполнены, то сразу перемещаем место в перемаркировано
    if (isPallets && !isAllPalletsFilled) {
      movePlaceToMarked(currentItem.id ?? '', currentItem.id ?? '')
      addPlaceInPallet(currentItem)
      return
    }

    handlePrintSticker(newVal)
    if (isBoxesRelabeling) {
      setCurrentPlace(currentItem)
    }
  }

  const startPalletAdding = (
    currentItem: IOrderPackedPallet,
    newVal: string,
  ) => {
    handlePrintSticker(newVal)
  }

  // handlers

  const handleScanBarcode = (value: TextFieldPropValue): void => {
    if (!value) return
    let newVal = rusToLatin(value)

    //если просканировали шк закрытия паллеты, вызываем закрытие паллеты
    if (newVal === CLOSE_PALLET_BARCODE) {
      if (!relabeledPallets.length) {
        notification?.show(
          'alert',
          `Чтобы закрыть паллету, необходимо упаковать хотя бы одно место`,
        )
        return
      }
      if (
        relabelingPlaces.length &&
        relabeledPallets.length &&
        relabeledPallets.length === pallets.length
      ) {
        notification?.show(
          'alert',
          `Внимание, это последняя паллета, все оставшиеся места нужно упаковать в нее`,
        )
        return
      }

      startClosePallet()
      return
    }

    const scannedIsPallet =
      order?.relabelingType === RelabelingTypes.PALLETS && isAllPalletsFilled
    const currentItem = scannedIsPallet
      ? palletsForRelabeling.find((p) => p.id === newVal)
      : relabelingPlaces.find((p) => p.id === newVal)

    if (currentItem && scannedIsPallet) {
      startPalletAdding(currentItem as IOrderPackedPallet, newVal)
      return
    }

    if (currentItem && !scannedIsPallet) {
      startPlaceAdding(currentItem as IOrderPackagingPlace, newVal)
      return
    }

    //обрабатываем ненайденный в задании шк
    processBarcodeNotFound(newVal)
  }

  const handlePrintSticker = (id: string, isRepeat: boolean = false): void => {
    if (isBoxesRelabeling) {
      printPlaceSticker(id, isRepeat)
      return
    }
    printPalletSticker(id, isRepeat)
  }

  // метод срабатывает в случае успешного присвоения нового шк места/паллеты на бэкенде, перемещает места в премаркировано и, если тип перемаркировки - паллеты, сохраняет новый id паллеты
  const handleSuccessStickerUpdate = (id: string, new_barcode: string) => {
    setCurRelabelId(null)

    // перемещаем из Без маркировки в Перемаркировано
    const move = isBoxesRelabeling ? movePlaceToMarked : movePalletToMarked
    move(id, new_barcode)
    if (!isAllPalletsFilled && currentPlace) {
      addPlaceInPallet({
        ...currentPlace,
        newBarcode: new_barcode.trim(),
      })
    }

    // если перемаркировка паллет, то сохраняем в паллете новый шк
    if (!isAllPalletsFilled && isPallets && currentPalletData) {
      savePalletWithNewId(new_barcode, currentPalletData)
      setCurrentPalletData(null)
    }
  }

  // метод для получения доп документов, если их нет, то ставим флаг isAllDocsPrinted = true и завершаем перемаркировку
  const handleOpenAddFiles = async () => {
    if (!order?.id) return
    await getAddFiles.fetch({ orderId: order.id }).then((res) => {
      if (res.files.length) {
        setAddFiles(res.files)
        setShowAdditionalDocs(true)
        return
      }
      setIsAllDocsPrinted(true)
    })
  }

  // метод для выставления статус упакован и перемаркирован на бэкенде; в случае успеха, выходим из заказа
  const handleCompletePack = async (): Promise<void> => {
    if (!order) return
    await completePack
      .fetch({ order_id: order.id })
      .then((result: ApiReqResult) => {
        if (result.success) {
          notification?.show(
            'success',
            'Заказ успешно перемаркирован. Упаковка завершена.',
          )
          setShowStickerPrint(true)
          setIsSuccessRelabel(true)
        }
      })
  }

  const handleCloseShowScanSticker = () => {
    if (isPallets && !(relabelingPlaces.length - 1) && !isAllPalletsFilled) {
      handleCloseAndFocusBarcode(setShowScanSticker)
      return
    }

    if (!isPallets && !(relabelingPlaces.length - 1) && !isAllPalletsFilled) {
      setShowScanSticker(false)
      return
    }

    handleCloseAndFocusBarcode(setShowScanSticker)
  }

  const printPalletsLists = async (
    isNeedPrintAddDocs: boolean = true,
  ): Promise<void> => {
    setCurrentOperation('palletList')

    // для каждой паллеты вызываем метод получения паллетного листа
    // если первый паллетный лист, то вызываем модалку с информацией о печати паллетных листов
    // если последний, то проверяем на наличии доп документов и вызываем модалку для их печати
    notification?.show('warning', `Печать упаковочных листов`)
    let hasSticker = false
    for (const [index, pallet] of palletsForRelabeling.entries()) {
      if (!pallet.id) return

      const isFirst = index === 0
      const isLast = index === palletsForRelabeling.length - 1

      try {
        await printPalletList(pallet.id, pallet.pallet_num, isFirst)
        hasSticker = true
      } catch (e) {
        if (isLast && !hasSticker) {
          notification?.show(
            'alert',
            `Не напечатан ни одни упаковочный лист. Обратитесь к администратору`,
          )
        }
      } finally {
        if (isLast && hasSticker && isNeedPrintAddDocs) {
          handleOpenAddFiles()
        }
      }
    }
  }

  // custom hooks

  const { checkWeight, changeWeight, clearWeight } = useManageMaxWeight()

  // методы получения стикеров
  const {
    loadingPrint,
    printPalletSticker,
    printPlaceSticker,
    printSticker,
    printWBBarcode,
    printPalletList,
    printWBPackingList,
  }: useManagePrintStickerRes = useManagePrintSticker({
    setCurOperation: setCurrentOperation,
    setCurRelabelId,
    setShowScanSticker,
  })

  // методы для модификации паллет
  const {
    startClosePallet,
    closePallet,
    savePalletWithNewId,
    addPlaceInPallet,
    closePackedPallet,
  }: useManagePalletsRes = useManagePallets({
    setCurOperation: setCurrentOperation,
    printSticker,
    handleOpenAddFiles,
    setCurrentPalletData,
    clearWeight: () => clearWeight('pallet'),
  })

  // методы для перемещения паллет/мест из не перемаркировано в перемаркировано
  const {
    markedPallets,
    markedPlaces,
    movePalletToMarked,
    movePlaceToMarked,
    markedCount,
  }: useMoveRelabelingItemRes = useMoveRelabelingItem({
    originalCount,
    handleOpenAddFiles,
    startClosePallet,
    printPalletsLists,
    changeWeight: (weight) => changeWeight(weight, 'pallet'),
    printWBBarcode,
    printWBPackingList,
  })

  // выставляет статус заказу в зависимости от типа перемаркировки
  const { orderStatus } = useSetOrderStatus({ isSuccessRelabel })

  // useEffect hooks
  useEffect(() => {
    // если всё перемаркировано и все доп документы, паллетные листы распечатаны, завершить перемаркировку
    if (
      isAllDocsPrinted &&
      originalCount &&
      originalCount === markedCount &&
      (order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS &&
      isAllPalletsFilled
        ? isPalletListsPrinted
        : true)
    ) {
      handleCompletePack()
    }
  }, [isAllDocsPrinted, markedCount, isPalletListsPrinted])

  // если документы есть и они распечатаны, проставить флаг isAllDocsPrinted = true
  useEffect(() => {
    if (
      addFiles.length &&
      addFiles.length === addFiles.filter((f) => f.printed).length
    ) {
      setIsAllDocsPrinted(true)
    }
  }, [addFiles])

  const relabelIsDone = !!(
    isAllDocsPrinted &&
    originalCount === markedCount &&
    (order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS &&
    isAllPalletsFilled
      ? isPalletListsPrinted
      : true)
  )

  useEffect(() => {
    if (order && order.operationType !== OperationTypes.RELABELING) {
      notification?.show('alert', `Заказ не в статусе 'Перемаркировка'`)
      history.push('/')
    }
  }, [order])

  if (!order) return null

  return (
    <>
      <div className={styles.main}>
        <RelabelingSideBar
          relabelIsDone={relabelIsDone}
          originalCount={originalCount}
          markedCount={markedCount}
          orderStatus={orderStatus}
          shippingFormViewed={shippingFormViewed}
          handleOpenAddFiles={handleOpenAddFiles}
          handleCompletePack={handleCompletePack}
          handleScanBarcode={handleScanBarcode}
          handleGetWbBarcode={printWBBarcode}
          inputDisabled={loadingPrint}
          isSuccessRelabel={isSuccessRelabel}
          printWBPackingList={printWBPackingList}
        />
        <div className={styles.bodyWrap}>
          <div className={styles.body}>
            {order?.relabelingType && (
              <MainBlock
                type={order.relabelingType}
                markedPlaces={markedPlaces}
                markedPallets={markedPallets}
                handlePrint={(id: string) => handlePrintSticker(id, true)}
                packagingOptions={packagingOptions ?? []}
                isAllPalletsFilled={isAllPalletsFilled}
                pallets={relabeledPallets}
                showPacked={showPacked}
                setShowPacked={() => setShowPacked(true)}
                currentPallet={currentPallet}
                setPallets={(pallets) => setRelabeledPallets(pallets)}
              />
            )}
          </div>
        </div>
      </div>

      {showStickerPrint && !showStickerError ? (
        <StickerPrintModal
          isOpen={true}
          title="Заказ успешно перемаркирован"
          onSuccess={handleSavePlace}
          onClose={() => handleCloseAndFocusBarcode(setShowStickerPrint)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          btnTitle="Закрыть"
        />
      ) : null}

      {showStickerError ? (
        <StickerErrorModal
          isOpen={true}
          sticker={sticker}
          onClose={handleCloseStickerError}
          isFinal={!items?.length}
          showStickerPrint={() => setShowStickerPrint(true)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {showPacked ? (
        <PalletPackedModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowPacked)}
          handlePrint={(id: string) => handlePrintSticker(id, true)}
          pallets={relabeledPallets}
          setPallets={(pallets) => setRelabeledPallets(pallets)}
          fetchClosePackedPallet={closePackedPallet}
        />
      ) : null}

      {showShippingForm ? (
        <ShippingFormModal
          isOpen={showShippingForm}
          onClose={() => handleCloseAndFocusBarcode(setShowShippingForm)}
          shippingFormData={order?.shipping_form || []}
          blurRef={barcodeInput}
          order={order}
        />
      ) : null}

      {showScanSticker && !showStickerPrintInfo && curRelabelId ? (
        <ScanStickerModal
          isOpen={true}
          onClose={handleCloseShowScanSticker}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          handleRepeatPrintSticker={() =>
            handlePrintSticker(curRelabelId, true)
          }
          loadingPrint={loadingPrint}
          curRelabelId={curRelabelId}
          onSuccessCallback={handleSuccessStickerUpdate}
          newBarcodesArr={newBarcodesArr}
          setNewBarcodesArr={setNewBarcodesArr}
          disableUniquenessCheck={disableUniquenessCheck}
          setDisableUniquenessCheck={setDisableUniquenessCheck}
        />
      ) : null}

      {showStickerPrintInfo ? (
        <ItemStickerPrintModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowStickerPrintInfo)}
          title={getTitleByOperation(currentOperation)}
          subtitle={getSubtitleByOperation(currentOperation)}
          backTo="перемаркировке"
        />
      ) : null}

      {showAdditionalDocs && !showStickerPrintInfo && !showStickerError ? (
        <AdditionalDocsModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowAdditionalDocs)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          isAllRelabel={originalCount === markedCount}
        />
      ) : null}

      {showClosePallet && currentPallet !== null && !showStickerError ? (
        <ClosePallet
          isOpen={true}
          currentPalletData={relabeledPallets[currentPallet]}
          onSubmit={closePallet}
          onClose={() => setShowClosePallet(false)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {closingErrorData?.show ? (
        <WaitingClosingModal
          orderId={order?.id ?? ''}
          currentOperation="palletClosing"
          isOpen={true}
          onClose={() => {
            setClosingErrorData(null)
          }}
          errorData={closingErrorData?.errors}
          onRetry={() =>
            closePackedPallet(
              closingErrorData?.data as IPalletErrorData['data'],
              {
                processErr: true,
                isNeedPrint: true,
                isNeedSave: true,
                isNeedScan: order?.relabelingType === RelabelingTypes.PALLETS,
              },
            )
          }
        />
      ) : null}
    </>
  )
}

export default B2BRelabelingOrder
