import React, { useState } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import cx from 'classnames'
import days from 'dayjs'

import styles from './order-scan-form-page.module.scss'

import { Badge, BadgePropStatus } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'
import { IconSearch } from '@consta/uikit/IconSearch'
import { IconClose } from '@consta/uikit/IconClose'
import { IconForward } from '@consta/uikit/IconForward'
import { Informer } from '@consta/uikit/Informer'
import { IconWarning } from '@consta/uikit/IconWarning'

import { InputWrapper, ScanWrapper, Select } from 'src/components'

import { useGetPackedOrders } from 'src/hooks'

import { useAppContext } from 'src/context'

import { rusToLatin } from 'src/helpers'

import { PATHS, MONTHS, SCAN_OPTIONS } from 'src/config'

import { IOrdersLastItem, ISelectOption } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

const OrderScanFormPage = () => {
  const [orderBarcode, setOrderBarcode] = useState<TextFieldPropValue>('')
  const [password, setPassword] = useState<TextFieldPropValue>('')
  const [unfinishedPackaging, setUnfinishedPackaging] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [type, setType] = useState<ISelectOption | null>({
    label: 'Упаковка заказа',
    value: 'simple',
  })

  const [packedOrders, setPackedOrders] = useState<IOrdersLastItem[] | null>(
    null,
  )

  const history = useHistory()

  const { setGlobalLoading, performWithAdvance, advanceMode } = useAppContext()

  const getType = (type: ISelectOption | null) => {
    if (type?.value === 'batch') {
      return 'batch'
    }

    if (type?.value === 'resume') {
      return 'resume'
    }

    if (type?.value === 'relabel') {
      return 'relabel'
    }

    if (type?.value === 'docs_print') {
      return 'docs'
    }
  }

  const openPage = (val: string) => {
    history.push({
      pathname: generatePath(PATHS.ORDER_FORM, {
        orderId: val,
      }),
      state: {
        type: getType(type),
      },
    })
    setGlobalLoading(true)
  }

  const handleSubmit = (value: TextFieldPropValue): boolean | void => {
    if (!value) return false
    let newVal = rusToLatin(value)
    setOrderBarcode(newVal)

    if (type?.value === 'docs_print') {
      handleOpenWithAdvance(newVal)
      return
    }
    openPage(newVal)
  }

  const handleOpenWithAdvance = (val: string) => {
    if (advanceMode) {
      openPage(val)
    } else {
      performWithAdvance(() => () => {
        openPage(val)
      })
    }
  }

  useGetPackedOrders(setPackedOrders)

  const handleHandSearch = (): void => {}

  const handleClose = (): void => {}

  const handleContinue = (): void => {}

  const handleOpenPackingDocOrder = (id: string) => {
    if (advanceMode) {
      history.push({
        pathname: generatePath(PATHS.ORDER_FORM, {
          orderId: id.trim(),
        }),
        state: {
          type: getType(type),
        },
      })
    } else {
      performWithAdvance(() => () => {
        history.push({
          pathname: generatePath(PATHS.ORDER_FORM, {
            orderId: id.trim(),
          }),
          state: {
            type: getType(type),
          },
        })
      })
    }
  }

  const openPackedOrder = (id: string) => {
    if (!unfinishedPackaging) {
      history.push(
        generatePath(PATHS.ORDER_CLOSED, {
          orderId: id.trim(),
        }),
      )
    }
  }

  const continuePacking = (id: string) => {
    if (advanceMode) {
      history.push({
        pathname: generatePath(PATHS.RESUME_ORDER_FORM, {
          orderId: id.trim(),
        }),
        state: {
          type: 'resume',
        },
      })
    } else {
      performWithAdvance(() => () => {
        history.push({
          pathname: generatePath(PATHS.RESUME_ORDER_FORM, {
            orderId: id.trim(),
          }),
          state: {
            type: 'resume',
          },
        })
      })
    }
  }

  const OrderItem = ({ order }: { order: IOrdersLastItem }) => {
    const getStatus = (
      status: string,
    ): {
      label: string
      status: BadgePropStatus
    } => {
      if (status === 'awaiting_packing') {
        return {
          label: 'ОЖИДАЕТ УПАКОВКИ',
          status: 'system',
        }
      }
      if (status === 'packed') {
        return {
          label: 'УПАКОВАН',
          status: 'success',
        }
      }
      if (status === 'on_packing') {
        return {
          label: 'ИДЕТ УПАКОВКА',
          status: 'warning',
        }
      }
      if (status === 'packingdoc') {
        return {
          label: 'Упакован, загрузка док-тов',
          status: 'normal',
        }
      }
      return {
        label: 'ошибка',
        status: 'error',
      }
    }

    const handleOpen = () => {
      if (order.packing_state === 'packed') {
        return openPackedOrder(order.id)
      }
      if (order.packing_state === 'packingdoc') {
        return handleOpenPackingDocOrder(order.id)
      }
    }

    return (
      <div
        className={cx(
          styles.orderItem,
          order.packing_state === 'on_packing' && styles.orderPacking,
        )}
        onClick={handleOpen}
      >
        <div className={styles.left}>
          <div className={styles.date}>
            {order.packed_at ? (
              <>
                <span>
                  {days(order.packed_at).date()}{' '}
                  {MONTHS[days(order.packed_at).month()]}
                </span>
                <span>{days(order.packed_at).format('HH:mm:ss')}</span>
              </>
            ) : null}
          </div>
          <div className={styles.id}>{order.id}</div>
        </div>
        <div className={styles.right}>
          {order.packing_state === 'on_packing' ? (
            <Button
              className={styles.stickerBtn}
              view="secondary"
              size="s"
              label="Продолжить упаковку"
              onClick={() => continuePacking(order.id)}
            />
          ) : null}
          <Badge
            status={getStatus(order.packing_state).status}
            label={getStatus(order.packing_state).label}
          />
        </div>
      </div>
    )
  }

  const sortPackedOrders = (a: IOrdersLastItem, b: IOrdersLastItem) => {
    return days(a.packed_at).isBefore(b.packed_at) ? 1 : -1
  }

  return (
    <>
      <ScanWrapper title="Упаковка товара" subtitle="Форма сканирования заказа">
        {!unfinishedPackaging ? (
          <div className={styles.employeeMain}>
            <div className={styles.scan}>
              <InputWrapper
                withDelay
                label={'Осканируйте штрихкод заказа'}
                id={'orderBarcode'}
                placeholder={'Введите штрихкод'}
                handleChange={handleSubmit}
                autoFocus
                value={orderBarcode}
                error={error}
                size="l"
                className={styles.employeeMainInput}
                form="defaultBrick"
              />
              <Select
                options={SCAN_OPTIONS}
                value={type}
                onChange={({ value }) => setType(value)}
                size="l"
                form="clearDefault"
                className={styles.select}
              />
            </div>
            <Button
              view="secondary"
              label="Искать вручную"
              iconLeft={IconSearch}
              onClick={() => handleHandSearch()}
              size="l"
              className={styles.btn}
            />
          </div>
        ) : (
          <div className={styles.employeeMain}>
            <InputWrapper
              value={password}
              label={'Осканируйте специальный штрихкод или введите пароль'}
              placeholder={'Введите пароль'}
              id={'password'}
              handleChange={setPassword}
              error={error}
              size="l"
              className={styles.employeeMainInput}
            />
          </div>
        )}
        {packedOrders?.length ? (
          <>
            <h4 className={styles.ghostTitle}>Упакованные заказы</h4>
            <div className={styles.ordersItems}>
              {packedOrders
                .slice()
                .sort(sortPackedOrders)
                .map((o) => (
                  <OrderItem key={o.id} order={o} />
                ))}
            </div>
          </>
        ) : null}

        {unfinishedPackaging ? (
          <>
            <div className={styles.flexend}>
              <Button
                view="secondary"
                label="Отменить"
                iconLeft={IconClose}
                onClick={() => handleClose()}
                disabled={!password}
                size="l"
              />
              <div className={styles.lastBtn}>
                <Button
                  label="Продолжить упаковку"
                  iconRight={IconForward}
                  onClick={() => handleContinue()}
                  disabled={!password}
                  size="l"
                />
              </div>
            </div>
          </>
        ) : null}

        {unfinishedPackaging && (
          <div className={styles.alert}>
            <Informer
              status="alert"
              view="filled"
              title="У вас есть не законченная упаковка заказа!"
              label="Позовите старшего смены чтобы продолжить работу"
              icon={IconWarning}
            />
          </div>
        )}
      </ScanWrapper>
    </>
  )
}

export default OrderScanFormPage
