import { useMutation } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IOrderPackagingPlace } from 'src/interfaces'

const useGetAcceptanceOptions = () => {
  return useMutation(async (data: { type: string; value: string }) => {
    const { type, value } = data

    let path = `/receiving/${type}?query=${value}`

    try {
      //const result = await api.get(path)
    } catch (e) {
      console.log(e)
    }

    // if (!result?.data?.success) {
    //   throw result
    // }
    // return result.data
    return {
      success: true,
      type: type,
      options: [
        {
          value: 'supplier_1',
          label: 'Поставщик 1',
        },
        {
          value: 'supplier_2',
          label: 'Поставщик 2',
        },
      ],
    }
  })
}

export default useGetAcceptanceOptions
