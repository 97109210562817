import { useEffect, useMemo, useState } from 'react'

import { usePackingOrderContext, usePackingPrintContext } from '../../context'
import { useAppContext } from 'src/context'

import { useNotifications } from 'src/notification'

import { useUpdatePackingPallet, useUpdatePackingPlace } from 'src/hooks'
import useGetPalletCount from './useGetPalletCount'

import { parseError, getItemsQuantity, getNewPallet } from 'src/helpers'

import {
  IOrderPackagingPlace,
  IOrderPackagingPlaceItem,
  IError,
  IErrorData,
  IUpdatePackingPlaceResult,
  IOrderPallet,
  IOrderReqPallet,
  packType,
  useManagePlacesAndPalletsRes,
  IUpdatePackingPalletResult,
} from 'src/interfaces'

interface IUseManagePlacesAndPalletsProps {
  orderId: string
  onSuccessLastPallet: () => void
  clearWeight: (type: 'pallet' | 'place') => void
}

const useManagePlacesAndPallets = ({
  orderId,
  onSuccessLastPallet,
  clearWeight,
}: IUseManagePlacesAndPalletsProps) => {
  const notification = useNotifications()

  const {
    order,
    items,
    places,
    currentPlace,
    setCurrentPlace,
    handleSavePlace,
    pallets,
    setPallets,
    currentPallet,
    setCurrentPallet,
    setPlaces,
    setCurrentOperation,
    setCurrentClosePlaceModal,
    curPackingType,
    setCurPackingType,
    closingErrorData,
    setClosingErrorData,
    setErrorWeight,
    setShowClosePlace,
    setShowClosePallet,
    setCurPackageId,
    palletCount,
    setAlert,
  } = usePackingOrderContext()

  const { printSticker, setLastStickerWithoutError } = usePackingPrintContext()

  const { setGlobalLoading } = useAppContext()

  const [orderStatus, setOrderStatus] = useState<string>('Паллетирование')

  const packingPalletMutation = useUpdatePackingPallet()
  const packingPlaceMutation = useUpdatePackingPlace({
    orderId,
  })

  const originalItemsCount = useMemo(
    () => getItemsQuantity(order?.items),
    [order?.items],
  )
  const placesCount =
    curPackingType === packType.packInPallets
      ? pallets.reduce((acc, pallet) => pallet.places.length + acc, 0)
      : places.length
  const isPackingInPallets = curPackingType === packType.packInPallets
  const isPackingInPlaces = curPackingType === packType.packInPlaces
  const isFbo = false
  const needPallets = (order?.maxBoxesWithoutPallet ?? 0) < places.length

  const openPallet = () => {
    const newPallet = getNewPallet(places, pallets.length + 1)
    const newPallets = [newPallet]
    setPallets(newPallets)
    setCurrentPallet(0)

    setCurPackingType(packType.packInPallets)
    setOrderStatus('Паллетирование')
  }

  const handleClosePlace = async (
    newData: IOrderPackagingPlace,
  ): Promise<void> => {
    if (currentPlace === null) return

    const isFinal = !items?.length
    const newPlaceData = { ...newData, is_final: isFinal }
    handleChangePlaceInPallet(newPlaceData)
    setCurrentOperation('placeClosing')
    return await closePlace(newPlaceData, isFinal)
  }

  const handleChangePallet = (pallet: IOrderPallet) => {
    if (currentPallet === null) return false

    const newPallets = pallets.slice()
    newPallets[currentPallet] = pallet
    setPallets(newPallets)
  }

  const handleChangePlaceInPallet = (newData: IOrderPackagingPlace): void => {
    if (currentPlace === null || currentPallet === null) return

    const newPallets = pallets.slice()
    const curPallet = newPallets[currentPallet]
    const newPlaces = curPallet.places.slice()

    newPlaces[currentPlace] = newData
    curPallet.places = newPlaces

    setPallets(newPallets)
  }

  const startClosePallet = () => {
    if (!places.length && isPackingInPlaces) {
      notification?.show(
        'alert',
        `Чтобы сформировать паллету, необходимо закрыть хотя бы одно место`,
      )
      return
    }
    if (!pallets.length && isPackingInPallets) {
      notification?.show(
        'alert',
        `Чтобы закрыть паллету, необходимо закрыть хотя бы одно место`,
      )
      return
    }
    if (currentPlace !== null) {
      notification?.show(
        'alert',
        `Чтобы закрыть паллету, необходимо закрыть текущее место`,
      )
      return
    }
    if (isPackingInPlaces) {
      openPallet()
    }
    setCurrentOperation('palletClosing')
    setShowClosePallet(true)
  }

  const updatePlaceInPallet = (
    newPlace: IOrderPackagingPlace,
    currentPallet: number,
    pallets: IOrderPallet[],
  ): void => {
    const newPallets = pallets.slice()
    const curPallet = newPallets[currentPallet]
    const newPlaces = curPallet.places.slice()

    newPlaces.push(newPlace)
    curPallet.places = newPlaces

    setPallets(newPallets)
    setCurrentPallet(currentPallet)
    setCurrentPlace(newPlaces.length - 1)
  }

  const updatePlaceInPlaces = (newPlace: IOrderPackagingPlace): void => {
    setCurrentPlace(places.length)
    setPlaces([...places, newPlace])
  }

  const updatePalletPlaceItems = (
    newItems: IOrderPackagingPlaceItem[],
  ): void => {
    if (currentPlace === null || currentPallet === null) return

    const newPallets = pallets.slice()
    const curPallet = newPallets[currentPallet]
    const newPlaces = curPallet.places.slice()

    newItems.forEach((newItem) => {
      newPlaces[currentPlace].items.unshift(newItem)
    })

    curPallet.places = newPlaces

    setPallets(newPallets)
  }

  const updatePlaces = (newItems: IOrderPackagingPlaceItem[]): void => {
    if (currentPlace === null) return

    const newPlaces = places.slice()
    newItems.forEach((newItem) => {
      newPlaces[currentPlace].items.unshift(newItem)
    })
    setPlaces(newPlaces)
  }

  const getPalletReqData = (pallet: IOrderPallet): IOrderReqPallet => {
    const formattedPlaces = pallet.places.map((place) => ({
      placeId: place.id,
      orderId,
    }))

    return {
      ...pallet,
      places: formattedPlaces,
    }
  }

  const processErrorsOnClose = (
    err: IErrorData,
    data: any,
    type: 'place' | 'pallet',
  ): void | never => {
    if (err?.data?.errors && type === 'place') {
      const weightErr = err.data.errors.find(
        (e: IError) => e.code === 'INCORRECT_WEIGHT',
      )
      if (weightErr) {
        notification?.show('alert', weightErr.message)
        setErrorWeight(weightErr.message)
        setCurrentClosePlaceModal('weightingPlace')
        setCurPackageId(data.packaging_id)
        setShowClosePlace(true)
        return
      }
    }
    setClosingErrorData({
      show: true,
      data: data,
      errors: err.data ?? err,
    })
    parseError(err)
    throw err
  }

  const closePlace = async (
    data: IOrderPackagingPlace,
    isFinal: boolean,
  ): Promise<void> => {
    const reqPlaceData = {
      ...data,
      items: data.items.map(
        ({
          article,
          dateOfManufacture,
          weight,
          manualRequireWeight,
          title,
          ...rest
        }) => ({
          ...rest,
        }),
      ),
    }

    console.log('reqPlaceData')
    console.log(reqPlaceData)

    await packingPlaceMutation
      .mutateAsync({ place: reqPlaceData })
      .then(async (result: IUpdatePackingPlaceResult) => {
        const {
          place_id,
          sticker: { content, printer: printerName },
        } = result

        setClosingErrorData(null)
        setCurPackageId(null)
        clearWeight('place')

        printSticker(printerName, content, {
          copies: 1,
          needShowPrintStickerInfo: !isFinal,
          needFocusAfterPrint: !isFinal,
        })
          .catch((e) => {
            if (isFinal && isPackingInPlaces) {
              setLastStickerWithoutError(false)
            }
          })
          .finally(() => {
            if (isFinal && isPackingInPlaces && needPallets) {
              setShowPalletCount(true)
              return
            }

            if (isFinal && isPackingInPlaces && !needPallets) {
              handleUpdatePalletCount('0')
              return
            }
          })

        if (isPackingInPallets) {
          handleSavePlaceInPallet(place_id, data)
          return
        }

        handleSavePlace(place_id, data)
      })
      .catch((err: any) => {
        processErrorsOnClose(err, data, 'place')
      })
      .finally(() => setGlobalLoading(false))
  }

  const handleSavePlaceInPallet = (
    placeId: string,
    data: IOrderPackagingPlace,
  ): void => {
    if (currentPlace === null || currentPallet === null) return

    const curPlace = pallets[currentPallet].places[currentPlace]

    handleChangePlaceInPallet({
      ...curPlace,
      id: placeId,
      weight: data.weight,
      packaging_id: data.packaging_id,
    })

    setCurrentPlace(null)

    notification?.show('success', `Место ${placesCount} успешно создано`, {
      soundName: 'PACKING_PLACE_POSTED',
    })
  }

  const handleSavePallet = (palletId: string, data: IOrderPallet): void => {
    if (currentPallet === null) return
    handleChangePallet({
      ...pallets[currentPallet],
      id: palletId,
      weight: data.weight,
      dimensions: data.dimensions,
    })
    setCurrentPallet(null)

    notification?.show(
      'success',
      `Паллета ${pallets.length} успешно сформирована`,
      {
        soundName: 'PACKING_PLACE_POSTED',
      },
    )
  }

  const handleClosePallet = async (pallet: IOrderPallet): Promise<void> => {
    handleChangePallet(pallet)

    setClosingErrorData(null)

    const palletData = getPalletReqData(pallet)
    await fetchUpdatePackingPallet(palletData, false)
      .then((pallet_id) => handleSavePallet(pallet_id, pallet))
      .catch((err) => processErrorsOnClose(err, palletData, 'pallet'))
  }

  const closeEmptyPallets = async (
    startPalletNum: number,
    endPalletNum: number,
  ): Promise<void> => {
    let status = true
    for (const value of Array.from(
      { length: endPalletNum },
      (v, i) => i + 1,
    ).slice(startPalletNum - 1)) {
      if (!status) {
        break
      }
      const emptyPallet: IOrderReqPallet = {
        pallet_num: value,
        places: [{ orderId: order?.id ?? '' }],
      }
      try {
        await fetchUpdatePackingPallet(emptyPallet, false)
        if (value === endPalletNum) {
          onSuccessLastPallet()
        }
      } catch (err) {
        console.log(err)
        status = false
        processErrorsOnClose(err, emptyPallet, 'pallet')
      }
    }
  }

  const { handleUpdatePalletCount, showPalletCount, setShowPalletCount } =
    useGetPalletCount({
      orderId,
      onSuccessUpdateWithoutCount: onSuccessLastPallet,
      onSuccessUpdatePalletCount: closeEmptyPallets,
    })

  const fetchUpdatePackingPallet = async (
    palletData: IOrderReqPallet,
    fetchFromError: boolean,
  ): Promise<string> => {
    setCurrentOperation('palletClosing')

    setClosingErrorData(null)

    return await packingPalletMutation
      .mutateAsync({ pallet: palletData })
      .then(async (result: IUpdatePackingPalletResult) => {
        const {
          pallet_id,
          sticker: { content, printer: printerName },
        } = result

        const isEmptyPallet = !palletData.places[0].placeId
        if (isEmptyPallet) {
          if (fetchFromError && palletCount) {
            try {
              await closeEmptyPallets(palletData.pallet_num + 1, +palletCount)
            } catch (e) {
              console.error(e)
            }

            if (palletCount === palletData.pallet_num) {
              onSuccessLastPallet()
            }
          }
          return Promise.resolve(pallet_id)
        }

        const isFinal = !items?.length

        printSticker(printerName, content, {
          copies: 1,
          needShowPrintStickerInfo: !isFinal,
          needFocusAfterPrint: !isFinal,
        }).finally(() => {
          if (isFinal) {
            handleUpdatePalletCount(`${pallets.length}`)
          }
        })

        return Promise.resolve(pallet_id)
      })
      .catch((err: any) => {
        throw err
      })
      .finally(() => setGlobalLoading(false))
  }

  const handleFetchClosePallet = async (emptyPallet: IOrderReqPallet) => {
    await fetchUpdatePackingPallet(emptyPallet, !!closingErrorData).catch(
      (err) => processErrorsOnClose(err, emptyPallet, 'pallet'),
    )
  }

  useEffect(() => {
    if (isFbo) {
      setCurPackingType(packType.packInPallets)
      return
    }
    setOrderStatus('Упаковка коробами')
  }, [order])

  useEffect(() => {
    // если мест больше чем order.maxBoxesWithoutPallet, то выводим сообщение, о том что нужно сформировать паллету
    if (order?.maxBoxesWithoutPallet && pallets.length) {
      const lastPallet = pallets[0]
      const needCreatePallet =
        !lastPallet.id &&
        lastPallet.places.length > order?.maxBoxesWithoutPallet
      if (needCreatePallet) {
        setAlert(
          'Внимание, необходимо сформировать паллету для данного типа отгрузки',
        )
      }
    }
  }, [pallets, order?.maxBoxesWithoutPallet])

  useEffect(() => {
    const itemsQuantity = items ? getItemsQuantity(items) : null
    const isAllPacked =
      itemsQuantity !== null &&
      originalItemsCount &&
      originalItemsCount === originalItemsCount - itemsQuantity

    if (!isAllPacked) return

    setShowClosePlace(true)
    if (isPackingInPallets) {
      setShowClosePallet(true)
    }
  }, [originalItemsCount, items])

  return {
    orderStatus,
    startClosePallet,
    handleFetchClosePallet,
    updatePlaceInPallet,
    updatePlaceInPlaces,
    updatePalletPlaceItems,
    updatePlaces,
    handleClosePlace,
    handleClosePallet,
    showPalletCount,
    setShowPalletCount,
    handleUpdatePalletCount,
  } as useManagePlacesAndPalletsRes
}

export default useManagePlacesAndPallets
