import axios, { AxiosResponse } from 'axios'
import { appLocalStorage } from 'src/libs'

import { apiResErrorLog, apiErrorLog } from './logger'

import { API_BASE_URL } from 'src/config'

declare module 'axios' {
  export interface AxiosRequestConfig {
    source?: string
    errorCallback?: (error: string) => string
  }
}

interface ResError {
  code: number
  message: string
  errorsticker?: {
    content: string
    printer: string
  }
}

const token = appLocalStorage.token

const api = axios.create({
  baseURL: `https://${API_BASE_URL}`,
  headers: {
    Authorization: token && `Bearer ${token}`,
  },
})

api.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res.data.success === false && res.data.errors) {
      apiResErrorLog(res)
      res.data.errors.forEach((e: ResError) => {
        if (e?.errorsticker?.content) {
          const { content, printer } = e?.errorsticker
          window.printSticker(content, { qzOptions: { printer } })
        }

        window.showNotification(
          'alert',
          res.config.errorCallback
            ? res.config.errorCallback(e.message)
            : e.message,
        )
      })
    }
    if (!res.data.success && !res.data.errors) {
      apiResErrorLog(res)
      res.data
        ? window.showNotification('alert', res.data)
        : window.showNotification(
            'alert',
            'Непредвиденная ошибка работы с сервером. Обратитесь к администратору.',
          )
    }
    return res
  },
  async (error) => {
    if (error.response?.status === 401) {
      window.showNotification('alert', '401 Unauthorized')
      if (
        error.response.config.url !== `v1/auth` &&
        error.response.config.url !== `v1/auth/refresh`
      ) {
        console.error(error)
        appLocalStorage.clear()
        window.location.reload()
      }
    } else {
      apiErrorLog(error)
      window.showNotification(
        'alert',
        'Непредвиденная ошибка работы с сервером. Обратитесь к администратору.',
      )
      throw error
    }
  },
)

export default api
