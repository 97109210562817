import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { appLocalStorage } from 'src/libs'
import { privateRoutes, publicRoutes, Routes } from './'

import { TemplateWrapper } from 'src/templates'

import { PRIVATE_PATHS } from 'src/config'

const renderRoutes = (routes: Routes) => {
  const defaultPaths: { [key: string]: string } = {
    acceptance: PRIVATE_PATHS.ACCEPTANCE_SCAN,
    packaging: PRIVATE_PATHS.ORDER_SCAN,
    RegistryReconciliation: PRIVATE_PATHS.REGISTRY_SCAN,
  }

  const defaultPath =
    defaultPaths[appLocalStorage.currentOperation] ??
    routes.find((route) => route.default)?.path

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      {routes.map((route) => (
        <Route exact key={route.path} path={route.path}>
          <TemplateWrapper template={route.template}>
            <Suspense fallback={null}>
              <route.component />
            </Suspense>
          </TemplateWrapper>
        </Route>
      ))}
      {defaultPath && <Redirect to={defaultPath} />}
    </Switch>
  )
}

export const renderPrivateRoutes = () => renderRoutes(privateRoutes)

export const renderPublicRoutes = () => renderRoutes(publicRoutes)
