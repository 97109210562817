import React, { useEffect, useMemo, useState } from 'react'

import { useUpdatePackingPallet } from 'src/hooks'
import { useNotifications } from 'src/notification'

import { usePackingOrderContext } from '../../context'
import { useAppContext } from 'src/context'

import {
  getRelabelPalletReqData,
  checkAllPalletsFilled,
  parseError,
} from 'src/helpers'

import {
  IOrderPallet,
  IOrderPalletMarked,
  IOrderReqPallet,
  IUpdatePackingPalletResult,
  RelabelingTypes,
  useManagePalletsRes,
  IErrorData,
  IOrderPackagingPlace,
  IOrderPackagingMarkedPlace,
} from 'src/interfaces'

interface IUseManagePalletsProps {
  setCurOperation: (val: string) => void
  printSticker: (
    id: string,
    sticker: {
      content: string
      printer: string
    },
    options: {
      message: string
      isNeedScan?: boolean
      isNeedShowPrintInfo?: boolean
      isNeedFocus?: boolean
    },
  ) => Promise<void>
  handleOpenAddFiles: () => void
  setCurrentPalletData: (palletData: IOrderPallet | null) => void
  clearWeight: () => void
}

const useManagePallets = ({
  setCurOperation,
  printSticker,
  handleOpenAddFiles,
  setCurrentPalletData,
  clearWeight,
}: IUseManagePalletsProps) => {
  const [palletIds, setPalletIds] = useState<string[]>([])
  const [showPalletWarning, setShowPalletWarning] = useState<boolean>(true)

  const { setGlobalLoading } = useAppContext()
  const {
    order,
    relabelingPlaces,
    currentPallet,
    setCurrentPallet,
    pallets,
    setShowClosePallet,
    relabeledPallets,
    setRelabeledPallets,
    setClosingErrorData,
  } = usePackingOrderContext()

  const notification = useNotifications()
  const packingPalletMutation = useUpdatePackingPallet()

  const isBoxesRelabeling =
    order?.relabelingType === RelabelingTypes.BOXES ||
    order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS
  const isPallets = order?.relabelingType === RelabelingTypes.PALLETS

  const isAllPalletsFilled = useMemo(
    () => checkAllPalletsFilled(pallets),
    [pallets],
  )

  useEffect(() => {
    console.log(palletIds)
  }, [palletIds])

  useEffect(() => {
    const palletIdsArr = pallets.reduce(
      (acc: string[], pallet: IOrderPallet) => {
        if (!isPallets) {
          return !pallet.places.length ? [...acc, pallet.id ?? ''] : acc
        }
        return !pallet.places.length ||
          (pallet.places.length && !pallet.temp_id)
          ? [...acc, pallet.id ?? '']
          : acc
      },
      [],
    )
    setPalletIds(palletIdsArr)
  }, [pallets])

  const startClosePallet = () => {
    if (!relabeledPallets.length && (order?.places?.length ?? 0) > 1) {
      notification?.show(
        'alert',
        `Чтобы закрыть паллету, необходимо упаковать хотя бы одно место`,
      )
      return
    }
    setCurOperation('palletClosing')
    setShowClosePallet(true)
  }

  const closePallet = async (pallet: IOrderPallet): Promise<void> => {
    handleChangePallet(pallet)

    setClosingErrorData(null)

    const newIds = palletIds.slice()
    const palletId = newIds.shift()

    if (!palletId) {
      return
    }
    setPalletIds(newIds)
    const palletData = getRelabelPalletReqData(
      palletId,
      pallet,
      order?.id ?? '',
    )
    setCurrentPalletData(pallet)
    await fetchUpdatePackingPallet(palletData)
      .then((pallet_id) => {
        if (!pallet_id) {
          return
        }
        savePalletWithNewId(pallet_id, pallet)
        if (!relabelingPlaces.length && !isAllPalletsFilled) {
          handleOpenAddFiles()
        }
      })
      .catch((err) => processErrorsOnClose(err, palletData))
  }

  const savePalletWithNewId = (
    palletId: string,
    data: {
      weight?: number
      dimensions?: { width: number; height: number; depth: number }
    },
  ): void => {
    if (currentPallet === null) return
    handleChangePallet({
      ...relabeledPallets[currentPallet],
      id: palletId,
      weight: data.weight,
      dimensions: data.dimensions,
    })
    setCurrentPallet(null)

    notification?.show(
      'success',
      `Паллета ${relabeledPallets.length} успешно сформирована`,
      {
        soundName: 'PACKING_PLACE_POSTED',
      },
    )
  }

  const handleChangePallet = (pallet: IOrderPallet) => {
    if (currentPallet === null) return false

    const newPallets = relabeledPallets.slice()
    newPallets[currentPallet] = pallet
    setRelabeledPallets(newPallets)
  }

  const fetchUpdatePackingPallet = async (
    palletData: IOrderReqPallet,
    isNeedPrint = true,
    isNeedScan = true,
  ): Promise<string | void> => {
    setCurOperation('palletClosing')

    setClosingErrorData(null)

    console.log(palletData)

    return await packingPalletMutation
      .mutateAsync({
        palletId: palletData?.pallet_id ?? '',
        pallet: palletData,
      })
      .then(async (result: IUpdatePackingPalletResult) => {
        const { sticker } = result
        const isFinal = relabelingPlaces.length

        clearWeight()

        if (!isNeedPrint) return Promise.resolve(palletData?.pallet_id)

        if (!sticker) return Promise.reject(result)

        await printSticker(palletData?.pallet_id ?? '', sticker, {
          message: 'Стикер успешно отправлен на печать',
          isNeedShowPrintInfo: !isFinal,
          isNeedScan: isNeedScan && isPallets ? true : false,
          isNeedFocus: isPallets ? false : true,
        })
        if (isBoxesRelabeling) {
          return Promise.resolve(palletData?.pallet_id)
        }
      })
      .catch((err: any) => {
        console.error(err)
        throw err
      })
      .finally(() => setGlobalLoading(false))
  }

  const closePackedPallet = async (
    palletData: IOrderReqPallet,
    options?: {
      processErr?: boolean
      isNeedPrint?: boolean
      isNeedSave?: boolean
      isNeedScan?: boolean
    },
  ): Promise<void> => {
    try {
      await fetchUpdatePackingPallet(
        palletData,
        options?.isNeedPrint,
        options?.isNeedScan,
      )
      if (options?.isNeedSave && palletData.pallet_id) {
        savePalletWithNewId(palletData.pallet_id, palletData)
      }

      //если всё перемаркировано, вызвать окно с доп документами
      if (isBoxesRelabeling && !relabelingPlaces.length) {
        handleOpenAddFiles()
      }
    } catch (err) {
      if (options?.processErr) {
        processErrorsOnClose(err, palletData)
      }
    }
  }

  const processErrorsOnClose = (err: IErrorData, data: any): void | never => {
    setClosingErrorData({
      show: true,
      data: data,
      errors: err.data ?? err,
    })
    parseError(err)
    throw err
  }

  const updatePlaceInPallet = (
    newPlace: IOrderPackagingPlace,
    currentPallet: number,
    pallets: IOrderPalletMarked[],
  ): void => {
    const newPallets = pallets.slice()
    const curPallet = newPallets[currentPallet]
    const newPlaces = curPallet.places.slice()

    newPlaces.push(newPlace)
    curPallet.places = newPlaces

    setRelabeledPallets(newPallets)
    setCurrentPallet(currentPallet)
  }

  const getNewPallet = (places: IOrderPackagingPlace[]): IOrderPallet => {
    return {
      weight: 0,
      places,
      pallet_num: relabeledPallets.length + 1,
    }
  }

  const addPlaceInPallet = (
    newPlace: IOrderPackagingMarkedPlace | null,
  ): void => {
    if (!newPlace) return

    if (currentPallet === null) {
      const newPallet = getNewPallet([])
      const newPallets = [...relabeledPallets, newPallet]

      updatePlaceInPallet(newPlace, newPallets.length - 1, newPallets)
      return
    }
    updatePlaceInPallet(newPlace, currentPallet, relabeledPallets)
  }

  // useEffect hooks

  useEffect(() => {
    if (
      relabelingPlaces.length &&
      relabeledPallets.length &&
      relabeledPallets.length === pallets.length &&
      showPalletWarning
    ) {
      setShowPalletWarning(false)
      notification?.show(
        'warning',
        `Внимание, это последняя паллета, все оставшиеся места нужно упаковать в нее`,
        {
          timeout: 30000,
        },
      )
    }
  }, [relabeledPallets, pallets, showPalletWarning])

  useEffect(() => {
    if (!isAllPalletsFilled) {
      notification?.show(
        'warning',
        `Внимание, нужно сформировать ${pallets.length} паллеты`,
        {
          timeout: 30000,
        },
      )
    }
  }, [isAllPalletsFilled])

  return {
    startClosePallet,
    closePallet,
    savePalletWithNewId,
    addPlaceInPallet,
    closePackedPallet,
  } as useManagePalletsRes
}

export default useManagePallets
