export enum TypesEnum {
  expirationDate = 'expirationDate',
  dateOfManufacture = 'dateOfManufacture',
}

export enum ShippingOptionsType {
  MIX_PACKINGS = 'MIX_PACKINGS',
  MONO_PACKINGS = 'MONO_PACKINGS',
  MONO_PALLETS = 'MONO_PALLETS',
}

export enum packType {
  packInPlaces = 'packInPlaces',
  packInPallets = 'packInPallets',
}

export enum OperationTypes {
  PACKAGING_B2B = 'PACKAGING_B2B',
  PACKAGING_B2C = 'PACKAGING_B2C',
  RELABELING = 'RELABELING',
}

export enum RelabelingTypes {
  BOXES = 'BOXES',
  PALLETS = 'PALLETS',
  BOXES_AND_PALLETS = 'BOXES_AND_PALLETS',
}

export enum ShipmentTypes {
  BOXES = 'BOXES',
  PALLETS = 'PALLETS',
}
