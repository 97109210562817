import React, { useState } from 'react'

import { User } from '@consta/uikit/User'

import { Dropdown } from 'src/components'

import { useAppContext } from 'src/context'

import {
  UserPropSize,
  UserPropView,
} from '@consta/uikit/__internal__/src/components/User/User'
import { IconProps } from '@consta/uikit/__internal__/src/icons/Icon/Icon'
import { IDropdownItem } from 'src/interfaces'

interface UserProps {
  size?: UserPropSize
  view?: UserPropView
  name?: string
  info?: string
  iconRight?: React.FC<IconProps>
  items?: IDropdownItem[]
}

const UserWithDropdown = ({
  view = 'ghost',
  name,
  info,
  size,
  iconRight,
  items,
}: UserProps) => {
  return (
    <Dropdown items={items}>
      <User
        view={view}
        iconRight={iconRight}
        name={name}
        info={info}
        size={size}
      />
    </Dropdown>
  )
}

type UserBlockProps = UserProps & {
  withMenu?: boolean
}

const UserBlock = (props: UserBlockProps) => {
  const { withMenu } = props
  const passedProps = { ...props }
  delete passedProps.withMenu

  const { currentUser } = useAppContext()

  const Component = !withMenu ? User : UserWithDropdown

  return (
    <Component
      {...passedProps}
      name={currentUser?.username || ''}
      info={currentUser?.workTable}
    />
  )
}

export default UserBlock
