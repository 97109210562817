import React, { useEffect, useRef, useState } from 'react'
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import isMobile from 'ismobilejs'
import days from 'dayjs'

import { appLocalStorage } from 'src/libs'

import styles from './register-form-page.module.scss'

import { Button } from '@consta/uikit/Button'
import { User } from '@consta/uikit/User'
import { IconPause } from '@consta/uikit/IconPause'
import { IconSelect } from '@consta/uikit/IconSelect'

import PlaceTableItem from './PlaceTableItem'
import ErrorPlaceModal from './ErrorPlaceModal'
import Informer from './Informer'
import PromtModal from './PromtModal'

import { AdvanceModeLock, InputWrapper } from 'src/components'

import { useAppContext } from 'src/context'

import {
  useGetRegisterById,
  useGetOrderByBarcode,
  useCompleteRegisterCheck,
  useSaveInterruptedRegister,
} from 'src/hooks'
import { useSound } from 'src/sound'
import { useNotifications } from 'src/notification'

import { COMPLETE_REGISTER } from 'src/config'

import {
  IPlaceItem,
  IRegister,
  GetOrderByBarcodeResult,
  IOrderErrorData,
  ApiReqResult,
} from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

const RegisterFormPage = () => {
  const params = useParams<{ registerId: string }>()
  const history = useHistory()
  const location = useLocation<{ register?: IRegister }>()

  const sound = useSound()
  const notification = useNotifications()

  const { setGlobalLoading, advanceMode } = useAppContext()

  const [places, setPlaces] = useState<IPlaceItem[]>([])
  const [errorData, setErrorData] = useState<IOrderErrorData | null>(null)
  const [informerText, setInformerText] = useState<string | null>(null)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [register, setRegister] = useState<IRegister | null>(null)
  const [barcode, setBarcode] = useState<string>('')
  const [showPromt, setShowPromt] = useState<boolean>(false)
  const [nextLocationPathname, setNextLocationPathname] = useState<
    string | null
  >(null)
  const [symbols, setSymbols] = useState<string[]>([])
  const barcodeInput = useRef<HTMLTextAreaElement | HTMLInputElement | null>(
    null,
  )
  const infoRef = useRef<HTMLDivElement | null>(null)

  const getCheckedItems = places.filter((place) => place.scanned).length

  const getRegister = useGetRegisterById()
  const getOrderInfo = useGetOrderByBarcode()
  const completeRegisterCheck = useCompleteRegisterCheck()
  const saveInterruptedRegister = useSaveInterruptedRegister()

  const getRegisterById = async (id: string) => {
    await getRegister
      .fetch({
        registerId: id,
      })
      .then((result) => {
        if (result.success && result.objects) {
          setRegister(result.objects)
        }
      })
      .catch((e) => {
        history.push('/')
      })
      .finally(() => {
        setGlobalLoading(false)
      })
  }

  useEffect(() => {
    setGlobalLoading(true)

    const curRegister = location?.state?.register
    if (curRegister) {
      setRegister(curRegister)
      setGlobalLoading(false)
    } else {
      getRegisterById(params.registerId)
    }
  }, [])

  useEffect(() => {
    if (register?.items) {
      checkLocalStorageData(register)
    }
  }, [register])

  useEffect(() => {
    // @ts-ignore
    const unblock = history.block((location, action) => {
      if (checkIsActual()) return true

      setShowPromt(true)
      setNextLocationPathname(location.pathname)
      return false
    })

    return () => {
      unblock()
    }
  }, [])

  const checkIsActual = () => {
    if (!appLocalStorage.getData('currentRegister')) return true

    const savedData = JSON.parse(appLocalStorage.getData('currentRegister'))
    return savedData?.isActual
  }

  const checkLocalStorageData = (register: IRegister) => {
    const savedData =
      appLocalStorage.getData('currentRegister') &&
      JSON.parse(appLocalStorage.getData('currentRegister'))

    if (
      savedData &&
      !savedData.isActual &&
      savedData.number === register.number
    ) {
      const savedItems = savedData.items
      const formattedItems = register.items.map((item) => {
        const foundItem = savedItems.find(
          (savedItem: IPlaceItem) => savedItem.barcode === item.barcode,
        )
        return foundItem
          ? {
              ...item,
              scanned: foundItem.scanned,
            }
          : item
      })

      const newItems = formattedItems
        .slice()
        .sort((a, b) => (a.scanned ? 1 : b.scanned ? -1 : 0))
      setPlaces(newItems)

      notification?.show('warning', 'есть несихронизированные данные')
    } else {
      updateLocalStorageRegisterData(register.items, true)
      const newItems = register.items
        .slice()
        .sort((a, b) => (a.scanned ? 1 : b.scanned ? -1 : 0))
      setPlaces(newItems)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const handleClickOutside = (e: any) => {
    if (!infoRef?.current) return

    if (!infoRef.current.contains(e.target)) {
      setShowInfo(false)
    }
  }

  const handleCompleteRegister = async (places: IPlaceItem[]) => {
    if (!register) return

    await completeRegisterCheck
      .fetch({
        number: register.number,
        items: places,
      })
      .then((result: ApiReqResult) => {
        if (result.success) {
          notification?.show('success', 'Реестр проверен успешно')
          appLocalStorage.clearData('currentRegister')
          setTimeout(() => {
            history.push('/')
          }, 2000)
        }
      })
  }

  const checkIsLastScanned = async (places: IPlaceItem[]) => {
    const getCheckedItems = places.filter((place) => place.scanned).length

    if (places.length && getCheckedItems === places.length) {
      const newPlaces = places.map((p) => ({
        order_id: p.order_id,
        barcode: p.barcode,
        scanned: p.scanned,
        barcodeadd: p.barcodeadd,
      }))
      await handleCompleteRegister(newPlaces)
    }
  }

  const markСheckPlace = (barcode: string) => {
    const checkedPlace = places.find(
      (p) => p.barcode === barcode || p.barcodeadd === barcode,
    )
    if (!checkedPlace) return

    const newPlaces = places.filter(
      (p) => p.barcode !== barcode && p.barcodeadd !== barcode,
    )
    const checkedPlaces = newPlaces.filter((p) => p.scanned)
    const noCheckedPlaces = newPlaces.filter((p) => !p.scanned)

    const placesWithCheckedPlace = [
      ...noCheckedPlaces,
      { ...checkedPlace, scanned: true },
      ...checkedPlaces,
    ]
    setPlaces(placesWithCheckedPlace)

    updateLocalStorageRegisterData(placesWithCheckedPlace, false)

    checkIsLastScanned(placesWithCheckedPlace)
  }

  const handleGetOrderInfo = async (barcode: string) => {
    await getOrderInfo
      .fetch({
        placeBarcode: barcode,
      })
      .then((result: GetOrderByBarcodeResult) => {
        if (result.success && result.objects) {
          setErrorData({
            title: `Место ${barcode} не найдено`,
            order_id: result.objects.order_id,
            ship_date: result.objects.ship_date,
            courier_id: result.objects.courier_id,
          })
          sound?.play('OTHER_ERROR')
        }
      })
      .catch((e) => {
        if (e.errors?.[0]?.message) {
          setErrorData({
            title: `Место ${barcode} не найдено`,
            subtitle: e.errors?.[0]?.message,
          })
        } else {
          setErrorData({
            title: `Место ${barcode} не найдено`,
            subtitle: 'Ошибка, данные места на найдены',
          })
        }
      })
  }

  const handleChange = async (barcode: TextFieldPropValue) => {
    setBarcode('')
    setSymbols([])

    if (!barcode) {
      notification?.show('alert', 'Штрихкод не считан')
      return
    }

    if (register?.status === 'CheckOut') {
      notification?.show(
        'alert',
        'Проверка реестра завершена, сканирование запрещено',
      )
      return
    }

    if (barcode === COMPLETE_REGISTER) {
      const newPlaces = places.map((p) => ({
        order_id: p.order_id,
        barcode: p.barcode,
        scanned: p.scanned,
        barcodeadd: p.barcodeadd,
      }))
      await handleCompleteRegister(newPlaces)
      return
    }

    const place = places.find(
      (curPlace) =>
        curPlace.barcode === barcode.trim() ||
        curPlace.barcodeadd === barcode.trim(),
    )

    if (!place) {
      await handleGetOrderInfo(barcode)
      return
    }

    if (place.scanned) {
      setErrorData({
        title: `Место ${barcode} уже отсканировано`,
      })
      sound?.play('OTHER_ERROR')
      return
    }

    setInformerText(`Место ${barcode} найдено в реестре`)
    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')
    markСheckPlace(barcode)
  }

  const onBlur = () => {
    barcodeInput?.current?.focus()
  }

  const handleCancel = async () => {
    if (!register) return null

    const newPlaces = places.map((p) => ({
      order_id: p.order_id,
      barcode: p.barcode,
      scanned: p.scanned,
      barcodeadd: p.barcodeadd,
    }))

    await saveInterruptedRegister
      .fetch({
        number: register.number,
        items: newPlaces,
      })
      .then((result: ApiReqResult) => {
        if (result.success) {
          appLocalStorage.clearData('currentRegister')

          notification?.show(
            'warning',
            'Проверка реестра прервана, данные сохранены',
          )
          setTimeout(() => {
            history.push('/')
          }, 2000)
        }
      })
  }

  const updateLocalStorageRegisterData = (
    items: IPlaceItem[],
    isActual: boolean,
  ) => {
    const registerForSave = {
      ...register,
      items,
      isActual,
    }
    appLocalStorage.setData('currentRegister', JSON.stringify(registerForSave))
  }

  const handleKeyDown = async (e: any) => {
    setSymbols((prevState) => [...prevState, e.key])
  }

  useEffect(() => {
    if (symbols.length && symbols[symbols.length - 1] === 'Enter') {
      const formattedBarcode = symbols
        .map((s, i) =>
          s === 'Shift' && /^[a-z]]+$/.test(symbols[i + 1])
            ? s.toUpperCase()
            : s,
        )
        .filter((s) => s !== 'Enter' && s !== 'Unidentified' && s !== 'Shift')
        .join('')
      handleChange(formattedBarcode)
      return
    }
  }, [symbols])

  useEffect(() => {
    if (advanceMode) {
      document.removeEventListener('keydown', handleKeyDown)
      return
    }
    document.addEventListener('keydown', handleKeyDown)
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [advanceMode, symbols])

  if (!register) return null

  const handleConfirm = () => {
    setShowPromt(false)
    if (nextLocationPathname) {
      appLocalStorage.clearData('currentRegister')
      history.block(true)
      history.push(nextLocationPathname)
    }
  }

  const handleCloseRegister = () => {
    history.push('/')
    appLocalStorage.clearData('currentRegister')
  }

  return (
    <>
      <div
        className={styles.registryMain}
        style={{ height: window.innerHeight }}
      >
        <div className={styles.header}>
          <div className={styles.registerInfo} ref={infoRef}>
            <User
              size="m"
              view="clear"
              name={register.number}
              info={register.cell_number}
              iconRight={IconSelect}
              onIconRightClick={() => setShowInfo(!showInfo)}
            />
            {showInfo ? (
              <div className={styles.info}>
                <div className={styles.block}>
                  <h6>Реестр</h6>
                  <span className={styles.bold}>{register.number}</span>
                </div>
                {register.cell_number ? (
                  <div className={styles.block}>
                    <h6>Корзина</h6>
                    <span>{register.cell_number}</span>
                  </div>
                ) : null}
                {register.ship_date ? (
                  <div className={styles.block}>
                    <h6>Дата отгрузки</h6>
                    <span>{days(register.ship_date).format('DD.MM.YYYY')}</span>
                  </div>
                ) : null}
                {register.NameOrganization ? (
                  <div className={styles.block}>
                    <h6>Контрагент</h6>
                    <span>{register.NameOrganization}</span>
                  </div>
                ) : null}
                {register.courier_id ? (
                  <div className={styles.block}>
                    <h6>Служба доставки</h6>
                    <span>{register.courier_id}</span>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className={styles.btns}>
            {!isMobile().any ? <AdvanceModeLock /> : null}
            {register.status === 'CheckOut' ? (
              <Button
                label="Закрыть"
                view="ghost"
                size="s"
                onClick={handleCloseRegister}
              />
            ) : (
              <Button
                label="Прервать"
                view="ghost"
                iconLeft={IconPause}
                size="s"
                onClick={handleCancel}
              />
            )}
          </div>
        </div>
        <div className={styles.top}>
          <div className={styles.verified}>
            <h5>Проверено</h5>
            <h4>
              <span className={styles.verifiedGreen}>{getCheckedItems}</span> из{' '}
              {places.length}
            </h4>
          </div>
          {/*<h5>Сканируем: {barcode}</h5>*/}
          {/*<h5>Символы: {symbols.join(', ')}</h5>*/}
          {advanceMode ? (
            <InputWrapper
              id={'barcode'}
              handleChange={handleChange}
              autoFocus
              size="m"
              className={styles.barcode}
              inputRef={barcodeInput}
              onBlur={onBlur}
            />
          ) : null}
        </div>
        {places?.length ? (
          <div className={styles.registriesItems}>
            {places.slice().map((p) => (
              <PlaceTableItem key={p.barcode} place={p} />
            ))}
          </div>
        ) : null}
      </div>

      {errorData ? (
        <ErrorPlaceModal
          errorData={errorData}
          isOpen={true}
          onClose={() => setErrorData(null)}
        />
      ) : null}

      {informerText ? (
        <Informer onClose={() => setInformerText(null)} text={informerText} />
      ) : null}

      {showPromt ? (
        <PromtModal
          isOpen={true}
          onClose={() => setShowPromt(false)}
          title="Есть несохраненные данные"
          message="Вы уверены что хотите покинуть этот реестр?"
          handleConfirm={handleConfirm}
        />
      ) : null}
    </>
  )
}

export default RegisterFormPage
