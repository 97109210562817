import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import InputMask from 'react-input-mask'

import styles from './input-wrapper.module.scss'

import { TextField } from '@consta/uikit/TextField'
import { IconClose } from '@consta/uikit/IconClose'
import { IconWarning } from '@consta/uikit/IconWarning'

import { useAppContext } from 'src/context'
import { useNotifications } from 'src/notification'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconProps } from '@consta/uikit/__internal__/src/icons/Icon/Icon'
import {
  TextFieldPropAutoComplete,
  TextFieldPropName,
  TextFieldPropSize,
} from '@consta/uikit/__internal__/src/components/TextField/TextField'

interface InputWrapperProps {
  id?: string
  label?: string
  type?: string
  placeholder?: string
  size?: TextFieldPropSize
  disabled?: boolean
  maxLength?: number
  onFocus?: React.FocusEventHandler<HTMLElement>
  onBlur?: React.FocusEventHandler<HTMLElement>
  onEnter?: () => void
  error?: string
  value?: string | null
  handleChange?: (value: TextFieldPropValue, type: string) => void
  readOnly?: boolean
  autoFocus?: boolean
  withDelay?: boolean
  withDelayAndManual?: boolean
  className?: any
  rightSide?: string | React.FC<IconProps>
  leftSide?: string | React.FC<IconProps>
  clearable?: boolean
  onClear?: () => void
  inputRef?: React.Ref<HTMLTextAreaElement | HTMLInputElement>
  mask?: string
  isInteger?: boolean
  isNumber?: boolean
  form?:
    | 'default'
    | 'defaultClear'
    | 'defaultBrick'
    | 'brick'
    | 'brickDefault'
    | 'brickClear'
    | 'brickRound'
    | 'round'
    | 'roundClear'
    | 'roundBrick'
    | 'clearRound'
    | 'clearDefault'
    | 'clearBrick'
    | 'clearClear'
  autoComplete?: TextFieldPropAutoComplete
  name?: TextFieldPropName
}

const InputWrapper = ({
  type = 'text',
  className,
  id,
  label,
  error,
  value,
  handleChange = () => false,
  onEnter,
  readOnly = false,
  autoFocus = false,
  withDelay,
  withDelayAndManual,
  placeholder,
  rightSide,
  size,
  leftSide,
  clearable = false,
  onClear = () => false,
  inputRef,
  mask,
  isInteger = false,
  isNumber = false,
  disabled,
  onBlur,
  form,
  name,
  autoComplete,
}: InputWrapperProps) => {
  type states = {
    alert: 'alert'
    success: 'success'
    warning: 'warning'
  }

  const { resetAfkInterval } = useAppContext()
  const notification = useNotifications()

  const [started, setStarted] = useState(false)
  const [localValue, setLocalValue] = useState<string>('')
  const prevValue = useRef<string | null | undefined>('')
  const timer = useRef<number | null>(null)
  const curValue = useRef<string | null | undefined>('')

  useEffect(() => {
    if (started) {
      if (isInteger && localValue && !checkIsInteger(localValue)) {
        setLocalValue('')
        return
      }
      if (withDelayAndManual) {
        checkWithDelayAndManualTimer(localValue)
      }
      if (withDelay) {
        if (window.timeoutId) {
          window.clearTimeout(window.timeoutId)
        }
        window.timeoutId = window.setTimeout(() => {
          if (localValue) {
            handleChange(localValue, 'withDelay')
            setLocalValue('')
          }
        }, 200)
      }
    } else {
      setStarted(true)
    }
  }, [localValue])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (value && onEnter) {
      if (e.key === 'Enter') {
        onEnter()
      }
    }
  }

  const checkWithDelayAndManualTimer = (localValue: string) => {
    curValue.current = localValue
    handleChange(localValue, 'withManual')
    if (window.timeoutId !== timer.current) {
      prevValue.current = localValue
      handleChange(localValue, 'withManual')
      window.timeoutId = window.setTimeout(() => {
        const prev = prevValue.current
        prevValue.current = curValue.current
        const cur = curValue.current ?? ''
        if (prev ? cur.length - prev.length > 4 : cur.length > 4) {
          handleChange(cur, 'withDelay')
          setLocalValue('')
        } else {
          handleChange(cur, 'withManual')
        }
      }, 300)
      timer.current = window.timeoutId
      return
    }
    handleChange(localValue, 'withManual')
  }

  const checkIsInteger = (value: TextFieldPropValue) => {
    if (value) {
      return /^[0-9]+$/.test(value) || value === ''
    }
    return true
  }

  const checkIsNumber = (value: TextFieldPropValue) => {
    if (value) {
      return /^[0-9.]+$/.test(value) || value === ''
    }
    return true
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.getModifierState('CapsLock')) {
      notification?.show('alert', 'Внимание! Нажата клавиша CAPS LOCK', {
        id: 'capsIsOn',
        persistent: true,
        allWidth: true,
        icon: IconWarning,
      })
    } else {
      if (typeof notification?.state?.capsIsOn !== 'undefined') {
        notification?.clear('capsIsOn')
      }
    }
  }

  const getState = (): keyof states | undefined => {
    if (error) {
      return 'alert'
    }
    return undefined
  }

  const handleChangeInput = (value: TextFieldPropValue, type: string) => {
    if (isInteger && value && !checkIsInteger(value)) {
      return
    }
    if (isNumber && value && !checkIsNumber(value)) {
      return
    }
    handleChange(value, type)
  }

  const primaryHandleChange = ({ value }: { value: TextFieldPropValue }) => {
    resetAfkInterval()

    if (!withDelay && !withDelayAndManual) {
      if (!mask) {
        handleChangeInput(value ?? '', 'noDelay')
      } else {
        handleChange(value !== null ? value : '', 'noDelay')
      }
    } else {
      if (!mask) {
        setLocalValue(String(value ?? ''))
      } else {
        setLocalValue(String(value !== null ? value : ''))
      }
    }
  }

  return (
    <div className={cx(styles.input, className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <div>
        {clearable && (
          <IconClose size="s" className={styles.clearable} onClick={onClear} />
        )}

        {!mask ? (
          <TextField
            readOnly={readOnly}
            type={type}
            id={id}
            state={getState()}
            value={!withDelay ? value : localValue}
            onChange={primaryHandleChange}
            onKeyDown={(e) => handleKeyDown(e)}
            onKeyUp={(e) => handleKeyUp(e)}
            autoFocus={autoFocus}
            placeholder={placeholder}
            rightSide={rightSide}
            size={size}
            leftSide={leftSide}
            inputRef={inputRef}
            disabled={disabled}
            onBlur={onBlur}
            form={form}
            autoComplete={autoComplete}
            name={name}
          />
        ) : (
          // @ts-ignore
          <InputMask
            mask={mask}
            readOnly={readOnly}
            type={type}
            id={id}
            state={getState()}
            value={!withDelay ? value : localValue}
            // @ts-ignore
            onChange={primaryHandleChange}
            onKeyDown={(e) => handleKeyDown(e)}
            onKeyUp={(e) => handleKeyUp(e)}
            autoFocus={autoFocus}
            placeholder={placeholder}
            rightSide={rightSide}
            size={size}
            leftSide={leftSide}
            inputRef={inputRef}
            form={form}
          >
            {
              // @ts-ignore
              (inputProps) => <TextField {...inputProps} />
            }
          </InputMask>
        )}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  )
}

export default InputWrapper
