import React, { useEffect, useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'

import styles from './shipping-form-modal.module.scss'

import { Badge } from '@consta/uikit/Badge'

import { Modal, Img } from 'src/components'

import { useSound } from 'src/sound'

import { usePackingOrderContext } from '../../context'

import {
  ModalProps,
  IShippingFormItem,
  IOrder,
  ShippingOptionsType,
  OperationTypes,
  IDimensionsObj,
} from 'src/interfaces'

interface IShippingFormModalProps extends ModalProps {
  shippingFormData: IShippingFormItem[] | []
  blurRef: React.MutableRefObject<HTMLTextAreaElement | HTMLInputElement | null>
  order: IOrder | null
}

const ShippingFormModal = (props: IShippingFormModalProps) => {
  const { isOpen, onClose, shippingFormData, blurRef, order } = props

  const { setShippingFormViewed, pallets } = usePackingOrderContext()

  const isRelabeling = order?.operationType === OperationTypes.RELABELING
  const isFbo = order?.operationType !== OperationTypes.PACKAGING_B2C
  const maxPlaceWeight = +(order?.delivery?.maximum_place_weight ?? 0)
  const maxPalletWeight = +(order?.delivery?.maximum_pallet_weight ?? 0)

  const sound = useSound()

  useEffect(() => {
    if (blurRef?.current) {
      blurRef.current.blur()
    }
    setShippingFormViewed(true)
  }, [])

  useEffect(() => {
    const playSoundErr = () => {
      sound?.play('OTHER_ERROR')
    }
    document.addEventListener('keydown', playSoundErr)
    return function cleanup() {
      document.removeEventListener('keydown', playSoundErr)
    }
  })

  const getValue = (key: string) => {
    return shippingFormData.find((field) => field.id === key)?.value
  }

  const getDimensions = (dimensions?: IDimensionsObj): string | null => {
    if (!dimensions?.width && !dimensions?.height && !dimensions?.depth) {
      return null
    }

    return `${dimensions?.width / 10 ?? '--'}х${
      dimensions?.height / 10 ?? '--'
    }х${dimensions?.depth / 10 ?? '--'}`
  }

  const otherFormValues = useMemo(
    () =>
      shippingFormData.filter(
        (f) => f.id !== 'shipment_num' && f.id !== 'instruction',
      ),
    [shippingFormData],
  )

  const getShippingType = (type?: string) => {
    if (type === ShippingOptionsType.MONO_PACKINGS) {
      return 'монокороб'
    }

    if (type === ShippingOptionsType.MONO_PALLETS) {
      return 'монопаллета'
    }

    return 'Микскороб'
  }

  if (!order) return null

  const maxPlaceDimensions = getDimensions(
    order?.delivery?.maximum_place_dimensions,
  )
  const maxPalletDimensions = getDimensions(
    order?.delivery?.maximum_pallet_dimensions,
  )

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.shippingForm}
      size="m"
      withClose
      onClose={onClose}
    >
      <div className={styles.top}>
        <h3 className={styles.title}>Анкета отгрузки</h3>
      </div>

      <>
        <Img className={styles.img} src={order?.delivery?.logo} yandex />

        <div className={styles.mainInfo}>
          {isFbo ? (
            <div className={styles.infoBlock}>
              <h6>Тип Короба</h6>
              <span>
                <Badge
                  className={styles.badge}
                  size="m"
                  status="error"
                  label={getShippingType(order?.shippingOptions?.packingType)}
                />
              </span>
            </div>
          ) : null}
          {isRelabeling ? (
            <div className={styles.infoBlock}>
              <h6>Число паллет</h6>
              <span>{pallets.length}</span>
            </div>
          ) : null}
          {maxPlaceWeight ? (
            <div className={styles.infoBlock}>
              <h6>Макс. вес места, кг</h6>
              <span>{maxPlaceWeight / 1000}</span>
            </div>
          ) : null}
          {maxPalletWeight ? (
            <div className={styles.infoBlock}>
              <h6>Макс. вес паллеты, кг</h6>
              <span>{maxPalletWeight / 1000}</span>
            </div>
          ) : null}
          {maxPlaceDimensions ? (
            <div className={styles.infoBlock}>
              <h6>Макс. габариты места, см</h6>
              <span>{maxPlaceDimensions}</span>
            </div>
          ) : null}
          {maxPalletDimensions ? (
            <div className={styles.infoBlock}>
              <h6>Макс. габариты паллеты, см</h6>
              <span>{maxPalletDimensions}</span>
            </div>
          ) : null}
          {getValue('shipment_num') ? (
            <div className={styles.infoBlock}>
              <h6>
                Поставка{' '}
                {order?.operationType === 'PACKAGING_B2B' ? 'МП' : null}
              </h6>
              <span>{getValue('shipment_num')}</span>
            </div>
          ) : null}
          {otherFormValues.map((f) => (
            <div key={f.id} className={styles.infoBlock}>
              <h6>{f.title}</h6>
              <span>{f.value}</span>
            </div>
          ))}
        </div>
      </>

      {getValue('instruction') ? (
        <div className={styles.content}>
          <h3>Руководство по упаковке</h3>
          <div className={styles.instruction}>
            {ReactHtmlParser(getValue('instruction') ?? '')}
          </div>
        </div>
      ) : null}
    </Modal>
  )
}

export default ShippingFormModal
