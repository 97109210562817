import React from 'react'

import styles from './b2c-packing-order.module.scss'

import { UnPackTable, PackTable, BatchPackedTable } from '../components'

import {
  IOrder,
  IOrderItem,
  IOrderPackagingPlace,
  IOrderPackagingOption,
} from 'src/interfaces'

interface MainBlockProps {
  order: IOrder | null
  items: IOrderItem[] | null
  places: IOrderPackagingPlace[]
  currentPlace: number | null
  packagingOptions: IOrderPackagingOption[]
  setShowPacked: (value: boolean) => void
  handlePrintItemSticker: (id: string, expires?: string) => void
  handleChooseItem: (item: IOrderItem) => void
}

const MainBlock = (props: MainBlockProps) => {
  const {
    order,
    items,
    places,
    currentPlace,
    packagingOptions,
    setShowPacked,
    handlePrintItemSticker,
    handleChooseItem,
  } = props

  return (
    <div className={styles.mainBlock}>
      {items?.length ? (
        <UnPackTable
          title="Не упаковано"
          items={items}
          handleChooseItem={handleChooseItem}
        />
      ) : null}

      {places.length ? (
        <PackTable
          order={order}
          currentPlace={currentPlace}
          places={places}
          packagingOptions={packagingOptions}
          setShowPacked={setShowPacked}
          handlePrintItemSticker={handlePrintItemSticker}
        />
      ) : null}
    </div>
  )
}

export default MainBlock
