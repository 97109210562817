import React, { useEffect } from 'react'

import styles from './item-count-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import { Modal, InputWrapper, ItemPreview, EnterHandler } from 'src/components'

import { ModalProps } from 'src/interfaces'

import { isNumber } from 'src/helpers'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IOrderItem, IBatch, ISupplyOriginalItem } from 'src/interfaces'
import { IconClose } from '@consta/uikit/IconClose'

interface ICountItem extends IOrderItem {
  batch?: IBatch
}

interface ItemCountProps extends ModalProps {
  count: string
  item: ISupplyOriginalItem | null
  setCount: (count: string) => void
  onItemAdding: (count: number) => void
}

const ItemCountModal = (props: ItemCountProps) => {
  const { count, item, isOpen, setCount, onItemAdding, onClose } = props

  return (
    <EnterHandler onEnter={() => onItemAdding(+count)}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.itemCount}
        size="s"
      >
        <IconClose className={styles.close} onClick={() => onClose()} />
        {item ? (
          <ItemPreview
            image={item.image}
            title={item.title}
            batch={item.batch}
            barcode={
              item.barcodes?.find((barcode) => barcode.pack_id === item.pack_id)
                ?.barcode ?? ''
            }
          />
        ) : null}

        <InputWrapper
          label={'Введите количество товара'}
          value={count}
          id={'count'}
          handleChange={(value: TextFieldPropValue) => setCount(String(value))}
          autoFocus
          className={styles.itemInput}
          placeholder={'Количество товара'}
          size="l"
          isInteger
        />
        <div className={styles.btns}>
          <Button
            label="Подтвердить"
            className={styles.btn}
            disabled={!count}
            onClick={() => onItemAdding(+count)}
          />
          <Button
            label="Закрыть"
            view="ghost"
            className={styles.btn}
            onClick={() => onClose()}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ItemCountModal
