import React from 'react'

import styles from './b2b-relabeling-order.module.scss'

import {
  BoxMarkingTable,
  PalletMarkingTable,
  BoxMarkedInPallets,
} from '../components'

import { usePackingOrderContext } from '../context'

import {
  IOrderPackagingPlace,
  IOrderPackedPallet,
  IOrderPackagingOption,
  RelabelingTypes,
  IOrderPalletMarked,
} from 'src/interfaces'

interface IMainBlockProps {
  type: RelabelingTypes
  markedPlaces: IOrderPackagingPlace[]
  markedPallets: IOrderPackedPallet[]
  handlePrint: (itemId: string) => void
  packagingOptions: IOrderPackagingOption[]
  isAllPalletsFilled: boolean
  pallets: IOrderPalletMarked[]
  currentPallet: number | null
  showPacked: boolean
  setShowPacked: () => void
  setPallets: (pallets: IOrderPalletMarked[]) => void
}

const MainBlock = (props: IMainBlockProps) => {
  const {
    type,
    markedPlaces,
    markedPallets,
    handlePrint,
    packagingOptions,
    isAllPalletsFilled,
    pallets,
    currentPallet,
    setShowPacked,
  } = props

  const { relabelingPlaces, palletsForRelabeling } = usePackingOrderContext()

  return (
    <div className={styles.mainBlock}>
      {type === RelabelingTypes.BOXES ||
      type === RelabelingTypes.BOXES_AND_PALLETS ? (
        <>
          {relabelingPlaces.length ? (
            <BoxMarkingTable
              title="Без маркировки"
              items={relabelingPlaces}
              packagingOptions={packagingOptions}
            />
          ) : null}

          {markedPlaces.length && isAllPalletsFilled ? (
            <BoxMarkingTable
              className={styles.markedTable}
              title={
                <span className={styles.markedTitle}>Перемаркировано</span>
              }
              items={markedPlaces}
              withStickerPrint
              handlePrint={handlePrint}
              packagingOptions={packagingOptions}
            />
          ) : null}

          {markedPlaces.length && !isAllPalletsFilled ? (
            <BoxMarkedInPallets
              handlePrint={handlePrint}
              pallets={pallets}
              currentPallet={currentPallet}
              setShowPacked={setShowPacked}
            />
          ) : null}
        </>
      ) : null}

      {type === RelabelingTypes.PALLETS ? (
        <>
          {palletsForRelabeling.length && isAllPalletsFilled ? (
            <PalletMarkingTable
              title="Без маркировки"
              items={palletsForRelabeling}
            />
          ) : null}

          {relabelingPlaces.length && !isAllPalletsFilled ? (
            <BoxMarkingTable
              title="Без маркировки"
              items={relabelingPlaces}
              packagingOptions={packagingOptions}
            />
          ) : null}

          {markedPallets.length && isAllPalletsFilled ? (
            <PalletMarkingTable
              className={styles.markedTable}
              title={
                <span className={styles.markedTitle}>Перемаркировано</span>
              }
              items={markedPallets}
              withStickerPrint
              handlePrint={handlePrint}
            />
          ) : null}

          {pallets.length && !isAllPalletsFilled ? (
            <BoxMarkedInPallets
              handlePrint={handlePrint}
              pallets={pallets}
              currentPallet={currentPallet}
              setShowPacked={setShowPacked}
            />
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default MainBlock
