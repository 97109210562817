import React, { useState } from 'react'

import styles from './choice-pallet-modal.module.scss'

import palletImg from './assets/pallet_avatar.svg'

import { Button } from '@consta/uikit/Button'

import { Modal, SelectList } from 'src/components'

import { ModalProps, ISelectListOption, IOrderPallet } from 'src/interfaces'

interface ChoicePalletModal extends ModalProps {
  pallets: IOrderPallet[]
  handleSubmit: (palletId: string) => void
  sourcePallet: string
}

const ChoicePalletModal = (props: ChoicePalletModal) => {
  const { isOpen, onClose, pallets, handleSubmit, sourcePallet } = props

  const [selected, setSelected] = useState<ISelectListOption | null>(null)

  const palletsOptions = pallets
    .slice()
    .reverse()
    .filter((pallet) => (pallet.id ?? '0') !== sourcePallet)
    .map((pallet) => {
      return {
        value: pallet.id ?? '0',
        label: (
          <div>
            <img src={palletImg} />
            <span>Паллета {pallet.pallet_num}</span>
          </div>
        ),
        rightSide: (
          <span className={styles.barcode}>
            {pallet.id ?? 'текущая паллета'}
          </span>
        ),
      }
    })

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.choicePallet}
      title="Выбор паллеты"
      size="s"
    >
      <h4 className={styles.subtitle}>
        Выберите паллету для перемещения места
      </h4>
      <div className={styles.selectWrap}>
        <SelectList
          value={selected}
          options={palletsOptions}
          onChange={(selected: ISelectListOption) => setSelected(selected)}
          className={styles.wrap}
        />
      </div>
      <div className={styles.btns}>
        <Button
          label="Подтвердить"
          disabled={!selected}
          className={styles.btn}
          onClick={() => handleSubmit(selected?.value ?? '0')}
        />
        <Button
          label="Назад"
          view="ghost"
          className={styles.btn}
          onClick={onClose}
        />
      </div>
    </Modal>
  )
}

export default ChoicePalletModal
