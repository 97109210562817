import React, { useState } from 'react'

import styles from './scan-serial-input.module.scss'

import { InputWrapper, EnterHandler } from 'src/components'

import { Button } from '@consta/uikit/Button'
import { IconAllDone } from '@consta/uikit/IconAllDone'
import { TextFieldPropValue } from '@consta/uikit/TextField'

import { useNotifications } from 'src/notification'

import { checkStarsTemplate } from 'src/helpers'

interface ScanSerialInputProps {
  value: TextFieldPropValue
  onChange: (v: TextFieldPropValue) => void
  code: string
  status: string
  label: string
  template: string
  cachedSerials?: string[]
  setCachedSerials?: (serials: string[]) => void
}

const ScanSerialInput = (props: ScanSerialInputProps) => {
  const {
    value,
    onChange,
    code,
    status = 'undone',
    label,
    template,
    cachedSerials,
    setCachedSerials,
  } = props

  const [localVal, setLocalVal] = useState<TextFieldPropValue>('')
  const [error, setError] = useState<string>('')

  const notification = useNotifications()

  const checkChz = (val: string) => {
    const chzTemplate =
      '0100000000000000210000000000000000000000000000000000000000000'

    if (val.trim() === chzTemplate) {
      setError('Недопустимое значение')
      notification?.show('alert', `Недопустимое значение.`)
      return false
    }

    if (new RegExp('^010000000000000021').test(val.trim())) {
      setError('Недопустимое значение')
      notification?.show('alert', `Недопустимое значение.`)
      return false
    }

    return true
  }

  const checkTemplateAndChange = (val: TextFieldPropValue, code: string) => {
    if (!val) return

    if (val && /[а-яА-Я]/i.test(val)) {
      notification?.show(
        'alert',
        `Обнаружена кириллица в серийном номере. Измените раскладку клавиатуры.`,
      )
      return false
    }

    if (cachedSerials && setCachedSerials && cachedSerials.includes(val)) {
      notification?.show('alert', `Такой серийный номер уже был ввёден`)
      return false
    }

    if (template && template !== '*' && !new RegExp(template).test(val)) {
      setError('Введенный номер не соответствует шаблону')
      notification?.show('alert', `Введенный номер не соответствует шаблону.`)
      return false
    }

    if (code === 'chestniy_znak' && !checkChz(val)) {
      return false
    }

    // if (template && template !== '*') {
    //   const isValid = checkStarsTemplate(val, template)
    //   if (!isValid) {
    //     setError('Введенный номер не соответствует шаблону')
    //     notification?.show('alert', `Введенный номер не соответствует шаблону.`)
    //     return false
    //   }
    // }

    if (cachedSerials && setCachedSerials) {
      //@ts-ignore
      setCachedSerials((state: string[]) => [...state, val])
    }
    onChange(val)
  }

  const handleChange = (
    val: TextFieldPropValue,
    type: string = 'withDelay',
  ) => {
    setLocalVal('')
    if (!val) return

    if (type === 'withDelay') {
      checkTemplateAndChange(val.trim(), code)
    }

    if (type === 'withManual') {
      setLocalVal(val.trim())
      setError('')
    }
  }

  const handleManualChange = (val: TextFieldPropValue) => {
    setError('')
    if (!val) return

    checkTemplateAndChange(val, code)
  }

  const handleEnter = (localVal: TextFieldPropValue) => {
    handleManualChange(localVal)
  }

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.colLabel}>{label}</h4>
      <div className={styles.block}>
        {status === 'undone' ? (
          <EnterHandler onEnter={() => handleEnter(localVal)}>
            <div className={styles.undone}>
              <InputWrapper
                value={localVal || value}
                id={code}
                handleChange={(val: TextFieldPropValue, type: string) =>
                  handleChange(val, type)
                }
                className={styles.itemInput}
                size="l"
                withDelayAndManual
                error={error}
                autoFocus
              />
              <Button
                className={styles.btn}
                form="brickDefault"
                label="Подтвердить"
                disabled={!localVal && !value}
                size="l"
                onClick={() => handleManualChange(localVal)}
              />
            </div>
          </EnterHandler>
        ) : null}
        {status === 'done' ? (
          <div className={styles.done}>
            <InputWrapper
              disabled
              readOnly
              value={value}
              id={code}
              className={styles.itemInput}
              size="l"
            />
            <IconAllDone className={styles.icon} view="success" />
          </div>
        ) : null}

        {status === 'disabled' ? (
          <InputWrapper
            disabled
            readOnly
            value={value}
            id={code}
            className={styles.itemInput}
            size="l"
          />
        ) : null}
      </div>
    </div>
  )
}

export default ScanSerialInput
