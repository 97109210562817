import React from 'react'
import cx from 'classnames'

import styles from './marking-table.module.scss'

import boxImg from './assets/box_avatar.svg'

import { Button } from '@consta/uikit/Button'

import { Table } from 'src/components'

import { getPackageTitle } from 'src/helpers'

import {
  IOrderPackagingMarkedPlace,
  IOrderPackagingOption,
} from 'src/interfaces'

interface MarkingTableProps {
  title?: string | React.ReactNode
  withStickerPrint?: boolean
  handlePrint?: (itemId: string) => void
  className?: string
  items: IOrderPackagingMarkedPlace[]
  packagingOptions: IOrderPackagingOption[]
}

const BoxMarkingTable = (props: MarkingTableProps) => {
  const {
    title,
    withStickerPrint,
    className,
    items,
    handlePrint = () => false,
    packagingOptions,
  } = props

  if (!items) return null

  return (
    <div className={cx(styles.BoxTable, className)}>
      {items.length && title ? <h4>{title}</h4> : null}

      <div
        className={cx(
          styles.tableWrap,
          withStickerPrint ? styles.tableBlue : null,
        )}
      >
        <Table stickyHeader withoutHead={true} striped>
          {items
            .slice()
            .sort((a, b) => +a.sequence_num - +b.sequence_num)
            .map((item, i) => (
              <Table.Row key={item.id}>
                <Table.Column title="" className={styles.tdImg}>
                  <div className={styles.left}>
                    <img className={styles.img} src={boxImg} alt="" />
                    <div>
                      <h6>Короб {item.sequence_num}</h6>
                      <span className={styles.barcode}>
                        {item.newBarcode ?? item.id}
                      </span>
                      <span className={styles.dimensions}>
                        {getPackageTitle(item, packagingOptions)}
                      </span>
                    </div>
                  </div>
                </Table.Column>
                <Table.Column title="" align="right">
                  {withStickerPrint ? (
                    <Button
                      label="Повторить печать"
                      view="secondary"
                      size="s"
                      onClick={() =>
                        handlePrint(
                          item.temp_id ? item.id ?? '' : item.newBarcode ?? '',
                        )
                      }
                    />
                  ) : null}
                </Table.Column>
              </Table.Row>
            ))}
        </Table>
      </div>
    </div>
  )
}

export default BoxMarkingTable
