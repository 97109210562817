import React from 'react'

import styles from './b2b-packing-order.module.scss'

import { UnPackTable, PackTable, PalletsPackedTable } from '../components'

import {
  IOrder,
  IOrderItem,
  IOrderPackagingPlace,
  IOrderPackagingOption,
  IOrderPallet,
  packType,
} from 'src/interfaces'

interface MainBlockProps {
  order: IOrder | null
  items: IOrderItem[] | null
  currentPlace: number | null
  pallets: IOrderPallet[]
  currentPallet: number | null
  curPackingType: packType
  setShowPacked: (value: boolean) => void
  handlePrintItemSticker: (
    id: string,
    barcode: string,
    expires?: string,
    dateOfManufacture?: string,
    advanced?: boolean,
  ) => void
  handleChooseItem?: (item: IOrderItem) => void
  places: IOrderPackagingPlace[]
  packagingOptions: IOrderPackagingOption[]
  addWeightToCache: (itemId: string, weight: number) => void
  updatePlacesWeight: (place: IOrderPackagingPlace) => void
}

const MainBlock = (props: MainBlockProps) => {
  const {
    order,
    items,
    currentPlace,
    setShowPacked,
    handlePrintItemSticker,
    handleChooseItem,
    pallets,
    currentPallet,
    curPackingType,
    places,
    packagingOptions,
    addWeightToCache,
    updatePlacesWeight,
  } = props

  return (
    <div className={styles.mainBlock}>
      {items?.length ? (
        <UnPackTable
          title="Не упаковано"
          items={items}
          handleChooseItem={handleChooseItem}
        />
      ) : null}

      {curPackingType === packType.packInPallets ? (
        <>
          {pallets.length ? (
            <PalletsPackedTable
              order={order}
              pallets={pallets}
              currentPallet={currentPallet}
              currentPlace={currentPlace}
              showPacked={() => setShowPacked(true)}
              handlePrintItemSticker={handlePrintItemSticker}
            />
          ) : null}
        </>
      ) : (
        <>
          {places.length ? (
            <PackTable
              order={order}
              currentPlace={currentPlace}
              places={places}
              packagingOptions={packagingOptions}
              setShowPacked={setShowPacked}
              handlePrintItemSticker={handlePrintItemSticker}
              addPack={true}
              needSticker={true}
              needAdvanced={false}
              addWeightToCache={addWeightToCache}
              updatePlacesWeight={updatePlacesWeight}
            />
          ) : null}
        </>
      )}
    </div>
  )
}

export default MainBlock
