import React, { useEffect, useState } from 'react'

import styles from './choice-manual-package-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconClose } from '@consta/uikit/IconClose'

import { EnterHandler, Modal, SelectList } from 'src/components'

import {
  IOrderPackagingOption,
  ModalProps,
  ISelectListOption,
  IDimensions,
} from 'src/interfaces'

interface ChoiceManualPackageProps extends ModalProps {
  packagingOptions: IOrderPackagingOption[]
  selected: IOrderPackagingOption | null
  onBack: () => void
  onSelect: (option: IOrderPackagingOption | null) => void
  onSubmit: (packageId: string, options: { dimensions?: IDimensions }) => void
}

const ChoiceManualPackageModal = (props: ChoiceManualPackageProps) => {
  const { isOpen, packagingOptions, selected, onBack, onSelect, onSubmit } =
    props

  const [error, setError] = useState<string>('')

  const handleSelect = (option: ISelectListOption) => {
    onSelect(
      packagingOptions.find(
        (o: IOrderPackagingOption) => o.id === option.value,
      ) || null,
    )
  }

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected.id, {})
    }
  }

  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.manualPackage}
        title="Выберите упаковку"
        size="s"
      >
        <div className={styles.selectWrap}>
          <SelectList
            value={
              selected ? { label: selected.title, value: selected.id } : null
            }
            options={packagingOptions.map((o: IOrderPackagingOption) => ({
              label: o.title,
              value: o.id,
            }))}
            onChange={handleSelect}
            className={styles.wrap}
          />
        </div>
        <div className={styles.btns}>
          <Button
            label="Назад"
            view="ghost"
            className={styles.btn}
            onClick={onBack}
          />
          <Button
            label="Выбрать"
            disabled={!selected}
            className={styles.btn}
            onClick={handleSubmit}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ChoiceManualPackageModal
