import React, { useEffect, useState } from 'react'

import styles from './item-scan-serial-modal.module.scss'

import { TextFieldPropValue } from '@consta/uikit/TextField'

import { Modal, ScanSerialInput, ItemPreview } from 'src/components'

import { useAcceptanceContext } from 'src/context'

import { useNotifications } from 'src/notification'

import { ModalProps, ISerialNumber, ISupplyOriginalItem } from 'src/interfaces'

interface ItemScanSerialModalProps extends ModalProps {
  multiple: boolean
  item: ISupplyOriginalItem | null
  cacheItems: ISupplyOriginalItem[] | null
  onSubmit: (items: ISupplyOriginalItem[]) => void
}

const ItemScanSerialModal = (props: ItemScanSerialModalProps) => {
  const { multiple, isOpen, onClose, item, cacheItems, onSubmit } = props

  const notification = useNotifications()

  const { savedChz, setSavedChz } = useAcceptanceContext()

  const [active, setActive] = useState<number>(0)
  const [newItems, setNewItems] = useState<ISupplyOriginalItem[]>([])

  useEffect(() => {
    if (active && savedChz) {
      handleChangeValue(savedChz, 'chestniy_znak', 0)
    }
  }, [active])

  const handleChangeValue = (
    val: TextFieldPropValue,
    code: string,
    scanIndex: number,
  ) => {
    if (!val) return
    if (val && /[а-яА-Я]/i.test(val)) {
      notification?.show(
        'alert',
        `Обнаружена кириллица в серийном номере. Измените раскладку клавиатуры.`,
      )
      return false
    }

    const newScannedSerialNumber = {
      code,
      value: String(val),
    }

    let actualItems: ISupplyOriginalItem[] = []

    if (newItems[active]) {
      actualItems = [...newItems].map(
        (item: ISupplyOriginalItem, i: number) => ({
          ...item,
          quantity: 1,
          scanned_serial_numbers:
            active === i
              ? [
                  ...(item?.scanned_serial_numbers ?? []),
                  { ...newScannedSerialNumber },
                ]
              : item.scanned_serial_numbers,
        }),
      )
    } else {
      if (item) {
        actualItems = [
          ...newItems,
          {
            ...item,
            quantity: 1,
            scanned_serial_numbers: [{ ...newScannedSerialNumber }],
          },
        ]
      }
    }

    setNewItems(actualItems)

    if ((item?.scan_serial_numbers?.length ?? -1) - 1 === scanIndex) {
      if (multiple && active + 1 !== item?.quantity) {
        setActive(active + 1)
      } else {
        if (savedChz) {
          setSavedChz(null)
        }
        onSubmit(actualItems)
      }
    }
  }

  const getStatus = (active: number, scan: ISerialNumber) => {
    const val = newItems[active]?.scanned_serial_numbers?.find(
      (ser) => ser?.code === scan.code,
    )?.value
    if (val) {
      return 'done'
    }

    const findItemIndex =
      item?.scan_serial_numbers.findIndex((item) => item.code === scan.code) ??
      -1

    if (findItemIndex > -1) {
      const prevScanCode = item?.scan_serial_numbers[findItemIndex - 1]?.code
      const prevScanVal = newItems[active]?.scanned_serial_numbers?.find(
        (ser) => ser.code === prevScanCode,
      )?.value
      if (findItemIndex > 0 && !prevScanVal) {
        return 'disabled'
      }
    }
    return 'undone'
  }

  const getValue = (scan: ISerialNumber) => {
    return newItems[active]?.scanned_serial_numbers?.find(
      (ser) => ser?.code === scan.code,
    )?.value
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.itemCount}
      size="m"
      withClose
      onClose={onClose}
    >
      <ItemPreview
        image={item?.image ?? ''}
        title={item?.title ?? ''}
        batch={item?.batch}
        barcode={
          item?.barcodes?.find((barcode) => barcode.pack_id === item?.pack_id)
            ?.barcode ?? ''
        }
      />

      {multiple && (item?.quantity ?? 0 > 1) ? (
        <div className={styles.count}>
          {active + 1} из {item?.quantity}
        </div>
      ) : null}

      <div className={styles.wrap}>
        {item?.scan_serial_numbers.map((scan: ISerialNumber, i: number) => (
          <ScanSerialInput
            key={`${i}-${active}`}
            value={getValue(scan) ?? ''}
            onChange={(val: TextFieldPropValue) =>
              handleChangeValue(val, scan.code, i)
            }
            status={getStatus(active, scan)}
            code={scan.code}
            label={`Введите ${scan.title}`}
            template={scan.template}
          />
        ))}
      </div>
    </Modal>
  )
}

export default ItemScanSerialModal
