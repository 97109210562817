import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_CREATE_BATCH } from 'src/mocks'

import { IBatch, useCreateBatchResult } from 'src/interfaces'

interface useCreateBatchParams {
  itemId: string
}

const useCreateBatch = (params: useCreateBatchParams) => {
  return useMutation(async (data: { batch: IBatch }) => {
    try {
      const result = await provideMock<
        useCreateBatchParams,
        { data: useCreateBatchResult }
      >(
        async () => await api.post(`/items/${params.itemId}/batches`, data),
        params,
        {
          key: 'itemId',
          value: 'mockItem',
          result: MOCK_CREATE_BATCH,
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result?.data
    } catch (e) {
      throw e
    }
  })
}

export default useCreateBatch
