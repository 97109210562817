import React, { useEffect } from 'react'

import styles from './promt-modal.module.scss'

import img from './assets/danger.svg'

import { Button } from '@consta/uikit/Button'

import { Modal } from 'src/components'

import { ModalProps } from 'src/interfaces'

interface IPromtModalProps extends ModalProps {
  title?: string
  message: string
  handleConfirm: () => void
}

const PromtModal = (props: IPromtModalProps) => {
  const {
    isOpen,
    onClose,
    onMount,
    handleConfirm,
    title = 'Подтверждение',
    message,
  } = props

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.check}
      title={title}
      subtitle={message}
      img={img}
      imgClass={styles.img}
      size="s"
    >
      <div className={styles.btns}>
        <Button
          label="Отмена"
          view="ghost"
          onClick={() => onClose()}
          size="m"
        />
        <Button
          label="Подтвердить"
          view="primary"
          className={styles.primary}
          onClick={handleConfirm}
          size="m"
        />
      </div>
    </Modal>
  )
}

export default PromtModal
