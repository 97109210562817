import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './settings-page.module.scss'

import { Tabs } from '@consta/uikit/Tabs'
import { IconClose } from '@consta/uikit/IconClose'

import PrintTuningSetting from './PrintTuningSetting'

import { SETTINGS_TABS, SETTINGS_TAB, PATHS } from 'src/config'

const SettingsComponents: { [key: string]: React.FC } = {
  printSettings: PrintTuningSetting,
}

const TabComponent = ({ type }: { type: string }) => {
  let Component: any = SettingsComponents[type]

  return <Component />
}

const SettingsPage = () => {
  const history = useHistory()

  const [tab, setTab] = useState<SETTINGS_TAB>(SETTINGS_TABS[0])

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.top}>
            <h2>Настройки</h2>
            <IconClose
              className={styles.close}
              onClick={() => history.push(PATHS.ORDER_SCAN)}
            />
          </div>
          <div className={styles.mainBlock}>
            <Tabs
              value={tab}
              onChange={({ value }) => setTab(value)}
              items={SETTINGS_TABS}
              getLabel={(tab) => tab.label}
              view="clear"
              //linePosition="right"
              className={styles.tabs}
            />
            <div className={styles.body}>
              <TabComponent type={tab.value} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsPage
