import React, { useEffect, useState } from 'react'

import styles from './acceptance-filter.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconSearch } from '@consta/uikit/IconSearch'
import { IconCalendar } from '@consta/uikit/IconCalendar'

import { CalendarInput, ComboboxWrapper, EnterHandler } from 'src/components'

import {
  IContragent,
  ICombobox,
  ComboboxItem,
  IAcceptanceFilterDate,
  IOptionsLoading,
} from 'src/interfaces'

const statuses: ICombobox[] = [
  {
    value: 'entered',
    label: 'Новая',
    id: 'entered',
  },
  {
    value: 'arrived',
    label: 'Поступилa на склад',
    id: 'arrived',
  },
  {
    value: 'processing',
    label: 'В процессе приемки',
    id: 'processing',
  },
  {
    value: 'registration',
    label: 'Оформление документов',
    id: 'registration',
  },
  {
    value: 'done',
    label: 'Завершена',
    id: 'done',
  },
]

interface IAcceptanceFilterProps {
  date: IAcceptanceFilterDate
  setDate: (value: IAcceptanceFilterDate) => void
  selectedContragents: ComboboxItem | null
  setSelectedContragents: (value: ComboboxItem | null) => void
  selectedSuppliers: ComboboxItem | null
  setSelectedSuppliers: (value: ComboboxItem | null) => void
  selectedWarehouses: ComboboxItem | null
  setSelectedWarehouses: (value: ComboboxItem | null) => void
  selectedStatuses: ComboboxItem | null
  setSelectedStatuses: (value: ComboboxItem | null) => void
  suppliers: ICombobox[] | []
  warehouses: ICombobox[] | []
  contragents: IContragent[] | []
  handleSearchSettings: (type: string, value: string) => void
  handleSubmit: () => void
  handleClear: () => void
  loading: boolean
  optionsLoading: IOptionsLoading
}

const AcceptanceFilter = ({
  date,
  setDate,
  selectedContragents,
  setSelectedContragents,
  selectedSuppliers,
  setSelectedSuppliers,
  selectedWarehouses,
  setSelectedWarehouses,
  selectedStatuses,
  setSelectedStatuses,
  suppliers,
  warehouses,
  contragents,
  handleSearchSettings,
  handleSubmit,
  handleClear,
  loading,
  optionsLoading,
}: IAcceptanceFilterProps) => {
  const handleSearchSelect = (
    type: string,
    item: ComboboxItem,
    searchValue: string,
  ): boolean => {
    handleSearchSettings(type, searchValue)
    return true
  }

  const noOptionsArr: ICombobox[] = [
    {
      label: 'Пока тут пусто. Введите для поиска',
      value: '',
      id: 1,
      disabled: true,
    },
  ]

  return (
    <EnterHandler onEnter={handleSubmit}>
      <div className={styles.filter}>
        <div>
          <div className={styles.date}>
            <div className={styles.dateWrap}>
              <CalendarInput
                label="Дата поставки"
                id={'dateFrom'}
                placeholder={'C'}
                handleChange={(value) => setDate({ ...date, from: value })}
                value={date.from}
                size="l"
                className={styles.dateInput}
                rightSide={IconCalendar}
              />
              <CalendarInput
                id={'dateTo'}
                placeholder={'По'}
                handleChange={(value) => setDate({ ...date, to: value })}
                value={date.to}
                size="l"
                className={styles.dateInput}
                rightSide={IconCalendar}
              />
            </div>
          </div>
          <div className={styles.combobox}>
            <ComboboxWrapper
              id="contragents"
              label="Контрагент или коммерческое наименование"
              items={contragents?.length ? contragents : noOptionsArr}
              value={selectedContragents}
              placeholder="Выберите контрагента"
              onChange={({ value }) => setSelectedContragents(value)}
              size="l"
              searchFunction={(...args) =>
                handleSearchSelect('contragents', ...args)
              }
              loading={optionsLoading.contragents}
            />
          </div>
        </div>
        <div>
          <div className={styles.combobox}>
            <ComboboxWrapper
              id="suppliers"
              label="Поставщик"
              items={suppliers?.length ? suppliers : noOptionsArr}
              value={selectedSuppliers}
              placeholder="Выберите поставщика"
              onChange={({ value }) => setSelectedSuppliers(value)}
              size="l"
              searchFunction={(...args) =>
                handleSearchSelect('suppliers', ...args)
              }
              loading={optionsLoading.suppliers}
            />
          </div>
          <div className={styles.combobox}>
            <ComboboxWrapper
              id="warehouses"
              label="Склад"
              items={warehouses?.length ? warehouses : noOptionsArr}
              value={selectedWarehouses}
              placeholder="Выберите склад"
              onChange={({ value }) => setSelectedWarehouses(value)}
              size="l"
              searchFunction={(...args) =>
                handleSearchSelect('warehouses', ...args)
              }
              loading={optionsLoading.warehouses}
            />
          </div>
          <div className={styles.combobox}>
            <ComboboxWrapper
              label="Статус заявки"
              items={statuses}
              value={selectedStatuses}
              placeholder="Выберите статус заявки"
              onChange={({ value }) => setSelectedStatuses(value)}
              isMultiple
              size="l"
            />
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            iconLeft={IconSearch}
            label="Искать"
            onClick={handleSubmit}
            loading={loading}
          />
          <Button label="Сбросить все" view="clear" onClick={handleClear} />
        </div>
      </div>
    </EnterHandler>
  )
}

export default AcceptanceFilter
