import React, { useEffect, useState } from 'react'

import styles from '../b2b-packing-order.module.scss'

import { IconWarning } from '@consta/uikit/IconWarning'

import { usePackingOrderContext } from '../../context'

import { useNotifications } from 'src/notification'
import { IOrderPackagingPlace } from '../../../../interfaces/order/order'

interface IUseManageMaxWeightRes {
  commonPlaceWeight: number
  changeWeight: (weight: number, type: 'place' | 'pallet') => void
  clearWeight: (type: 'place' | 'pallet') => void
  checkWeight: (weight: number, type: 'place' | 'pallet') => boolean
  addWeightToCache: (itemId: string, weight: number) => void
  cachedWeights: { [key: string]: number }
  updatePlacesWeight: (place: IOrderPackagingPlace) => void
}

const useManageMaxWeight = () => {
  const [commonPlaceWeight, setCommonPlaceWeight] = useState<number>(0)
  const [commonPalletWeight, setCommonPalletWeight] = useState<number>(0)
  const [cachedWeights, setCachedWeights] = useState<{ [key: string]: number }>(
    {},
  )

  const { order } = usePackingOrderContext()

  const maxPlaceWeight = order?.delivery?.maximum_place_weight ?? null
  const maxPalletWeight = order?.delivery?.maximum_pallet_weight ?? null

  const notification = useNotifications()

  const changeWeight = (
    weight: number,
    type: 'place' | 'pallet' = 'place',
  ): void => {
    if (type === 'pallet') {
      setCommonPalletWeight((state) => state + weight)
      return
    }
    setCommonPlaceWeight((state) => state + weight)
  }

  const addWeightToCache = (itemId: string, weight: number): void => {
    setCachedWeights({ ...cachedWeights, [itemId]: weight })
  }

  const clearWeight = (type: 'place' | 'pallet' = 'place'): void => {
    if (type === 'pallet') {
      setCommonPalletWeight(0)
      return
    }
    setCommonPlaceWeight(0)
  }

  const updatePlacesWeight = (place: IOrderPackagingPlace) => {
    const weight = place.items.reduce((acc, item) => {
      return acc + +(item?.weight ?? 0) * item.quantity
    }, 0)
    setCommonPlaceWeight(weight)
  }

  const checkWeight = (
    weight: number,
    type: 'place' | 'pallet' = 'place',
  ): boolean => {
    if (type === 'place') {
      if (!maxPlaceWeight) return true

      if (maxPlaceWeight < commonPlaceWeight + weight) {
        notification?.show(
          'alert',
          <div className={styles.informer}>
            <h3>Ошибка сканирования</h3>
            <p>
              Вес места с текущим товаром превышает максимально допустимый вес
              места.
            </p>
          </div>,
          {
            icon: IconWarning,
          },
        )
        return false
      }
      return true
    }

    if (type === 'pallet') {
      if (!maxPalletWeight) return true

      if (maxPalletWeight < commonPalletWeight + weight) {
        notification?.show(
          'alert',
          <div className={styles.informer}>
            <h3>Ошибка сканирования</h3>
            <p>
              Вес паллеты с текущим местом превышает максимально допустимый вес
              паллеты.
            </p>
          </div>,
          {
            icon: IconWarning,
          },
        )
        return false
      }
      return true
    }

    return true
  }

  useEffect(() => {
    console.log('commonPalletWeight')
    console.log(commonPalletWeight)
  }, [commonPalletWeight])

  useEffect(() => {
    console.log('commonPlaceWeight')
    console.log(commonPlaceWeight)
  }, [commonPlaceWeight])

  return {
    commonPlaceWeight,
    changeWeight,
    clearWeight,
    checkWeight,
    cachedWeights,
    addWeightToCache,
    updatePlacesWeight,
  } as IUseManageMaxWeightRes
}

export default useManageMaxWeight
