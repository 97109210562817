import React, { useEffect } from 'react'

import styles from './kit-modal.module.scss'

import UnPackTable from '../UnPackTable'

import { Modal, ItemScanner, Img } from 'src/components'

import { ModalProps, IOrderItem } from 'src/interfaces'

interface KitModalProps extends ModalProps {
  kitData: {
    kit_id?: number
    kit_title?: string
  }
  items: IOrderItem[] | null
  onStartItem: (value: string | null) => void
  itemCountCheckboxValue: boolean
  onItemCountCheckboxChange: (value: boolean) => void
}

const KitModal = (props: KitModalProps) => {
  const {
    isOpen,
    kitData,
    items,
    onStartItem,
    itemCountCheckboxValue,
    onItemCountCheckboxChange,
    onClose,
    onMount,
  } = props

  const filteredItems =
    items?.filter((item: IOrderItem) => item?.kit_id === kitData?.kit_id) ?? []

  useEffect(() => {
    if (!filteredItems.length) {
      onClose()
    }
  }, [filteredItems])

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      title="Отсканируйте остальные товары набора"
      titleAlign="left"
      size="l"
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <ItemScanner
            className={styles.itemScanner}
            advanceModeForCount
            itemCountCheckboxValue={itemCountCheckboxValue}
            onChange={onStartItem}
            onItemCountCheckboxChange={onItemCountCheckboxChange}
            label="Отсканируйте штрихкод товара"
          />
          <div className={styles.info}>
            <div className={styles.titles}>
              <div className={styles.ghost}>Наименование набора</div>
              <h3>{kitData.kit_title}</h3>
            </div>
            <Img
              className={styles.img}
              src={undefined} //TODO real img
              yandex
            />
          </div>
        </div>

        <UnPackTable items={filteredItems} />
      </div>
    </Modal>
  )
}

export default KitModal
