import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import useDynamicRefs from 'use-dynamic-refs'

import styles from './accepated-table-item.module.scss'

import { Popover } from '@consta/uikit/Popover'
import { Badge } from '@consta/uikit/Badge'
import { IconRemove } from '@consta/uikit/IconRemove'

import { Img } from 'src/components'

import {
  IPackType,
  ISupplyPartItem,
  ISupplyItem,
  ISupplyOriginalItem,
  IBatch,
} from 'src/interfaces'

interface ITareItemProps {
  item: ISupplyPartItem
  index: number
  originalItem: ISupplyOriginalItem | null
  defection: boolean
  handleReducedQuantity: (id: string, packId: string, batchId?: string) => void
  status?: string
  planItem: ISupplyItem | null
}

const getBatchData = (
  item: ISupplyPartItem | null,
  originalItem: ISupplyOriginalItem | null,
) => {
  if (!item || !originalItem) return null

  const currentBatch = originalItem?.batches?.find(
    (batch: IBatch) => batch.id === item.batch_id,
  )

  if (currentBatch) {
    return (
      <>
        {currentBatch?.exp_date ? (
          <div className={styles.withExp}>
            <div className={styles.exp}>
              {dayjs(currentBatch?.exp_date).format('DD.MM.YYYY')}
            </div>
            <div>{currentBatch?.num}</div>
          </div>
        ) : (
          <div className={styles.withoutExp}>
            <div>{currentBatch?.num}</div>
          </div>
        )}
      </>
    )
  }

  return null
}

const TareItem = ({
  item,
  originalItem,
  defection,
  handleReducedQuantity,
  status,
  planItem,
}: ITareItemProps) => {
  const [showTip, setShowTip] = useState<string[]>([])
  const [getRef, setRef] = useDynamicRefs()

  const [surplusItems, setSurplusItems] = useState<ISupplyPartItem[] | null>(
    null,
  )
  const [ordinaryItems, setOrdinaryItems] = useState<ISupplyPartItem[] | null>(
    null,
  )

  const getNewItemsByBatches = (
    batchesQuantity: { [key: string]: number },
    availableItems: number,
  ) => {
    let copyAvailableItems = availableItems > 0 ? availableItems : 0
    let ordinaryItems: ISupplyPartItem[] = []
    let surplusItems: ISupplyPartItem[] = []

    const batchArr = Object.keys(batchesQuantity).reverse()

    console.log(batchesQuantity)

    batchArr.forEach((batch) => {
      if (batchesQuantity[batch] <= copyAvailableItems) {
        ordinaryItems.push({
          ...item,
          batch_id: batch,
          quantity: batchesQuantity[batch],
        })
        copyAvailableItems = copyAvailableItems - batchesQuantity[batch]
        return
      }
      if (batchesQuantity[batch] > copyAvailableItems && copyAvailableItems) {
        ordinaryItems.push({
          ...item,
          batch_id: batch,
          quantity: copyAvailableItems,
        })
        batchesQuantity = {
          ...batchesQuantity,
          [batch]: batchesQuantity[batch] - copyAvailableItems,
        }
        surplusItems.push({
          ...item,
          batch_id: batch,
          quantity: batchesQuantity[batch],
        })

        copyAvailableItems = 0
        return
      }

      surplusItems.push({
        ...item,
        batch_id: batch,
        quantity: batchesQuantity[batch],
      })
    })

    return [ordinaryItems.reverse(), surplusItems.reverse()]
  }

  const getFormattedItem = (item: ISupplyPartItem) => {
    setSurplusItems(null)
    setOrdinaryItems(null)

    const planQuantity = planItem?.quantity ?? 0
    const curItemQuantity = item?.quantity ?? 0
    const allItemQuantityPrevTars = item.allItemQuantityPrevTars ?? 0
    const availableItems = planQuantity - allItemQuantityPrevTars

    if (item?.batchesQuantity) {
      const [ordinaryItems, surplusItems] = getNewItemsByBatches(
        item.batchesQuantity,
        availableItems,
      )

      setOrdinaryItems(ordinaryItems)
      setSurplusItems(surplusItems)
      return
    }

    if (availableItems >= curItemQuantity) {
      setOrdinaryItems([item])
      return
    }
    if (availableItems < curItemQuantity && availableItems > 0) {
      setOrdinaryItems([{ ...item, quantity: availableItems }])
      setSurplusItems([{ ...item, quantity: curItemQuantity - availableItems }])
      return
    }
    if (availableItems < curItemQuantity && availableItems <= 0) {
      setSurplusItems([{ ...item, quantity: curItemQuantity }])
    }
  }

  useEffect(() => {
    getFormattedItem(item)
  }, [item])

  if (!originalItem) return null

  const getPack = (item: ISupplyPartItem | null) => {
    if (!item) return

    const pack = originalItem.packs.find(
      (p: IPackType) => p.id === item.pack_id,
    )

    const handleShowTip = (val: string) => {
      if (showTip.includes(val)) {
        const newTips = showTip.filter((id) => id !== val)
        setShowTip(newTips)
      } else {
        setShowTip([...showTip, val])
      }
    }

    if (pack && pack?.quantity === 1) {
      return (
        <div className={styles.pack}>
          <span>{pack.title}</span>
        </div>
      )
    }
    if (pack && pack?.quantity > 1) {
      return (
        <div className={cx(styles.pack, styles.largePack)}>
          <span
            onClick={() => handleShowTip(item.id)}
            // @ts-ignore
            ref={setRef(item.id)}
          >
            упк
          </span>
          {showTip.includes(item.id) ? (
            <Popover
              onClickOutside={() => handleShowTip(item.id)}
              offset={25}
              direction="downLeft"
              // @ts-ignore
              anchorRef={getRef(item.id)}
            >
              <div className={styles.Popover}>{pack.title}</div>
            </Popover>
          ) : null}
        </div>
      )
    }
  }

  const getBarcode = (
    originalItem: ISupplyOriginalItem,
    item: ISupplyPartItem,
  ) => {
    return (
      originalItem.barcodes.find((barcode) => barcode.pack_id === item.pack_id)
        ?.barcode || ''
    )
  }

  return (
    <>
      {surplusItems ? (
        <>
          {surplusItems.map((surplusItem) => (
            <div className={styles.tableTr}>
              <div>
                <Img src={originalItem.image} size={100} yandex modal />
              </div>
              <div className={styles.name}>
                {originalItem?.title}{' '}
                <span>{getBarcode(originalItem, item)}</span>
              </div>
              <div className={styles.defectiveItem}>
                {defection ? (
                  <Badge label="Брак" view="stroked" status="error" size="m" />
                ) : null}
              </div>
              <div className={styles.surplus}>
                <Badge label="Излишки" status="error" size="s" />
              </div>
              <div className={styles.surplusQuantity}>
                {status === 'open' ? (
                  <div
                    className={styles.remove}
                    onClick={() =>
                      handleReducedQuantity(
                        item.id,
                        item.pack_id,
                        surplusItem.batch_id,
                      )
                    }
                  >
                    <IconRemove view="brand" size="s" />
                  </div>
                ) : null}
                {surplusItem.quantity}
              </div>
              <div>{getPack(item)}</div>
              <div className={styles.batches}>
                {getBatchData(surplusItem, originalItem)}
              </div>
            </div>
          ))}
        </>
      ) : null}
      {ordinaryItems ? (
        <>
          {ordinaryItems.map((ordinaryItem) => (
            <div className={styles.tableTr}>
              <div>
                <Img src={originalItem.image} size={100} yandex modal />
              </div>
              <div className={styles.name}>
                {originalItem?.title}{' '}
                <span>{getBarcode(originalItem, item)}</span>
              </div>
              <div className={styles.defectiveItem}>
                {defection ? (
                  <Badge label="Брак" view="stroked" status="error" size="m" />
                ) : null}
              </div>
              <div className={styles.surplus}></div>
              <div>
                {status === 'open' ? (
                  <div
                    className={styles.remove}
                    onClick={() =>
                      handleReducedQuantity(
                        item.id,
                        item.pack_id,
                        ordinaryItem.batch_id,
                      )
                    }
                  >
                    <IconRemove view="brand" size="s" />
                  </div>
                ) : null}
                {ordinaryItem.quantity}
              </div>
              <div>{getPack(item)}</div>
              <div className={styles.batches}>
                {getBatchData(ordinaryItem, originalItem)}
              </div>
            </div>
          ))}
        </>
      ) : null}
    </>
  )
}

export default TareItem
