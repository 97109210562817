import React from 'react'
import cx from 'classnames'

import styles from './marking-table.module.scss'

import palletImg from './assets/pallet_avatar.svg'

import { Button } from '@consta/uikit/Button'

import { Table } from 'src/components'

import { IOrderPackedMarkedPallet } from 'src/interfaces'

interface PalletMarkingTableProps {
  title?: string | React.ReactNode
  withStickerPrint?: boolean
  className?: string
  items: IOrderPackedMarkedPallet[]
  handlePrint?: (itemId: string) => void
}

const PalletMarkingTable = (props: PalletMarkingTableProps) => {
  const {
    title,
    withStickerPrint,
    className,
    items,
    handlePrint = () => false,
  } = props

  if (!items) return null

  return (
    <div className={cx(styles.BoxTable, className)}>
      {items.length && title ? <h4>{title}</h4> : null}

      <div
        className={cx(
          styles.tableWrap,
          withStickerPrint ? styles.tableBlue : null,
        )}
      >
        <Table stickyHeader withoutHead={true} striped>
          {items.map((item, i) => (
            <Table.Row key={item.id}>
              <Table.Column title="" className={styles.tdImg}>
                <div className={styles.left}>
                  <img className={styles.img} src={palletImg} alt="" />
                  <div>
                    <h6>Паллета {item.pallet_num}</h6>
                    <span className={styles.barcode}>
                      {item.newBarcode ?? item.id}
                    </span>
                  </div>
                </div>
              </Table.Column>
              <Table.Column title="" align="right">
                {withStickerPrint ? (
                  <Button
                    label="Повторить печать"
                    view="secondary"
                    size="s"
                    onClick={() =>
                      handlePrint(
                        item.temp_id ? item.id ?? '' : item.newBarcode ?? '',
                      )
                    }
                  />
                ) : null}
              </Table.Column>
            </Table.Row>
          ))}
        </Table>
      </div>
    </div>
  )
}

export default PalletMarkingTable
