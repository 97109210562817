import { useMutation } from 'react-query'

import api from 'src/api'

import { IOrderPallet, IOrderReqPallet } from 'src/interfaces'

import { MOCK_STICKER_6x6 } from 'src/mocks'
let temp = 0
const useUpdatePackingPallet = () => {
  return useMutation(
    async (data: { pallet: IOrderReqPallet; palletId?: string }) => {
      console.log(data.palletId)
      try {
        const result = data.palletId
          ? await api.post(`/packing/pallet/${data.palletId}`, {
              pallet: data.pallet,
            })
          : await api.post(`/packing/pallet`, { pallet: data.pallet })

        if (!result?.data?.success) {
          throw result
        }
        return result.data
      } catch (e) {
        throw e
      }

      // return {
      //   success: true,
      //   pallet_id: 'mockPallet2',
      //   pallet_num: 2,
      //   sticker: {
      //     content: MOCK_STICKER_6x6,
      //     printer: 'Microsoft Print to PDF',
      //   },
      // }
      // console.log(data)
      // console.log(temp)
      // if (temp === 1) {
      //   temp = temp + 1
      //   throw new Error('error')
      // } else {
      //   temp = temp + 1
      //   return {
      //     success: true,
      //     pallet_id: 'mock',
      //     pallet_num: 1,
      //     sticker: {
      //       content: MOCK_STICKER_6x6,
      //       printer: 'Microsoft Print to PDF',
      //     },
      //   }
      // }
      // console.log(data.palletId)
      // if (data.palletId === 'mockPallet1') {
      //   return {
      //     success: true,
      //     pallet_id: 'mock',
      //     pallet_num: 1,
      //     sticker: {
      //       content: MOCK_STICKER_6x6,
      //       printer: 'Microsoft Print to PDF',
      //     },
      //   }
      // } else {
      //   return {
      //     success: true,
      //     pallet_id: 'mockPallet2',
      //     pallet_num: 2,
      //     sticker: {
      //       content: MOCK_STICKER_6x6,
      //       printer: 'Microsoft Print to PDF',
      //     },
      //   }
      // }
    },
  )
}

export default useUpdatePackingPallet
