import React, { useEffect, useState } from 'react'

import styles from './weigth-dimensions-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import {
  Modal,
  InputWrapper,
  DimensionsInput,
  EnterHandler,
} from 'src/components'

import { usePackingOrderContext } from '../../../context'

import { ModalProps, IDimensions, IOrderPallet } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface IWeightDimensionsPalletModal extends ModalProps {
  pallet: IOrderPallet
  hardClose?: boolean
  onSubmit: (pallet: IOrderPallet) => void
  title?: string
  subtitle?: string
  img?: string
  withClose?: boolean
}

const WeightDimensionsPalletModal = (props: IWeightDimensionsPalletModal) => {
  const {
    pallet,
    isOpen,
    onClose,
    onSubmit,
    onMount,
    title,
    subtitle,
    img,
    withClose,
  } = props

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  const [weight, setWeight] = useState<string | null>(null)
  const [dimensions, setDimensions] = useState<IDimensions>({
    width: null,
    height: null,
    depth: null,
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>()

  const { order } = usePackingOrderContext()

  const maxPalletDimensions = order?.delivery?.maximum_pallet_dimensions
  const maxPalletWeight = order?.delivery?.maximum_pallet_weight

  const handleSubmit = () => {
    if (!checkDisabled()) {
      return false
    }

    let valid = true

    if (maxPalletDimensions && !isValidDimensions()) {
      valid = false
    }

    if (maxPalletWeight) {
      if (weight && +weight * 1000 > maxPalletWeight) {
        setErrors((state) => ({
          ...state,
          weight: 'Превышает допустимые значения',
        }))
        valid = false
      }
    }

    if (!valid) {
      return false
    }

    const newWeight = +Number(weight).toFixed(3) * 1000

    const newPallet: IOrderPallet = {
      ...pallet,
      weight: newWeight,
      dimensions: {
        width: Number(dimensions?.width) * 10,
        height: Number(dimensions?.height) * 10,
        depth: Number(dimensions?.depth) * 10,
      },
    }
    onSubmit(newPallet)
  }

  const checkDisabled = () => {
    return (
      Number(weight) &&
      Number(dimensions.width) &&
      Number(dimensions.height) &&
      Number(dimensions.depth)
    )
  }

  const isValidDimensions = () => {
    if (!maxPalletDimensions) return true

    const valid: { [key: string]: string } = {}

    if (
      maxPalletDimensions.width &&
      maxPalletDimensions.width / 10 < Number(dimensions.width)
    ) {
      valid.width = `Превышает допустимые значения (${
        maxPalletDimensions.width / 10
      })`
    }

    if (
      maxPalletDimensions.height &&
      maxPalletDimensions.height / 10 < Number(dimensions.height)
    ) {
      valid.height = `Превышает допустимые значения (${
        maxPalletDimensions.height / 10
      })`
    }

    if (
      maxPalletDimensions.depth &&
      maxPalletDimensions.depth / 10 < Number(dimensions.depth)
    ) {
      valid.depth = `Превышает допустимые значения (${
        maxPalletDimensions.depth / 10
      })`
    }

    if (Object.keys(valid).length) {
      setErrors(valid)
      return false
    }

    return true
  }

  const handleChangeDimensions = (d: IDimensions, field: string) => {
    setDimensions(d)

    const errObj = { ...errors }
    delete errObj[field]
    setErrors(errObj)
  }

  const handleChangeWeight = (value: TextFieldPropValue) => {
    setWeight(value)

    const errObj = { ...errors }
    delete errObj.weight
    setErrors(errObj)
  }

  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.itemCount}
        withClose={withClose}
        onClose={onClose}
        title={title}
        subtitle={subtitle}
        img={img}
        imgClass={styles.palletImg}
        size="s"
      >
        <DimensionsInput
          value={dimensions}
          onChange={handleChangeDimensions}
          integerNumbersInputs
          autoFocus
          errors={errors}
        />

        <InputWrapper
          label={'Введите вес'}
          value={weight}
          id={'weight'}
          handleChange={handleChangeWeight}
          className={styles.itemInput}
          size="l"
          rightSide="кг"
          isNumber
          error={errors?.weight}
        />

        <div className={styles.btns}>
          <Button
            label="Подтвердить"
            className={styles.btn}
            onClick={handleSubmit}
            disabled={!checkDisabled()}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default WeightDimensionsPalletModal
