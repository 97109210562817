import React, { useState } from 'react'

import styles from './un-packed-table.module.scss'

import { Badge } from '@consta/uikit/Badge'
import { IconAdd } from '@consta/uikit/IconAdd'
import { File } from '@consta/uikit/File'

import { Table, Img } from 'src/components'

import { useAppContext } from 'src/context'
import { usePackingOrderContext } from '../../context'

import { hideSymbols } from 'src/helpers'

import { IOrderItem } from 'src/interfaces'

interface UnPackTableProps {
  title?: string
  items: IOrderItem[] | null
  handleChooseItem?: (item: IOrderItem) => void
}

const UnPackTable = (props: UnPackTableProps) => {
  const { title, items, handleChooseItem } = props
  const [tipsAdd, setTipsAdd] = useState<number[]>([])

  const { advanceMode } = useAppContext()
  const { order } = usePackingOrderContext()

  if (!items) return null

  return (
    <div className={styles.unPackTable}>
      {title ? <h4>{title}</h4> : null}

      <div className={styles.tableWrap}>
        <Table stickyHeader>
          {items.map((item: IOrderItem, i) => (
            <Table.Row key={item.id}>
              <Table.Column title="Фото" className={styles.tdImg}>
                {item.id === 'SDoc' ? (
                  <File className={styles.file} extension="pdf" />
                ) : (
                  <Img
                    className={styles.img}
                    src={item.image}
                    size={100}
                    yandex
                    modal
                  />
                )}
              </Table.Column>
              <Table.Column
                title={
                  <div>
                    Номенклатура <span className={styles.light}>Артикул</span>
                  </div>
                }
                className={styles.tdName}
              >
                {typeof item.kit_id !== 'undefined' ? (
                  <Badge
                    size="s"
                    view="stroked"
                    form="round"
                    label="часть набора"
                    className={styles.kitBadge}
                  />
                ) : null}
                {item.id === 'SDoc' ? (
                  <Badge
                    size="s"
                    view="stroked"
                    form="round"
                    label={'документы'}
                    className={styles.kitBadge}
                  />
                ) : null}
                <span className={styles.title}>{item.title}</span>
                <span className={styles.light}>{item.article}</span>
              </Table.Column>
              <Table.Column
                title="Кол-во"
                align="right"
                className={styles.quantity}
              >
                {item.quantity}
              </Table.Column>
              <Table.Column
                title="Штрихкод"
                align="right"
                className={styles.tdBarcode}
              >
                {hideSymbols(6, item?.barcodes?.[0]?.barcode)}
              </Table.Column>
              {handleChooseItem && advanceMode ? (
                <Table.Column title="" align="right" className={styles.addTd}>
                  <div className={styles.add}>
                    <IconAdd
                      className={styles.addIcon}
                      size="s"
                      view="brand"
                      onClick={() => handleChooseItem(item)}
                      onMouseOver={() => setTipsAdd([...tipsAdd, i])}
                      onMouseOut={() =>
                        setTipsAdd(tipsAdd.filter((tip) => tip !== i))
                      }
                    />
                    {tipsAdd.includes(i) ? (
                      <div className={styles.tip}>Выбрать данный товар</div>
                    ) : null}
                  </div>
                </Table.Column>
              ) : (
                <Table.Column title="" className={styles.addTd}></Table.Column>
              )}
            </Table.Row>
          ))}
        </Table>
      </div>
    </div>
  )
}

export default UnPackTable
