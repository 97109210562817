import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_UPDATE_PACKING_PLACE } from 'src/mocks'

import { IOrderPackagingPlace, IUpdatePackingPlaceResult } from 'src/interfaces'

interface UsePackingPlaceParamsType {
  orderId: string
}

const useUpdatePackingPlace = (params: UsePackingPlaceParamsType) => {
  return useMutation(async (data: { place: IOrderPackagingPlace }) => {
    try {
      const result = await provideMock<
        UsePackingPlaceParamsType,
        { data: IUpdatePackingPlaceResult }
      >(
        async () =>
          await api.post(`/packing/order/${params.orderId}/place`, data),
        params,
        [
          {
            key: 'orderId',
            value: 'mock',
            result: MOCK_UPDATE_PACKING_PLACE,
          },
          {
            key: 'orderId',
            value: 'mockb2b',
            result: MOCK_UPDATE_PACKING_PLACE,
          },
        ],
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useUpdatePackingPlace
