import React, { useState, useEffect } from 'react'

import styles from './afk-notification.module.scss'

import { Timer } from '@consta/uikit/Timer'

import { appLocalStorage } from 'src/libs'

const TIMER_SECONDS = 60

interface AfkNotificationProps {
  onReset: () => void
  setAuthorized: (value: boolean) => void
}

const AfkNotification = (props: AfkNotificationProps) => {
  const { onReset, setAuthorized } = props

  const [timerSeconds, setTimeSeconds] = useState(TIMER_SECONDS)

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newValue = timerSeconds - 1
      if (newValue > 1) {
        setTimeSeconds(newValue)
      } else {
        //TODO global logout method
        window.clearNotification('afk')
        appLocalStorage.clear()
        setAuthorized(false)
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [timerSeconds])

  const handleReset = () => {
    onReset()
    window.clearNotification('afk')
  }

  return (
    <div className={styles.updateMessage}>
      <div className={styles.timer}>
        <Timer
          seconds={timerSeconds}
          progress={(timerSeconds / TIMER_SECONDS) * 100}
        />
      </div>

      {`Вы неактивны более 2 часов, сессия будет завершена`}
      <span className={styles.updateButton} onClick={handleReset}>
        Отменить
      </span>
    </div>
  )
}

export default AfkNotification
