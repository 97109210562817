import {
  OperationTypes,
  ShipmentTypes,
  RelabelingTypes,
  ShippingOptionsType,
} from 'src/interfaces'

export const MOCK_GET_PACKING_BY_ID = {
  success: true,
  // order: {
  //   "id": "mock",
  //   "operationType": "",
  //   "maxBoxesWithoutPallet": 2,
  //   "relabelingType":RelabelingTypes.BOXES_AND_PALLETS,
  //   "date": "2022-03-11T10:39:58",
  //   "packing_state": "packed",
  //   "global_shipping_guidelines": "",
  //   "order_shipping_comments": " Тест комментария к складу по-умолчанию (/api/lite/settings/warehouseComment)",
  //   "preferred_barcode_type": "WILDBERRIES",
  //   "require_place_weight": true,
  //   "shippingOptions": {
  //     "packingType":ShippingOptionsType.MIX_PACKINGS,
  //   },
  //   "shipping_form": [
  //     {
  //       "id": "shipment_barcode",
  //       "title": "ШК поставки",
  //       "value": "4447335677"
  //     },
  //     {
  //       "id": "shipment_num",
  //       "title": "Номер поставки МП",
  //       "value": "1566"
  //     },
  //     {
  //       "id": "shipment_warehouse_destination",
  //       "title": "Склад назначения",
  //       "value": "Склад назначения 1"
  //     },
  //     {
  //       "id": "transit_warehouse",
  //       "title": "Транзитный склад",
  //       "value": "Транзитный склад 1"
  //     },
  //     {
  //       "id": "shipment_date",
  //       "title": "Дата поставки",
  //       "value": "10.03.2022"
  //     },
  //     {
  //       "id": "shipment_supplier",
  //       "title": "Поставщик",
  //       "value": "Поставщик 1"
  //     },
  //     {
  //       "id": "instruction",
  //       "title": "Заголовок инструкции",
  //       "value": "Тело инструкции"
  //     }
  //   ],
  //   "delivery": {
  //     "logo": "https://storage.yandexcloud.net/lk-kak2c/delivery_wildberries_left.svg",
  //     "title": "Wildberries ФБО",
  //     "contract_title": "",
  //     "sorting_center_acceptance_date": "",
  //     "maximum_place_weight": 0,
  //     "maximum_places_count": 0
  //   },
  //   "items": [
  //     {
  //       "id": "KK1024",
  //       "title": "Компьютер (демо товар) 1",
  //       "article": "demo1024",
  //       "quantity": 5,
  //       "image": "https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea.jpg",
  //       "image_preview": "https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea-100.jpg",
  //       "require_weight": false,
  //       "require_dimensions": false,
  //       "barcodes": [
  //         {
  //           "barcode": "1222777",
  //           "type": ""
  //         },
  //         {
  //           "barcode": "12227778",
  //           "type": ""
  //         },
  //         {
  //           "barcode": "122277789",
  //           "type": ""
  //         },
  //         {
  //           "barcode": "314544121644",
  //           "type": ""
  //         },
  //         {
  //           "barcode": "200003332223332",
  //           "type": "COMMON"
  //         },
  //         {
  //           "barcode": "2000033322233327",
  //           "type": "COMMON"
  //         },
  //         {
  //           "barcode": "12223",
  //           "type": "WILDBERRIES"
  //         },
  //         {
  //           "barcode": "344343343445",
  //           "type": "WILDBERRIES"
  //         }
  //       ],
  //       "scan_serial_numbers": [
  //         {
  //           "code": "chestniy_znak",
  //           "title": "Честный знак",
  //           "template": "*"
  //         }
  //       ]
  //     },
  //     {
  //       "id": "KK1042",
  //       "title": "Ложка",
  //       "article": "loz",
  //       "quantity": 5,
  //       "image": "https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf.jpg",
  //       "image_preview": "https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf-100.jpg",
  //       "require_weight": false,
  //       "require_dimensions": false,
  //       "barcodes": [
  //         {
  //           "barcode": "1",
  //           "type": ""
  //         },
  //         {
  //           "barcode": "435345435435",
  //           "type": ""
  //         },
  //         {
  //           "barcode": "70100401010204",
  //           "type": ""
  //         },
  //         {
  //           "barcode": "70100401010204111",
  //           "type": "COMMON"
  //         },
  //         {
  //           "barcode": "701004010102043",
  //           "type": "COMMON"
  //         },
  //         {
  //           "barcode": "1",
  //           "type": "WILDBERRIES"
  //         },
  //         {
  //           "barcode": "75675656577677",
  //           "type": "WILDBERRIES"
  //         }
  //       ],
  //       "scan_serial_numbers": [
  //         {
  //           "code": "chestniy_znak",
  //           "title": "Честный знак",
  //           "template": "*"
  //         }
  //       ]
  //     },
  //     {
  //       "id": "KK1043",
  //       "title": "стакан",
  //       "article": "sta",
  //       "quantity": 5,
  //       "image": "",
  //       "image_preview": "",
  //       "require_weight": false,
  //       "require_dimensions": false,
  //       "barcodes": [
  //         {
  //           "barcode": "70100401010202",
  //           "type": "COMMON"
  //         },
  //         {
  //           "barcode": "70100401010203",
  //           "type": "WILDBERRIES"
  //         }
  //       ],
  //       "scan_serial_numbers": [
  //         {
  //           "code": "chestniy_znak",
  //           "title": "Честный знак",
  //           "template": "123"
  //         }
  //       ]
  //     }
  //   ],
  //   "places": [
  //     {
  //       "id": "WB_2718281994",
  //       "temp_id": "2718281994",
  //       "packaging_id": "KK250*180*100",
  //       "sequence_num": "1",
  //       "weight": 3,
  //       "items": [
  //         {
  //           "id": "KK1042",
  //           "barcode_used": "1",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         },
  //         {
  //           "id": "KK1042",
  //           "barcode_used": "1",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         },
  //         {
  //           "id": "KK1042",
  //           "barcode_used": "1",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         }
  //       ]
  //     },
  //     {
  //       "id": "WB_2718281995",
  //       "temp_id": "2718281995",
  //       "packaging_id": "KK180*100*100",
  //       "sequence_num": "2",
  //       "weight": 2,
  //       "items": [
  //         {
  //           "id": "KK1042",
  //           "barcode_used": "1",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         },
  //         {
  //           "id": "KK1042",
  //           "barcode_used": "1",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         }
  //       ]
  //     },
  //     {
  //       "id": "WB_2718281997",
  //       "temp_id": "2718281997",
  //       "packaging_id": "KK200*100*100",
  //       "sequence_num": "3",
  //       "weight": 3,
  //       "items": [
  //         {
  //           "id": "KK1024",
  //           "barcode_used": "1222777",
  //           "quantity": 1,
  //           "serial_numbers": [
  //             {
  //               "code": "chestniy_znak",
  //               "value": "965444"
  //             }
  //           ]
  //         },
  //         {
  //           "id": "KK1024",
  //           "barcode_used": "1222777",
  //           "quantity": 1,
  //           "serial_numbers": [
  //             {
  //               "code": "chestniy_znak",
  //               "value": "888888"
  //             }
  //           ]
  //         },
  //         {
  //           "id": "KK1024",
  //           "barcode_used": "1222777",
  //           "quantity": 1,
  //           "serial_numbers": [
  //             {
  //               "code": "chestniy_znak",
  //               "value": "12222"
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       "id": "WB_2718281998",
  //       "temp_id": "2718281998",
  //       "packaging_id": "KK250*180*100",
  //       "sequence_num": "4",
  //       "weight": 2,
  //       "items": [
  //         {
  //           "id": "KK1024",
  //           "barcode_used": "1222777",
  //           "quantity": 1,
  //           "serial_numbers": [
  //             {
  //               "code": "chestniy_znak",
  //               "value": "84444"
  //             }
  //           ]
  //         },
  //         {
  //           "id": "KK1024",
  //           "barcode_used": "1222777",
  //           "quantity": 1,
  //           "serial_numbers": [
  //             {
  //               "code": "chestniy_znak",
  //               "value": "76543"
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       "id": "WB_2718281999",
  //       "temp_id": "2718281999",
  //       "packaging_id": "KKvp_300*300",
  //       "sequence_num": "5",
  //       "weight": 2,
  //       "items": [
  //         {
  //           "id": "KK1043",
  //           "barcode_used": "70100401010202",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         },
  //         {
  //           "id": "KK1043",
  //           "barcode_used": "70100401010202",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         }
  //       ]
  //     },
  //     {
  //       "id": "WB_2718282014",
  //       "temp_id": "2718282014",
  //       "packaging_id": "KKvp_200*200",
  //       "sequence_num": "6",
  //       "weight": 3,
  //       "items": [
  //         {
  //           "id": "KK1043",
  //           "barcode_used": "70100401010202",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         },
  //         {
  //           "id": "KK1043",
  //           "barcode_used": "70100401010202",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         },
  //         {
  //           "id": "KK1043",
  //           "barcode_used": "70100401010202",
  //           "quantity": 1,
  //           "serial_numbers": []
  //         }
  //       ]
  //     }
  //   ],
  //   "pallets": [
  //     {
  //       "pallet_id": "0000000125",
  //       "pallet_num": 1,
  //       "weight": 1000,
  //       "dimensions": {
  //         "depth": 300,
  //         "width": 100,
  //         "height": 200
  //       },
  //       "places": [
  //         "WB_2718281994",
  //         "WB_2718281995"
  //       ]
  //     },
  //     {
  //       "pallet_id": "0000000126",
  //       "pallet_num": 2,
  //       "weight": 1000,
  //       "dimensions": {
  //         "depth": 300,
  //         "width": 100,
  //         "height": 200
  //       },
  //       "places": [
  //         "WB_2718281997",
  //         "WB_2718281998",
  //         "WB_2718281999",
  //         "WB_2718282014"
  //       ]
  //     }
  //   ],
  //   "ad_attachments": []
  // },
  order: {
    id: 'mock',
    operationType: OperationTypes.PACKAGING_B2C,
    relabelingType: RelabelingTypes.PALLETS,
    maxBoxesWithoutPallet: 0,
    date: '2021-11-30T20:42:26',
    packing_state: 'packed',
    global_shipping_guidelines: '',
    order_shipping_comments: '',
    preferred_barcode_type: 'OZON',
    require_place_weight: true,
    shippingOptions: {
      packingType: ShippingOptionsType.MIX_PACKINGS,
    },
    shipping_form: [
      {
        id: 'shipping_num',
        title: 'Номер поставки',
        value: '120721-001',
      },
      {
        id: '1000',
        title: 'Наименование юрлица',
        value: 'ИП Кулиш А.С.',
      },
      {
        id: '1002',
        title: 'Дата доставки на склад МП',
        value: '15.09.21',
      },
      {
        id: '1005',
        title: 'Нанесение дополнительного стикера с описанием товара',
        value: 'нет (но все товары должны быть с бар кодом товара)',
      },
      {
        id: '1006',
        title: 'Тип отгрузки: короба, моно, микс',
        value: 'монокороба',
      },
      {
        id: '1007',
        title: 'Дополнительная упаковка: да/нет',
        value: 'нет',
      },
      {
        id: 'instruction',
        title: 'Инструкция',
        value: `
          <h4>Tempor velit ut aliqua ea anim amet amet magna</h4>
          <p>Tempor velit ut aliqua ea anim amet amet magna. Velit quis qui mollit eu cupidatat esse non ex aliquip mollit laborum voluptate.</p>
<p>Tempor velit ut aliqua ea anim amet amet magna. Velit quis qui mollit eu cupidatat esse non ex aliquip mollit laborum voluptate.</p>
<h4>Tempor velit ut aliqua ea anim amet amet magna</h4>
          <p>Tempor velit ut aliqua ea anim amet amet magna. Velit quis qui mollit eu cupidatat esse non ex aliquip mollit laborum voluptate.</p>
`,
      },
    ],
    delivery: {
      logo: 'https://cdn1.ozone.ru/s3/cms/39/t2a/logo-logo-ozon-blue.svg',
      title: 'Озон ФБО',
      contract_title: '',
      sorting_center_acceptance_date: '',
      maximum_place_weight: 10000,
      maximum_pallet_weight: 300000,
      maximum_place_dimensions: {
        width: 10,
        height: 20,
        depth: 30,
      },
      maximum_pallet_dimensions: {
        width: 200,
        height: 300,
        depth: 400,
      },
      maximum_places_count: 0,
    },
    items: [
      {
        id: 'mock',
        title: 'Ложка',
        article: 'loz',
        quantity: 5,
        image:
          'https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf.jpg',
        image_preview:
          'https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf-100.jpg',
        require_weight: false,
        require_dimensions: false,
        require_batch_expdate: false,
        expiryMonths: 1,
        weight: 2000,
        barcodes: [
          {
            barcode: '1',
            type: '',
          },
          {
            barcode: '435345435435',
            type: 'OZON',
          },
          {
            barcode: '70100401010204',
            type: '',
          },

          {
            barcode: '70100401010204111',
            type: 'COMMON',
          },
          {
            barcode: '701004010102043',
            type: 'COMMON',
          },
        ],
        scan_serial_numbers: [
          // {
          //   code: 'chestniy_znak',
          //   title: 'Честный знак',
          //   template: '*',
          // },
          // {
          //   code: 'imei',
          //   title: 'imei',
          //   template: '*',
          // },
          // {
          //   code: 'fsfcs',
          //   title: 'fsfcs',
          //   template: '*',
          // },
        ],
      },
      {
        id: 'KK1043',
        title: 'стакан',
        article: 'sta',
        quantity: 5,
        image: '',
        image_preview: '',
        require_weight: false,
        require_dimensions: false,
        require_batch_expdate: false,
        barcodes: [
          {
            barcode: '3',
            type: '',
          },
          {
            barcode: '2',
            type: 'OZON',
          },
        ],
        scan_serial_numbers: [
          {
            code: 'fsfcs',
            title: 'fsfcs',
            template: '*',
          },
        ],
      },
      // {
      //   id: 'SDoc',
      //   title: 'стакан',
      //   article: 'sta',
      //   quantity: 1,
      //   image: '',
      //   image_preview: '',
      //   require_weight: false,
      //   require_dimensions: false,
      //   require_batch_expdate: false,
      //   expiryDaysLimitShipments: 7,
      //   expiryMonths: 1,
      //   barcodes: [
      //     {
      //       barcode: 'SDoc',
      //       type: '',
      //     },
      //   ],
      //   scan_serial_numbers: [],
      // },
    ],
    places: [
      {
        id: 'mock',
        packaging_id: 'some-id-2',
        sequence_num: 1,
        weight: 3,
        items: [
          {
            id: 'mock',
            barcode_used: '3141592659',
            quantity: 2,
            weight: 1,
            serial_numbers: [
              {
                code: 'chestniy_znak',
                value: '3',
              },
            ],
            packagings: [
              {
                packaging_id: 'some-id-2',
                quantity: 1,
              },
            ],
          },
          {
            id: 'mock',
            barcode_used: '3141592659',
            quantity: 1,
            weight: 1,
            serial_numbers: [
              {
                code: 'chestniy_znak',
                value: '3',
              },
            ],
            packagings: [
              {
                packaging_id: 'some-id-2',
                quantity: 1,
              },
            ],
          },
          {
            id: 'KK1043',
            barcode_used: '3141592659',
            quantity: 1,
            weight: 1,
            packagings: [
              {
                packaging_id: 'some-id-2',
                quantity: 1,
              },
            ],
            serial_numbers: [
              {
                code: 'chestniy_znak',
                value: '3',
              },
            ],
          },
        ],
      },
      {
        id: 'mock2',
        packaging_id: 'other_pack',
        sequence_num: 2,
        weight: 3,
        items: [
          {
            id: 'KK1043',
            barcode_used: '3141592660',
            quantity: 1,
            weight: 1,
            serial_numbers: [],
          },
          {
            id: 'KK1043',
            barcode_used: '3141592660',
            quantity: 1,
            weight: 1,
            serial_numbers: [],
          },
          {
            id: 'KK1043',
            barcode_used: '3141592660',
            quantity: 1,
            weight: 1,
            serial_numbers: [],
          },
        ],
      },
      // {
      //   id: 'mock3',
      //   packaging_id: 'KK270*205*130',
      //   sequence_num: 3,
      //   weight: 4,
      //   items: [
      //     {
      //       id: 'KK1042',
      //       barcode_used: '3141592661',
      //       quantity: 1,
      //       weight: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //     {
      //       id: 'KK1042',
      //       barcode_used: '3141592661',
      //       quantity: 1,
      //       weight: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //     {
      //       id: 'KK1042',
      //       barcode_used: '3141592661',
      //       quantity: 1,
      //       weight: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //     {
      //       id: 'KK1042',
      //       barcode_used: '3141592661',
      //       quantity: 1,
      //       weight: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   id: 'mock4',
      //   packaging_id: 'KKvp_150*150',
      //   sequence_num: 4,
      //   weight: 3,
      //   items: [
      //     {
      //       id: 'KK1043',
      //       barcode_used: '3141592659',
      //       quantity: 1,
      //       weight: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //     {
      //       id: 'KK1043',
      //       barcode_used: '3141592659',
      //       quantity: 1,
      //       weight: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //     {
      //       id: 'KK1043',
      //       barcode_used: '3141592659',
      //       quantity: 1,
      //       weight: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '13',
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   id: 'mock5',
      //   packaging_id: 'KKvp_150*150',
      //   sequence_num: 5,
      //   weight: 3,
      //   items: [
      //     {
      //       id: 'KK1043',
      //       barcode_used: '3141592659',
      //       quantity: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //     {
      //       id: 'KK1043',
      //       barcode_used: '3141592659',
      //       quantity: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '3',
      //         },
      //       ],
      //     },
      //     {
      //       id: 'KK1043',
      //       barcode_used: '3141592659',
      //       quantity: 1,
      //       serial_numbers: [
      //         {
      //           code: 'chestniy_znak',
      //           value: '13',
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
    // pallets: [
    //   {
    //     "pallet_id": "mockPallet",
    //     "pallet_num": 1,
    //     "weight": 1000,
    //     "dimensions": {
    //       "depth": 300,
    //       "width": 100,
    //       "height": 200
    //     },
    //     "places": ['mock', 'mock2']
    //   },
    //   {
    //     "pallet_id": "mockPallet",
    //     "pallet_num": 2,
    //     "weight": 1000,
    //     "dimensions": {
    //       "depth": 300,
    //       "width": 300,
    //       "height": 100
    //     },
    //     "places": ['mock3', 'mock4']
    //   }
    // ],
    // pallets: [
    //   {
    //     id: 'mock',
    //     pallet_num: 1,
    //     weight: 6,
    //     dimensions: {
    //       depth: 5,
    //       width: 3,
    //       height: 4,
    //     },
    //     places: ['3141592659', '3141592660'],
    //   },
    //   {
    //     id: 'mock2',
    //     pallet_num: 2,
    //     weight: 1000,
    //     dimensions: {
    //       depth: 11,
    //       width: 9,
    //       height: 10,
    //     },
    //     places: ['3141592661'],
    //   },
    // ],
    ad_attachments: [
      // {
      //   name: 'Салфетка',
      //   quantity: 1,
      //   note: 'примечание',
      //   id: '1',
      //   barcode: '2',
      //   img: '',
      // },
    ],
    files: [
      {
        name: 'Счёт__31_от_14.pdf',
        url: 'static-eu.insales.ru/order_files/165623972/Счёт__31_от_14.04.2021.pdf',
        id: 'mock',
      },
      {
        name: 'Счёт__32_от_14.pdf',
        url: 'https://static-eu.insales.ru/order_files/165623972/Счёт__31_от_14.04.2021.pdf',
        id: 'mock',
      },
    ],
  },
  packagings: [
    {
      id: 'some-id',
      title: 'Коробка 50х30х20',
      barcodes: ['BX-503020', 'BX*503020'],
    },
    {
      id: 'some-id-2',
      title: 'Коробка 50х30х10',
      barcodes: ['BX-503010'],
    },
  ],
}

export const MOCK_GET_B2B_PACKING_BY_ID = {
  ...MOCK_GET_PACKING_BY_ID,
  order: {
    ...MOCK_GET_PACKING_BY_ID.order,
    operationType: OperationTypes.PACKAGING_B2B,
    maxBoxesWithoutPallet: 2,
    pallets: [
      // {
      //   id: 'mock33',
      //   pallet_num: 1,
      //   weight: 567,
      //   dimensions: {
      //     width: 10,
      //     height: 10,
      //     depth: 10,
      //   },
      //   places: ['mockPlace'],
      // },
    ],
  },
}

export const MOCK_GET_B2B_RELABELLING_BY_ID = {
  ...MOCK_GET_PACKING_BY_ID,
  order: {
    ...MOCK_GET_PACKING_BY_ID.order,
    operationType: OperationTypes.RELABELING,
    relabelingType: RelabelingTypes.BOXES_AND_PALLETS,
    shipmentType: ShipmentTypes.PALLETS,
    maxBoxesWithoutPallet: 2,
    pallets: [
      {
        pallet_id: 'mockPallet',
        pallet_num: 1,
        weight: 1000,
        dimensions: {
          depth: 300,
          width: 100,
          height: 200,
        },
        places: [],
      },
      // {
      //   pallet_id: 'mockPallet2',
      //   pallet_num: 2,
      //   weight: 1000,
      //   dimensions: {
      //     depth: 300,
      //     width: 300,
      //     height: 100,
      //   },
      //   places: [],
      // },
    ],
  },
}

export const MOCK_GET_KIT_PACKING_BY_ID = {
  ...MOCK_GET_PACKING_BY_ID,
  order: {
    ...MOCK_GET_PACKING_BY_ID.order,
    items: [
      {
        id: 'some-item-id',
        article: 'A0121202',
        title: 'Норвежская лесная Эдалт 2 кг',
        quantity: 5,
        image: '',
        image_preview: '',
        require_weight: true,
        require_dimensions: false,
        kit_id: 1020122,
        kit_title: 'Большой набор разных товаров',
        scan_serial_numbers: [],
        barcodes: [
          {
            type: 'COMMON',
            barcode: '40010030040',
          },
          {
            type: 'WILDBERRIES',
            barcode: '1122334455',
          },
        ],
      },
      {
        id: 'some-item-id-2',
        article: 'A0121201',
        title: 'Норвежская лесная Янг 2 кг (2)',
        quantity: 5,
        image: '',
        image_preview: '',
        require_weight: false,
        require_dimensions: false,
        kit_id: 102012,
        kit_title: 'Большой набор разных товаров',
        scan_serial_numbers: [],
        barcodes: [
          {
            type: 'COMMON',
            barcode: '40010030041',
          },
          {
            type: 'WILDBERRIES',
            barcode: '1122334455',
          },
        ],
      },
      {
        id: 'some-item-id-3',
        article: 'A0121203',
        title: 'Норвежская лесная Миддл 2 кг (3)',
        quantity: 5,
        image: '',
        image_preview: '',
        require_weight: true,
        require_dimensions: false,
        kit_id: 102012,
        kit_title: 'Большой набор разных товаров',
        scan_serial_numbers: [],
        barcodes: [
          {
            type: 'COMMON',
            barcode: '40010030043',
          },
          {
            type: 'WILDBERRIES',
            barcode: '1122334455',
          },
        ],
      },
    ],
  },
}
