import React, { useEffect, useRef } from 'react'

import styles from './auth-page.module.scss'

import { Steps } from '@consta/uikit/Steps'
import { IconArrowLeft } from '@consta/uikit/IconArrowLeft'
import { IconArrowRight } from '@consta/uikit/IconArrowRight'

import { STEP } from 'src/config'

interface IMobileSteps {
  steps: STEP[]
  activeStep: STEP
  onChangeStep: (props: { e: React.MouseEvent; value: STEP | null }) => void
}

const MobileSteps = ({ steps, activeStep, onChangeStep }: IMobileSteps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const scroll: { [key: string]: number } = {
    databaseStep: 0,
    packageStep: 173,
    employeeStep: 346,
    workplaceStep: 453,
  }

  const handleRight = () => {
    if (!ref?.current) return
    ref.current.scrollLeft = ref.current.scrollLeft + 173
  }

  const handleLeft = () => {
    if (!ref?.current) return
    ref.current.scrollLeft = ref.current.scrollLeft - 173
  }

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollLeft = scroll[activeStep.value]
    }
  }, [activeStep])

  return (
    <div className={styles.stepsContainer}>
      <IconArrowLeft view="brand" onClick={handleLeft} />
      <div className={styles.steps} ref={ref}>
        <Steps
          items={steps}
          size="l"
          getLabel={(item) => item.label}
          getCompleted={(item) => item.completed || false}
          value={activeStep}
          onChange={onChangeStep}
          className={styles.stepsWrap}
        />
      </div>
      <IconArrowRight view="brand" onClick={handleRight} />
    </div>
  )
}

export default MobileSteps
