import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './b2b-relabeling-order.module.scss'

import MainBlock from './MainBlock'
import DocsSideBar from './DocsSideBar'

import {
  StickerPrintModal,
  ShippingFormModal,
  AdditionalDocsModal,
} from '../components'

import { StickerErrorModal, ItemStickerPrintModal } from 'src/components'

import { useNotifications } from 'src/notification'
import { useGetAddFiles } from 'src/hooks'
import {
  useMoveRelabelingItem,
  useManagePallets,
  useManagePrintSticker,
  useSetOrderStatus,
} from './hooks'

import { usePackingOrderContext, usePackingPrintContext } from '../context'

import {
  //saveBase64AsPdf,
  getSubtitleByOperation,
  getTitleByOperation,
  checkAllPalletsFilled,
} from 'src/helpers'

import {
  RelabelingTypes,
  useManagePrintStickerRes,
  IOrderPallet,
  useManagePalletsRes,
  useMoveRelabelingItemRes,
} from 'src/interfaces'

const B2BDocsOrder = () => {
  // context

  const {
    items,
    showShippingForm,
    setShowShippingForm,
    order,
    handleSavePlace,
    palletsForRelabeling,
    packages: packagingOptions,
    shippingFormViewed,
    currentPallet,
    setAddFiles,
    pallets,
    relabeledPallets,
    setRelabeledPallets,
  } = usePackingOrderContext()

  const {
    showStickerError,
    sticker,
    showStickerPrintInfo,
    setShowStickerPrintInfo,
    handleCloseStickerError,
    barcodeInput,
    blurOrFocusBarcodeInput,
    handleCloseAndFocusBarcode,
  } = usePackingPrintContext()

  // state

  const [showStickerPrint, setShowStickerPrint] = useState<boolean>(false)
  const [showAdditionalDocs, setShowAdditionalDocs] = useState<boolean>(false)

  const [curOperation, setCurOperation] = useState<string | null>('palletList')
  const [showPacked, setShowPacked] = useState<boolean>(false)
  const [currentPalletData, setCurrentPalletData] =
    useState<IOrderPallet | null>(null)

  // other

  const isBoxesRelabeling =
    order?.relabelingType === RelabelingTypes.BOXES ||
    order?.relabelingType === RelabelingTypes.BOXES_AND_PALLETS
  const originalCount =
    (isBoxesRelabeling ? order?.places?.length : order?.pallets?.length) ?? 0

  const isAllPalletsFilled = useMemo(
    () => checkAllPalletsFilled(pallets),
    [pallets],
  )

  // request hooks

  const getAddFiles = useGetAddFiles()

  //  other hooks

  const history = useHistory()
  const notification = useNotifications()

  // other functions

  const handlePrintSticker = (id: string, isRepeat: boolean = false): void => {
    if (isBoxesRelabeling) {
      printPlaceSticker(id, isRepeat)
      return
    }
    printPalletSticker(id, isRepeat)
  }

  // метод для получения доп документов, если их нет, то ставим флаг isAllDocsPrinted = true и завершаем перемаркировку
  const handleOpenAddFiles = async () => {
    if (!order?.id) return
    await getAddFiles.fetch({ orderId: order.id }).then((res) => {
      if (res.files.length) {
        setAddFiles(res.files)
        setShowAdditionalDocs(true)
        return
      }
    })
  }

  const printPalletsLists = async (
    isNeedPrintAddDocs: boolean = true,
  ): Promise<void> => {
    setCurOperation('palletList')

    // для каждой паллеты вызываем метод получения паллетного листа
    // если первый паллетный лист, то вызываем модалку с информацией о печати паллетных листов
    // если последний, то проверяем на наличии доп документов и вызываем модалку для их печати
    notification?.show('warning', `Печать упаковочных листов`)
    let hasSticker = false
    for (const [index, pallet] of palletsForRelabeling.entries()) {
      if (!pallet.id) return

      const isFirst = index === 0
      const isLast = index === palletsForRelabeling.length - 1

      try {
        await printPalletList(pallet.id, pallet.pallet_num, isFirst)
        hasSticker = true
      } catch (e) {
        if (isLast && !hasSticker) {
          notification?.show(
            'alert',
            `Не напечатан ни одни упаковочный лист. Обратитесь к администратору`,
          )
        }
      } finally {
        if (isLast && hasSticker && isNeedPrintAddDocs) {
          handleOpenAddFiles()
        }
      }
    }
  }

  // custom hooks

  // методы получения стикеров
  const {
    printPalletSticker,
    printPlaceSticker,
    printSticker,
    printWBBarcode,
    printPalletList,
    printWBPackingList,
  }: useManagePrintStickerRes = useManagePrintSticker({
    setCurOperation,
  })

  // методы для модификации паллет
  const { startClosePallet }: useManagePalletsRes = useManagePallets({
    setCurOperation,
    printSticker,
    handleOpenAddFiles,
    setCurrentPalletData,
  })

  // методы для перемещения паллет/мест из не перемаркировано в перемаркировано
  const { markedPallets, markedPlaces, markedCount }: useMoveRelabelingItemRes =
    useMoveRelabelingItem({
      originalCount,
      handleOpenAddFiles,
      startClosePallet,
      printPalletsLists,
    })

  // useEffect hooks

  useEffect(() => {
    if (order?.packing_state && order.packing_state !== 'packed') {
      history.push('/')
    }
  }, [order])
  if (!order) return null

  return (
    <>
      <div className={styles.main}>
        <DocsSideBar
          originalCount={originalCount}
          markedCount={markedCount}
          shippingFormViewed={shippingFormViewed}
          handleOpenAddFiles={handleOpenAddFiles}
          handleGetWbBarcode={printWBBarcode}
          printWBPackingList={printWBPackingList}
        />
        <div className={styles.bodyWrap}>
          <div className={styles.body}>
            {order?.relabelingType && (
              <MainBlock
                type={order.relabelingType}
                markedPlaces={markedPlaces}
                markedPallets={markedPallets}
                handlePrint={(id: string) => handlePrintSticker(id, true)}
                packagingOptions={packagingOptions ?? []}
                isAllPalletsFilled={isAllPalletsFilled}
                pallets={relabeledPallets}
                showPacked={showPacked}
                setShowPacked={() => setShowPacked(true)}
                currentPallet={currentPallet}
                setPallets={(pallets) => setRelabeledPallets(pallets)}
              />
            )}
          </div>
        </div>
      </div>

      {showStickerPrint && !showStickerError ? (
        <StickerPrintModal
          isOpen={true}
          title="Заказ успешно перемаркирован"
          onSuccess={handleSavePlace}
          onClose={() => handleCloseAndFocusBarcode(setShowStickerPrint)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          btnTitle="Закрыть"
        />
      ) : null}

      {showStickerError ? (
        <StickerErrorModal
          isOpen={true}
          sticker={sticker}
          onClose={handleCloseStickerError}
          isFinal={!items?.length}
          showStickerPrint={() => setShowStickerPrint(true)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}

      {showShippingForm ? (
        <ShippingFormModal
          isOpen={showShippingForm}
          onClose={() => handleCloseAndFocusBarcode(setShowShippingForm)}
          shippingFormData={order?.shipping_form || []}
          blurRef={barcodeInput}
          order={order}
        />
      ) : null}

      {showStickerPrintInfo ? (
        <ItemStickerPrintModal
          isOpen={true}
          onClose={() => setShowStickerPrintInfo(false)}
          title={getTitleByOperation(curOperation)}
          subtitle={getSubtitleByOperation(curOperation)}
          backTo="печати"
        />
      ) : null}

      {showAdditionalDocs && !showStickerPrintInfo && !showStickerError ? (
        <AdditionalDocsModal
          isOpen={true}
          onClose={() => handleCloseAndFocusBarcode(setShowAdditionalDocs)}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          isAllRelabel={true}
          isNeedAllPrint={false}
        />
      ) : null}
    </>
  )
}

export default B2BDocsOrder
