import React, { useEffect, useState } from 'react'

import styles from './add-attachments-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { Badge } from '@consta/uikit/Badge'
import { IconAllDone } from '@consta/uikit/IconAllDone'

import { Modal, InputWrapper, Table, Img } from 'src/components'

import ItemCountModal from '../ItemAdding/ItemCountModal'

import { useNotifications } from 'src/notification'

import { usePackingOrderContext } from '../../context'

import { ModalProps, IAdAttachments } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface AddAttachmentsModalProps extends ModalProps {
  addAttachments: IAdAttachments[]
  handleСompleteAddAttachment: () => void
}

const AddAttachmentsModal = (props: AddAttachmentsModalProps) => {
  const { isOpen, addAttachments, handleСompleteAddAttachment } = props

  const [showCount, setShowCount] = useState<boolean>(false)
  const [curAttachment, setCurAttachment] = useState<IAdAttachments | null>(
    null,
  )
  const [count, setCount] = useState<string>('1')

  const { packedAttachments, setPackedAttachments } = usePackingOrderContext()

  const notification = useNotifications()

  const getPackedById = (id: string) =>
    packedAttachments.find((packedAttachment) => packedAttachment.id === id)

  const isDisable = () => {
    const getPackedAttachment = (id: string) =>
      packedAttachments.find((packedAttachment) => packedAttachment.id === id)
    return !(
      addAttachments.filter(
        (attachment) =>
          attachment.quantity === getPackedAttachment(attachment.id)?.quantity,
      ).length === addAttachments.length
    )
  }

  const handleAddAttachment = (id: string, quantity: number) => {
    if (!id) return
    const newAttachments = [
      ...packedAttachments.filter(
        (packedAttachment) => packedAttachment.id !== id,
      ),
      { id, quantity },
    ]
    console.log(newAttachments)
    setPackedAttachments(newAttachments)
  }

  const handleAddAttachmentByBarcode = (barcode: TextFieldPropValue) => {
    const attachment = addAttachments.find(
      (attachment) => attachment.barcode === barcode,
    )
    if (!attachment) {
      notification?.show(
        'alert',
        `Доп. вложение с штрихкодом ${barcode} не найдено`,
      )
      return false
    }

    const packedAttachment = packedAttachments.find(
      (packedAttachment) => packedAttachment.id === attachment.id,
    )
    if (packedAttachment && packedAttachment.quantity >= attachment.quantity) {
      notification?.show('alert', `Лишнее доп. вложение`)
      return false
    }
    if (attachment.quantity === 1) {
      const newAttachments = [
        ...packedAttachments,
        { id: attachment.id, quantity: 1 },
      ]
      setPackedAttachments(newAttachments)
      return
    }

    setShowCount(true)
    setCurAttachment(attachment)
  }

  const handleAddCount = (count: number) => {
    if (curAttachment === null) return

    const packedAttachment = packedAttachments.find(
      (packedAttachment) => packedAttachment.id === curAttachment.id,
    )

    if ((packedAttachment?.quantity ?? 0) + count > curAttachment.quantity) {
      notification?.show('alert', `Недопустимое количество`)
      return false
    }

    const newAttachments = [
      ...packedAttachments.filter(
        (packedAttachment) => packedAttachment.id !== curAttachment.id,
      ),
      {
        id: curAttachment.id,
        quantity: (packedAttachment?.quantity ?? 0) + count,
      },
    ]

    setPackedAttachments(newAttachments)

    setShowCount(false)
    setCount('1')
    setCurAttachment(null)
  }

  const handleСomplete = () => {
    handleСompleteAddAttachment()
  }

  return (
    <>
      {!showCount ? (
        <Modal
          isOpen={true}
          hasOverlay
          className={styles.AddAttachments}
          rootClassName={styles.wrap}
          size="m-plus"
          closeClass={styles.close}
        >
          <div className={styles.top}>
            <h3>Упаковка дополнительных вложений</h3>
            <h5>
              Упакуйте дополнительные вложения из списка к основному заказу
            </h5>
          </div>
          <InputWrapper
            label={'Отсканируйте ШК доп. вложения'}
            id={'barcode'}
            handleChange={handleAddAttachmentByBarcode}
            className={styles.itemInput}
            size="l"
            autoFocus
            withDelay
          />

          <div className={styles.tableWrap}>
            <Table stickyHeader>
              {addAttachments &&
                addAttachments.map((attachment: any, i: number) => (
                  <Table.Row key={attachment.name}>
                    <Table.Column title="" className={styles.tdImg}>
                      <Img
                        src={attachment.img}
                        className={styles.img}
                        size={100}
                        yandex
                      />
                    </Table.Column>
                    <Table.Column
                      title={
                        <div className={styles.thName}>
                          Наименование
                          <span className={styles.comment}>Комментарий</span>
                        </div>
                      }
                      className={styles.tdName}
                    >
                      <div className={styles.name}>
                        {/*<span className={styles.barcode}>*/}
                        {/*  {attachment.barcode}*/}
                        {/*</span>*/}
                        <span>{attachment.name}</span>
                        <span className={styles.note}>{attachment.note}</span>
                      </div>
                    </Table.Column>
                    <Table.Column title="Кол-во" align="right">
                      {getPackedById(attachment.id)?.quantity ?? 0} /{' '}
                      {attachment.quantity}
                    </Table.Column>
                    <Table.Column
                      title="Статус"
                      className={styles.tdStatus}
                      align="center"
                    >
                      <>
                        {getPackedById(attachment.id)?.quantity ===
                        attachment.quantity ? (
                          <Badge
                            icon={IconAllDone}
                            status="success"
                            label="ок"
                          />
                        ) : (
                          <>
                            {/*{attachment.barcode ? (*/}
                            {/*  <Badge status="system" label="не упаковано" />*/}
                            {/*) : (*/}
                            {/*  <Button*/}
                            {/*    view="secondary"*/}
                            {/*    size="s"*/}
                            {/*    label="Упаковано"*/}
                            {/*    onClick={() =>*/}
                            {/*      handleAddAttachment(*/}
                            {/*        attachment.id,*/}
                            {/*        attachment.quantity,*/}
                            {/*      )*/}
                            {/*    }*/}
                            {/*  />*/}
                            {/*)}*/}
                            <Button
                              view="secondary"
                              size="s"
                              label="Упаковано"
                              onClick={() =>
                                handleAddAttachment(
                                  attachment.id,
                                  attachment.quantity,
                                )
                              }
                            />
                          </>
                        )}
                      </>
                    </Table.Column>
                  </Table.Row>
                ))}
            </Table>
          </div>

          <Button
            className={styles.btn}
            disabled={isDisable()}
            size="l"
            label="Завершить упаковку"
            onClick={handleСomplete}
          />
        </Modal>
      ) : (
        <>
          {curAttachment !== null ? (
            <ItemCountModal
              count={count}
              item={{
                image: curAttachment.img,
                title: curAttachment.name,
                barcode: curAttachment.barcode ?? '',
              }}
              isOpen={true}
              setCount={setCount}
              onItemAdding={handleAddCount}
              onClose={() => setShowCount(false)}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export default AddAttachmentsModal
