import React from 'react'
import cx from 'classnames'

import styles from './pallets-packed-table.module.scss'

import IconPrinter from './assets/IconPrinter.svg'
import boxImg from './assets/box_avatar.svg'

import { Button } from '@consta/uikit/Button'

import { useGetStickerByPallet } from 'src/hooks'

import { usePackingOrderContext, usePackingPrintContext } from '../../context'
import { useAppContext } from 'src/context'

import { getPlacesString, getPackageTitle } from 'src/helpers'

import { IOrderPalletMarked } from 'src/interfaces'

interface IBoxMarkedInPalletsTable {
  pallets: IOrderPalletMarked[]
  currentPallet: number | null
  handlePrint: (itemId: string) => void
  setShowPacked?: () => void
  movePlace?: (placeId?: string, palletId?: string) => void
  withTitle?: boolean
  needShowPrintStickerInfo?: boolean
  needFocusAfterPrint?: boolean
  advance?: boolean
}

const BoxMarkedInPallets = ({
  pallets,
  handlePrint,
  setShowPacked,
  movePlace,
  withTitle = true,
  needShowPrintStickerInfo = true,
  needFocusAfterPrint = true,
  advance = false,
}: IBoxMarkedInPalletsTable) => {
  const { advanceMode, performWithAdvance } = useAppContext()
  const { packages, setCurrentOperation, order } = usePackingOrderContext()
  const { printSticker } = usePackingPrintContext()

  const getPalletSticker = useGetStickerByPallet()

  const printPalletSticker = async (id: string) => {
    if (!order?.id) return

    await getPalletSticker
      .fetch({ pallet_id: id, operationType: order?.operationType })
      .then((result) => {
        if (result.success) {
          const { sticker } = result

          //const printer = 'Microsoft Print to PDF'

          printSticker(sticker.printer, sticker.content, {
            copies: 1,
            needShowPrintStickerInfo: needShowPrintStickerInfo,
            needFocusAfterPrint: needFocusAfterPrint,
          })
        }
      })
  }

  const handlePrintSticker = async (id?: string) => {
    if (!advanceMode && !advance) {
      performWithAdvance(() => () => {
        if (id && order?.id) {
          setCurrentOperation('palletSticker')
          printPalletSticker(id)
        }
      })
    } else {
      if (id && order?.id) {
        setCurrentOperation('palletSticker')
        printPalletSticker(id)
      }
    }
  }

  const showPacked = () => {
    if (setShowPacked) {
      setShowPacked()
    }
  }

  const handleShowPacked = () => {
    if (!setShowPacked) return

    if (!advanceMode) {
      performWithAdvance(() => () => {
        showPacked()
      })
      return
    }
    showPacked()
  }

  return (
    <div className={styles.acceptedTable}>
      <div className={styles.tableTitle}>
        {withTitle ? <h3>Перемаркировано</h3> : null}

        {pallets.length && setShowPacked ? (
          <Button
            label="Посмотреть полностью"
            view="secondary"
            onClick={() => handleShowPacked()}
          />
        ) : null}
      </div>
      <div>
        <div className={styles.tableHead}>
          <div>Паллета</div>
          <div>
            Номенклатура <span>Штрихкод места</span>
          </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.tableBody}>
          {pallets?.length ? (
            <>
              {pallets
                .slice()
                .reverse()
                .map((pallet, i) => (
                  <div className={styles.pallet} key={`pallet-${i}`}>
                    <div className={styles.palletInfoWrap}>
                      <div className={styles.palletInfo}>
                        <h3 className={styles.title}>
                          Паллета {pallet.pallet_num}
                        </h3>
                        <div className={styles.info}>
                          {pallet.id && (
                            <span className={styles.barcode}>{pallet.id}</span>
                          )}
                          {!pallet?.id ? (
                            <div
                              className={cx(
                                styles.badgePallet,
                                styles.badgeBlue,
                              )}
                            >
                              Открыто
                            </div>
                          ) : (
                            <div
                              className={cx(
                                styles.badgePallet,
                                styles.badgeGreen,
                              )}
                            >
                              Закрыто
                            </div>
                          )}
                          {pallet?.id ? (
                            <img
                              className={styles.palletPrint}
                              src={IconPrinter}
                              alt="IconPrinter"
                              onClick={() => handlePrintSticker(pallet.id)}
                            />
                          ) : null}
                        </div>
                        <div className={styles.length}>
                          {getPlacesString(pallet?.places?.length ?? 1)}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tablePlace}>
                      {pallet.places
                        .slice()
                        .sort((a, b) => +a.sequence_num - +b.sequence_num)
                        .map((item, i) => (
                          <div className={styles.row} key={item.id}>
                            <div className={styles.left}>
                              <img className={styles.img} src={boxImg} alt="" />
                              <div>
                                <h6>Короб {item.sequence_num}</h6>
                                <span className={styles.barcode}>
                                  {item.newBarcode ?? item.id}
                                </span>
                                <span className={styles.dimensions}>
                                  {getPackageTitle(item, packages)}
                                </span>
                              </div>
                            </div>
                            <div className={styles.left}>
                              {item.newBarcode ?? item.temp_id ? (
                                <Button
                                  label="Повторить печать"
                                  view="secondary"
                                  size="s"
                                  onClick={() =>
                                    handlePrint(
                                      item.temp_id
                                        ? item.id ?? ''
                                        : item.newBarcode ?? '',
                                    )
                                  }
                                />
                              ) : null}

                              <div className={styles.move}>
                                {movePlace && (item?.newBarcode || item?.id) ? (
                                  <Button
                                    label="Переместить"
                                    size="s"
                                    onClick={() =>
                                      movePlace(
                                        item?.id,
                                        pallet?.id ?? pallet?.pallet_id,
                                      )
                                    }
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default BoxMarkedInPallets
