import React, { useEffect, useState, useReducer, useRef } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import styles from './acceptance-form-page.module.scss'

import { Badge } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'

import {
  ItemScanner,
  SideBar,
  SearchItemsModal,
  StickerErrorModal,
} from 'src/components'

import { UnProcessed, AcceptedTable } from './tables'
import OpenTareErrorModal from './OpenTareErrorModal'
import DiscrepanciesModal from './DiscrepanciesModal'

import ItemAdding from './ItemAdding'

import {
  useAppContext,
  useAcceptanceContext,
  AcceptanceContextProvider,
} from 'src/context'

import { useNotifications } from 'src/notification'
import { useSound } from 'src/sound'
import { usePrinter } from 'src/printer'

import {
  useGetSupplyById,
  useAcceptedSupplyById,
  useStartSupply,
  useUpdateSupplyPart,
  useSearchItemByBarcode,
  useGetSticker,
} from 'src/hooks'

import { PATHS } from 'src/config'

import {
  getItemsQuantity,
  rusToLatin,
  checkIsTare,
  allItemsById,
  allItemsByIdAndPackId,
  allItemsInArrItemsByIdAndPackId,
  isChz,
  checkBase64MIMEType,
} from 'src/helpers'

import {
  ISupplyItem,
  ISupplyPart,
  ISupplyPartItem,
  IAcceptanceFormState,
  AcceptanceFormActions,
  AcceptanceFormActionTypes,
  useUpdateSupplyPartResult,
  ISerialNumberPart,
  ISerialNumber,
  ISupplyOriginalItem,
  getStickerResult,
} from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

const initialState = {
  items: [],
  cacheItems: [],
  indicateItemCount: false,
  startFormDate: '',
  addingItem: null,
  curTareId: '',
  tares: [],
  supplyIsStart: false,

  showSelectBatch: false,
  showOnlyGoodWarning: false,
  showOnlyDefectiveWarning: false,
  showDiscrepancies: false,
  showOpenTareError: false,
  showSearchItemsModal: false,
  showPrintError: false,
  sticker: null,
}

const reducer = (
  state: IAcceptanceFormState,
  action: AcceptanceFormActions,
) => {
  switch (action.type) {
    case AcceptanceFormActionTypes.setItems:
      return { ...state, items: action.items }
    case AcceptanceFormActionTypes.setCacheItems:
      return { ...state, cacheItems: action.cacheItems }
    case AcceptanceFormActionTypes.setIndicateItemCount:
      return { ...state, indicateItemCount: action.indicateItemCount }
    case AcceptanceFormActionTypes.setStartFormDate:
      return { ...state, startFormDate: action.startFormDate }
    case AcceptanceFormActionTypes.setAddingItem:
      return {
        ...state,
        addingItem:
          action.addingItem === null
            ? null
            : {
                ...action.addingItem,
                scan_serial_numbers: (
                  action?.addingItem?.scan_serial_numbers ?? []
                ).sort((a: ISerialNumber, b: ISerialNumber) => {
                  if (a.code === 'chestniy_znak') {
                    return -1
                  } else {
                    return 1
                  }
                  return 0
                }),
              },
      }
    case AcceptanceFormActionTypes.setCurTareId:
      return { ...state, curTareId: action.curTareId }
    case AcceptanceFormActionTypes.setTares:
      return { ...state, tares: action.tares }
    case AcceptanceFormActionTypes.setShowSelectBatch:
      return { ...state, showSelectBatch: action.showSelectBatch }
    case AcceptanceFormActionTypes.setShowDiscrepancies:
      return { ...state, showDiscrepancies: action.showDiscrepancies }
    case AcceptanceFormActionTypes.setStartSupply:
      return { ...state, supplyIsStart: action.supplyIsStart }
    case AcceptanceFormActionTypes.setShowOpenTareError:
      return { ...state, showOpenTareError: action.showOpenTareError }
    case AcceptanceFormActionTypes.setShowSearchItemsModal:
      return { ...state, showSearchItemsModal: action.showSearchItemsModal }
    case AcceptanceFormActionTypes.setShowPrintError:
      return { ...state, showPrintError: action.showPrintError }
    case AcceptanceFormActionTypes.setSticker:
      return { ...state, sticker: action.sticker }
    default:
      return state
  }
}

const AcceptanceFormPage = () => {
  const { setGlobalLoading, advanceMode } = useAppContext()
  const { setSavedChz } = useAcceptanceContext()

  useEffect(() => {
    setGlobalLoading(true)
  }, [])

  const params = useParams<{ supplyId: string }>()

  const history = useHistory()
  const notification = useNotifications()
  const sound = useSound()
  const printer = usePrinter()

  const acceptedSupplyMutation = useAcceptedSupplyById({
    supplyId: params.supplyId,
  })
  const searchItem = useSearchItemByBarcode()

  const barcodeInput = useRef<HTMLTextAreaElement | HTMLInputElement | null>(
    null,
  )

  const [
    {
      items,
      cacheItems,
      indicateItemCount,
      startFormDate,
      addingItem,
      curTareId,
      tares,
      showSelectBatch,
      showDiscrepancies,
      supplyIsStart,
      showOpenTareError,
      showSearchItemsModal,
      showPrintError,
      sticker,
    },
    dispatch,
  ] = useReducer<React.Reducer<IAcceptanceFormState, AcceptanceFormActions>>(
    reducer,
    initialState,
  )

  const updateItems = (newItems: ISupplyItem[]) => {
    dispatch({ type: AcceptanceFormActionTypes.setItems, items: newItems })

    dispatch({
      type: AcceptanceFormActionTypes.setAddingItem,
      addingItem: null,
    })
  }

  const newItemsWithoutRemoveItem = (
    removingItemId: string,
    removingPackId: string,
    delCount = 1,
    itemsArr = items,
  ) => {
    const itemForDeletingIndex = itemsArr.findIndex(
      (item: ISupplyItem) =>
        item.id === removingItemId && item.pack_id === removingPackId,
    )

    if (itemForDeletingIndex === -1) {
      return itemsArr
    }

    const newItems = [...itemsArr.map((item) => ({ ...item }))]
    if (itemsArr[itemForDeletingIndex].quantity === 1) {
      newItems.splice(itemForDeletingIndex, 1)
    } else {
      if (delCount < newItems[itemForDeletingIndex].quantity) {
        newItems[itemForDeletingIndex].quantity =
          newItems[itemForDeletingIndex].quantity - delCount
      } else {
        newItems[itemForDeletingIndex].quantity = 0
        newItems.splice(itemForDeletingIndex, 1)
      }
    }
    return newItems
  }

  const getItemsWithoutPartItems = (
    items: ISupplyItem[],
    parts: ISupplyPart[],
  ) => {
    if (!parts.length) return items

    let newItems = items
    parts.forEach((part: ISupplyPart) => {
      part.items.forEach((item: ISupplyPartItem) => {
        newItems = newItemsWithoutRemoveItem(
          item.id,
          item.pack_id,
          item.quantity,
          newItems,
        )
      })
    })

    return newItems
  }

  const handleGetData = (
    items: ISupplyItem[],
    parts: ISupplyPart[],
    originalItems: ISupplyOriginalItem[],
  ) => {
    dispatch({ type: AcceptanceFormActionTypes.setTares, tares: parts })

    const newItems = getItemsWithoutPartItems(items, parts)
    updateItems(newItems)

    const originalItemsWithBatches = originalItems.map((originalItems) => {
      const item = items?.find((item) => item.id === originalItems.id)
      if (item?.batches?.length) {
        return {
          ...originalItems,
          batches: item.batches,
        }
      } else {
        return originalItems
      }
    })

    dispatch({
      type: AcceptanceFormActionTypes.setCacheItems,
      cacheItems: originalItemsWithBatches,
    })
  }
  const {
    data: { success: supplyDataLoaded, receipts: receipt } = {
      success: false,
      receipts: null,
    },
  } = useGetSupplyById(
    { supplyId: params.supplyId },
    handleGetData,
    () => history.push('/'),
    () => setGlobalLoading(false),
  )

  const updateSupplyPart = useUpdateSupplyPart({ supplyId: params.supplyId })

  const startSupply = useStartSupply({ supplyId: params.supplyId })

  const getSticker = useGetSticker({ id: params.supplyId, type: 'supply' })

  const originalItemsCount = getItemsQuantity(receipt?.items)

  useEffect(() => {
    dispatch({
      type: AcceptanceFormActionTypes.setStartFormDate,
      startFormDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
    })
  }, [])

  const removeItemsFromPlan = (
    removingItemId: string,
    removingPackId: string,
    delCount = 1,
  ) => {
    const itemForDeletingIndex = items.findIndex(
      (item: ISupplyItem) =>
        item.id === removingItemId && item.pack_id === removingPackId,
    )

    if (itemForDeletingIndex > -1) {
      const newItems = [...items.map((item) => ({ ...item }))]
      if (items[itemForDeletingIndex].quantity === 1) {
        newItems.splice(itemForDeletingIndex, 1)
      } else {
        if (delCount < newItems[itemForDeletingIndex].quantity) {
          newItems[itemForDeletingIndex].quantity =
            newItems[itemForDeletingIndex].quantity - delCount
        } else {
          newItems[itemForDeletingIndex].quantity = 0
          newItems.splice(itemForDeletingIndex, 1)
        }
      }
      dispatch({ type: AcceptanceFormActionTypes.setItems, items: newItems })
    }

    dispatch({
      type: AcceptanceFormActionTypes.setAddingItem,
      addingItem: null,
    })
  }

  const isSurplus = (item: ISupplyPartItem) => {
    const planQuantity =
      receipt?.items.find(
        (rItem) => rItem.id === item.id && rItem.pack_id === item.pack_id,
      )?.quantity || 0
    const tareQuantity = tares.reduce((acc: number, tare: ISupplyPart) => {
      return allItemsByIdAndPackId(tare.items, item.id, item.pack_id) + acc
    }, 0)

    return tareQuantity > planQuantity
  }

  const handleAddItemToTare = async (newItems: ISupplyPartItem[]) => {
    if (curTareId === null) return false

    const newTares = tares.slice()
    const currentTare = newTares.find((tare) => tare.tare_id === curTareId)

    let allQuantity = newItems.reduce((acc, item) => acc + item.quantity, 0)
    removeItemsFromPlan(newItems[0].id, newItems[0].pack_id, allQuantity)

    newItems.forEach((newItem: ISupplyPartItem) => {
      const quantity = newItem.quantity

      newItem.quantity = 1
      ;[...Array(quantity)].forEach(
        () => currentTare && currentTare.items.unshift(newItem),
      )
    })

    await dispatch({
      type: AcceptanceFormActionTypes.setTares,
      tares: newTares,
    })

    if (receipt?.stickerTask) {
      handleGetSticker(newItems[0].id, allQuantity)
    }

    if (isSurplus(newItems[0])) {
      sound?.play('RECEIVING_SURPLUS_ITEM')
    } else {
      sound?.play('PACKING_ITEM_ADDED_TO_PLACE')
    }
  }

  const createPartFromTare = (tare: ISupplyPart) => {
    const part = {
      ...tare,
      items: tare.items.reduce(
        (
          a: ISupplyPartItem[],
          c: ISupplyPartItem,
          i: number,
          arr: ISupplyPartItem[],
        ) => {
          const matchScanSerials = (
            a?: ISerialNumberPart[],
            b?: ISerialNumberPart[],
          ) => {
            if (!a || !b) return null

            let match = true
            for (let key in a) {
              if (a[key].value !== b[key].value) {
                match = false
              }
            }
            return match
          }

          const findCurrentTareItem = a.find(
            (ci: ISupplyPartItem) =>
              ci.id === c.id &&
              ci.pack_id === c.pack_id &&
              ci.batch_id === c.batch_id &&
              (c?.serial_numbers?.length
                ? matchScanSerials(ci?.serial_numbers, c?.serial_numbers)
                : true),
          )
          if (findCurrentTareItem) {
            findCurrentTareItem.quantity =
              findCurrentTareItem.quantity + c.quantity
          } else {
            a.push({ ...c })
          }

          return a
        },
        [] as ISupplyPartItem[],
      ),
    }
    delete part.status

    return part
  }

  const updateAcceptedTare = (partId: string) => {
    const newTares = tares.slice()
    const currentTare = newTares.find((tare) => tare.tare_id === curTareId)
    if (currentTare) {
      currentTare.status = 'closed'
      currentTare.id = partId
    }

    dispatch({
      type: AcceptanceFormActionTypes.setTares,
      tares: newTares,
    })
  }

  const removeEmptyTare = (tareId: string) => {
    const newTares = tares.filter(
      (tare: ISupplyPart) => tare.tare_id !== tareId,
    )

    dispatch({
      type: AcceptanceFormActionTypes.setTares,
      tares: newTares,
    })
    dispatch({
      type: AcceptanceFormActionTypes.setCurTareId,
      curTareId: null,
    })
  }

  const updatePart = (tareId: string) => {
    const currentTare = tares.find((tare) => tare.tare_id === tareId)

    if (!currentTare?.items?.length) {
      removeEmptyTare(tareId)
      return
    }

    if (currentTare) {
      setGlobalLoading(true)
      const part = createPartFromTare(currentTare)

      console.log('part')
      console.log(part)

      updateSupplyPart
        .mutateAsync({ part })
        .then((res: useUpdateSupplyPartResult) => {
          dispatch({
            type: AcceptanceFormActionTypes.setCurTareId,
            curTareId: null,
          })
          updateAcceptedTare(res.part_id)

          notification?.show(
            'success',
            'Частичная приемка поставки прошла успешно',
            { soundName: 'PACKING_PLACE_POSTED' },
          )
        })
        .finally(() => setGlobalLoading(false))
    }
  }

  const createNewTare = (tareId: string) => {
    const newTare = {
      tare_id: tareId,
      condition: 'good',
      status: 'open',
      items: [],
    }
    dispatch({
      type: AcceptanceFormActionTypes.setTares,
      tares: [...tares, newTare],
    })
    sound?.play('PACKING_ITEM_ADDED_TO_PLACE')
  }

  const getStartSupply = () => {
    if (
      (receipt?.status === 'entered' || receipt?.status === 'arrived') &&
      !tares.length &&
      !supplyIsStart
    ) {
      startSupply.mutateAsync({ started_at: startFormDate }).then((result) => {
        dispatch({
          type: AcceptanceFormActionTypes.setStartSupply,
          supplyIsStart: true,
        })
      })
    }
  }

  const searchUnknownItemByBarcode = async (
    val: string | undefined,
    isChz: boolean,
  ) => {
    const barcode = isChz ? val?.slice(2, 16) : val
    await searchItem
      .fetch({
        merchantId: receipt?.merchant?.id ?? '',
        barcode: barcode,
      })
      .then((result) => {
        dispatch({
          type: AcceptanceFormActionTypes.setCacheItems,
          cacheItems: [...cacheItems, result.item],
        })
        dispatch({
          type: AcceptanceFormActionTypes.setAddingItem,
          addingItem: result.item,
        })
      })
      .catch(() => {
        notification?.show(
          'alert',
          `Товар со штрихкодом ${barcode} не найден`,
          { soundName: 'PACKING_WRONG_ITEM' },
        )
      })
  }

  const getCurrentItemByBarcode = (barcode: string, isChz: boolean) => {
    const formattedBarcode = isChz ? barcode.slice(2, 16) : barcode

    const item = cacheItems.find((item: ISupplyOriginalItem) =>
      item.barcodes.find(
        (itemBarcode) => itemBarcode.barcode === formattedBarcode,
      ),
    )
    if (!item) return
    const packId =
      item?.barcodes.find(
        (itemBarcode) => itemBarcode.barcode === formattedBarcode,
      )?.pack_id || ''

    return {
      ...item,
      pack_id: packId,
    }
  }

  const getCurrentItem = (val: string, type: 'barcode' | 'packId' | 'id') => {
    const item =
      type === 'id'
        ? cacheItems.find((item: ISupplyOriginalItem) => item.id === val)
        : cacheItems.find((item: ISupplyOriginalItem) =>
            item.packs.find((pack) => pack.id === val),
          )

    if (!item) return
    return {
      ...item,
      pack_id: type === 'packId' ? val : undefined,
    }
  }

  const checkIsNeedSaveChz = (
    val: string,
    currentItem: ISupplyOriginalItem,
  ) => {
    const hasChz = currentItem.scan_serial_numbers?.find(
      (num) => num.code === 'chestniy_znak',
    )
    return isChz(val) && receipt?.scan_serial_numbers && hasChz
  }

  const createOrUpdateTare = (value: string) => {
    if (value === curTareId) {
      updatePart(value)
      return
    }

    if (curTareId) {
      notification?.show(
        'alert',
        'Необходимо закрыть текущую тару, перед началом приемки новой',
      )
      return
    }
    if (tares.find((tare) => tare.tare_id === value)) {
      notification?.show('alert', 'Тара с таким id уже существует')
      return
    }

    getStartSupply()
    dispatch({
      type: AcceptanceFormActionTypes.setCurTareId,
      curTareId: value,
    })
    createNewTare(value)
  }

  const handleStartItem = async (
    value: TextFieldPropValue,
    type: 'barcode' | 'packId' | 'id' = 'barcode',
  ) => {
    if (!value) return

    let newVal = rusToLatin(value)

    if (checkIsTare(newVal)) {
      createOrUpdateTare(newVal)
      return
    }

    if (!curTareId) {
      notification?.show(
        'alert',
        'Перед началом приемки необходимо открыть тару',
      )
      return
    }

    const currentItem =
      type === 'barcode'
        ? getCurrentItemByBarcode(newVal, isChz(newVal))
        : getCurrentItem(newVal, type)

    if (currentItem && checkIsNeedSaveChz(newVal, currentItem)) {
      setSavedChz(newVal)
    }

    if (currentItem) {
      const newAddingItem = { ...currentItem }

      const cacheBatches = currentItem?.batches ?? []

      if (cacheBatches) {
        newAddingItem['batches'] = [...cacheBatches]
      }

      dispatch({
        type: AcceptanceFormActionTypes.setAddingItem,
        addingItem: newAddingItem,
      })
    } else {
      await searchUnknownItemByBarcode(
        type === 'barcode' ? newVal : undefined,
        isChz(newVal),
      )
    }
  }

  const printSticker = async (
    printerName: string,
    content: string,
    copies: number,
  ) => {
    if (checkBase64MIMEType(content) !== 'application/pdf') {
      notification?.show(
        'alert',
        'Ошибка формирования стикера - данные получены не в PDF',
      )
      return false
    }

    await printer?.setPrinter(printerName)
    await printer?.print(content, {
      onSuccess: () => {
        notification?.show('success', 'Стикер успешно отправлен на печать')
      },
      onError: (err) => {
        console.error('STICKER PRINT ERROR:')
        console.error(err)
        notification?.show('alert', `Ошибка печати стикера - ${err}`)
        dispatch({
          type: AcceptanceFormActionTypes.setSticker,
          sticker: {
            content: content,
            printer: printerName,
          },
        })
        dispatch({
          type: AcceptanceFormActionTypes.setShowPrintError,
          showPrintError: true,
        })
      },
      qzOptions: {
        copies: copies,
        printer: printerName,
      },
    })
  }

  const handleGetSticker = (itemId: string, count: number) => {
    getSticker.mutateAsync({ itemId }).then(async (res: getStickerResult) => {
      if (res.stickerExists && res.sticker) {
        const {
          sticker: { content, printer: printerName },
        } = res

        await printSticker(printerName, content, count)
      }
    })
  }

  const getReceivingItems = () => {
    return tares.reduce((ac: number, tare: ISupplyPart) => {
      const count = tare.items.reduce((ac: number, item: ISupplyPartItem) => {
        return ac + item.quantity
      }, 0)
      return ac + count
    }, 0)
  }

  const getQuantityConsideringPlanAndSurplus = (
    itemQuantity: number,
    planItemQuantity: number,
    allItemsTares: number,
    allItemsInCurTare: number,
  ) => {
    let quantity = 0
    if (allItemsTares === allItemsInCurTare) {
      if (planItemQuantity <= allItemsInCurTare) {
        quantity = planItemQuantity
      } else {
        quantity = itemQuantity
      }
    }
    if (planItemQuantity - (allItemsTares - allItemsInCurTare) > 0) {
      quantity = planItemQuantity - (allItemsTares - allItemsInCurTare)
    }

    return quantity
  }

  const handleUpdateItems = (deletedItems: ISupplyPartItem[]) => {
    let newItems = items.slice()

    deletedItems.forEach((item: ISupplyPartItem) => {
      const planItem = receipt?.items?.find(
        (i) => i.id === item.id && i.pack_id === item.pack_id,
      )
      const originalItem = newItems.find(
        (i) => i.id === item.id && i.pack_id === item.pack_id,
      )

      const allItemsInCurTare =
        allItemsByIdAndPackId(deletedItems, item.id, item.pack_id) || 0
      const allItemsTares =
        allItemsInArrItemsByIdAndPackId(tares, item.id, item.pack_id) || 0

      if (planItem && originalItem) {
        const allItemsInOtherTares =
          planItem.quantity - (allItemsTares - allItemsInCurTare)

        if (
          planItem.quantity >= originalItem.quantity + item.quantity &&
          allItemsInOtherTares > originalItem.quantity
        ) {
          originalItem.quantity = originalItem.quantity + item.quantity
        }
        if (allItemsInOtherTares) {
          originalItem.quantity = allItemsInOtherTares
        }
      }

      if (planItem && !originalItem) {
        const quantity = getQuantityConsideringPlanAndSurplus(
          item.quantity,
          planItem.quantity,
          allItemsTares,
          allItemsInCurTare,
        )
        if (quantity) {
          let newItem = { ...planItem }
          newItem.quantity = quantity
          newItems = [...newItems, newItem]
        }
      }
    })

    dispatch({
      type: AcceptanceFormActionTypes.setItems,
      items: [...newItems],
    })
  }

  const handleConfirmGoods = (type: string) => {
    if (curTareId === null) return false

    const newTares = tares.slice()
    const currentTare = newTares.find((tare) => tare.tare_id === curTareId)
    if (currentTare) {
      type === 'defective'
        ? (currentTare.condition = 'defective')
        : (currentTare.condition = 'good')
    }
    dispatch({ type: AcceptanceFormActionTypes.setTares, tares: newTares })
  }

  const handleAccepted = () => {
    if (curTareId) {
      notification?.show('alert', 'Закройте тару перед завершением приемки')
      return false
    }
    acceptedSupplyMutation.mutateAsync().then((res) => {
      notification?.show('success', 'Приемка успешно завершена')
      history.push(generatePath(PATHS.ACCEPTANCE_SCAN))
    })
  }

  const handlePauseSupply = () => {
    if (curTareId) {
      dispatch({
        type: AcceptanceFormActionTypes.setShowOpenTareError,
        showOpenTareError: true,
      })
      return
    }
    history.push(generatePath(PATHS.ACCEPTANCE_SCAN))
  }

  const handleConfirm = () => {
    history.push(generatePath(PATHS.ACCEPTANCE_SCAN))
  }

  const handleReducedQuantity = (
    id: string,
    packId: string,
    batchId?: string,
  ) => {
    const curTare = tares.find((tare) => tare.tare_id === curTareId)
    const items = curTare?.items

    if (!items || !curTare) return

    const deleteItem = items.find(
      (item: ISupplyPartItem) =>
        item.id === id &&
        item.pack_id === packId &&
        (batchId ? batchId === item.batch_id : true),
    )

    const deleteIndex = items.findIndex(
      (item: ISupplyPartItem) =>
        item.id === id &&
        item.pack_id === packId &&
        (batchId ? batchId === item.batch_id : true),
    )
    const newItems = items.filter(
      (item: ISupplyPartItem, i: number) => i !== deleteIndex,
    )
    const newTares = tares.filter(
      (tare: ISupplyPart) => tare.tare_id !== curTareId,
    )
    dispatch({
      type: AcceptanceFormActionTypes.setTares,
      tares: [
        ...newTares,
        {
          ...curTare,
          items: newItems,
        },
      ],
    })

    const planItemsCount =
      receipt?.items.find((item) => item.id === id && item.pack_id === packId)
        ?.quantity ?? 0
    const allItemsInTaresCount = allItemsInArrItemsByIdAndPackId(
      tares,
      id,
      packId,
    )

    if (deleteItem && planItemsCount >= allItemsInTaresCount) {
      handleUpdateItems([deleteItem])
    }
  }

  const handleCloseAddingItem = (isFinal?: boolean) => {
    if (!isFinal) {
      notification?.show('warning', 'Приемка позиции отменена')
    }
    dispatch({
      type: AcceptanceFormActionTypes.setAddingItem,
      addingItem: null,
    })
  }

  const handleChooseItem = (item: ISupplyItem) => {
    const packId = item.pack_id

    if (packId) {
      handleStartItem(packId, 'packId')
    } else {
      handleStartItem(item.id, 'id')
    }
  }

  const handleClosePrintError = () => {
    dispatch({
      type: AcceptanceFormActionTypes.setShowPrintError,
      showPrintError: false,
    })
    dispatch({
      type: AcceptanceFormActionTypes.setSticker,
      sticker: null,
    })
    blurOrFocusBarcodeInput('focus')
  }

  useEffect(() => {
    console.log('tares')
    console.log(tares)
  }, [tares])

  const blurOrFocusBarcodeInput = (type: 'focus' | 'blur') => {
    if (!barcodeInput?.current) return
    type === 'focus'
      ? barcodeInput.current.focus()
      : barcodeInput.current.blur()
  }

  const handleCloseWithFocusBarcode = (act: AcceptanceFormActions) => {
    dispatch(act)
    blurOrFocusBarcodeInput('focus')
  }

  if (!supplyDataLoaded) return null

  return (
    <>
      <div className={styles.main}>
        <SideBar
          btnsClass={styles.leftPanelBtn}
          bottomBtns={
            <>
              <div>
                <Button
                  label="Описание приемки"
                  view="secondary"
                  onClick={() => false}
                />
                <Button
                  label="Отчет о приемке"
                  view="secondary"
                  onClick={() =>
                    dispatch({
                      type: AcceptanceFormActionTypes.setShowDiscrepancies,
                      showDiscrepancies: true,
                    })
                  }
                />
              </div>
              <div>
                <Button
                  label="Приостановить"
                  disabled={!tares.length}
                  onClick={handlePauseSupply}
                />
                <Button
                  label="Завершить"
                  disabled={!tares.length}
                  onClick={handleAccepted}
                />
              </div>
            </>
          }
        >
          <div className={styles.packCount}>
            Принято:
            <h2>
              <span>{getReceivingItems() || 0}</span> из{' '}
              {originalItemsCount || 0}
            </h2>
          </div>

          <div className={styles.packName}>
            <div>
              Приемка товара <h2>{receipt?.id}</h2>
            </div>
            <span className={styles.merchant}>
              {receipt?.merchant?.title}{' '}
              {receipt?.merchant?.trade_title !== receipt?.merchant?.title ? (
                <>
                  {!receipt?.merchant?.trade_title ||
                    !receipt?.merchant?.title ||
                    ' | '}{' '}
                  {receipt?.merchant?.trade_title}
                </>
              ) : null}
            </span>
            <Badge
              status="system"
              label={receipt?.warehouse?.title || ''}
              className={styles.badge}
              size="l"
            />
          </div>

          <ItemScanner
            label="Отсканируйте штрихкод"
            itemCountCheckboxValue={indicateItemCount}
            onItemCountCheckboxChange={(checked) =>
              dispatch({
                type: AcceptanceFormActionTypes.setIndicateItemCount,
                indicateItemCount: checked,
              })
            }
            onChange={(val) => handleStartItem(val, 'barcode')}
            inputRef={barcodeInput}
            additional={
              <button
                className={styles.searchBtn}
                onClick={() =>
                  dispatch({
                    type: AcceptanceFormActionTypes.setShowSearchItemsModal,
                    showSearchItemsModal: true,
                  })
                }
              >
                Поиск
              </button>
            }
          />
        </SideBar>
        <div className={styles.bodyWrap}>
          <div className={styles.body}>
            <div className={styles.mainBlock}>
              {items.length ? (
                <UnProcessed
                  cacheItems={cacheItems}
                  planItems={items}
                  handleChooseItem={handleChooseItem}
                />
              ) : null}
              {tares.length ? (
                <AcceptedTable
                  handleReducedQuantity={handleReducedQuantity}
                  handleUpdateItems={handleUpdateItems}
                  handleConfirmGoods={handleConfirmGoods}
                  handleUpdateTares={(newTares) =>
                    dispatch({
                      type: AcceptanceFormActionTypes.setTares,
                      tares: newTares,
                    })
                  }
                  tares={tares}
                  cacheItems={cacheItems}
                  curTareId={curTareId}
                  supplyId={params.supplyId}
                  planItems={receipt?.items}
                  blurOrFocusBarcodeInput={blurOrFocusBarcodeInput}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {addingItem ? (
        <ItemAdding
          indicateItemCount={indicateItemCount}
          addingItem={addingItem}
          cacheItems={cacheItems}
          updateCache={(newItems: ISupplyOriginalItem[]) =>
            dispatch({
              type: AcceptanceFormActionTypes.setCacheItems,
              cacheItems: newItems,
            })
          }
          setAddingItem={(item: ISupplyOriginalItem) =>
            dispatch({
              type: AcceptanceFormActionTypes.setAddingItem,
              addingItem: item,
            })
          }
          items={receipt?.items ?? []}
          tares={tares}
          currentTare={curTareId}
          onAddItemToTare={handleAddItemToTare}
          scanSerial={receipt?.scan_serial_numbers}
          onClose={handleCloseAddingItem}
          blurOrFocusBarcodeInput={blurOrFocusBarcodeInput}
        />
      ) : null}

      {showOpenTareError ? (
        <OpenTareErrorModal
          isOpen={true}
          onClose={() =>
            handleCloseWithFocusBarcode({
              type: AcceptanceFormActionTypes.setShowOpenTareError,
              showOpenTareError: false,
            })
          }
          handleConfirm={handleConfirm}
        />
      ) : null}

      {showDiscrepancies ? (
        <DiscrepanciesModal
          isOpen={true}
          planItems={receipt?.items ?? []}
          cacheItems={cacheItems}
          tares={tares}
          supplyId={receipt?.id}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          onClose={() =>
            handleCloseWithFocusBarcode({
              type: AcceptanceFormActionTypes.setShowDiscrepancies,
              showDiscrepancies: false,
            })
          }
        />
      ) : null}

      {showSearchItemsModal ? (
        <SearchItemsModal
          isOpen={true}
          merchantId={receipt?.merchant?.id ?? ''}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          onClose={() =>
            handleCloseWithFocusBarcode({
              type: AcceptanceFormActionTypes.setShowSearchItemsModal,
              showSearchItemsModal: false,
            })
          }
        />
      ) : null}

      {showPrintError ? (
        <StickerErrorModal
          isOpen={true}
          sticker={sticker}
          onClose={handleClosePrintError}
          onMount={() => blurOrFocusBarcodeInput('blur')}
        />
      ) : null}
    </>
  )
}

const AcceptanceFormPageWithContext = () => (
  <AcceptanceContextProvider>
    <AcceptanceFormPage />
  </AcceptanceContextProvider>
)

export default AcceptanceFormPageWithContext
