import React, { useEffect, useState } from 'react'

import styles from './informer.module.scss'

import { Timer } from '@consta/uikit/Timer'
import { Button } from '@consta/uikit/Button'

interface IInformer {
  onClose: () => void
  text: string
}

const Informer = ({ onClose, text }: IInformer) => {
  const [timeCount, setTimeCount] = useState<number>(5)
  const [timer, setTimer] = useState<any>(null)

  const countDown = () => {
    setTimeCount((state) => state - 1)
  }

  useEffect(() => {
    setTimer(setInterval(countDown, 1000))
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (timeCount === 0) {
      onClose()
    }
  }, [timeCount])

  return (
    <div className={styles.informer}>
      <div className={styles.left}>
        <Timer
          className={styles.timer}
          size="m"
          seconds={timeCount}
          progress={timeCount * 20}
        />
        <div className={styles.text}>{text}</div>
      </div>
      <Button
        className={styles.btn}
        size="s"
        onClick={onClose}
        label="Закрыть"
        view="ghost"
      />
    </div>
  )
}

export default Informer
