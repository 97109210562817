import { useState } from 'react'

import { useUpdatePalletCount } from 'src/hooks'
import { usePackingOrderContext } from '../../context'

interface useGetPalletCount {
  orderId: string
  onSuccessUpdateWithoutCount: () => void
  onSuccessUpdatePalletCount: (
    startPalletNum: number,
    endPalletNum: number,
  ) => Promise<void>
}

interface useGetPalletCountRes {
  handleUpdatePalletCount: (count: string) => Promise<void>
  showPalletCount: boolean
  palletCount: null | number
  setShowPalletCount: (val: boolean) => boolean
}

const useGetPalletCount = ({
  orderId,
  onSuccessUpdateWithoutCount,
  onSuccessUpdatePalletCount,
}: useGetPalletCount) => {
  const { pallets, setPalletCount } = usePackingOrderContext()

  const [showPalletCount, setShowPalletCount] = useState<boolean>(false)

  const updatePalletCount = useUpdatePalletCount({
    orderId,
  })

  const handleUpdatePalletCount = (count: string): void => {
    updatePalletCount.mutateAsync({ palletCount: count }).then(() => {
      setShowPalletCount(false)

      if (+count && !pallets.length) {
        setPalletCount(+count)
        onSuccessUpdatePalletCount(1, +count)
        return
      }

      onSuccessUpdateWithoutCount()
    })
  }

  return {
    handleUpdatePalletCount,
    showPalletCount,
    setShowPalletCount,
  } as useGetPalletCountRes
}

export default useGetPalletCount
