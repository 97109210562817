import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import styles from './select-batch-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import {
  Modal,
  InputWrapper,
  InputWithDateMask,
  SelectList,
  ItemPreview,
  EnterHandler,
} from 'src/components'

import { useCreateBatch } from 'src/hooks'

import {
  ISelectListOption,
  ModalProps,
  ISupplyItem,
  ISupplyOriginalItem,
  useCreateBatchResult,
  IBatch,
} from 'src/interfaces'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconCalendar } from '@consta/uikit/IconCalendar'

interface ISelectBatchModal extends ModalProps {
  expdate?: boolean
  item: ISupplyOriginalItem | null
  onSubmit: (batch: IBatch, isNewBatch?: boolean) => void
}

const SelectBatchModal = (props: ISelectBatchModal) => {
  const { isOpen, onSubmit, expdate, item, onClose } = props

  const createBatchMutation = useCreateBatch({ itemId: item?.id ?? '' })

  const [selectedBatch, setSelectedBatch] = useState<ISelectListOption | null>(
    null,
  )
  const [batchNum, setBatchNum] = useState<TextFieldPropValue>(null)
  const [batchDate, setBatchDate] = useState<TextFieldPropValue>(null)

  const [batchesOptions, setBatchesOptions] = useState<ISelectListOption[]>([])

  useEffect(() => {
    if (item) {
      const options =
        item?.batches?.map((b) => {
          return {
            label: (
              <span className={styles.batchSelect}>
                {dayjs(b.exp_date).format('DD.MM.YYYY')} <span>{b.num}</span>
              </span>
            ),
            id: b.id,
            value: b.id,
          }
        }) ?? []
      setBatchesOptions(options)
      if (options.length === 1) {
        setSelectedBatch(options[0])
      }
    }
  }, [])

  const handleChoiceBatch = () => {
    const findBatch = item?.batches?.find(
      (b: IBatch) => b.id === selectedBatch?.value,
    )

    if (findBatch) {
      onSubmit(findBatch)
    }
  }

  const handleAddNewBatch = async () => {
    const newBatch = {
      num: batchNum ? String(batchNum) : undefined,
      exp_date: batchDate
        ? dayjs(batchDate, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')
        : undefined,
    }

    createBatchMutation
      .mutateAsync({
        batch: newBatch,
      })
      .then((result: useCreateBatchResult) => {
        onSubmit(
          {
            id: result.batch_id,
            ...newBatch,
          },
          true,
        )
      })
  }

  const handleEnter = () => {
    if (selectedBatch) {
      handleChoiceBatch()
    } else {
      handleAddNewBatch()
    }
  }

  return (
    <EnterHandler onEnter={handleEnter}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.selectBatch}
        withClose
        onClose={onClose}
      >
        <div className={styles.top}>
          {item && (
            <ItemPreview
              image={item.image}
              title={item.title}
              barcode={
                item.barcodes?.find(
                  (barcode) => barcode.pack_id === item.pack_id,
                )?.barcode ?? ''
              }
            />
          )}
        </div>

        <div className={styles.body}>
          <div className={styles.left}>
            <h3>Выберите партию из списка</h3>
            <SelectList
              options={batchesOptions}
              value={selectedBatch}
              onChange={(value) => setSelectedBatch(value)}
              className={styles.select}
            />
            <Button
              label="Выбрать"
              className={styles.btn}
              disabled={!selectedBatch}
              onClick={handleChoiceBatch}
            />
          </div>
          <div className={styles.right}>
            <h3>Новая партия</h3>
            <div className={styles.newBatch}>
              {expdate ? (
                <InputWithDateMask
                  value={batchDate}
                  id={'dateTo'}
                  handleChange={(value: TextFieldPropValue) =>
                    setBatchDate(value)
                  }
                  className={styles.itemInput}
                  size="l"
                  placeholder="DD.MM.20YY"
                  rightSide={IconCalendar}
                />
              ) : null}

              <InputWrapper
                value={batchNum}
                id={'batchNum'}
                handleChange={(value: TextFieldPropValue) =>
                  setBatchNum(String(value))
                }
                className={styles.itemInput}
                placeholder={'№ партии'}
                size="l"
              />
            </div>
            <Button
              label="Добавить партию"
              className={styles.btn}
              disabled={expdate ? !batchNum || !batchDate : !batchNum}
              onClick={handleAddNewBatch}
            />
          </div>
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default SelectBatchModal
