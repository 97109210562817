import React from 'react'

import styles from './combobox-wrapper.module.scss'

import { Combobox } from '@consta/uikit/Combobox'
import { Loader } from '@consta/uikit/Loader'

import { ComboboxProps } from '@consta/uikit/Combobox'

import { useAppContext } from 'src/context'

import { ComboboxItem } from 'src/interfaces'

interface ComboboxWrapperProps extends ComboboxProps {
  id?: string
  label?: string
  isMultiple?: boolean
  searchFunction?: (item: any, searchValue: string) => boolean
  loading?: boolean
}

const ComboboxWrapper = (props: ComboboxWrapperProps) => {
  const {
    id,
    label,
    items,
    value,
    onChange,
    isMultiple,
    renderItem,
    size,
    searchFunction,
    loading,
    placeholder,
  } = props

  const { resetAfkInterval } = useAppContext()

  const handleChange = (data: {
    value: ComboboxItem | null
    e: React.SyntheticEvent<Element, Event>
  }) => {
    resetAfkInterval()

    onChange(data)
  }

  return (
    <div className={styles.wrapper}>
      {label ? <label>{label}</label> : null}
      <Combobox
        items={items}
        value={value}
        onChange={onChange}
        multiple={isMultiple}
        renderItem={renderItem}
        size={size}
        searchFunction={searchFunction}
        placeholder={placeholder}
      />
      {loading ? <Loader size="s" className={styles.loader} /> : null}
    </div>
  )
}

export default ComboboxWrapper
