import React, { useEffect, useState } from 'react'

import styles from './additional-docs-modal.module.scss'

import IconPrinter from './assets/IconPrinter.svg'
import IconPrinterBlue from './assets/IconPrinterBlue.svg'
import IconEye from './assets/IconEye.svg'
import IconSettings from './assets/IconSettings.svg'

import { Button } from '@consta/uikit/Button'
import { File } from '@consta/uikit/File'
import { IconAllDone } from '@consta/uikit/IconAllDone'

import { Modal } from 'src/components'

import { usePackingOrderContext } from '../../context'

import { useGetAddFile } from 'src/hooks'
import { useNotifications } from 'src/notification'
import { usePrinter } from 'src/printer'

import { ModalProps, IAddFile } from 'src/interfaces'

interface IAdditionalDocsModal extends ModalProps {
  isAllRelabel?: boolean
  isNeedAllPrint?: boolean
}

const AdditionalDocsModal = (props: IAdditionalDocsModal) => {
  const {
    isOpen,
    onClose,
    onMount,
    isAllRelabel = false,
    isNeedAllPrint = true,
  } = props

  const [files, setFiles] = useState<IAddFile[]>([])

  const isAllPrinted = files.length !== files.filter((f) => f.printed).length

  const { addFiles, setAddFiles, order } = usePackingOrderContext()

  const getPrintFile = useGetAddFile()
  const notification = useNotifications()
  const printer = usePrinter()

  useEffect(() => {
    setFiles(addFiles)
  }, [addFiles])

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  const printSticker = async (
    id: string,
    sticker: {
      content: string
      printer: string
    },
    options: {
      copies: number
      successMessage: string
    },
  ) => {
    await printer?.print(sticker.content, {
      onSuccess: () => {
        notification?.show('success', options.successMessage)
        markedFileAsPrinted(id)
      },
      onError: (err) => {
        console.error('STICKER PRINT ERROR:')
        console.error(err)
        notification?.show('alert', `Ошибка печати документа - ${err}`)
      },
      qzOptions: {
        copies: options.copies,
        printer: sticker.printer,
      },
    })
  }

  const markedFileAsPrinted = (id: string) => {
    setFiles((prevState) =>
      prevState.map((f) => {
        if (f.id === id) {
          return {
            ...f,
            printed: true,
          }
        } else {
          return f
        }
      }),
    )
  }

  const handlePrintFile = async (
    id: string,
    fileName: string,
    copies: number,
  ) => {
    if (!order?.id) return
    try {
      const result = await getPrintFile.fetch({
        id,
        orderId: order.id,
      })
      if (result.success) {
        if (!result.content) {
          notification?.show('alert', `Ошибка печати документа ${fileName}`)
          return Promise.resolve()
        }

        if (!result.printer) {
          notification?.show(
            'alert',
            `Ошибка печати документа ${fileName} - не указан принтер`,
          )
          return Promise.resolve()
        }

        const printer = 'Microsoft Print to PDF'
        //const printer = 'PRINTER_A4'

        const sticker = {
          content: result.content,
          printer: result.printer,
        }

        await printSticker(id, sticker, {
          copies,
          successMessage: `Документ ${fileName} успешно отправлен на печать`,
        })
      }
    } catch (e) {
      console.log(e)
      notification?.show('alert', `Ошибка печати документа ${fileName}`)
      return Promise.resolve()
    }
  }
  const handlePrintAllFiles = async () => {
    for (const file of files) {
      await handlePrintFile(file.id, file.name, file.copies ?? 1)
    }
  }

  const handleClose = () => {
    setAddFiles(files)
    onClose()
  }

  const getFileExt = (name: string) => {
    const arrName = name.split('.')
    return arrName[arrName.length - 1]
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.additionalDocs}
      title="Печать дополнительных документов"
      size="s"
    >
      {/*<img className={styles.settings} src={IconSettings} alt="IconSettings" />*/}
      <h4 className={styles.subtitle}>
        Напечатайте документы из списка и приложите к заказу
      </h4>
      <div className={styles.listWrap}>
        <ul className={styles.list}>
          {files.map((file) => (
            <li className={styles.listItem} key={file.id}>
              <div className={styles.info}>
                <File extension={getFileExt(file.name)} />
                <span>{file.name}</span>
              </div>
              <div className={styles.actions}>
                {file.printed ? <IconAllDone size="s" view="success" /> : null}
                {file.url ? (
                  <a
                    href={
                      file.url.includes('https://')
                        ? `${file.url}`
                        : `https://${file.url}`
                    }
                    target="_blank"
                  >
                    <img className={styles.view} src={IconEye} alt="Eye" />
                  </a>
                ) : null}
                <img
                  className={styles.print}
                  src={IconPrinter}
                  alt="printer"
                  onClick={() =>
                    handlePrintFile(file.id, file.name, file.copies ?? 1)
                  }
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.btns}>
        <Button
          label="Завершить"
          className={styles.btn}
          onClick={handleClose}
          disabled={isAllRelabel && isNeedAllPrint ? isAllPrinted : false}
        />
        <button
          className={styles.additionalDocsBtn}
          onClick={handlePrintAllFiles}
        >
          <img src={IconPrinterBlue} alt="IconPrinterBlue" />
          <span>Напечатать все</span>
        </button>
      </div>
    </Modal>
  )
}

export default AdditionalDocsModal
