import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_START_SUPPLY } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface useStartSupplyParamsType {
  supplyId: string
}

interface useStartSupplyParamsType {
  supplyId: string
}

const useStartSupply = (params: useStartSupplyParamsType) => {
  return useMutation(async (data: { started_at: string }) => {
    const result = await provideMock<
      useStartSupplyParamsType,
      { data: ApiReqResult }
    >(
      async () =>
        await api.post(`/receiving/supply/${params.supplyId}/start`, data),
      params,
      {
        key: 'supplyId',
        value: 'mock',
        result: MOCK_START_SUPPLY,
      },
    )()

    if (!result?.data?.success) {
      throw result
    }
    return result?.data
  })
}

export default useStartSupply
