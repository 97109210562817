import React, { useEffect, useState } from 'react'

import styles from './item-scan-serial-modal.module.scss'

import { TextFieldPropValue } from '@consta/uikit/TextField'

import { Modal, ScanSerialInput, ItemPreview } from 'src/components'

import { usePackingOrderContext } from '../../../context'
import { useCheckMatchWithItemBarcodes } from '../../../hooks'

import { useNotifications } from 'src/notification'

import { ModalProps, ISerialNumber, IOrderItem } from 'src/interfaces'

interface ItemScanSerialModalProps extends ModalProps {
  item: IOrderItem | null
  onSubmit: (items: IOrderItem[]) => void
  items: IOrderItem[]
  multiple?: boolean
}

const ItemScanSerialModal = (props: ItemScanSerialModalProps) => {
  const { isOpen, onClose, item, onSubmit, items, multiple = false } = props

  const notification = useNotifications()

  const { cachedSerials, setCachedSerials, order } = usePackingOrderContext()

  const checkBarcode = useCheckMatchWithItemBarcodes({
    items: order?.items ?? [],
  })

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [newItems, setNewItems] = useState<IOrderItem[]>([])

  useEffect(() => {
    setNewItems(items)
  }, [items])

  const handleChangeValue = (
    val: TextFieldPropValue,
    code: string,
    scanIndex: number,
  ) => {
    if (!val || !newItems.length) return

    if (code === 'chestniy_znak' && val.trim().length > 129) {
      notification?.show(
        'alert',
        `Ошибка сканирования - некорректная длина честного знака`,
      )
      return false
    }

    if (cachedSerials.includes(val.trim())) {
      notification?.show(
        'alert',
        `Ошибка сканирования - такой серийный номер уже был введён.`,
      )
      return false
    }

    if (!checkBarcode(val.trim())) {
      notification?.show(
        'alert',
        `Ошибка сканирования - совпадает с шк товара.`,
      )
      return false
    }

    if (cachedSerials.includes(val.trim())) {
      notification?.show(
        'alert',
        `Ошибка сканирования - такой серийный номер уже был введён.`,
      )
      return false
    }

    if (val && /[а-яА-Я]/i.test(val)) {
      notification?.show(
        'alert',
        `Обнаружена кириллица в серийном номере. Измените раскладку клавиатуры.`,
      )
      return false
    }

    //@ts-ignore
    setCachedSerials((state) => [...state, val.trim()])

    const newScannedSerialNumber = {
      code,
      value: String(val),
    }

    const actualItems = newItems.map((item: IOrderItem, i: number) => {
      const newSerialNumbers =
        activeIndex === i
          ? [...(item?.serial_numbers ?? []), { ...newScannedSerialNumber }]
          : item.serial_numbers

      return {
        ...item,
        serial_numbers: newSerialNumbers,
      }
    })

    setNewItems(actualItems)

    const isLastSerialCode =
      (item?.scan_serial_numbers?.length ?? -1) - 1 === scanIndex
    if (!isLastSerialCode) return

    const hasUnfilledSerials = multiple && activeIndex + 1 !== newItems.length
    if (hasUnfilledSerials) {
      setActiveIndex(activeIndex + 1)
    } else {
      onSubmit(actualItems)
    }
  }

  const getStatus = (activeIndex: number, scan: ISerialNumber) => {
    const val = newItems[activeIndex]?.serial_numbers?.find(
      (ser) => ser?.code === scan.code,
    )?.value
    if (val) {
      return 'done'
    }

    const findItemIndex =
      item?.scan_serial_numbers.findIndex((item) => item.code === scan.code) ??
      -1

    if (findItemIndex > -1) {
      const prevScanCode = item?.scan_serial_numbers[findItemIndex - 1]?.code
      const prevScanVal = newItems[activeIndex]?.serial_numbers?.find(
        (ser) => ser.code === prevScanCode,
      )?.value
      if (findItemIndex > 0 && !prevScanVal) {
        return 'disabled'
      }
    }
    return 'undone'
  }

  const getValue = (scan: ISerialNumber) => {
    return newItems[activeIndex]?.serial_numbers?.find(
      (ser) => ser?.code === scan.code,
    )?.value
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.itemCount}
      size="m"
    >
      <ItemPreview
        image={item?.image ?? ''}
        title={item?.title ?? ''}
        barcode={item?.barcode_used ?? ''}
      />

      {multiple && newItems.length > 1 ? (
        <div className={styles.count}>
          {activeIndex + 1} из {newItems.length}
        </div>
      ) : null}

      <div className={styles.wrap}>
        {item?.scan_serial_numbers.map((scan: ISerialNumber, i: number) => (
          <ScanSerialInput
            key={`${i}-${activeIndex}`}
            value={getValue(scan) ?? ''}
            onChange={(val: TextFieldPropValue) =>
              handleChangeValue(val, scan.code, i)
            }
            status={getStatus(activeIndex, scan)}
            code={scan.code}
            label={`Введите ${scan.title}`}
            template={scan.template}
          />
        ))}
      </div>
    </Modal>
  )
}

export default ItemScanSerialModal
