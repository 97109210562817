import React, { useState, useEffect } from 'react'
import { Link, generatePath } from 'react-router-dom'
import days from 'dayjs'
import cx from 'classnames'

import styles from './acceptance-scan-page.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconSearch } from '@consta/uikit/IconSearch'
import { IconFilter } from '@consta/uikit/IconFilter'
import { IconTop } from '@consta/uikit/IconTop'
import { Badge } from '@consta/uikit/Badge'
import { Loader } from '@consta/uikit/Loader'

import { InputWrapper, ScanWrapper, Table } from 'src/components'

import AcceptanceFilter from './AcceptanceFilter'

import { useAppContext } from 'src/context'

import {
  ACCEPTANCE_STATUSES,
  ACCEPTANCE_STATUSES_STYLES,
  DEFAUTL_STATUSES,
  PATHS,
} from 'src/config'

import {
  IAcceptanceItem,
  IAcceptanceRowItem,
  IAcceptanceColumn,
  getSuppliesResult,
  getAcceptanceOptionsResult,
  IAcceptanceFilterDate,
  ComboboxItem,
  IAcceptanceFilterOptions,
  IGetSupplyReqObj,
  IOptionsLoading,
} from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { useGetSupplies, useGetAcceptanceOptions } from '../../hooks'

//TODO useBrowserQuery

const columns: IAcceptanceColumn[] = [
  {
    title: <div className={styles.tableHead}>Дата поставки</div>,
    accessor: `date`,
    renderCell: (row: IAcceptanceRowItem) => (
      <div className={styles.date}>
        {row.date}
        <div>{row.time}</div>
      </div>
    ),
  },
  {
    //'/acceptance/:supplyId'
    title: <div className={styles.tableHead}>№ Поставки</div>,
    accessor: `supplyId`,
    renderCell: (row: IAcceptanceRowItem) => (
      <div className={styles.column}>
        <Link
          to={generatePath(PATHS.ACCEPTANCE_FORM, { supplyId: row.supplyId })}
        >
          {row.supplyId}
        </Link>
      </div>
    ),
  },
  {
    title: (
      <div className={styles.headContragent}>
        Контрагент <div>Коммерч. наименование</div>
      </div>
    ),
    accessor: `counteragent`,
    width: 290,
    renderCell: (row: IAcceptanceRowItem) => (
      <div className={styles.contragent}>
        {row.counteragent}
        <div>{row.tradeName}</div>
      </div>
    ),
    sortByField: 'counteragent',
  },
  {
    title: <div className={styles.tableHead}>Поставщик</div>,
    accessor: `supplier`,
    renderCell: (row: IAcceptanceRowItem) => (
      <div className={styles.column}>{row.supplier}</div>
    ),
  },
  {
    title: <div className={styles.tableHead}>Склад</div>,
    accessor: `warehouse`,
    renderCell: (row: IAcceptanceRowItem) => (
      <div className={styles.column}>{row.warehouse}</div>
    ),
  },
  {
    title: <div className={styles.tableHead}>Статус поставки</div>,
    accessor: `status`,
    renderCell: (row) => (
      <Badge
        status={ACCEPTANCE_STATUSES_STYLES[row.status]}
        label={ACCEPTANCE_STATUSES[row.status]}
        className={styles.badge}
      />
    ),
  },
]

const AcceptanceScanPage = () => {
  const { setGlobalLoading, advanceMode } = useAppContext()

  const [multiSearch, setMultiSearch] = useState<TextFieldPropValue>('')
  const [date, setDate] = useState<IAcceptanceFilterDate>({
    from: null,
    to: null,
  })
  const [selectedContragents, setSelectedContragents] =
    useState<ComboboxItem | null>(null)
  const [selectedSuppliers, setSelectedSuppliers] =
    useState<ComboboxItem | null>(null)
  const [selectedWarehouses, setSelectedWarehouses] =
    useState<ComboboxItem | null>(null)

  const [selectedStatuses, setSelectedStatuses] = useState<ComboboxItem | null>(
    // @ts-ignore
    DEFAUTL_STATUSES,
  )

  const [options, setOptions] = useState<IAcceptanceFilterOptions>({
    suppliers: [],
    contragents: [],
    warehouses: [],
  })

  const [rows, setRows] = useState<IAcceptanceRowItem[] | []>([])
  const [receipts, setReceipts] = useState<IAcceptanceItem[] | []>([])

  const [searchType, setSearchType] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')

  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [optionsLoading, setOptionsLoading] = useState<IOptionsLoading>({
    suppliers: false,
    contragents: false,
    warehouses: false,
  })

  const getSupplies = useGetSupplies() //TODO use useQuery, not useMutation

  const getAcceptanceOptions = useGetAcceptanceOptions()

  const handleSubmit = () => {
    setLoading(true)
    setTableLoading(true)
    // @ts-ignore
    const statuses = selectedStatuses?.length
      ? // @ts-ignore
        selectedStatuses.map((s) => s.value).join(',')
      : null

    const reqObj: IGetSupplyReqObj = {
      multi: multiSearch ? multiSearch : undefined,
      counteragent: selectedContragents?.value,
      supplier: selectedSuppliers?.value,
      warehouse: selectedWarehouses?.value,
      dateFrom: date.from
        ? days(date.from).format('YYYY-MM-DDTHH:mm:ss')
        : undefined,
      dateTo: date.to ? days(date.to).format('YYYY-MM-DDT23:59:59') : undefined,
      status: statuses,
    }

    getSupplies
      .mutateAsync(reqObj)
      .then((result: getSuppliesResult) => {
        const { receipts } = result
        setReceipts(receipts)
      })
      .finally(() => {
        setLoading(false)
        setTableLoading(false)
      })
  }

  useEffect(() => {
    handleSubmit()
  }, [])

  const handleClear = () => {
    setSelectedContragents(null)
    setSelectedSuppliers(null)
    setSelectedWarehouses(null)
    setMultiSearch('')
    setDate({
      from: null,
      to: null,
    })
    // @ts-ignore
    setSelectedStatuses(DEFAUTL_STATUSES)
  }

  const sortAcceptanceTable = (a: IAcceptanceItem, b: IAcceptanceItem) => {
    return days(b.date).isBefore(days(a.date)) ? -1 : 1
  }

  useEffect(() => {
    const newData = receipts?.sort(sortAcceptanceTable).map((item) => ({
      id: `${item.id}-${item.date}`,
      supplyId: item.id,
      date: days(item.date).format('DD.MM.YYYY'),
      time: days(item.date).format('HH:mm:ss'),
      counteragent: item.merchant?.title,
      tradeName: item.merchant?.trade_title,
      supplier: item.supplier?.title,
      warehouse: item.warehouse?.title,
      status: item.status,
    }))
    setRows(newData)
  }, [receipts])

  useEffect(() => {
    if (multiSearch && multiSearch?.length > 2) {
      setTableLoading(true)
      // @ts-ignore
      const statuses = selectedStatuses?.length
        ? // @ts-ignore
          selectedStatuses.map((s) => s.value).join(',')
        : null

      const reqObj: IGetSupplyReqObj = {
        multi: multiSearch ? multiSearch : undefined,
        counteragent: selectedContragents?.value,
        supplier: selectedSuppliers?.value,
        warehouse: selectedWarehouses?.value,
        dateFrom: date.from
          ? days(date.from).format('YYYY-MM-DDTHH:mm:ss')
          : undefined,
        dateTo: date.to
          ? days(date.to).format('YYYY-MM-DDT23:59:59')
          : undefined,
        status: statuses,
      }

      getSupplies
        .mutateAsync(reqObj)
        .then((result: getSuppliesResult) => {
          const { receipts } = result
          setReceipts(receipts)
        })
        .finally(() => setTableLoading(false))
    }
  }, [multiSearch])

  const handleSearchSettings = (type: string, value: string) => {
    setSearchType(type)
    setSearchValue(value)
  }

  useEffect(() => {
    if (searchValue?.length > 2) {
      setOptionsLoading({
        ...optionsLoading,
        [searchType]: true,
      })
      getAcceptanceOptions
        .mutateAsync({ type: searchType, value: searchValue })
        .then((result: getAcceptanceOptionsResult) => {
          setOptions({
            ...options,
            [result.type]: result.options.map((o) => ({ ...o, id: o.value })),
          })
          setOptionsLoading({
            ...optionsLoading,
            [searchType]: false,
          })
        })
    }
  }, [searchValue])

  return (
    <>
      <ScanWrapper
        title="Приемка товара"
        subtitle="Список заявок на приемку товара"
        size="l"
        className={styles.main}
      >
        <div className={styles.top}>
          <div className={styles.applicationBarcode}>
            <InputWrapper
              id={'applicationBarcode'}
              placeholder={'№ заявки или имя контрагента'}
              handleChange={(value) => setMultiSearch(value)}
              autoFocus
              value={multiSearch}
              error={error}
              size="l"
              className={styles.applicationBarcodeInput}
              leftSide={IconSearch}
            />
            <IconFilter
              onClick={() => setShowFilter(!showFilter)}
              view="brand"
              className={styles.icon}
            />
          </div>
          {showFilter ? (
            <Button
              iconLeft={IconTop}
              label={'Свернуть'}
              view="clear"
              onClick={() => setShowFilter(false)}
            />
          ) : null}
        </div>

        {showFilter ? (
          <AcceptanceFilter
            date={date}
            setDate={setDate}
            selectedContragents={selectedContragents}
            setSelectedContragents={setSelectedContragents}
            selectedSuppliers={selectedSuppliers}
            setSelectedSuppliers={setSelectedSuppliers}
            selectedWarehouses={selectedWarehouses}
            setSelectedWarehouses={setSelectedWarehouses}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            suppliers={options.suppliers}
            contragents={options.contragents}
            warehouses={options.warehouses}
            handleSearchSettings={handleSearchSettings}
            handleSubmit={handleSubmit}
            handleClear={handleClear}
            loading={loading}
            optionsLoading={optionsLoading}
          />
        ) : null}
        {!tableLoading && receipts?.length ? (
          <Table className={styles.table}>
            {rows.map((row) => (
              <Table.Row key={row.id}>
                <Table.Column title="Дата поставки" className={styles.dateTd}>
                  <div className={styles.date}>
                    {row.date}
                    <div>{row.time}</div>
                  </div>
                </Table.Column>
                <Table.Column title="№ Поставки" className={styles.numTd}>
                  <Link
                    to={generatePath(PATHS.ACCEPTANCE_FORM, {
                      supplyId: row.supplyId,
                    })}
                  >
                    {row.supplyId}
                  </Link>
                </Table.Column>
                <Table.Column
                  title={
                    <div className={styles.headContragent}>
                      Контрагент <div>Коммерч. наименование</div>
                    </div>
                  }
                >
                  <div className={styles.contragent}>
                    {row.counteragent}
                    <div>{row.tradeName}</div>
                  </div>
                </Table.Column>
                <Table.Column title="Поставщик" className={styles.supplyTd}>
                  {row.supplier}
                </Table.Column>
                <Table.Column title="Склад" className={styles.supplyTd}>
                  {row.warehouse}
                </Table.Column>
                <Table.Column title="Статус поставки">
                  <Badge
                    status={ACCEPTANCE_STATUSES_STYLES[row.status]}
                    label={ACCEPTANCE_STATUSES[row.status]}
                    className={styles.badge}
                    size="l"
                  />
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
        ) : null}
        {tableLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : null}
      </ScanWrapper>
    </>
  )
}

export default AcceptanceScanPage
