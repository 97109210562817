import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import styles from './closed-order-page.module.scss'

import { Badge } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'
import { IconSearch } from '@consta/uikit/IconSearch'

import { TextFieldPropValue } from '@consta/uikit/TextField'

import { InputWrapper, PackedItemsTable, SideBar, Img } from 'src/components'

import { useGetPackedOrderById } from 'src/hooks'

import { IOrderPackagingPlace } from 'src/interfaces'

import { useAppContext } from 'src/context'
import { usePackingOrderContext } from '../context'
import { PalletPackedModal } from '../components'

const ClosedOrder = () => {
  const history = useHistory()

  const { order, packages } = usePackingOrderContext()

  const [error, setError] = useState<string>('')
  const [itemBarcode, setItemBarcode] = useState<TextFieldPropValue>('')
  const [packedPlaces, setPackedPlaces] = useState<
    IOrderPackagingPlace[] | null
  >(null)
  const [filteredPackedPlaces, setFilteredPackedPlaces] = useState<
    IOrderPackagingPlace[] | null
  >(null)

  const { setGlobalLoading } = useAppContext()

  useEffect(() => {
    setGlobalLoading(false)
  }, [])

  useEffect(() => {
    if (!itemBarcode) {
      setFilteredPackedPlaces(packedPlaces)
    }
  }, [packedPlaces])

  useEffect(() => {
    if (packedPlaces) {
      if (itemBarcode) {
        const newPlaces = packedPlaces.filter((p) =>
          p.items.find((i) => {
            return i.barcode_used.startsWith(itemBarcode)
          }),
        )
        const formattedNewPlaces = newPlaces.map((p) => {
          const newItems = p.items.filter((i) =>
            i.barcode_used.startsWith(itemBarcode),
          )
          return {
            ...p,
            items: newItems,
          }
        })
        setFilteredPackedPlaces(formattedNewPlaces)
      } else {
        setFilteredPackedPlaces(packedPlaces)
      }
    }
  }, [itemBarcode])

  return (
    <>
      <div className={styles.main}>
        <SideBar
          withAdvance={false}
          badge={
            <Badge status="success" label="Упакован" className={styles.badge} />
          }
          bottomBtns={
            <>
              <Button
                label="Вернуться на главную"
                onClick={() => history.push('/order/scan')}
              />
            </>
          }
        >
          <div className={styles.packName}>
            Упаковка заказа <h2>{order?.id}</h2>
          </div>

          <div className={styles.itemForm}>
            <InputWrapper
              value={itemBarcode}
              label={'Отсканируйте штрихкод товара для поиска'}
              id={'itemBarcode'}
              handleChange={(value) => setItemBarcode(value)}
              autoFocus
              error={error}
              className={styles.itemInput}
              leftSide={IconSearch}
              size="l"
              clearable
              onClear={() => setItemBarcode('')}
            />
          </div>

          <div className={styles.comment}>
            <h5>Комментарий к отгрузке</h5>
            <div>{order?.order_shipping_comments}</div>
          </div>

          <div className={styles.icon}>
            <Img
              className={styles.deliveryImg}
              src={order?.delivery?.logo}
              yandex
            />
            <div className={styles.iconTitles}>
              <h6>{order?.delivery?.title}</h6>
              {order?.delivery?.contract_title}
            </div>
          </div>
        </SideBar>
        <div className={styles.bodyWrap}>
          <div className={styles.body}>
            {filteredPackedPlaces?.length ? (
              <PackedItemsTable
                orderId={order?.id}
                items={order?.items}
                places={filteredPackedPlaces}
                className={styles.table}
                packagingOptions={packages ?? []}
                packed
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default ClosedOrder
