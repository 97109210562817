import React from 'react'

import styles from './warning-modal.module.scss'

import img from './assets/warning.svg'

import { Button } from '@consta/uikit/Button'

import { Modal, EnterHandler } from 'src/components'

import { ModalProps } from 'src/interfaces'

interface IWarningModal extends ModalProps {
  title: string
  subtitle: string
  handleConfirm: () => void
}

const WarningModal = (props: IWarningModal) => {
  const { isOpen, onClose, title, subtitle, handleConfirm } = props

  return (
    <EnterHandler onEnter={handleConfirm}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.warning}
        title={title}
        subtitle={subtitle}
        img={img}
        imgClass={styles.img}
      >
        <div className={styles.btns}>
          <Button
            label="Подтвердить"
            view="primary"
            className={styles.primary}
            onClick={handleConfirm}
            size="l"
          />
          <Button
            label="Отменить"
            view="ghost"
            className={styles.primary}
            onClick={() => onClose()}
            size="l"
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default WarningModal
