import React, { useContext, createContext, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useNotifications } from 'src/notification'
import { usePrinter } from 'src/printer'

import { useAppContext } from 'src/context'

import {
  //saveBase64AsPdf,
  checkBase64MIMEType,
} from 'src/helpers'

import { getStickerResult, applyPrintItemStickerParams } from 'src/interfaces'

export const PackingPrintContext = createContext({
  showStickerError: false,
  showStickerPrintInfo: false,
  sticker: null as {
    content: string
    printer: string
  } | null,
  setShowStickerError: (showStickerError: boolean) =>
    void showStickerError as void,
  setShowStickerPrintInfo: (showStickerPrint: boolean) =>
    void showStickerPrint as void,
  setSticker: (
    sticker: {
      content: string
      printer: string
    } | null,
  ) => void sticker as void,
  printSticker: (
    printerName: string,
    content: string,
    options?: {
      copies?: number
      needShowPrintStickerInfo?: boolean
      needFocusAfterPrint?: boolean
    },
  ) => Promise.resolve() as Promise<void | boolean>,
  applyPrintItemSticker: (printFunc: any) => void false as any,
  lastStickerWithoutError: false as boolean,
  setLastStickerWithoutError: (val: boolean) => void val as void,
  loadingSticker: false as boolean,
  setLoadingSticker: (val: boolean) => void val as void,
  handleCloseStickerError: (withFocus?: boolean) => void false as void,
  barcodeInput: { current: null } as React.MutableRefObject<
    HTMLTextAreaElement | HTMLInputElement | null
  >,
  blurOrFocusBarcodeInput: (type: 'focus' | 'blur') => void false as void,
  handleCloseAndFocusBarcode: (closeCallback: React.Dispatch<boolean>) =>
    void false as void,
})

export const PackingPrintProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const history = useHistory()
  const notification = useNotifications()
  const printer = usePrinter()

  const { setGlobalLoading } = useAppContext()

  const [showStickerError, setShowStickerError] = useState<boolean>(false)
  const [showStickerPrintInfo, setShowStickerPrintInfo] =
    useState<boolean>(false)
  const [sticker, setSticker] = useState<{
    content: string
    printer: string
  } | null>(null)
  const [lastStickerWithoutError, setLastStickerWithoutError] =
    useState<boolean>(true)
  const [loadingSticker, setLoadingSticker] = useState<boolean>(false)

  const barcodeInput = useRef<HTMLTextAreaElement | HTMLInputElement | null>(
    null,
  )

  const handleCloseStickerError = (withFocusBarcodeInput: boolean = true) => {
    setShowStickerError(false)
    setSticker(null)
    if (withFocusBarcodeInput) {
      blurOrFocusBarcodeInput('focus')
    }
  }

  const printSticker = async (
    printerName: string,
    content: string,
    options?: {
      copies?: number
      needShowPrintStickerInfo?: boolean
      needFocusAfterPrint?: boolean
      onFinally?: () => void
    },
  ) => {
    if (checkBase64MIMEType(content) !== 'application/pdf') {
      setShowStickerError(true)
      notification?.show(
        'alert',
        'Ошибка формирования стикера - данные получены не в PDF',
      )
      return false
    }

    if (!printerName) {
      notification?.show(
        'alert',
        'Ошибка печати стикера - Не было передано имя принтера для печати стикера',
      )
      return Promise.reject()
    }

    setSticker({ content, printer: printerName })

    // saveBase64AsPdf({ TODO move to config options
    //   content,
    //   fileName: 'sticker.pdf',
    // })

    if (printerName && content) {
      await printer?.setPrinter(printerName)

      await printer?.print(content, {
        onSuccess: () => {
          notification?.show('success', 'Стикер успешно отправлен на печать')
          if (options?.needShowPrintStickerInfo ?? true) {
            setShowStickerPrintInfo(true)
          }
          if (options?.needFocusAfterPrint ?? true) {
            setTimeout(() => {
              blurOrFocusBarcodeInput('focus')
            }, 200)
          }
        },
        onError: (err) => {
          console.error('STICKER PRINT ERROR:')
          console.error(err)
          notification?.show('alert', `Ошибка печати стикера - ${err}`)
          setShowStickerError(true)
          throw err
        },
        onFinally: () => {
          if (options?.onFinally) {
            options.onFinally()
          }
        },
        qzOptions: {
          copies: options?.copies ?? 1,
          printer: printerName,
        },
      })
      return Promise.resolve()
    }
  }

  const blurOrFocusBarcodeInput = (type: 'focus' | 'blur') => {
    if (!barcodeInput?.current) return
    type === 'focus'
      ? barcodeInput.current.focus()
      : barcodeInput.current.blur()
  }

  const handleCloseAndFocusBarcode = (
    closeCallback: React.Dispatch<boolean>,
  ) => {
    closeCallback(false)

    blurOrFocusBarcodeInput('focus')
  }

  const applyPrintItemSticker = (printFunc: any) => {
    return async ({
      itemId,
      barcode,
      quantity,
      dateOfManufacture,
      expiryMonths,
      advanced = false,
      needFocusAfterPrint = true,
    }: applyPrintItemStickerParams) => {
      setLoadingSticker(true)
      notification?.show('warning', 'Получение стикера товара')

      await printFunc
        .mutateAsync({
          itemId,
          barcode,
          dateOfManufacture,
          expiryMonths,
          advanced,
        })
        .then(async (res: getStickerResult) => {
          if (res.stickerExists && res.sticker) {
            const {
              sticker: { content, printer: printerName },
            } = res

            //const printer = 'Microsoft Print to PDF'

            await printSticker(printerName, content, {
              copies: quantity,
              needShowPrintStickerInfo: !advanced,
              needFocusAfterPrint: needFocusAfterPrint,
              onFinally: () => setLoadingSticker(false),
            })
          }
        })
        .finally(() => setLoadingSticker(false))
    }
  }

  return (
    <PackingPrintContext.Provider
      value={{
        showStickerError,
        sticker,
        setShowStickerError,
        setSticker,
        printSticker,
        handleCloseStickerError,
        applyPrintItemSticker,
        setShowStickerPrintInfo,
        showStickerPrintInfo,
        lastStickerWithoutError,
        setLastStickerWithoutError,
        loadingSticker,
        setLoadingSticker,
        barcodeInput,
        blurOrFocusBarcodeInput,
        handleCloseAndFocusBarcode,
      }}
    >
      {children}
    </PackingPrintContext.Provider>
  )
}

export const usePackingPrintContext = () => {
  const context = useContext(PackingPrintContext)
  return context
}
