import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_GET_STICKER } from 'src/mocks'

import { getStickerResult } from 'src/interfaces'

interface getStickerParams {
  id: string
  type: 'supply' | 'order'
}

interface getStickerData {
  itemId: string
  barcode?: string
  dateOfManufacture?: string
  advanced?: boolean
  expiryMonths?: boolean
}

const getSticketPath = (params: getStickerParams, data: getStickerData) => {
  let path = ''
  if (params.type === 'supply') {
    path = `/receiving/supply/${params.id}/item/${data.itemId}/sticker`
    return path
  }

  path = `/packing/order/${params.id}/item/${data.itemId}/sticker/${data.barcode}?`

  if (data.dateOfManufacture) {
    path += `dateOfManufacture=${data.dateOfManufacture}&`
  }
  if (data.expiryMonths) {
    path += `expiryMonths=${data.expiryMonths}&`
  }
  if (data.advanced) {
    path += `advanced=true&`
  } else {
    path += `advanced=false&`
  }

  path = path.slice(0, path.length - 1)

  return path
}

const useGetSticker = (params: getStickerParams) => {
  return useMutation(async (data: getStickerData) => {
    try {
      const path = getSticketPath(params, data)

      console.log(path)

      const result = await provideMock<typeof data, { data: getStickerResult }>(
        async () => await api.get(path),
        data,
        [
          {
            key: 'itemId',
            value: 'mock',
            result: MOCK_GET_STICKER,
          },
          {
            key: 'itemId',
            value: 'KK1043',
            result: MOCK_GET_STICKER,
          },
        ],
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result?.data
    } catch (e) {
      throw e
    }
  })
}

export default useGetSticker
