import React, { useState } from 'react'

import styles from './unprocessed.module.scss'

import { IconAdd } from '@consta/uikit/IconAdd'

import { Img, Table, PackPreview } from 'src/components'

import { ISupplyItem, ISupplyOriginalItem } from 'src/interfaces'

import { getBarcode } from 'src/helpers'

interface IUnProcessedTableProps {
  cacheItems: ISupplyOriginalItem[]
  planItems: ISupplyItem[]
  handleChooseItem: (item: ISupplyItem) => void
}

const UnProcessed = ({
  cacheItems,
  planItems,
  handleChooseItem,
}: IUnProcessedTableProps) => {
  const [tipsAdd, setTipsAdd] = useState<number[]>([])

  const formattedItems = planItems
    .map((planItem) => {
      const originalItem = cacheItems.find((item) => item.id === planItem.id)
      if (originalItem) {
        return {
          id: planItem.id,
          image: originalItem.image,
          article: originalItem.article,
          title: originalItem.title,
          barcodes: originalItem.barcodes,
          quantity: planItem.quantity,
          batches: originalItem.batches,
          packs: originalItem.packs,
          pack_id: planItem.pack_id,
        }
      } else {
        return null
      }
    })
    .filter((item) => item?.id)

  return (
    <div className={styles.unProcessed}>
      <h4>К приемке</h4>

      <div className={styles.tableWrap}>
        <Table stickyHeader>
          {formattedItems &&
            formattedItems.map((item: any, i: number) => (
              <Table.Row key={`${item.id}-${item.pack_id}`}>
                <Table.Column title="Фото" className={styles.tdImg}>
                  <Img
                    src={item.image}
                    className={styles.img}
                    size={100}
                    yandex
                    modal
                  />
                </Table.Column>
                <Table.Column
                  title={
                    <div className={styles.thName}>
                      Номенклатура <span className={styles.art}>Артикул</span>
                      <span className={styles.barcode}>Штрихкод товара</span>
                    </div>
                  }
                  className={styles.tdName}
                >
                  <div className={styles.name}>
                    <span>{item.title}</span>
                    <span className={styles.art}>{item.article}</span>
                    <span className={styles.barcode}>{getBarcode(item)}</span>
                  </div>
                </Table.Column>
                <Table.Column title="Кол-во" align="right">
                  {item.quantity}
                </Table.Column>
                <Table.Column title="Упаковка">
                  <PackPreview item={item} />
                </Table.Column>
                <Table.Column title="">
                  <div className={styles.add}>
                    <IconAdd
                      className={styles.addIcon}
                      size="m"
                      view="brand"
                      onClick={() => handleChooseItem(item)}
                      onMouseOver={() => setTipsAdd([...tipsAdd, i])}
                      onMouseOut={() =>
                        setTipsAdd(tipsAdd.filter((tip) => tip !== i))
                      }
                    />
                    {tipsAdd.includes(i) ? (
                      <div className={styles.tip}>Выбрать данный товар</div>
                    ) : null}
                  </div>
                </Table.Column>
              </Table.Row>
            ))}
        </Table>
      </div>
    </div>
  )
}

export default UnProcessed
