import React, { useState } from 'react'

import styles from './img.module.scss'

import { Modal } from 'src/components'

import { getYandexStoreImage } from 'src/helpers'

import mockImg from 'src/assets/images/no-image.png'

interface ImgWithModalProps {
  src?: string
  size?: number
  yandex?: boolean
  modal?: boolean
  className?: string
}

const Img = (props: ImgWithModalProps) => {
  const { src, size, yandex, modal, className } = props

  const [showModal, setShowModal] = useState<boolean>(false)

  let value = !src ? mockImg : src

  if (src && yandex) {
    value = getYandexStoreImage(src, size)
  }

  let style = !value
    ? {}
    : {
        backgroundImage: `url(${value})`,
      }

  const handleClick = () => {
    if (modal) {
      setShowModal(true)
    }
  }

  return (
    <>
      <div
        className={className ? className : styles.img}
        style={style}
        onClick={handleClick}
      />

      {src && modal ? (
        <Modal
          isOpen={showModal}
          withClose
          size="min"
          onClose={() => setShowModal(false)}
        >
          <img className={styles.modalImg} src={src} />
        </Modal>
      ) : null}
    </>
  )
}

export default Img
