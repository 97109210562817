import React from 'react'

import styles from './batch-packing-order.module.scss'

import { UnPackTable, BatchPackedTable } from '../components'

import { getFormattedOrderSimilarItems } from 'src/helpers'

import {
  IOrder,
  IOrderItem,
  IOrderPackagingPlace,
  IOrderPackagingOption,
} from 'src/interfaces'

interface MainBlockProps {
  order: IOrder | null
  items: IOrderItem[] | null
  places: IOrderPackagingPlace[]
  currentPlace: number | null
  packagingOptions: IOrderPackagingOption[]
  setShowPacked: (value: boolean) => void
  handleChooseItem: (item: IOrderItem) => void
}

const MainBlock = (props: MainBlockProps) => {
  const { items, places, handleChooseItem } = props

  const formattedItems = getFormattedOrderSimilarItems(items ?? [])

  return (
    <div className={styles.mainBlock}>
      {items?.length ? (
        <UnPackTable
          title="Не упаковано"
          items={formattedItems}
          handleChooseItem={handleChooseItem}
        />
      ) : null}

      {places?.length ? (
        <BatchPackedTable places={places} title="Упаковано" />
      ) : null}
    </div>
  )
}

export default MainBlock
