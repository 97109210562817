import React, { useRef } from 'react'
import cx from 'classnames'
import useDynamicRefs from 'use-dynamic-refs'

import styles from './accepted-table.module.scss'

import { IconAdd } from '@consta/uikit/IconAdd'
import { IconRemove } from '@consta/uikit/IconRemove'
import { Badge } from '@consta/uikit/Badge'

import AcceptedTableCollapse from './AcceptedTableCollapse'
import TareItem from './AcceptanceTableItem/AcceptanceTableItem'

import { DefectiveSwitchWrapper } from 'src/components'

import { useAppContext } from 'src/context'

import { useNotifications } from 'src/notification'

import { useDeleteSupplyPart } from 'src/hooks'

import {
  ISupplyPart,
  ISupplyPartItem,
  ISupplyOriginalItem,
  ISupplyItem,
  ISupply,
} from 'src/interfaces'
import { IconTrash } from '@consta/uikit/IconTrash'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface IAcceptedTableProps {
  supplyId: string
  curTareId: TextFieldPropValue
  tares: ISupplyPart[]
  cacheItems: ISupplyOriginalItem[]
  handleUpdateTares: (newTares: ISupplyPart[]) => void
  handleUpdateItems: (items: ISupplyPartItem[]) => void
  handleReducedQuantity: (id: string, packId: string, batchId?: string) => void
  handleConfirmGoods: (type: string) => void
  planItems?: ISupplyItem[]
  blurOrFocusBarcodeInput: (type: 'focus' | 'blur') => void
}

const AcceptedTable = ({
  supplyId,
  tares,
  cacheItems,
  handleUpdateTares,
  curTareId,
  handleUpdateItems,
  handleReducedQuantity,
  handleConfirmGoods,
  planItems,
  blurOrFocusBarcodeInput,
}: IAcceptedTableProps) => {
  const switchRef = useRef(null)
  const [getRef, setRef] = useDynamicRefs()

  const { performWithAdvance } = useAppContext()

  const notification = useNotifications()

  const deleteSupplyPartMutation = useDeleteSupplyPart({ supplyId })

  const getBatchesQuantity = (
    currentTareItem: ISupplyPartItem | undefined,
    item: ISupplyPartItem,
  ) => {
    if (!item.batch_id) return
    const batches = currentTareItem?.batchesQuantity
    if (!!batches?.hasOwnProperty(item.batch_id)) {
      return {
        ...batches,
        [item.batch_id]: batches[item.batch_id] + item.quantity,
      }
    } else {
      return {
        ...batches,
        [item.batch_id]: item.quantity,
      }
    }
  }

  const getFormattedTareItems = (tare: ISupplyPart) => {
    return tare.items.reduce(
      (
        a: ISupplyPartItem[],
        c: ISupplyPartItem,
        i: number,
        arr: ISupplyPartItem[],
      ) => {
        const findCurrentTareItem = a.find(
          (ci: ISupplyPartItem) => ci.id === c.id && ci.pack_id === c.pack_id,
        )

        const findCurrentItem = cacheItems?.find(
          (ci: ISupplyOriginalItem) => ci.id === c.id,
        )

        const taresPosition = tares.map((tare) => tare.tare_id)

        const allItemQuantityPrevTars = tares.reduce(
          (ac: number, curTare: ISupplyPart) => {
            const count = curTare.items.reduce(
              (ac: number, item: ISupplyPartItem) => {
                if (
                  item.id === c.id &&
                  item.pack_id === c.pack_id &&
                  taresPosition.indexOf(tare.tare_id) >
                    taresPosition.indexOf(curTare.tare_id)
                ) {
                  return ac + item.quantity
                } else {
                  return ac + 0
                }
              },
              0,
            )
            return ac + count
          },
          0,
        )

        const batchesQuantity = getBatchesQuantity(findCurrentTareItem, c)

        if (findCurrentTareItem && findCurrentItem) {
          findCurrentTareItem.batchesQuantity = batchesQuantity
          findCurrentTareItem.quantity =
            findCurrentTareItem.quantity + c.quantity
        } else {
          a.push({
            ...c,
            allItemQuantityPrevTars,
            batchesQuantity,
          })
        }
        return a
      },
      [] as ISupplyPartItem[],
    )
  }

  const formattedTares = tares
    .slice()
    .map((tare: ISupplyPart) => ({
      ...tare,
      items: getFormattedTareItems(tare),
    }))
    .reverse()

  const handleDeleteTare = async (tareId: string, partId: string) =>
    performWithAdvance(() => () => {
      deleteSupplyPartMutation
        .mutateAsync({ partId })
        .then(() => {
          handleUpdateItems(
            tares.find((t) => t.tare_id === tareId)?.items ?? [],
          )
          handleUpdateTares(tares.filter((t) => t.tare_id !== tareId))
          notification?.show('success', 'Тара успешно удалена')
        })
        .catch((e) => {
          notification?.show('alert', e.message)
        })
    })

  return (
    <div className={styles.acceptedTable}>
      <div className={styles.titles}>
        <h4>Принято</h4>
      </div>
      <div>
        <div className={styles.tableHead}>
          <div>Статус</div>
          <div>
            Номенклатура <span>Штрихкод товара</span>
          </div>
          <div>Кол-во</div>
          <div>Упаковка</div>
          <div>Партия</div>
        </div>
        <div className={styles.tableBody}>
          {tares.length ? (
            <>
              {formattedTares.map((tare, i) => (
                <AcceptedTableCollapse
                  index={i}
                  label=""
                  icon={IconAdd}
                  closeIcon={IconRemove}
                  className={cx(
                    styles.collapse,
                    tare.condition !== 'good' ? styles.defective : null,
                  )}
                  stopClickRef={setRef('tare' + i)}
                  rightSide={
                    <div className={styles.rightSide}>
                      <div className={styles.status}>
                        <div>
                          {tare?.status === 'open' ? (
                            <Badge
                              form="round"
                              label="Открыто"
                              className={styles.badge}
                              size="m"
                            />
                          ) : (
                            <Badge
                              form="round"
                              label="Закрыто"
                              className={styles.badge}
                              size="m"
                              status="success"
                            />
                          )}
                          <span className={styles.id}>{tare.tare_id}</span>
                          {tare.condition === 'defective' ? (
                            <Badge
                              label="Тара брака"
                              view="stroked"
                              className={styles.badgeDefective}
                              size="m"
                              status="error"
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className={styles.quantity}>{`${tare.items.reduce(
                        (a, c) => a + c.quantity,
                        0,
                      )}`}</div>
                      {tare?.status === 'open' ? (
                        <div
                          // @ts-ignore
                          ref={getRef('tare' + i)}
                        >
                          <DefectiveSwitchWrapper
                            tare={tare}
                            handleConfirmGoods={handleConfirmGoods}
                            className={styles.toogle}
                            blurOrFocus={blurOrFocusBarcodeInput}
                          />
                        </div>
                      ) : (
                        <div className={styles.trash}>
                          <IconTrash
                            className={styles.trashIcon}
                            view="brand"
                            onClick={() =>
                              handleDeleteTare(tare.tare_id, tare?.id ?? '')
                            }
                          />
                        </div>
                      )}
                    </div>
                  }
                >
                  <>
                    {tare.items.length ? (
                      <>
                        {tare.items.map((item, i) => (
                          <TareItem
                            key={`${item.id}-${i}`}
                            status={tare.status}
                            defection={tare.condition === 'defective'}
                            item={item}
                            index={tare.items.length - i}
                            originalItem={
                              cacheItems.find(
                                (oi: ISupplyOriginalItem) => oi.id === item.id,
                              ) || null
                            }
                            planItem={
                              planItems?.find(
                                (planItem) =>
                                  planItem.id === item.id &&
                                  planItem.pack_id === item.pack_id,
                              ) ?? null
                            }
                            handleReducedQuantity={handleReducedQuantity}
                          />
                        ))}
                      </>
                    ) : null}
                  </>
                </AcceptedTableCollapse>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AcceptedTable
