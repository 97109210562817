import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_UPDATE_SUPPLY_PART } from 'src/mocks'

import { useUpdateSupplyPartResult, ISupplyPartFinal } from 'src/interfaces'

interface useUpdateSupplyPartParamsType {
  supplyId: string
}

const useUpdateSupplyPart = (params: useUpdateSupplyPartParamsType) => {
  return useMutation(async (data: { part: ISupplyPartFinal }) => {
    try {
      const result = await provideMock<
        useUpdateSupplyPartParamsType,
        { data: useUpdateSupplyPartResult }
      >(
        async () =>
          await api.post(`/receiving/supply/${params.supplyId}/part`, data),
        params,
        {
          key: 'supplyId',
          value: 'mock',
          result: MOCK_UPDATE_SUPPLY_PART,
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result?.data
    } catch (e) {
      throw e
    }
  })
}

export default useUpdateSupplyPart
