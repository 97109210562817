import React, { useEffect, useState } from 'react'

import styles from './change-add-pack.module.scss'

import { Badge } from '@consta/uikit/Badge'

import ChoiceManualPackageModal from '../ChoiceManualPackageModal'

import {
  usePackingOrderContext,
  usePackingPrintContext,
} from 'src/pages/PackingOrderPage/context'

import { useNotifications } from 'src/notification'

import {
  IDimensions,
  IOrderPackagingOption,
  IOrderPackagingPlaceItem,
  IOrderPackagingPlace,
} from 'src/interfaces'

interface IChangeAddPackProps {
  item: IOrderPackagingPlaceItem
  index: number
  placeNum: number
  status: boolean
}

const ChangeAddPack = ({
  item,
  index,
  placeNum,
  status,
}: IChangeAddPackProps) => {
  const {
    packages,
    setCachedPackages,
    cachedPackages,
    currentPlace,
    currentPallet,
    pallets,
    setPallets,
    places,
    setPlaces,
  } = usePackingOrderContext()

  const { blurOrFocusBarcodeInput } = usePackingPrintContext()

  const [tipsAdd, setTipsAdd] = useState<string[]>([])
  const [selected, setSelected] = useState<IOrderPackagingOption | null>(null)
  const [showChoiceManualPackage, setShowChoiceManualPackage] =
    useState<boolean>(false)

  const notification = useNotifications()

  const handleChangeAddPack = (id: string, packId: string): void => {
    if (currentPlace === null) return

    const getNewPlaces = (places: IOrderPackagingPlace[]) =>
      places.map((p, i) => ({
        ...p,
        items: p.items.map((item) => {
          const isItemInCurrentPlaceWithPack =
            item.id === id && item?.packagings && i === currentPlace
          if (isItemInCurrentPlaceWithPack) {
            return {
              ...item,
              packagings: [
                {
                  packaging_id: packId,
                  quantity: item.quantity,
                },
              ],
            }
          } else {
            return item
          }
        }),
      }))

    if (currentPallet !== null) {
      const newPallets = pallets.slice()
      const curPallet = newPallets[currentPallet]

      const newPlaces = getNewPlaces(curPallet.places)
      curPallet.places = newPlaces
      setPallets(newPallets)
    } else {
      const newPlaces = getNewPlaces(places)
      setPlaces(newPlaces)
    }

    notification?.show('success', `Доп. упаковка для ${id} успешно изменена`)
    blurOrFocusBarcodeInput('focus')
  }

  const handleAdditionalPackagingAdding = (
    packageId: string,
    options: { dimensions?: IDimensions },
  ) => {
    if (item?.id) {
      setCachedPackages({
        ...cachedPackages,
        [item.id]: packageId,
      })
      handleChangeAddPack(item.id, packageId)
    }
    setShowChoiceManualPackage(false)
  }

  const getPackage = () => {
    return (
      packages.find(
        (curPackage) => curPackage.id === item?.packagings?.[0]?.packaging_id,
      ) ?? null
    )
  }

  if (!item) return null

  return (
    <>
      <div className={styles.tipWrap}>
        <div
          onMouseOver={() => setTipsAdd([...tipsAdd, `${placeNum}-${index}`])}
          onMouseOut={() =>
            setTipsAdd(tipsAdd.filter((tip) => tip !== `${placeNum}-${index}`))
          }
        >
          <Badge
            style={{ cursor: !status ? 'pointer' : undefined }}
            label="Доп. упаковка"
            size="m"
            onClick={
              !status ? () => setShowChoiceManualPackage(true) : () => false
            }
          />
        </div>
        {tipsAdd.includes(`${placeNum}-${index}`) ? (
          <div className={styles.tip}>{getPackage()?.title}</div>
        ) : null}
      </div>
      {showChoiceManualPackage ? (
        <ChoiceManualPackageModal
          isOpen={true}
          packagingOptions={packages}
          selected={selected ? selected : getPackage()}
          onSelect={setSelected}
          onBack={() => setShowChoiceManualPackage(false)}
          onClose={() => setShowChoiceManualPackage(false)}
          onSubmit={handleAdditionalPackagingAdding}
        />
      ) : null}
    </>
  )
}

export default ChangeAddPack
