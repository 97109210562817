import React, { useEffect, useState } from 'react'

import styles from './item-count-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconClose } from '@consta/uikit/IconClose'

import { Modal, InputWrapper, ItemPreview, EnterHandler } from 'src/components'

import { ModalProps } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface ItemCountProps extends ModalProps {
  count: string
  item: { image: string; title: string; barcode: string } | null
  setCount: (count: string) => void
  onItemAdding: (directCount: number) => void
}

const ItemCountModal = (props: ItemCountProps) => {
  const { count, item, isOpen, setCount, onItemAdding, onClose } = props

  const handleAddCount = () => {
    if (!item || Number(count) < 1) return
    onItemAdding(Number(count))
  }

  return (
    <EnterHandler onEnter={handleAddCount}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.itemCount}
        size="s"
      >
        {item ? (
          <ItemPreview
            image={item.image}
            title={item.title}
            barcode={item.barcode}
          />
        ) : null}

        <InputWrapper
          label={'Введите количество товара'}
          value={count}
          id={'count'}
          handleChange={(value: TextFieldPropValue) => setCount(String(value))}
          autoFocus
          className={styles.itemInput}
          placeholder={'Количество товара'}
          size="l"
          isInteger
        />
        <div className={styles.btns}>
          <Button
            label="Закрыть"
            view="ghost"
            className={styles.btn}
            onClick={() => onClose()}
          />
          <Button
            label="Добавить"
            className={styles.btn}
            disabled={!count || Number(count) < 1}
            onClick={handleAddCount}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ItemCountModal
