import React, { useEffect, useState, useContext, createContext } from 'react'
import dayjs from 'dayjs'

import { appLocalStorage } from 'src/libs'

import AdvanceModeModal from './AdvanceModeModal'
import AfkNotification from './AfkNotification'

import { useRemoveAdvancedMode } from 'src/hooks'

import { version } from 'src/../package.json'

import { ICurrentUser } from 'src/interfaces'

export const AppContext = createContext({
  globalLoading: true,
  setGlobalLoading: (globalLoading: boolean) => void globalLoading as void,
  authorized: false,
  setAuthorized: (authorized: boolean) => void authorized as void,
  currentUser: {} as ICurrentUser,
  setCurrentUser: (currentUser: ICurrentUser) => void currentUser as void,
  advanceMode: false,
  setAdvanceMode: (value: boolean) => void value as void,
  removeAdvanceMode: () => void false as void,
  requestAdvanceMode: () => void false as void,
  performWithAdvance: (action: () => void) => void action as void,
  appVersion: version,
  setAppVersion: (value: string) => void value as void,
  resetAfkInterval: () => void false as void,
})

//TODO reducer
export const AppContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const removeAdvanceModeMutation = useRemoveAdvancedMode()

  const [authorized, setAuthorized] = useState(false)
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({})
  const [globalLoading, setGlobalLoading] = useState(true)

  const [advanceMode, setAdvanceMode] = useState<boolean>(false)
  const [showAdvanceModeModal, setShowAdvanceModeModal] =
    useState<boolean>(false)
  const [deferredAction, setDeferredAction] = useState<(() => void) | null>(
    null,
  )

  const [appVersion, setAppVersion] = useState(version)

  const [afkTimer, setAfkTimer] = useState(dayjs())

  useEffect(() => {
    if (authorized) {
      const interval = window.setInterval(() => {
        const diff = dayjs().diff(afkTimer, 'hours', true)
        if (diff > 2) {
          window.showNotification(
            'warning',
            <AfkNotification
              onReset={() => setAfkTimer(dayjs())}
              setAuthorized={setAuthorized}
            />,
            {
              id: 'afk',
              persistent: true,
              allWidth: true,
            },
          )
        }
      }, 60000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [authorized, afkTimer])

  const performWithAdvance = (action: () => void) => {
    if (advanceMode) {
      action()
    } else {
      setDeferredAction(action)
      setShowAdvanceModeModal(true)
    }
  }

  const removeAdvanceMode = async () => {
    await removeAdvanceModeMutation.mutateAsync().then(({ data }) => {
      if (data.success === true) {
        setAdvanceMode(false)
      }
    })
  }

  return (
    <AppContext.Provider
      value={{
        globalLoading,
        setGlobalLoading,
        authorized,
        setAuthorized,
        currentUser,
        setCurrentUser: (u: ICurrentUser) => {
          appLocalStorage.currentUser = u
          setCurrentUser(u)
        },
        advanceMode,
        setAdvanceMode,
        removeAdvanceMode,
        performWithAdvance,
        requestAdvanceMode: () => setShowAdvanceModeModal(true),
        appVersion,
        setAppVersion,
        resetAfkInterval: () => setAfkTimer(dayjs()),
      }}
    >
      {children}

      {showAdvanceModeModal ? (
        <AdvanceModeModal
          isOpen={true}
          deferredAction={deferredAction}
          setAdvanceMode={setAdvanceMode}
          onClose={() => {
            setDeferredAction(null)
            setShowAdvanceModeModal(false)
          }}
        />
      ) : null}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  return context
}
