import React, { useState, useMemo, useEffect } from 'react'
// @ts-ignore
import eruda from 'eruda'
import isMobile from 'ismobilejs'

import styles from './app.module.scss'

import Main from './Main'

import { useAppContext } from 'src/context'
import { useSound } from 'src/sound'

import { SoundProvider } from 'src/sound'
import { NotificationProvider } from 'src/notification'
import { PrinterProvider } from 'src/printer'

import { Loader } from '@consta/uikit/Loader'

import api from 'src/api'
import appLocalStorage from 'src/libs/localStorage'

const GlobalLoader = () => (
  <div className={styles.globalLoader}>
    <Loader />
  </div>
)

const App = () => {
  const {
    globalLoading,
    setGlobalLoading,
    authorized,
    setAuthorized,
    setCurrentUser,
  } = useAppContext()

  const sound = useSound()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const { token, currentUser, currentBase } = appLocalStorage

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthorized(true)
    }

    if (currentUser) {
      setCurrentUser(currentUser)
    }

    if (currentBase) {
      api.defaults.baseURL = `https://${currentBase}`
    }
    setIsReady(true)
    setGlobalLoading(false)
    if (isMobile().any) {
      eruda.init()
    }
  }, [])

  return useMemo(
    () => (
      <>
        {isReady ? (
          <SoundProvider>
            <NotificationProvider>
              <PrinterProvider>
                {globalLoading ? <GlobalLoader /> : null}
                <Main authorized={authorized} />
              </PrinterProvider>
            </NotificationProvider>
          </SoundProvider>
        ) : null}
      </>
    ),
    [authorized, globalLoading, isReady],
  )
}

export default App
