import React from 'react'

import styles from '../b2b-packing-order.module.scss'

import { IconWarning } from '@consta/uikit/IconWarning'

import { usePackingOrderContext } from '../../context'

import useCheckPlaceHasOtherArticle from './useCheckPlaceHasOtherArticle'
import { useNotifications } from 'src/notification'

import { getOrderItem } from 'src/helpers'

import { IOrderItem, ShippingOptionsType } from 'src/interfaces'

interface IUseCheckShouldAddItemProps {
  checkWeight: (weight: number, type: 'pallet' | 'place') => boolean
  cachedWeights: { [key: string]: number }
}

interface IUseCheckShouldAddItemRes {
  shouldAddItem: (value: string, isItemId?: boolean) => boolean
}

const useCheckShouldAddItem = ({
  checkWeight,
  cachedWeights,
}: IUseCheckShouldAddItemProps) => {
  const { order, items, currentPlace, currentPallet } = usePackingOrderContext()

  const isMonoPack =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PACKINGS
  const isMonoPallet =
    order?.shippingOptions?.packingType === ShippingOptionsType.MONO_PALLETS

  const notification = useNotifications()
  const { checkPlaceHasOtherArticle } = useCheckPlaceHasOtherArticle()

  const shouldAddItem = (value: string, isItemId: boolean = false): boolean => {
    const currentItem: IOrderItem | undefined = getOrderItem(
      value,
      isItemId,
      order?.items ?? [],
    )

    // если вес места вместе с добавляемым товаром больше максимального веса места
    if (
      currentItem &&
      !checkWeight(
        currentItem?.weight ?? cachedWeights[currentItem?.id] ?? 1000,
        'place',
      )
    ) {
      return false
    }

    // если упаковка монокоробами
    if ((isMonoPallet || isMonoPack) && currentItem && currentPlace !== null) {
      if (
        currentPallet === null
          ? checkPlaceHasOtherArticle(currentItem, 'place')
          : checkPlaceHasOtherArticle(currentItem, 'pallet')
      ) {
        if (isMonoPack) {
          notification?.show(
            'alert',
            <div className={styles.informer}>
              <h3>Ошибка сканирования</h3>
              <p>
                Упаковка монокоробами. Нельзя добавить товар с другим артикулом
                к текущему месту
              </p>
            </div>,
            {
              icon: IconWarning,
            },
          )
        }

        if (isMonoPallet) {
          notification?.show(
            'alert',
            <div className={styles.informer}>
              <h3>Ошибка сканирования</h3>
              <p>
                Упаковка монопаллетами. Нельзя добавить товар с другим артикулом
                к текущему месту и паллете
              </p>
            </div>,
            {
              icon: IconWarning,
            },
          )
        }

        return false
      }
    }

    if (!currentItem) {
      notification?.show(
        'alert',
        `Товар со штрихкодом ${value} не найден в задании на упаковку`,
        { soundName: 'PACKING_WRONG_ITEM' },
      )
      return false
    }

    const currentActualItem: boolean = Boolean(
      items?.find((i: IOrderItem) => i.id === currentItem.id),
    )
    if (!currentActualItem) {
      notification?.show('alert', 'Лишний товар', {
        soundName: 'PACKING_SURPLUS_ITEM',
      })
      return false
    }
    return true
  }

  return { shouldAddItem } as IUseCheckShouldAddItemRes
}
export default useCheckShouldAddItem
