import React, { useState } from 'react'

import styles from './choice-oversized-package-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import {
  Modal,
  InputWrapper,
  DimensionsInput,
  EnterHandler,
} from 'src/components'

import { ModalProps, IDimensions, IOrderPackagingOption } from 'src/interfaces'

interface ChoiceOversizedPackageModalProps extends ModalProps {
  onBack: () => void
  onSubmit: (packageId: string, options: { dimensions?: IDimensions }) => void
}

const ChoiceOversizedPackageModal = (
  props: ChoiceOversizedPackageModalProps,
) => {
  const { isOpen, onBack, onSubmit } = props

  const [value, setValue] = useState<IDimensions>({
    width: null,
    height: null,
    depth: null,
  })

  const isInValid = Object.entries(value).some(([key, value]) => !Number(value))

  const handleSubmit = () => {
    if (isInValid) {
      return
    }
    onSubmit('other_pack', { dimensions: value })
  }

  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.wrapper}
        title="Габариты нестандартного места"
        subtitle="Введите габариты нестандартного места упаковки"
      >
        <DimensionsInput
          value={value}
          onChange={setValue}
          integerNumbersInputs
          autoFocus
        />

        <div className={styles.btns}>
          <Button label="Назад" view="ghost" onClick={onBack} />
          <Button
            label="Подтвердить"
            disabled={isInValid}
            onClick={handleSubmit}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ChoiceOversizedPackageModal
