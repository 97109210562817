import { usePackingOrderContext } from '../../context'

import { useNotifications } from 'src/notification'

import { IOrderItem } from 'src/interfaces'

interface useCheckPlaceHasOtherArticleRes {
  checkPlaceHasOtherArticle: (
    currentItem: IOrderItem,
    type: 'place' | 'pallet',
  ) => boolean
}

const useCheckPlaceHasOtherArticle = () => {
  const { currentPallet, places, pallets } = usePackingOrderContext()

  const checkPlaceHasOtherArticle = (
    currentItem: IOrderItem,
    type: 'place' | 'pallet',
  ): boolean => {
    if (type === 'pallet' && currentPallet !== null) {
      const lastPalletPlaces = pallets[currentPallet].places
      const lastPlace = lastPalletPlaces[lastPalletPlaces.length - 1]
      const isOtherArticleInPlace = !!(
        !lastPlace.hasOwnProperty('id') &&
        lastPlace.items.filter((item) => item.article !== currentItem.article)
          .length
      )

      return isOtherArticleInPlace
    }

    const lastPlace = places[places.length - 1]
    const isOtherArticleInPlace = !!(
      !lastPlace.hasOwnProperty('id') &&
      lastPlace.items.filter((item) => item.article !== currentItem.article)
        .length
    )

    return isOtherArticleInPlace
  }

  return { checkPlaceHasOtherArticle } as useCheckPlaceHasOtherArticleRes
}

export default useCheckPlaceHasOtherArticle
