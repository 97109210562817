import React, { useState } from 'react'

import ChoicePackageModal from './ChoicePackageModal'
import ChoiceManualPackageModal from './ChoiceManualPackageModal'
import ChoiceOversizedPackageModal from './ChoiceOversizedPackageModal'

import {
  ModalProps,
  IOrderPackagingOption,
  IOrderPackagingPlace,
  IDimensions,
} from 'src/interfaces'

interface ChoicePackageProps extends ModalProps {
  packagingOptions: IOrderPackagingOption[]
  onBack: () => void
  onSubmit: (packageId: string, options: { dimensions?: IDimensions }) => void
}

const ChoicePackage = (props: ChoicePackageProps) => {
  const { isOpen, packagingOptions, onBack, onSubmit, onClose } = props

  const [mode, setMode] = useState('barcode')
  const [selected, setSelected] = useState<IOrderPackagingOption | null>(null)

  const handleSubmit = (
    packageId: string,
    options: { dimensions?: IDimensions },
  ) => {
    onSubmit(packageId, options)
  }

  if (!isOpen) return null

  return (
    <>
      <ChoicePackageModal
        isOpen={mode === 'barcode'}
        packagingOptions={packagingOptions}
        showManual={() => setMode('manual')}
        showOversized={() => setMode('oversized')}
        onBack={onBack}
        onClose={onClose}
        onSubmit={handleSubmit}
      />

      <ChoiceManualPackageModal
        isOpen={mode === 'manual'}
        packagingOptions={packagingOptions}
        selected={selected}
        onSelect={setSelected}
        onBack={() => setMode('barcode')}
        onClose={onClose}
        onSubmit={handleSubmit}
      />

      <ChoiceOversizedPackageModal
        isOpen={mode === 'oversized'}
        onBack={() => setMode('barcode')}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default ChoicePackage
