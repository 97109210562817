import React from 'react'
import cx from 'classnames'

import styles from './advance-mode-lock.module.scss'

import { IconUnlock } from '@consta/uikit/IconUnlock'
import { IconLock } from '@consta/uikit/IconLock'

import { useAppContext } from 'src/context'

interface AdvanceModeLockProps {
  className?: string
}

const AdvanceModeLock = (props: AdvanceModeLockProps) => {
  const { className } = props

  const { advanceMode, requestAdvanceMode, removeAdvanceMode } = useAppContext()

  const handleAdvanceMode = () => {
    if (!advanceMode) {
      requestAdvanceMode()
    } else {
      removeAdvanceMode()
    }
  }

  const AdvanceModeIcon = advanceMode ? IconUnlock : IconLock

  return (
    <div className={cx(styles.wrapper, className)}>
      <AdvanceModeIcon view="secondary" size="m" onClick={handleAdvanceMode} />
    </div>
  )
}

export default AdvanceModeLock
