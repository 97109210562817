import React from 'react'
import { useHistory } from 'react-router-dom'

import { appLocalStorage } from 'src/libs'

import styles from './scan-header.module.scss'

import { IconArrowDown } from '@consta/uikit/IconArrowDown'
import { IconSettings } from '@consta/uikit/IconSettings'
import { Badge } from '@consta/uikit/Badge'

import { UserBlock, AdvanceModeLock } from 'src/components'

import { useAppContext } from 'src/context'

import { IDropdownItem } from 'src/interfaces'

import { PATHS } from 'src/config'

interface ScanFormHeaderProps {
  title?: string
  subtitle?: string
}

const ScanFormHeader = ({ title, subtitle }: ScanFormHeaderProps) => {
  const { setAuthorized, advanceMode } = useAppContext()

  const history = useHistory()

  const userItems: IDropdownItem[] = [
    {
      name: 'Выйти',
      action: () => {
        appLocalStorage.clear()
        setAuthorized(false)
      },
    },
  ]

  const openSettings = () => {
    history.push(PATHS.SETTINGS)
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <h3 className={styles.title}>{title}</h3>
          <h5 className={styles.subtitle}>{subtitle}</h5>
        </div>

        <div className={styles.right}>
          {advanceMode ? (
            <>
              <Badge
                form="round"
                status="normal"
                label="Расширенный"
                className={styles.badge}
              />
              <IconSettings
                className={styles.settings}
                view="secondary"
                size="m"
                onClick={openSettings}
              />
            </>
          ) : null}

          <AdvanceModeLock className={styles.lock} />

          <UserBlock
            withMenu
            iconRight={IconArrowDown}
            size="l"
            items={userItems}
          />
        </div>
      </div>
    </>
  )
}

export default ScanFormHeader
