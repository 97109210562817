import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import ItemCountModal from './ItemCountModal'
import ExpDateModal from './ExpDateModal'
import AdditionalPackagingModal from '../AdditionalPackagingModal'
import ItemScanSerialModal from './ItemScanSerialModal'

import { ItemPreview, WeightDimensionsModal } from 'src/components'

import { useNotifications } from 'src/notification'

import { usePackingOrderContext } from '../../context'

import {
  IOrderItem,
  IOrderPackagingOption,
  IOrderPackagingPlace,
  IOrderPackagingPlaceItem,
} from 'src/interfaces'

interface ItemAddingProps {
  indicateItemCount?: boolean
  addingItem: IOrderItem | null
  packagingOptions: IOrderPackagingOption[]
  currentPlace: number | null
  places: IOrderPackagingPlace[]
  onClose: () => void
  onPlaceAdd: (newPlace: IOrderPackagingPlace) => void
  onAddItemToPlace?: (newItems: IOrderPackagingPlaceItem[]) => void
  setAddingItem: (item: IOrderItem) => void
  blurOrFocusBarcodeInput: (type: 'focus' | 'blur') => void
  sequence_num: number
  remainingItems?: number
  isNeedEnterWeigthDimensions?: boolean
  type: 'pallets' | 'places'
  checkPlaceWeight?: (weight: number) => boolean
  addWeightToCache?: (itemId: string, weight: number) => void
}

const createAddingItem = (addingItems: IOrderItem[]) => {
  return addingItems.map((addingItem) => ({
    id: addingItem?.id,
    title: addingItem.title,
    packed_at: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
    barcode_used: addingItem?.barcode_used ?? '',
    quantity: 1,
    weight: addingItem?.weight ?? 1000,
    serial_numbers: addingItem?.serial_numbers ? addingItem.serial_numbers : [],
    kit_id: addingItem?.kit_id,
    kit_title: addingItem?.kit_title,
    article: addingItem?.article,
    packagings: addingItem?.packagings,
    expirationDate: addingItem?.expirationDate,
    dateOfManufacture: addingItem?.dateOfManufacture,
    manualRequireWeight: addingItem?.manualRequireWeight,
  }))
}

export const ItemAdding = (props: ItemAddingProps) => {
  const {
    indicateItemCount = false,
    addingItem,
    setAddingItem,
    currentPlace,
    onClose,
    onPlaceAdd,
    onAddItemToPlace = () => false,
    blurOrFocusBarcodeInput,
    sequence_num,
    remainingItems,
    isNeedEnterWeigthDimensions = false,
    type,
    checkPlaceWeight,
    addWeightToCache,
  } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [count, setCount] = useState<string>('')
  const [interimResult, setInterimResult] = useState<IOrderItem[]>([])

  const { isInValidItemQuantity } = usePackingOrderContext()

  useEffect(() => {
    blurOrFocusBarcodeInput('blur')
    return () => {
      const isLastItems = (remainingItems ?? 0) - Number(count || 1) > 0
      if (isLastItems) {
        blurOrFocusBarcodeInput('focus')
      }
    }
  }, [count])

  const handleAddPlace = (items: IOrderItem[]) => {
    onPlaceAdd({
      packaging_id: null,
      sequence_num: sequence_num,
      is_final: false,
      weight: 0,
      items: items ? createAddingItem(items) : [],
    })
  }

  const handleAddItem = (items: IOrderItem[]) => {
    console.log(items)
    if (currentPlace === null) {
      handleAddPlace(items)
    } else {
      if (items) {
        onAddItemToPlace(createAddingItem(items))
      }
    }
  }

  const getModalsArr = (
    addingItem: IOrderItem | null,
    indicateItemCount?: boolean,
  ) => {
    const modalsArr = []

    if (isNeedEnterWeigthDimensions) {
      modalsArr.push('weigthDimensions')
    }

    if (addingItem?.needAddPack) {
      modalsArr.push('addPack')
    }

    // если есть серийники не давать добавлять несколько товаров сразу
    if (
      indicateItemCount &&
      !addingItem?.scan_serial_numbers?.length &&
      !addingItem?.require_batch_expdate
    ) {
      modalsArr.push('itemCount')
    }

    if (addingItem?.require_batch_expdate) {
      modalsArr.push('dateExp')
    }

    if (addingItem?.scan_serial_numbers?.length) {
      modalsArr.push('scanSerial')
    }
    return modalsArr
  }

  const gotoNextStep = ({ result }: { result: IOrderItem[] }) => {
    const modalsArr = getModalsArr(addingItem, indicateItemCount)

    setAddingItem(result[0])

    const currentIndex = modalsArr.findIndex((m: string) => m === currentModal)
    if (currentIndex < modalsArr.length - 1) {
      setCurrentModal(modalsArr[currentIndex + 1])
    } else {
      setCount('')
      handleAddItem(result)
    }
  }

  useEffect(() => {
    if (!addingItem) return

    gotoNextStep({ result: [{ ...addingItem, quantity: 1 }] })
    setInterimResult([addingItem])
  }, [])

  const handleDateExpAdding = (items: IOrderItem[]) => {
    if (!addingItem || !items) return
    setInterimResult(items)
    gotoNextStep({ result: items })
  }

  const handleScanSerial = (items: IOrderItem[]) => {
    if (!items) return

    gotoNextStep({ result: items })
  }

  const handleAddCount = (count: number) => {
    if (!addingItem) return

    if (
      checkPlaceWeight &&
      !checkPlaceWeight((addingItem.weight ?? 1000) * count)
    ) {
      return
    }

    if (isInValidItemQuantity(addingItem.id, count, type)) {
      return
    }

    const newPackaging = addingItem.packagings
      ? [
          {
            packaging_id: addingItem.packagings?.[0]?.packaging_id,
            quantity: 1,
          },
        ]
      : undefined
    const result = [...Array(count)].map(() => ({
      ...addingItem,
      quantity: 1,
      packagings: newPackaging,
    }))

    setInterimResult(result)

    gotoNextStep({
      result: result,
    })
  }

  const handleEnterWeigthDimensions = (
    weight?: number,
    manualRequireWeight?: boolean,
  ) => {
    if (!addingItem) return

    if (weight) {
      gotoNextStep({ result: [{ ...addingItem, weight, manualRequireWeight }] })
      setInterimResult([{ ...addingItem, weight, manualRequireWeight }])
      return
    }

    gotoNextStep({ result: [addingItem] })
    setInterimResult([addingItem])
  }

  const handleAdditionalPackagingAdding = (packageId: string | null) => {
    if (!addingItem) return

    if (!packageId) {
      gotoNextStep({ result: [{ ...addingItem }] })
      return
    }

    const newPackaging = [
      {
        packaging_id: packageId,
        quantity: 1,
      },
    ]
    const result = [
      {
        ...addingItem,
        packagings: newPackaging,
      },
    ]

    gotoNextStep({
      result: result,
    })

    setInterimResult(result)
  }

  if (!addingItem) return null

  return (
    <>
      {currentModal === 'itemCount' ? (
        <ItemCountModal
          count={count}
          item={{
            image: addingItem.image,
            title: addingItem.title,
            barcode: addingItem.barcode_used ?? '',
          }}
          isOpen={true}
          setCount={setCount}
          onItemAdding={handleAddCount}
          onClose={onClose}
        />
      ) : null}

      {currentModal === 'dateExp' ? (
        <ExpDateModal
          item={addingItem}
          newItems={interimResult}
          isOpen={true}
          onDateExpAdding={handleDateExpAdding}
          onClose={onClose}
        />
      ) : null}

      {currentModal === 'addPack' ? (
        <AdditionalPackagingModal
          isOpen={true}
          item={addingItem}
          onAdditionalPackagingAdding={handleAdditionalPackagingAdding}
          onClose={onClose}
        />
      ) : null}

      {currentModal === 'weigthDimensions' ? (
        <WeightDimensionsModal
          item={addingItem}
          isOpen={true}
          onClose={onClose}
          onSubmit={handleEnterWeigthDimensions}
          withClose
          checkPlaceWeight={checkPlaceWeight}
          addWeightToCache={addWeightToCache}
          needUpdateWeightAndDimensions={
            addingItem.require_weight || addingItem.require_dimensions
          }
          top={
            <ItemPreview
              image={addingItem.image}
              title={addingItem.title}
              barcode={addingItem.barcode_used ?? ''}
            />
          }
        />
      ) : null}

      {currentModal === 'scanSerial' ? (
        <ItemScanSerialModal
          multiple={indicateItemCount}
          isOpen={true}
          item={addingItem}
          onSubmit={handleScanSerial}
          onClose={onClose}
          items={interimResult}
        />
      ) : null}
    </>
  )
}

export default ItemAdding
