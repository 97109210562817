import { usePackingOrderContext } from '../../context'

const useCheckNewStickerBarcode = () => {
  const { order } = usePackingOrderContext()

  const checkBarcode = (barcode: string) => {
    const checkInPallets = order?.pallets?.find((p) => p.pallet_id === barcode)
    if (checkInPallets) {
      return false
    }

    const checkInPlaces = order?.places.find((p) => p.id === barcode)
    if (checkInPlaces) {
      return false
    }

    const checkInItems = order?.items.find(
      (item) => !!item.barcodes.find((b) => b.barcode === barcode),
    )
    if (checkInItems) {
      return false
    }

    return true
  }

  return checkBarcode
}

export default useCheckNewStickerBarcode
