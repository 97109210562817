import api from 'src/api'

import { provideMock, MOCK_GET_STICKER_BY_PALLET } from 'src/mocks'

import { StickerResult } from 'src/interfaces'
import { ApiReqResult } from '../../interfaces/api'

interface useCompletePackParams {
  order_id: string
}

const completePack = async (params: useCompletePackParams) => {
  const url = `/packing/order/${params.order_id}/packed`
  const result = await api.post(url)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const useCompletePack = () => ({
  fetch: (params: useCompletePackParams) =>
    provideMock<useCompletePackParams, ApiReqResult>(completePack, params, {
      key: 'order_id',
      value: 'mock',
      result: { success: true },
    })(),
})

export default useCompletePack
