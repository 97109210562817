import { useQuery } from 'react-query'
import api from 'src/api'

import {
  provideMock,
  MOCK_GET_PACKING_BY_ID,
  MOCK_GET_B2B_PACKING_BY_ID,
  MOCK_GET_KIT_PACKING_BY_ID,
  MOCK_GET_B2B_RELABELLING_BY_ID,
} from 'src/mocks'

import {
  ApiReqResult,
  IOrder,
  IOrderItem,
  IOrderPackagingOption,
  IOrderPackagingPlace,
  IShippingFormItem,
} from 'src/interfaces'

interface UsePackingByIdParamsType {
  orderId: string
  type?: string
}

const getMethod = async (orderId: string, type?: string) => {
  if (type === 'relabel' || type === 'docs') {
    return await api.get(`/packing/order/${orderId}`)
  }

  const path = type
    ? `/packing/order/${orderId}/${type}`
    : `/packing/order/${orderId}/start`

  return await api.post(path)
}

const getPackingById = async (params: UsePackingByIdParamsType) => {
  const result = await getMethod(params.orderId, params.type)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface GetPackingByIdResult extends ApiReqResult {
  order: IOrder
  packagings: IOrderPackagingOption[]
}

const useGetPackingById = (
  params: UsePackingByIdParamsType,
  callback?: (
    items: IOrderItem[],
    places: IOrderPackagingPlace[],
    shippingForm?: IShippingFormItem[] | [],
  ) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetPackingByIdResult>(
    [`getPackingById`, params],
    provideMock<UsePackingByIdParamsType, GetPackingByIdResult>(
      getPackingById,
      params,
      [
        {
          key: 'orderId',
          value: 'mock',
          result: MOCK_GET_PACKING_BY_ID,
        },
        {
          key: 'orderId',
          value: 'mockb2b',
          result: MOCK_GET_B2B_PACKING_BY_ID,
        },
        {
          key: 'orderId',
          value: 'mockrelabel',
          result: MOCK_GET_B2B_RELABELLING_BY_ID,
        },
      ],
    ),
    {
      enabled: !!params,
      keepPreviousData: true,
      onSuccess: ({
        success,
        order: { items, places, shipping_form: shippingForm },
      }) => {
        if (success) {
          if (callback) {
            callback(items, places, shippingForm)
          }
        } else {
          if (error) {
            error()
          }
        }
      },
      onError: () => {
        if (error) {
          error()
        }
      },
      onSettled: () => {
        if (settled) {
          settled()
        }
      },
    },
  ) as { data: GetPackingByIdResult }
}

export default useGetPackingById
