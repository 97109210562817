import React, { useState, useEffect } from 'react'

import { Collapse } from '@consta/uikit/Collapse'

import { PropsWithHTMLAttributesAndRef } from '@consta/uikit/__internal__/src/utils/types/PropsWithHTMLAttributes'
import { IconProps } from '@consta/uikit/__internal__/src/icons/Icon/Icon'
import { CollapseIconPropDirection } from '@consta/uikit/__internal__/src/components/Collapse/CollapseIcon/CollapseIcon'
import {
  CollapsePropHorizontalSpace,
  CollapsePropSize,
  CollapsePropView,
} from '@consta/uikit/__internal__/src/components/Collapse/Collapse'

declare type CollapseProps = PropsWithHTMLAttributesAndRef<
  {
    size?: CollapsePropSize
    icon?: React.FC<IconProps>
    view?: CollapsePropView
    divider?: boolean
    label: string
    horizontalSpace?: CollapsePropHorizontalSpace
    hoverEffect?: boolean
    isOpen?: boolean
    index?: number
    length?: number
  } & (
    | {
        closeIcon?: React.FC<IconProps>
        directionIcon?: never
        closeDirectionIcon?: never
      }
    | {
        closeIcon?: never
        directionIcon?: CollapseIconPropDirection
        closeDirectionIcon?: CollapseIconPropDirection
      }
  ) &
    (
      | {
          iconPosition?: 'left'
          rightSide?: React.ReactNode
        }
      | {
          iconPosition?: 'right'
          rightSide?: never
        }
    ),
  HTMLDivElement
>

declare type AcceptedCollapseProps = {
  stopClickRef: any
}

declare type AcceptedTableCollapseProps = AcceptedCollapseProps & CollapseProps

const AcceptedTableCollapse = (props: AcceptedTableCollapseProps) => {
  const { index, length, stopClickRef } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (index === 0) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [length])

  const passedProps = { ...props }
  delete passedProps.index
  delete passedProps.length

  const handleOpen = (e: React.MouseEvent) => {
    if (
      stopClickRef &&
      stopClickRef.current &&
      stopClickRef.current.contains(e.target)
    ) {
      return
    }
    setIsOpen(!isOpen)
  }

  return (
    <Collapse {...passedProps} isOpen={isOpen} onClick={(e) => handleOpen(e)} />
  )
}

export default AcceptedTableCollapse
