import { saveAs } from 'file-saver'
import dayjs from 'dayjs'

import {
  IBatch,
  ISupplyOriginalItem,
  IOrderPackagingPlace,
  IOrderPackagingOption,
  IOrderItem,
  IOrderPackagingPlaceItem,
  IOrderPallet,
  IOrderReqPallet,
  IOrderPalletMarked,
  IAddPack,
  ICachedPackage,
} from 'src/interfaces'

export * from './errors'

export const getYandexStoreImage = (value?: string, size?: number): string => {
  if (value && value.indexOf('https://storage.yandexcloud.net/') === 0) {
    if (typeof size !== 'undefined') {
      const splitted = value.split('.')
      const extension = splitted.pop()
      value = splitted.join('.') + '-' + String(size) + '.' + extension
    }
  }

  return value || ''
}

export const checkBase64MIMEType = (b64: string) => {
  const signatures = {
    JVBERi0: 'application/pdf',
  } as any //TODO TS enum

  for (const s in signatures) {
    if (b64.indexOf(s) === 0) {
      return signatures[s]
    }
  }

  return null
}

export const saveBase64AsPdf = (data: {
  content: string
  fileName: string
}) => {
  const reportBlob = dataURItoBlob(
    'data:application/octet-stream;base64,' + data.content,
  )
  saveAs(reportBlob, data.fileName)

  function dataURItoBlob(dataURI: string) {
    var byteString = atob(dataURI.split(',')[1])
    var mimeString = dataURI.split(',')?.[0].split(':')[1].split(';')?.[0]
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    var blob = new Blob([ab], { type: mimeString })
    return blob
  }
}

export const getItemsQuantity = (items?: { quantity: number }[]) =>
  typeof items !== 'undefined'
    ? items.reduce((a: number, c) => {
        a = a + Number(c.quantity)
        return a
      }, 0)
    : null

export function rusToLatin(str: string | null) {
  if (!str) return ''

  const r = [
    'й',
    'ц',
    'у',
    'к',
    'е',
    'н',
    'г',
    'ш',
    'щ',
    'з',
    'х',
    'ъ',
    'ф',
    'ы',
    'в',
    'а',
    'п',
    'р',
    'о',
    'л',
    'д',
    'ж',
    'э',
    'я',
    'ч',
    'с',
    'м',
    'и',
    'т',
    'ь',
    'б',
    'ю',
  ]

  const s = [
    'q',
    'w',
    'e',
    'r',
    't',
    'y',
    'u',
    'i',
    'o',
    'p',
    '\\[',
    '\\]',
    'a',
    's',
    'd',
    'f',
    'g',
    'h',
    'j',
    'k',
    'l',
    ';',
    "'",
    'z',
    'x',
    'c',
    'v',
    'b',
    'n',
    'm',
    ',',
    '\\.',
  ]

  for (let i = 0; i < r.length; i++) {
    const reg = new RegExp(r[i], 'mig')
    str = str.replace(reg, function (a) {
      return a == a.toLowerCase() ? s[i] : s[i].toUpperCase()
    })
  }

  return str
}

export const checkWeight = (value: string) => {
  return (
    Number.isInteger(Number(value)) &&
    Number(value) > 0 &&
    /^[0-9]+$/.test(value)
  )
}

export const isNumber = (value: string) => {
  return /^[0-9]+$/.test(value)
}

export const checkIsTare = (barcode: string) => {
  return /^TR/.test(barcode)
}

export const allItemsById = (
  arr: { id: string; quantity: number }[],
  id: string,
) =>
  arr.reduce((ac: number, i: { id: string; quantity: number }) => {
    if (i.id === id) {
      return ac + i.quantity
    } else {
      return ac + 0
    }
  }, 0)

export const allItemsInArrItemsById = (
  arrs: { items: { id: string; quantity: number }[] }[],
  id: string,
) =>
  arrs.reduce(
    (ac: number, curArr: { items: { id: string; quantity: number }[] }) => {
      const count = curArr.items.reduce(
        (ac: number, i: { id: string; quantity: number }) =>
          i.id === id ? ac + i.quantity : ac,
        0,
      )
      return ac + count
    },
    0,
  )

export const allItemsByIdAndPackId = (
  arr: { id: string; quantity: number; pack_id: string }[],
  id: string,
  packId: string,
) =>
  arr.reduce(
    (ac: number, i: { id: string; quantity: number; pack_id: string }) => {
      if (i.id === id && i.pack_id === packId) {
        return ac + i.quantity
      } else {
        return ac + 0
      }
    },
    0,
  )

export const allItemsInArrItemsByIdAndPackId = (
  arrs: { items: { id: string; quantity: number; pack_id: string }[] }[],
  id: string,
  packId: string,
) =>
  arrs.reduce(
    (
      ac: number,
      curArr: { items: { id: string; quantity: number; pack_id: string }[] },
    ) => {
      const count = curArr.items.reduce(
        (ac: number, i: { id: string; quantity: number; pack_id: string }) =>
          i.id === id && i.pack_id === packId ? ac + i.quantity : ac,
        0,
      )
      return ac + count
    },
    0,
  )

export const checkStarsTemplate = (str: string, template: string) => {
  if (str.length !== template.length) {
    return false
  }

  for (let i = 0; i < template.length; i++) {
    const char = template[i]
    if (char !== '*' && char !== str[i]) {
      return false
    }
  }

  return true
}

export const isChz = (barcode: string) => {
  return /^01[0-9]{14}21/.test(barcode)
}

export const checkValidDate = (val: string) => {
  let valid = true
  const strWithoutDots = val.replace(/\./g, '')

  strWithoutDots.split('').forEach((c: string, i: number) => {
    if (i == 0 && !/[0-3]/.test(c)) {
      valid = false
    }
    if (i == 1 && !/[0-9]/.test(c)) {
      valid = false
    }
    if (i == 2 && !/[0-1]/.test(c)) {
      valid = false
    }
    if (i == 3 && !/[0-9]/.test(c)) {
      valid = false
    }
  })

  if (strWithoutDots[2] === '1' && /[3-9]/.test(strWithoutDots[3])) {
    valid = false
  }
  if (
    strWithoutDots[2] === '0' &&
    strWithoutDots.length > 3 &&
    !/[1-9]/.test(strWithoutDots[3])
  ) {
    valid = false
  }

  if (strWithoutDots[0] === '3' && /[2-9]/.test(strWithoutDots[1])) {
    valid = false
  }

  return valid
}

export const getBatchStr = (batch: IBatch) => {
  return `${batch.num ? batch.num : ''}${
    batch.num && batch.exp_date ? ', ' : ''
  }${
    batch.exp_date
      ? 'годен до ' + dayjs(batch.exp_date).format('DD.MM.YYYY')
      : ''
  }`
}

export const getBarcode = (item: ISupplyOriginalItem) => {
  return (
    item.barcodes.find((barcode) => barcode.pack_id === item.pack_id)
      ?.barcode || ''
  )
}

export const getPlacesString = (count: number) => {
  let resultStr = count + ' '
  const strLength = String(count).length
  if ([11, 12, 13, 14].indexOf(Number(count)) !== -1) {
    resultStr += 'товаров'
  } else {
    const symbol = strLength > 1 ? String(count)[strLength - 1] : count
    switch (Number(symbol)) {
      case 1:
        resultStr += 'место'
        break
      case 2:
      case 3:
      case 4:
        resultStr += 'места'
        break
      default:
        resultStr += 'мест'
    }
  }
  return resultStr
}

export const hideSymbols = (openSymbolsCount: number = 1, str: string = '') => {
  const lastSymbols = str.slice(
    str.length - openSymbolsCount >= 0 ? str.length - openSymbolsCount : 0,
    str.length,
  )
  const startSymbols =
    lastSymbols.length < str.length
      ? str.slice(0, str.length - openSymbolsCount)
      : ''
  const hideStartSymbols = [...Array(startSymbols.length)]
    .map((sym) => '*')
    .join('')

  return hideStartSymbols + lastSymbols
}

export const getPathWithoutLastPart = (pathname: string) => {
  return pathname.slice(0, pathname.lastIndexOf('/'))
}

export const getPackageTitle = (
  place: IOrderPackagingPlace,
  packages: IOrderPackagingOption[],
) => {
  return (
    packages.find((o: IOrderPackagingOption) => o.id === place.packaging_id)
      ?.title ??
    (place.packaging_id === 'other_pack'
      ? 'Нестандартная упаковка ' +
        (place.dimensions
          ? `(${place.dimensions?.width}х${place.dimensions?.height}х${place.dimensions?.depth})`
          : '')
      : '')
  )
}

export const getFormattedOrderSimilarItems = (items: IOrderItem[]) =>
  items
    ? items.reduce((a: IOrderItem[], c: IOrderItem) => {
        const findCurrentItem = a.find(
          (ci: IOrderItem) => ci.id === c.id && ci.article === c.article,
        )
        if (findCurrentItem) {
          findCurrentItem.quantity = findCurrentItem.quantity + c.quantity
        } else {
          a.push({ ...c })
        }

        return a
      }, [] as IOrderItem[])
    : []

export const getOrderItem = (
  value: string,
  isItemId: boolean = false,
  itemsArr: IOrderItem[],
): IOrderItem | undefined => {
  return !isItemId
    ? getOrderItemByBarcode(value, itemsArr)
    : getOrderItemById(value, itemsArr)
}

export const getOrderItemByBarcode = (barcode: string, arr: IOrderItem[]) =>
  arr.find((item: IOrderItem) =>
    item.barcodes.find((b) => b.barcode === barcode),
  )

export const getOrderItemById = (barcode: string, arr: IOrderItem[]) =>
  arr.find((item: IOrderItem) => item.id === barcode)

export const checkIsPreferredBarcodeType = (
  item: IOrderPackagingPlaceItem,
  preferredBarcode: string,
  orderItems: IOrderItem[],
) => {
  const orderItem = getOrderItemByBarcode(item.barcode_used, orderItems)
  if (!orderItem || !preferredBarcode) return true

  const preferred = orderItem.barcodes.find((b) => b.type === preferredBarcode)

  if (!preferred) return true

  return (
    orderItem.barcodes.find((b) => b.barcode === item.barcode_used)?.type ===
    preferredBarcode
  )
}

export const getTitleByOperation = (curOperation: string | null) => {
  if (curOperation === 'palletList') {
    return 'Печать упаковочных листов'
  }

  if (curOperation === 'wbBarcodeSticker') {
    return 'Печать шк поставки'
  }

  if (curOperation === 'WBPackingList') {
    return 'Печать упаковочных листов'
  }

  return 'Печать стикера'
}

export const getSubtitleByOperation = (curOperation: string | null) => {
  if (curOperation === 'palletList') {
    return 'Приложите упаковочные листы к паллетам'
  }
  if (curOperation === 'palletSticker') {
    return 'Приклейте стикер на паллету'
  }
  if (curOperation === 'placeSticker') {
    return 'Приклейте стикер на место'
  }

  if (curOperation === 'wbBarcodeSticker') {
    return 'Приклейте стикер на место'
  }

  if (curOperation === 'WBPackingList') {
    return 'Приклейте на места'
  }

  return 'Приклейте стикер'
}

export const checkAllPalletsFilled = (pallets: IOrderPallet[]): boolean => {
  if (!pallets.length) return true
  let status = true
  pallets.forEach((pallet) => {
    if (!pallet.places.length) {
      status = false
    }
  })
  return status
}

export const getRelabelPalletReqData = (
  palletId: string,
  pallet: IOrderPalletMarked,
  orderId: string,
): IOrderReqPallet => {
  const formattedPlaces = pallet.places.map((place) => ({
    placeId: place.newBarcode ?? place.id,
    orderId,
  }))

  return {
    ...pallet,
    pallet_id: palletId,
    places: formattedPlaces,
  }
}

export const checkMonoPallet = (
  id: string,
  currentPallet: number | null,
  pallets: { places: IOrderPackagingPlace[] }[],
) => {
  if (currentPallet === null) return false

  let isExistOtherId = false

  pallets[currentPallet].places.forEach((place) => {
    place.items.forEach((item) => {
      if (item.id !== id) {
        isExistOtherId = true
      }
    })
  })

  return !isExistOtherId
}

export const getNewPallet = (
  places: IOrderPackagingPlace[],
  pallet_num: number,
): IOrderPallet => {
  return {
    weight: 0,
    places,
    pallet_num,
  }
}

export const getB2bSubtitleByOperation = (currentOperation: string) => {
  if (currentOperation === 'placeClosing') {
    return 'Приклейте стикер на место'
  }

  if (currentOperation === 'palletClosing') {
    return 'Приклейте стикер на паллету'
  }

  return 'Приклейте стикер на упаковку'
}

export const getAddPackByItem = (
  id: string,
  cachedPackages: ICachedPackage,
): [needAddPack: boolean, packagings?: IAddPack[]] => {
  const curCachedPackage = cachedPackages?.[id]
  const needAddPack = !curCachedPackage

  const packagings =
    curCachedPackage && curCachedPackage !== 'noAddPackage'
      ? [
          {
            packaging_id: curCachedPackage,
            quantity: 1,
          },
        ]
      : undefined

  return [needAddPack, packagings]
}
