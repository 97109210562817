import React, { useState } from 'react'
import dayjs from 'dayjs'

import styles from './pack-table.module.scss'

import IconPrinter from './assets/IconPrinter.svg'

import { IconAdd } from '@consta/uikit/IconAdd'
import { IconRemove } from '@consta/uikit/IconRemove'
import { Badge } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'
import { File } from '@consta/uikit/File'

import PackTableCollapse from './PackTableCollapse'
import ChangeAddPack from '../ChangeAddPack'

import { Img } from 'src/components'

import ChangeWeight from '../ChangeWeight'

import { useGetStickerByPlace } from 'src/hooks'

import { useAppContext } from 'src/context'
import { usePackingPrintContext } from '../../context'

import { hideSymbols, getPackageTitle } from 'src/helpers'

import {
  IOrder,
  IOrderItem,
  IOrderPackagingPlace,
  IOrderPackagingOption,
  IOrderPackagingPlaceItem,
  OperationTypes,
} from 'src/interfaces'

interface PlaceItemProps {
  item: IOrderPackagingPlaceItem
  originalItem: IOrderItem | null
  index: number
  handlePrintItemSticker: (
    id: string,
    barcode: string,
    expires?: string,
    dateOfManufacture?: string,
    advanced?: boolean,
  ) => void
  needSticker: boolean
  placeNum: number
  status: boolean
  isB2B: boolean
  addPack?: boolean
  needAdvanced?: boolean
  addWeightToCache?: (itemId: string, weight: number) => void
  updatePlacesWeight?: (place: IOrderPackagingPlace) => void
}

interface IItemPrinterBtnProps {
  handleGetSticker: (advanced: boolean) => void
  needAdvanced?: boolean
}

const ItemPrinterBtn = (props: IItemPrinterBtnProps) => {
  const { handleGetSticker, needAdvanced = true } = props

  const [showBtns, setShowBtns] = useState<boolean>(false)

  const handlePrint = (needAdvanced: boolean) => {
    handleGetSticker(needAdvanced)
    setShowBtns(false)
  }

  return (
    <>
      {!needAdvanced ? (
        <img src={IconPrinter} onClick={() => handleGetSticker(true)} />
      ) : (
        <div className={styles.stickerBtn}>
          <img src={IconPrinter} onClick={() => setShowBtns(!showBtns)} />
          {showBtns ? (
            <ul className={styles.stickerBtns}>
              <li onClick={() => handlePrint(false)}>Обычный</li>
              <li onClick={() => handlePrint(true)}>Расширенный</li>
            </ul>
          ) : null}
        </div>
      )}
    </>
  )
}

const PlaceItem = (props: PlaceItemProps) => {
  const {
    item,
    originalItem,
    placeNum,
    index,
    handlePrintItemSticker,
    needSticker,
    status,
    isB2B,
    addPack = false,
    needAdvanced = false,
    addWeightToCache,
    updatePlacesWeight,
  } = props

  const { advanceMode, performWithAdvance } = useAppContext()

  const handleGetSticker = (
    id: string,
    barcode: string,
    expires?: string,
    dateOfManufacture?: string,
    advanced?: boolean,
  ) => {
    if (!advanceMode) {
      performWithAdvance(() => () => {
        if (id) {
          handlePrintItemSticker(
            id,
            barcode,
            expires,
            dateOfManufacture,
            advanced,
          )
        }
      })
    } else {
      if (id) {
        handlePrintItemSticker(
          id,
          barcode,
          expires,
          dateOfManufacture,
          advanced,
        )
      }
    }
  }

  if (!originalItem) return null

  return (
    <div className={styles.tableTr}>
      <div className={styles.tdImage}>
        {item.id === 'SDoc' ? (
          <File className={styles.file} extension="pdf" />
        ) : (
          <Img src={originalItem.image} size={100} yandex modal />
        )}
      </div>
      <div className={styles.name}>
        {item.id === 'SDoc' ? (
          <Badge
            size="s"
            view="stroked"
            form="round"
            label={'документы'}
            className={styles.kitBadge}
          />
        ) : null}
        {item.title}
        <span className={styles.light}>
          {hideSymbols(6, item.barcode_used)}
        </span>
      </div>
      <div className={styles.weight}>{isB2B ? item.weight : null}</div>
      <div className={styles.addPack}>
        {item.packagings?.[0] && addPack ? (
          <ChangeAddPack
            placeNum={placeNum}
            index={index}
            item={item}
            status={status}
          />
        ) : null}
      </div>
      <div className={styles.batches}>
        {item.expirationDate
          ? dayjs(item.expirationDate).format('DD.MM.YYYY')
          : null}
      </div>
      <div>{item.quantity}</div>
      <div>
        {item.manualRequireWeight &&
        !status &&
        addWeightToCache &&
        updatePlacesWeight ? (
          <ChangeWeight
            id={item.id}
            addWeightToCache={addWeightToCache}
            updatePlacesWeight={updatePlacesWeight}
          />
        ) : null}
        {needSticker ? (
          <ItemPrinterBtn
            needAdvanced={needAdvanced}
            handleGetSticker={(advanced: boolean) =>
              handleGetSticker(
                item.id,
                item.barcode_used,
                item.expirationDate,
                item.dateOfManufacture,
                advanced,
              )
            }
          />
        ) : null}
      </div>
    </div>
  )
}

interface PackTableProps {
  order: IOrder | null
  places: IOrderPackagingPlace[]
  currentPlace: number | null
  packagingOptions: IOrderPackagingOption[]
  setShowPacked: (value: boolean) => void
  updatePlacesWeight?: (place: IOrderPackagingPlace) => void
  handlePrintItemSticker: (
    id: string,
    barcode: string,
    expires?: string,
    dateOfManufacture?: string,
    advanced?: boolean,
  ) => void
  addPack?: boolean
  needSticker?: boolean
  needAdvanced?: boolean
  addWeightToCache?: (itemId: string, weight: number) => void
}

const PackTable = (props: PackTableProps) => {
  const {
    order,
    places,
    packagingOptions,
    setShowPacked,
    handlePrintItemSticker,
    addPack = false,
    needSticker = false,
    needAdvanced = false,
    addWeightToCache,
    updatePlacesWeight,
  } = props

  const { advanceMode, performWithAdvance } = useAppContext()
  const { printSticker } = usePackingPrintContext()

  const getSticker = useGetStickerByPlace()

  const isB2B = order?.operationType === OperationTypes.PACKAGING_B2B

  const formattedPlaces = places
    .slice()
    .reverse()
    .map((place: IOrderPackagingPlace) => ({
      ...place,
      items: place.items.reduce(
        (
          a: IOrderPackagingPlaceItem[],
          c: IOrderPackagingPlaceItem,
          i: number,
          arr: IOrderPackagingPlaceItem[],
        ) => {
          const findCurrentPlaceItem = a.find(
            (ci: IOrderPackagingPlaceItem) =>
              ci.id === c.id &&
              ci.packagings?.[0]?.packaging_id ===
                c.packagings?.[0]?.packaging_id &&
              ci.barcode_used === c.barcode_used &&
              ci.expirationDate === c.expirationDate,
          )
          if (findCurrentPlaceItem) {
            findCurrentPlaceItem.quantity =
              findCurrentPlaceItem.quantity + c.quantity
          } else {
            a.push({ ...c })
          }

          return a
        },
        [] as IOrderPackagingPlaceItem[],
      ),
    }))

  const printPlaceSticker = async (id: string) => {
    if (!order?.id) return

    await getSticker
      .fetch({ placeId: id, orderId: order.id })
      .then((result) => {
        if (result.success) {
          const { sticker } = result
          printSticker(sticker.printer, sticker.content, {
            copies: 1,
            needShowPrintStickerInfo: true,
            needFocusAfterPrint: true,
          })
        }
      })
  }

  const handleGetSticker = async (id: string | undefined) => {
    if (!advanceMode) {
      performWithAdvance(() => () => {
        if (id && order?.id) {
          printPlaceSticker(id)
        }
      })
    } else {
      if (id && order?.id) {
        printPlaceSticker(id)
      }
    }
  }

  const comrapeItems = (
    a: IOrderPackagingPlaceItem,
    b: IOrderPackagingPlaceItem,
  ) => {
    return dayjs(a.packed_at).isBefore(dayjs(b.packed_at)) ? 1 : -1
  }

  return (
    <div className={styles.packTable}>
      <div className={styles.titles}>
        <h4>Упаковано</h4>
        <Button
          label="Посмотреть полностью"
          view="secondary"
          onClick={() => setShowPacked(true)}
        />
      </div>

      <div>
        <div className={styles.tableHead}>
          <div>Фото</div>
          <div>
            Номенклатура
            <span>Штрих-код товара</span>
          </div>
          <div>{isB2B ? 'Вес 1 шт., гр' : null}</div>
          <div></div>
          <div>Срок годности</div>
          <div>Кол-во</div>
          <div></div>
        </div>

        <div className={styles.tableBody}>
          {formattedPlaces.map((place: IOrderPackagingPlace, i: number) => (
            <div
              key={`place-${i}-${place?.id ?? 'current'}`}
              className={styles.tableGroup}
            >
              <PackTableCollapse
                index={i}
                length={places.length}
                label=""
                icon={IconAdd}
                closeIcon={IconRemove}
                className={styles.collapse}
                rightSide={
                  <div className={styles.rightSide}>
                    <div className={styles.status}>
                      <div className={styles.placeWrap}>
                        {place?.id ? (
                          <img
                            className={styles.print}
                            src={IconPrinter}
                            alt="IconPrinter"
                            onClick={() => handleGetSticker(place.id)}
                          />
                        ) : null}

                        {!place?.id ? (
                          <Badge
                            form="round"
                            label="Открыто"
                            className={styles.badge}
                            size="m"
                          />
                        ) : (
                          <Badge
                            form="round"
                            label="Закрыто"
                            className={styles.badge}
                            size="m"
                            status="success"
                          />
                        )}
                        <span className={styles.num}>
                          Место {place.sequence_num}
                        </span>
                        <span className={styles.id}>{place?.id}</span>
                        <span className={styles.light}>
                          {getPackageTitle(place, packagingOptions)}
                        </span>
                      </div>
                    </div>
                    <div className={styles.move}></div>
                    <div className={styles.quantity}>{`${place.items.reduce(
                      (a, c) => a + c.quantity,
                      0,
                    )} шт.`}</div>
                  </div>
                }
              >
                <>
                  {place.items
                    .slice()
                    .sort(comrapeItems)
                    .map((item: IOrderPackagingPlaceItem, i: number) => (
                      <PlaceItem
                        key={item.id}
                        item={item}
                        originalItem={
                          order?.items.find(
                            (oi: IOrderItem) => oi.id === item.id,
                          ) || null
                        }
                        index={place.items.length - i}
                        handlePrintItemSticker={handlePrintItemSticker}
                        needSticker={needSticker}
                        placeNum={+place.sequence_num}
                        status={!!place?.id}
                        addPack={addPack}
                        needAdvanced={needAdvanced}
                        addWeightToCache={addWeightToCache}
                        updatePlacesWeight={updatePlacesWeight}
                        isB2B={isB2B}
                      />
                    ))}
                </>
              </PackTableCollapse>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PackTable
