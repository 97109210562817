import React from 'react'
import days from 'dayjs'
import cx from 'classnames'

import styles from './place-table-item.module.scss'

import box from './assets/box_avatar.svg'

import { Badge, BadgePropStatus } from '@consta/uikit/Badge'

import { MONTHS } from 'src/config'

import { IPlaceItem } from 'src/interfaces'

interface IPlaceTableItemProps {
  place: IPlaceItem
}

const PlaceTableItem = ({ place }: IPlaceTableItemProps) => {
  const getStatus = (place: IPlaceItem) => {
    if (place.scanned) {
      return (
        <span className={cx(styles.placeTop, styles.verified)}>Проверено</span>
      )
    }
    return <span className={styles.placeTop}>Место</span>
  }

  return (
    <div className={styles.placeItem}>
      <div className={styles.img}>
        <img src={box} alt="" />
      </div>
      <div className={styles.info}>
        <div className={styles.place}>
          {getStatus(place)}
          {place.barcode}
        </div>
        <div className={styles.order}>
          <div className={styles.date}>
            {place.order_date ? (
              <>{days(place.order_date).format('DD.MM.YYYY')}</>
            ) : null}
          </div>
          {place.order_id}
        </div>
      </div>
    </div>
  )
}

export default PlaceTableItem
