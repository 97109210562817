import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_UPDATE_STICKER } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface getStickerParams {
  type: 'place' | 'pallet'
  orderId: string
}

const useUpdateRelabelingSticker = (params: getStickerParams) => {
  return useMutation(async (data: { id: string; new_barcode: string }) => {
    try {
      const path =
        params.type === 'place'
          ? `/packing/order/${params.orderId}/place/${data.id}/barcode/new`
          : `/packing/pallet/${data.id}/barcode/new`

      const result = await provideMock<typeof data, { data: ApiReqResult }>(
        async () => await api.post(path, { new_barcode: data.new_barcode }),
        data,
        [
          {
            key: 'new_barcode',
            value: 'mock',
            result: MOCK_UPDATE_STICKER,
          },
          {
            key: 'new_barcode',
            value: 'mock2',
            result: MOCK_UPDATE_STICKER,
          },
          {
            key: 'new_barcode',
            value: 'mock3',
            result: MOCK_UPDATE_STICKER,
          },
          {
            key: 'new_barcode',
            value: 'mock4',
            result: MOCK_UPDATE_STICKER,
          },
        ],
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result?.data
    } catch (e) {
      throw e
    }
  })
}

export default useUpdateRelabelingSticker
