import React, { useState } from 'react'
import useDynamicRefs from 'use-dynamic-refs'
import cx from 'classnames'

import styles from './pack-preview.module.scss'

import { Popover } from '@consta/uikit/Popover'

import { IPackType } from 'src/interfaces'

interface PackPreviewProps {
  item: any
}

const PackPreview = (props: PackPreviewProps) => {
  const { item } = props

  const [showTip, setShowTip] = useState<string[]>([])
  const [getRef, setRef] = useDynamicRefs()

  const pack = item.packs.find((p: IPackType) => p.id === item.pack_id)

  const handleShowTip = (val: string) => {
    if (showTip.includes(val)) {
      const newTips = showTip.filter((id) => id !== val)
      setShowTip(newTips)
    } else {
      setShowTip([...showTip, val])
    }
  }

  if (pack && pack?.quantity === 1) {
    return (
      <div className={styles.pack}>
        <span>{pack.title}</span>
      </div>
    )
  }
  if (pack && pack?.quantity > 1) {
    return (
      <div className={cx(styles.pack, styles.largePack)}>
        <span
          onClick={() => handleShowTip(item.id)}
          // @ts-ignore
          ref={setRef(item.id)}
        >
          упк
        </span>
        {showTip.includes(item.id) ? (
          <Popover
            onClickOutside={() => handleShowTip(item.id)}
            offset={25}
            direction="downLeft"
            // @ts-ignore
            anchorRef={getRef(item.id)}
          >
            <div className={styles.Popover}>{pack.title}</div>
          </Popover>
        ) : null}
      </div>
    )
  }
  return null
}

export default PackPreview
