import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock, MOCK_ACCEPTED_SUPPLY } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface useAcceptedSupplyByIdParamsType {
  supplyId: string
}

const useAcceptedSupplyById = (params: useAcceptedSupplyByIdParamsType) => {
  return useMutation(async () => {
    try {
      const result = await provideMock<
        useAcceptedSupplyByIdParamsType,
        { data: ApiReqResult }
      >(
        async () =>
          await api.post(`/receiving/supply/${params.supplyId}/accepted`),
        params,
        {
          key: 'supplyId',
          value: 'mock',
          result: MOCK_ACCEPTED_SUPPLY,
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result?.data
    } catch (e) {
      throw e
    }
  })
}

export default useAcceptedSupplyById
