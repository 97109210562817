import React from 'react'
import { useLocation } from 'react-router-dom'
import cx from 'classnames'

import styles from './template-wrapper.module.scss'

import { AppVersion } from 'src/components'

import { PATHS } from 'src/config'

interface TemplateWrapperProps {
  template?: string
}

const TemplateWrapper = (
  props: React.PropsWithChildren<TemplateWrapperProps>,
) => {
  const { children, template } = props

  const location = useLocation()

  return (
    <div
      className={cx(
        styles.wrapper,
        location?.pathname.includes('/register/scan')
          ? styles.registerScan
          : null,
        location?.pathname.includes('/register') &&
          !location?.pathname.includes('/register/scan')
          ? styles.register
          : null,
      )}
    >
      {children}
      {template !== 'single' ? <AppVersion className={styles.version} /> : null}
    </div>
  )
}

export default TemplateWrapper
