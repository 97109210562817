import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock } from 'src/mocks'

import { ApiReqResult, IGetSupplyReqObj } from 'src/interfaces'

const useGetSupplies = () => {
  return useMutation(async (data: IGetSupplyReqObj) => {
    //TODO must use useQuery
    const {
      multi,
      dateFrom,
      dateTo,
      counteragent,
      supplier,
      warehouse,
      status,
    } = data

    let path = `/receiving/supplies?`

    if (multi) {
      if (
        dateFrom ||
        dateTo ||
        counteragent ||
        supplier ||
        warehouse ||
        status
      ) {
        path += `multi=${multi}&`
      } else {
        path += `multi=${multi}`
      }
    }

    if (dateFrom) path += `date_from=${dateFrom}&`
    if (dateTo) path += `date_to=${dateTo}&`
    if (counteragent) path += `merchant_id=${counteragent}&`
    if (supplier) path += `supplier_id=${supplier}&`
    if (warehouse) path += `warehouse_id=${warehouse}&`
    if (status) path += `status=${status}`

    if (path[path.length - 1] === '&') {
      path = path.substring(0, path.length - 1)
    }

    // provideMock(
    //   async () => {
    //     const result = await api.get(path)
    //     if (!result.data?.success) throw new Error(result.data.message)
    //     return result.data
    //   })
    const result = await api.get(path)
    if (!result.data?.success) throw new Error(result.data.message)
    return result.data
  })
}

// return {
//   success: true,
//   receipts: [
//     {
//       id: 'mock',
//       date: '2021-07-15T14:23:11',
//       status: 'arrived',
//       counteragent: {
//         id: 'some-id',
//         title: 'ООО Зингер',
//       },
//       supplier: {
//         id: 'some-id',
//         title: 'ООО Поставщик',
//       },
//       warehouse: {
//         id: 'some-id',
//         title: 'Основной',
//       },
//     },
//     {
//       id: 'mock',
//       date: '2021-07-14T14:23:11',
//       status: 'process',
//       counteragent: {
//         id: 'some-id',
//         title: 'ООО Зингер',
//       },
//       supplier: {
//         id: 'some-id',
//         title: 'ООО Поставщик',
//       },
//       warehouse: {
//         id: 'some-id',
//         title: 'Основной',
//       },
//     },
//     {
//       id: 'mock',
//       date: '2021-07-15T14:23:11',
//       status: 'entered',
//       counteragent: {
//         id: 'some-id',
//         title: 'ООО Зингер',
//       },
//       supplier: {
//         id: 'some-id',
//         title: 'ООО Поставщик',
//       },
//       warehouse: {
//         id: 'some-id',
//         title: 'Основной',
//       },
//     },
//   ],
// }

export default useGetSupplies
